/**
 * Exposes utility methods for workspace dataitems.
 *
 * @module WorkspaceDataItemUtils
 * @version 1.0.0
 */
import { orderBy } from 'lodash-es';
import {
  IWorkspaceDataItem,
  IDataItemIdentity,
  IWorkspaceEnrichedDataItem,
  EOtherItemTypes,
  OTHER_ITEM_TYPES,
} from 'models/IWorkspaceData';
import { EMeshItemTypes } from 'models/IMeshes';
import { EGeometryItemTypes } from 'models/IGeometries';
import { EVariableItemTypes } from 'models/IVariables';
import { OPERATION_STATES, IOperationMetadata } from 'models/IOperations';
import WorkspaceOperationUtils from 'store/selectors/WorkspaceOperationUtils';
import WorkspaceGeometrySelectors, { simplyGetWorkspaceGeometries } from 'store/selectors/WorkspaceGeometrySelectors';
import WorkspaceVariableSelectors, { simplyGetWorkspaceVariables } from 'store/selectors/WorkspaceVariableSelectors';
import WorkspaceMeshSelectors, { simplygetWorkspaceMeshes } from 'store/selectors/WorkspaceMeshSelectors';
import { EElementCategories, ELEMENT_CATEGORIES } from 'src/shared/panels/mesh-panel-constants';
import { store } from '..';
import { EQueryItemTypes } from 'src/models/IQueries';

/**
 * Returns ids for the dataItems provided. If no dataItems are provided an empty array is returned.
 *
 * @param dataItems
 */
export const getIds = (dataItems: Array<IWorkspaceDataItem>) => {
  if (!dataItems) {
    return [];
  }

  const ids = (dataItems || []).map(({ id }) => id);

  return ids;
};

/**
 * Returns all dataItems having id corresponding to the given ids. If none is mtching an empty array is returned.
 *
 * @param dataItems
 * @param itemIds
 */
export const getDataItemsByIds = (dataItems: Array<IWorkspaceDataItem>, itemIds: Array<string>) => {
  if (!dataItems || !itemIds) {
    return [];
  }

  const filteredItems = dataItems.filter(({ id }) => itemIds.indexOf(id) !== -1);

  return filteredItems;
};

/**
 * Returns the dataItem having id corresponding to the given id. Returns null if not found
 *
 * @param dataItems
 * @param itemId
 */
export const getDataItemById = (dataItems: Array<IWorkspaceDataItem>, itemId: string): IWorkspaceDataItem => {
  if (!dataItems || !itemId) {
    return null;
  }

  const item = dataItems.find(({ id }) => id === itemId);

  return item || null;
};

/**
 * Returns all dataItems having dataId corresponding to the given dataIds. If none is matching an empty array is returned.
 *
 * @param dataItems
 * @param dataIds
 */
export const getDataItemsByDataIds = (dataItems: Array<IDataItemIdentity>, dataIds: Array<string>) => {
  if (!dataItems || !dataIds) {
    return [];
  }

  const filteredItems = dataItems.filter(({ dataId }) => dataIds.indexOf(dataId) !== -1);

  return filteredItems;
};

/**
 * Returns the dataId of dataItems having id corresponding to the given ids. If none is matching an empty array is returned.
 *
 * @param dataItems
 * @param itemIds
 */
export const getDataIdsByIds = (dataItems: Array<IWorkspaceDataItem>, itemIds: Array<string>) => {
  if (!dataItems || !itemIds) {
    return [];
  }

  const dataIds = dataItems.filter(({ id }) => itemIds.indexOf(id) !== -1).map(({ dataId }) => dataId);

  return dataIds;
};

/**
 * Get most recent updated data item.
 *
 * @param dataItems
 */
export const getMostRecentUpdated = (dataItems: Array<IWorkspaceDataItem>) => {
  if (!dataItems || !dataItems.length) {
    return null;
  }

  const sortByUpdated = (item) => new Date(item.updated);
  const sorted = orderBy(dataItems, sortByUpdated, ['desc']);

  return sorted[0];
};

/**
 * Get data items that do not have the state set to failed.
 *
 * @param dataItems
 */
export const getItemsNotFailed = (dataItems: Array<IWorkspaceEnrichedDataItem>) => {
  if (!dataItems || !dataItems.length) {
    return [];
  }

  const filteredItems = dataItems.filter((item) => item.state !== OPERATION_STATES.FAILED);

  return filteredItems;
};

/**
 * Enriches dataitems with metadata from latest operation having the item as output
 * Assumes all dataItems.
 * Important! Expects max one operation to match each item. If more matches, the first will be used.
 *
 *
 * Does conditional metadata enrichment based on the item category.
 * For now, meshes are treated differently than anything else.
 *
 * @param operations
 * @param dataItems
 * @param itemCategory
 */
export const getEnrichedDataItems = (
  operations: Array<IOperationMetadata>,
  dataItems: Array<IWorkspaceDataItem>,
  itemCategory: EElementCategories,
): Array<IWorkspaceEnrichedDataItem> => {
  if (itemCategory === ELEMENT_CATEGORIES.MESH) {
    console.warn('Using getEnrichedDataItems is not allowed for meshes. You should use getEnrichedMeshItems instead');
    return [];
  }

  if (!dataItems) {
    return [];
  }

  if (!operations) {
    return dataItems.map((item) => {
      return { ...item, category: itemCategory };
    });
  }

  return dataItems.map((item) => {
    const latestOperation = operations.find(({ outputIds }) => outputIds && outputIds.indexOf(item.id) !== -1);

    if (!latestOperation) {
      return { ...item, category: itemCategory };
    }
    let itemOperationMetadata;

    switch (itemCategory) {
      case ELEMENT_CATEGORIES.MESH:
        itemOperationMetadata = WorkspaceOperationUtils.getMeshItemOperationMetadata(latestOperation, [
          latestOperation,
        ]);

        break;

      default:
        itemOperationMetadata = WorkspaceOperationUtils.getItemOperationMetadata(latestOperation);
        break;
    }

    return {
      ...item,
      ...(itemOperationMetadata || {}),
      category: itemCategory,
    };
  });
};

/**
 * Enriches mesh items with metadata from currently applied operations  having the item as output.
 * Takes CreateMeshOperation and interpolations into special consideration
 *
 * @param createMeshOperations
 * @param currentOperations
 * @param dataItems
 */
export const getEnrichedMeshItems = (
  createMeshOperations: Array<IOperationMetadata>,
  currentOperations: Array<IOperationMetadata>,
  dataItems: Array<IWorkspaceDataItem>,
): Array<IWorkspaceEnrichedDataItem> => {
  if (!dataItems) {
    return [];
  }

  const category = EElementCategories.MESH;

  if (!createMeshOperations && (!currentOperations || currentOperations.length === 0)) {
    return dataItems.map((item) => {
      return { ...item, category };
    });
  }

  return dataItems.map((item) => {
    const lastCreateMeshOperation = createMeshOperations
      ? createMeshOperations.find(({ outputIds }) => outputIds && outputIds.indexOf(item.id) !== -1)
      : undefined;

    const currentItemOperations = currentOperations
      ? currentOperations.filter(({ outputIds }) => outputIds && outputIds.indexOf(item.id) !== -1)
      : undefined;

    if (!lastCreateMeshOperation && (!currentItemOperations || currentItemOperations.length === 0)) {
      return { ...item, category };
    }
    const itemOperationMetadata = WorkspaceOperationUtils.getMeshItemOperationMetadata(
      lastCreateMeshOperation,
      currentItemOperations,
    );

    return {
      ...item,
      ...(itemOperationMetadata || {}),
      category,
    };
  });
};

/**
 * Gets the itemType of an item from the store based on itemId and category.
 *
 * @param itemId
 * @param itemCategory
 */
export const getItemType = (
  itemId: string,
  itemCategory: EElementCategories,
): EMeshItemTypes | EGeometryItemTypes | EVariableItemTypes | EOtherItemTypes => {
  const state = store.getState();

  switch (itemCategory) {
    case ELEMENT_CATEGORIES.MESH: {
      const getMesh = WorkspaceMeshSelectors.makeGetMesh();
      const mesh = getMesh(state, { meshId: itemId }) || {};

      return mesh.itemType;
    }

    case ELEMENT_CATEGORIES.VARIABLE: {
      const getVariable = WorkspaceVariableSelectors.makeGetVariable();
      const variable = getVariable(state, { variableId: itemId }) || {};

      return variable.itemType;
    }

    case ELEMENT_CATEGORIES.GEOMETRY: {
      const getGeometry = WorkspaceGeometrySelectors.makeGetGeometry();
      const geometry = getGeometry(state, { geometryId: itemId }) || {};

      return geometry.itemType;
    }

    default:
      return OTHER_ITEM_TYPES.UNKNOWN;
  }
};

/**
 * Gets the name of an item from the store based on itemId
 *
 * @param itemId
 */
export const getItemName = (itemId: string): string => {
  const state = store.getState();

  const getMesh = WorkspaceMeshSelectors.makeGetMesh();
  const mesh = getMesh(state, { meshId: itemId });
  if (mesh) {
    return mesh.name;
  }

  const getVariable = WorkspaceVariableSelectors.makeGetVariable();
  const variable = getVariable(state, { variableId: itemId });
  if (variable) {
    return variable.name;
  }

  const getGeometry = WorkspaceGeometrySelectors.makeGetGeometry();
  const geometry = getGeometry(state, { geometryId: itemId });
  if (geometry) {
    return geometry.name;
  }

  return '';
};

/**
 * Get a data item's category by its id
 * @param itemId
 * @returns ELEMENT_CATEGORIES
 */
export const getItemCategory = (itemId: string) => {
  const mesh = simplygetWorkspaceMeshes().find((m) => m.id === itemId);
  if (mesh) {
    return ELEMENT_CATEGORIES.MESH;
  }
  const geom = simplyGetWorkspaceGeometries().find((g) => g.id === itemId);
  if (geom) {
    return ELEMENT_CATEGORIES.GEOMETRY;
  }
  const vari = simplyGetWorkspaceVariables().find((v) => v.id === itemId);
  if (vari) {
    return ELEMENT_CATEGORIES.VARIABLE;
  }
  return undefined;
};

/**
 * Gets the element category based on itemType.
 *
 * @param itemType
 */
export const getElementCategory = (
  itemType: EMeshItemTypes | EGeometryItemTypes | EVariableItemTypes | EOtherItemTypes | EQueryItemTypes,
): EElementCategories => {
  if (!itemType) {
    return undefined;
  }

  if (Object.values(EMeshItemTypes).includes(itemType as EMeshItemTypes)) {
    return EElementCategories.MESH;
  }

  if (Object.values(EGeometryItemTypes).includes(itemType as EGeometryItemTypes)) {
    return EElementCategories.GEOMETRY;
  }

  if (Object.values(EVariableItemTypes).includes(itemType as EVariableItemTypes)) {
    return EElementCategories.VARIABLE;
  }

  if (Object.values(EQueryItemTypes).includes(itemType as EQueryItemTypes)) {
    return EElementCategories.QUERY;
  }

  console.warn('Unknow element category for itemType', itemType);
  return undefined;
};

const self = {
  getIds,
  getDataItemsByIds,
  getDataItemById,
  getDataItemsByDataIds,
  getDataIdsByIds,
  getMostRecentUpdated,
  getItemsNotFailed,
  getEnrichedDataItems,
  getEnrichedMeshItems,
  getItemType,
  getItemCategory,
  getElementCategory,
};

export default self;
