import AppRoutes from 'src/app/app.routes';

const workspace = AppRoutes.workspace.path;
const commentsbase = `${workspace}/comments`;

export default {
  commentsPanel: {
    path: commentsbase,
  },
};
