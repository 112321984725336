export interface IGetDataset {
  name: string;
  description: string;
  datasetType: 'file' | 'multidimensional' | 'timeseries' | 'gisvectordata';
  projectId: string;
  dataPath?: string;
  metadata: object;
  properties: object;
  tags?: [string];
  temporalInformation: {
    startTime?: string;
    endTime?: string;
    interval?: string;
    resolution?: string;
  };
  spatialInformation: {
    location?: {};
    primarySpatialReference?: string;
    resolution?: string;
    srid?: number;
  };
  storageSize: number;
  datasetFormat: string;
  rowVersion: string;
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
  usageTypes: Array<EDATASETUSAGE_TYPE>;
}

export enum EDATASETUSAGE_TYPE {
  Unknown = 0,
  Geometry = 1,
  Variable = 2,
  GeometryAndVariable = 3,
  Mesh = 4,
  Workspace = 5,
}
