import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'src/translations/i18n';
import { IGlobalState, store } from 'src/store';
import { IGetCustomProject, IGetProject } from '@mike/mike-shared-frontend/mike-project-explorer/models/IGetProject';
import { IGetProjectPath } from 'src/models/IGetProjectPath';
import { EDATASETUSAGE_TYPE, IGetDataset } from 'src/models/IGetDataset';
import MikeProjectExplorer from '@mike/mike-shared-frontend/mike-project-explorer/MikeProjectExplorer';
import { getSortedPageRows } from '@mike/mike-shared-frontend/mike-project-explorer/TableComponents/support';
import {
  getProjectContent,
  setProjectContentDialogOpen,
  setProjectContent,
  setProjectContentPage,
  setProjectContentRowsPerPage,
  setProjectContentOrder,
  setProjectContentOrderBy,
} from 'src/store/actions/projectContent';
import { firstTableCellRender, projectContentColumns } from './projectContentColumns';
import { Dialog, DialogActions } from '@material-ui/core';
import { MikeButton } from '@mike/mike-shared-frontend';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MikeDialogTitle from '@mike/mike-shared-frontend/mike-project-explorer/DialogComponents/MikeDialogTitle';
import { css } from 'emotion';
import { theme } from 'src/shared/styles/theme';
import { IItemToReplace } from 'src/store/reducers/ProjectContentReducer';

type IProps = {
  onSelectedDatasetsChanged: (selDatasets: Array<IGetDataset>) => void;
};

const dialogPaperStyle = css`
  width: 75vw;
  height: 75vh;
  min-height: 75vh;
  max-width: 75vw;
`;

const titleStyle = css`
  padding: ${theme.spacing(1)}px;
  z-index: 11;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  height: ${theme.spacing(8)}px;
  color: ${theme.palette.background.paper};
  width: 100%;
`;

const dialogContentStyle = css`
  padding-top: 0;
  background-color: ${theme.palette.background.default};
`;

const dialogActionsStyle = css`
  padding-right: ${theme.spacing(3)}px;
  background-color: ${theme.palette.background.paper};
  color: ${theme.palette.background.paper};
  width: 100%;
  z-index: 11;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.16);
`;

/**
 * @name MmgProjectContentExplorer
 * @param props
 * @summary Component managing state of shared ProjectExplorer
 *
 */
const MmgProjectContentExplorer = (props: IProps) => {
  const { onSelectedDatasetsChanged } = props;

  const projectContentDialogIsOpen: boolean = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.projectContentDialogIsOpen,
  );

  const itemTypeToReplace: IItemToReplace | null = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.itemToReplace,
  );

  const loadingProjectContent: boolean = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.loadingProjectContent,
  );

  const currentNavigationProject: IGetProject | null = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.currentNavigationProject,
  );

  const currentNavigationProjectPath: IGetProjectPath[] = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.currentNavigationProjectPath,
  );

  const projectContent: Array<IGetProject | IGetDataset> = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.projectContent,
  );

  const projectContentDialogIsSelectable: boolean = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.projectContentDialogIsSelectable,
  );

  const page: number = useSelector((state: IGlobalState) => state.ProjectContentReducer.page);

  const rowsPerPage: number = useSelector((state: IGlobalState) => state.ProjectContentReducer.rowsPerPage);

  const order: 'asc' | 'desc' = useSelector((state: IGlobalState) => state.ProjectContentReducer.order);

  const orderBy: string | ((item: any) => string | number) = useSelector(
    (state: IGlobalState) => state.ProjectContentReducer.orderBy,
  );

  // When project content dialog is opened  we need to clear previously selected items
  useEffect(
    () => {
      if (projectContentDialogIsOpen) {
        setSelectedItems(Array<IGetDataset>());
      }
    },
    [projectContentDialogIsOpen],
  );

  const DATASETTYPE = 'datasetType';

  const onItemClick = useCallback((selectedItem: IGetProject | IGetProjectPath | IGetDataset | IGetCustomProject) => {
    // We skip onItemClick if a dataset has been clicked (only datasets do not have datasetType)
    const isDataset = DATASETTYPE in selectedItem;
    if (!isDataset) {
      const item: IGetProject | IGetProjectPath = selectedItem as IGetProject | IGetProjectPath;
      store.dispatch(setProjectContentPage(0));
      store.dispatch(getProjectContent(item));
    }
  }, []);

  const onCancel = useCallback(() => {
    store.dispatch(setProjectContent(Array<IGetDataset | IGetProject>(), null, null, Array<IGetProjectPath>()));
    store.dispatch(setProjectContentDialogOpen(false, false));
  }, []);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedItems, setSelectedItems] = useState([] as Array<IGetDataset>);
  const onSelecteditemsChanged = useCallback(
    (theSelectedItems: Array<IGetDataset>) => {
      const length = theSelectedItems.length;
      projectContentDialogIsSelectable && setButtonDisabled(length === 0);
      if (itemTypeToReplace !== null && length > 0) {
        const lastSelectedItem = theSelectedItems[length - 1];
        if (lastSelectedItem.usageTypes.includes(itemTypeToReplace.usageType)) {
          setSelectedItems([lastSelectedItem]);
        } else {
          store.dispatch({ type: 'toast/ADD/WARN', toast: { text: t('PROJECT_EXPLORER_WRONG_DATASET_FORMAT') } });
        }
      } else {
        setSelectedItems(theSelectedItems);
      }
    },
    [projectContentDialogIsSelectable, itemTypeToReplace],
  );

  // When project content dialog is opened  we need to clear previously selected items
  useEffect(
    () => {
      if (projectContentDialogIsOpen) {
        setButtonDisabled(projectContentDialogIsSelectable);
        setSelectedItems(Array<IGetDataset>());
      }
    },
    [projectContentDialogIsOpen, projectContentDialogIsSelectable],
  );

  // If we use v1 endpoints we have to do pagination, odering and filtering client-side
  // Currently we cannot switch to v2 endpoints as there is no endpoint
  // to get the whole project content (datasets and folders) by one call
  const [pageRows, setPageRows] = useState(Array<IGetProject | IGetDataset | IGetCustomProject>());

  const onHandleRequestSort = useCallback((newOrderBy: string, newOrder: 'asc' | 'desc') => {
    store.dispatch(setProjectContentPage(0));
    store.dispatch(setProjectContentOrder(newOrder));
    store.dispatch(setProjectContentOrderBy(newOrderBy));
  }, []);

  const onHandleChangePage = useCallback((newPage: number) => {
    store.dispatch(setProjectContentPage(newPage));
  }, []);

  const onHandleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {
    store.dispatch(setProjectContentRowsPerPage(newRowsPerPage));
  }, []);

  useEffect(
    () => {
      setPageRows(getSortedPageRows(projectContent, orderBy, order, page, rowsPerPage));
    },
    [projectContent, orderBy, order, page, rowsPerPage],
  );

  const projectExplorerTitle = t('WORKSPACE_SELECT_FILES');

  const okButtonLabel = useMemo(
    () => {
      if (itemTypeToReplace) {
        return t('REPLACE');
      } else {
        const selectionPostfix = selectedItems.length > 0 ? ' (' + selectedItems.length + ')' : '';
        return t('WORKSPACE_IMPORT') + selectionPostfix;
      }
    },
    [selectedItems, itemTypeToReplace],
  );

  const onOk = useCallback(
    () => {
      onSelectedDatasetsChanged && onSelectedDatasetsChanged(selectedItems);
      store.dispatch(setProjectContentDialogOpen(false, false));
    },
    [onSelectedDatasetsChanged, selectedItems],
  );

  return (
    <Dialog maxWidth={'lg'} onClose={onCancel} open={projectContentDialogIsOpen} classes={{ paper: dialogPaperStyle }}>
      <MuiDialogTitle className={titleStyle}>
        <MikeDialogTitle title={projectExplorerTitle} onClose={onCancel} />
      </MuiDialogTitle>
      <MuiDialogContent className={dialogContentStyle}>
        <MikeProjectExplorer
          customFirstTableCellRender={firstTableCellRender}
          columns={projectContentColumns}
          onChangePage={onHandleChangePage}
          onChangeRowsPerPage={onHandleChangeRowsPerPage}
          onHandleRequestSort={onHandleRequestSort}
          onItemClick={onItemClick}
          onSelectionChange={onSelecteditemsChanged}
          order={order}
          orderBy={orderBy}
          selectedItems={selectedItems}
          loading={loadingProjectContent}
          page={page}
          pagination={projectContent.length > 10}
          project={currentNavigationProject !== null ? currentNavigationProject : undefined}
          projectContent={pageRows}
          projectPath={currentNavigationProjectPath}
          rowsPerPage={rowsPerPage}
          selectable={true}
          totalCount={projectContent.length}
        />
      </MuiDialogContent>
      <DialogActions className={dialogActionsStyle}>
        <MikeButton onClick={onCancel} buttontype="text">
          {'Cancel'}
        </MikeButton>
        <MikeButton onClick={onOk} buttontype="primary" disabled={buttonDisabled}>
          {okButtonLabel}
        </MikeButton>
      </DialogActions>
    </Dialog>
  );
};

export default MmgProjectContentExplorer;
