import { put, select, takeEvery, delay, all, call } from 'redux-saga/effects';
import { IBounds } from 'src/models/IWorkspaceNotifications';
import {
  getHiddenMeshTiles,
  getViewBounds,
  getWorkspaceFieldStatistics,
  getWorkspaceMeshTiles,
  getWorkspaceMeshTilesById,
} from 'src/store/selectors/WorkspaceDrawnDataSelectors';
import { IWorkspaceMeshTiles, RANGEMAX, RANGEMIN, UNIQUEVALUES } from 'src/store/reducers/WorkspaceMeshTilesReducer';
import { isEqual, uniq } from 'lodash-es';
import { EWorkspaceMeshActionType } from 'src/store/actions/WorkspaceMeshActionType';
import MikeVisualizerViewManager from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerViewManager';
// import MikeVisualizerMapshaper from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerMapShaper';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { fetchTiledLayer } from 'src/shared/layers/layer-utils';
import { Feature } from 'geojson';
import { RECENT_WORKSPACES_BOUNDS } from 'src/store/reducers/WorkspaceReducer';
import { getRouteParams } from './selectors';
import { HttpErrorHandler } from 'src/managers/http-utils';
import { getTiledMeshFieldStatistics } from 'src/proxies/WorkspaceMeshesProxy';
import { ISettingsLayer, IUserSettings } from 'src/models/IUserSettings';
import { getUserSettings } from 'src/store/selectors/WorkspaceSelectors';
import { updateWorkspaceDataStatisticAttributeName } from 'src/store/actions/workspaceDataActions';

const { showElements } = MikeVisualizerLib;
interface IStatistic {
  id: string;
  value: string;
  values: Array<number>;
}

export default function* watchMeshTiles() {
  yield takeEvery(EWorkspaceMeshActionType.CHECK_VIEW_BOUNDS, handleNewViewBounds);
  yield takeEvery(EWorkspaceMeshActionType.SET_STREAMING_BOUNDS, updateStreamingBounds);
  yield takeEvery(EWorkspaceMeshActionType.SHOW_MESH_TILES, handleShowMeshTiles);
  yield takeEvery(EWorkspaceMeshActionType.GET_TILED_MESH_FIELD_STATISTICS, handleGetFieldStatistics);
}

function* handleGetFieldStatistics(action) {
  const { workspaceId } = yield select(getRouteParams);
  const meshId = action.data;
  const allStatistics = yield select(getWorkspaceFieldStatistics);
  const fieldStatistic = allStatistics && allStatistics[meshId]; //const fieldStatistic = yield select(getWorkspaceFieldDataStatisticsById, { itemId: meshId });
  if (!fieldStatistic) {
    try {
      yield put({ type: EWorkspaceMeshActionType.LOADING_TILED_MESH_FIELD_STATISTICS, data: true });
      const response = yield call(getTiledMeshFieldStatistics, workspaceId, meshId);
      if (response && response.status === 200 && response.data) {
        const statistics = response.data;
        const fieldStatistics = statistics.filter(
          (fs: IStatistic) => !fs.id.endsWith(RANGEMIN) && !fs.id.endsWith(RANGEMAX) && !fs.id.endsWith(UNIQUEVALUES),
        );
        const minStatistics = statistics.filter((fs: IStatistic) => fs.id.endsWith(RANGEMIN));
        const maxStatistics = statistics.filter((fs: IStatistic) => fs.id.endsWith(RANGEMAX));
        const dataArraysWithRange = minStatistics.map((fs: IStatistic) => {
          const name = fs.id.replace(RANGEMIN, '');
          const maxStatistic = maxStatistics.find((max: IStatistic) => max.id === name + RANGEMAX);
          const minValue = Number(fs.value);
          const maxValue = maxStatistic !== undefined ? Number(maxStatistic.value) : Number(fs.value);
          return {
            id: name,
            value: '',
            range: [minValue, maxValue],
          };
        });
        const dataArraysWithUniqueValues = statistics.filter((fs: IStatistic) => fs.id.endsWith(UNIQUEVALUES));
        const dataArraysUniqueValues = dataArraysWithUniqueValues.map((fs: IStatistic) => {
          const vals = fs.values;
          const sorted = vals.sort();
          return {
            id: fs.id.replace(UNIQUEVALUES, ''),
            value: '',
            range: [sorted[0], sorted[sorted.length - 1]],
            uniqueValues: sorted,
          };
        });
        const dataArrays = dataArraysWithRange.concat(dataArraysUniqueValues);
        yield put({
          type: EWorkspaceMeshActionType.SET_TILED_MESH_FIELD_STATISTICS,
          data: { fieldStatistics, dataArrays, meshId },
        });
      }
    } catch (error) {
      HttpErrorHandler('Failed to fetch field statistics for tiled mesh.', error);
    } finally {
      yield put({ type: EWorkspaceMeshActionType.LOADING_TILED_MESH_FIELD_STATISTICS, data: false });
    }
  }
  const settings: IUserSettings = yield select(getUserSettings);
  const settingsLayer: ISettingsLayer = settings.tableOfContents.layers.find(
    (l: ISettingsLayer) => l.vtkItemId === meshId,
  );
  const gradientAttributeName =
    settingsLayer && settingsLayer.renderedAttribute ? settingsLayer.renderedAttribute : null;

  if (gradientAttributeName) {
    updateWorkspaceDataStatisticAttributeName(meshId, gradientAttributeName, false, true);
  }
}

const boundsToFeature = (b: IBounds) => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [[b.minX, b.minY], [b.minX, b.maxY], [b.maxX, b.maxY], [b.maxX, b.minY], [b.minX, b.minY]],
    },
  } as Feature<any, any>;
};

function* handleNewViewBounds(action) {
  const wait = action.data; // optionally wait e.g. after zoom button's zoom event
  if (wait) {
    delay(wait);
  }
  const { workspaceId } = yield select(getRouteParams);
  const previousViewBounds = yield select(getViewBounds);
  const hiddenMeshTiles = yield select(getHiddenMeshTiles);
  const { getCurrentViewBounds } = MikeVisualizerViewManager;
  const newBounds = getCurrentViewBounds();
  const recentBounds = localStorage.getItem(RECENT_WORKSPACES_BOUNDS);
  const rb = recentBounds ? JSON.parse(recentBounds) : {};
  const viewerBounds = [newBounds[0][0], newBounds[2][0], newBounds[0][1], newBounds[1][1], 0, 0];
  const updatedBounds = { ...rb, [workspaceId]: viewerBounds };
  localStorage.setItem(RECENT_WORKSPACES_BOUNDS, JSON.stringify(updatedBounds));
  const previousViewerBounds =
    previousViewBounds.length > 3
      ? [previousViewBounds[0][0], previousViewBounds[2][0], previousViewBounds[0][1], previousViewBounds[1][1], 0, 0]
      : [];
  if (!isEqual(previousViewerBounds, viewerBounds)) {
    yield put({ type: EWorkspaceMeshActionType.SET_VIEW_BOUNDS, data: newBounds });

    const meshTiles = yield select(getWorkspaceMeshTiles);
    const meshIds = Object.keys(meshTiles);
    const visibleMeshIds = meshIds.filter((id: string) => !hiddenMeshTiles.includes(id));
    yield all(
      visibleMeshIds.map((meshId: string) =>
        put({
          type: EWorkspaceMeshActionType.SET_STREAMING_BOUNDS,
          data: { meshId, newBounds },
        }),
      ),
    );
  }
}

const boundsChanged = (currentBounds: Array<Array<number>>, compareBounds: IBounds) => {
  const bounds = {
    minX: currentBounds[0][0],
    minY: currentBounds[0][1],
    maxX: currentBounds[2][0],
    maxY: currentBounds[2][1],
  };
  const lastBounds = {
    minX: compareBounds.minX,
    minY: compareBounds.minY,
    maxX: compareBounds.maxX,
    maxY: compareBounds.maxY,
  };
  return !isEqual(bounds, lastBounds);
};

function* handleShowMeshTiles(action) {
  const meshIds = action.data;

  const { getCurrentViewBounds } = MikeVisualizerViewManager;
  const newBounds = getCurrentViewBounds();

  const meshTiles: { [key: string]: Array<IWorkspaceMeshTiles> } = yield select(getWorkspaceMeshTiles);
  let meshIdsToProcess = [];
  meshIds.forEach((id: string) => {
    const mt = meshTiles[id];
    if (mt && mt.length > 0) {
      const lastBounds: IWorkspaceMeshTiles = mt[mt.length - 1];
      if (boundsChanged(newBounds, lastBounds.bounds)) {
        meshIdsToProcess = [...meshIdsToProcess, id];
      } else {
        showElements(uniq(lastBounds.partIds));
      }
    } else {
      meshIdsToProcess = [...meshIdsToProcess, id];
    }
  });

  yield all(
    meshIdsToProcess.map((meshId: string) =>
      put({
        type: EWorkspaceMeshActionType.SET_STREAMING_BOUNDS,
        data: { meshId, newBounds },
      }),
    ),
  );
}

function* updateStreamingBounds(action) {
  const { meshId, newBounds } = action.data;
  const hiddenMeshTiles = yield select(getHiddenMeshTiles);

  if (hiddenMeshTiles.includes(meshId)) {
    return;
  }
  const mtb = yield select(getWorkspaceMeshTilesById, { itemId: meshId });
  const overview = mtb ? mtb.find((b: IWorkspaceMeshTiles) => b.isOverview) : undefined;
  const previousBounds = mtb
    ? mtb.filter((b: IWorkspaceMeshTiles) => !b.isOverview).map((b: IWorkspaceMeshTiles) => b.bounds)
    : [];
  fetchTiledLayer(meshId, previousBounds, newBounds, overview !== undefined);
}
