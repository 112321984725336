export enum EWorkspaceMeshActionType {
  LOAD = 'workspace/meshes/LOAD',
  UPDATE_USER_HIDDEN = 'workspace/meshes/UPDATE_USER_HIDDEN',
  SHOW_ITEM_ONLY = 'workspace/meshes/SHOW_ITEM_ONLY',
  SHOW_ALL_BUT_USER_HIDDEN = 'workspace/meshes/SHOW_ALL_BUT_USER_HIDDEN',
  HIDE_ALL = 'workspace/meshes/HIDE_ALL',
  SHOW_ALL = 'workspace/meshes/SHOW_ALL',
  SHOW_ITEM = 'workspace/meshes/SHOW_ITEM',
  HIDE_ITEM = 'workspace/meshes/HIDE_ITEM',
  HIDE_ITEMS = 'workspace/meshes/HIDE_ITEMS',
  SELECT = 'workspace/meshes/SELECT',
  EDIT_NODES = 'maptools/mesh/EDIT_NODES',
  GET_NODE_NEIGHBOURS = 'workspace/meshes/GET_NODE_NEIGHBOURS',
  LOADING_NODE_NEIGHBOURS = 'workspace/meshes/LOADING_NODE_NEIGHBOURS',
  GET_NODE_NEIGHBOURS_FAILED = 'workspace/meshes/GET_NODE_NEIGHBOURS_FAILED',
  SET_NODE_NEIGHBOURS = 'workspace/meshes/SET_NODE_NEIGHBOURS',
  SUBMIT_NODE_UPDATE = 'workspace/meshes/SUBMIT_NODE_UPDATE',
  UNDO_NODE_SUBMIT = 'workspace/meshes/UNDO_NODE_SUBMIT',
  FINISH_NODE_UPDATES = 'workspace/meshes/FINISH_NODE_UPDATES',
  SAVE_NODE_UPDATES = 'workspace/meshes/SAVENODE_UPDATES',
  SAVE_NODE_UPDATES_FAILED = 'workspace/meshes/SAVE_NODE_UPDATES_FAILED',
  SAVE_NODE_UPDATES_SUCCEEDED = 'workspace/meshes/SAVE_NODE_UPDATES_SUCCEEDED',
  SET_NEW_NODE_COORDS = 'workspace/meshes/SET_NEW_NODE_COORDS',
  SET_COLOR_FROM_MESH_SURFACE = 'workspace/meshes/SET_COLOR_FROM_MESH_SURFACE',
  ADD_MESH_TILE_BOUNDS = 'workspace/meshes/ADD_MESH_TILE_BOUNDS',
  REMOVE_FIRST_MESH_TILE_BOUNDS = 'workspace/meshes/REMOVE_FIRST_MESH_TILE_BOUNDS',
  CHECK_VIEW_BOUNDS = 'workspace/meshes/CHECK_VIEW_BOUNDS',
  SET_VIEW_BOUNDS = 'workspace/meshes/SET_VIEW_BOUNDS',
  SET_STREAMING_BOUNDS = 'workspace/meshes/SET_STREAMING_BOUNDS',
  INIT_HIDDEN_MESH_TILES = 'workspace/meshes/INIT_HIDDEN_MESH_TILES',
  HIDE_MESH_TILES = 'workspace/meshes/HIDE_MESH_TILES',
  SHOW_MESH_TILES = 'workspace/meshes/SHOW_MESH_TILES',
  GET_TILED_MESH_FIELD_STATISTICS = 'workspace/meshes/GET_TILED_MESH_FIELD_STATISTICS',
  SET_TILED_MESH_FIELD_STATISTICS = 'workspace/meshes/SET_TILED_MESH_FIELD_STATISTICS',
  CLEAR_TILED_MESH_FIELD_STATISTICS = 'workspace/meshes/CLEAR_TILED_MESH_FIELD_STATISTICS',
  LOADING_TILED_MESH_FIELD_STATISTICS = 'workspace/meshes/LOADING_TILED_MESH_FIELD_STATISTICS',
  SET_TILED_MESH_DATA_STATISTICS = 'workspace/meshes/SET_TILED_MESH_DATA_STATISTICS',
  ADD_STREAMING_ID = 'workspace/meshes/ADD_STREAMING_ID',
}
