/**
 * Exposes methods to transform or combine workspace requests.
 *
 * @module WorkspaceManager
 * @version 2.0.0
 * @requires WorkspaceProxy
 */
import * as WorkspaceProxy from 'proxies/WorkspaceProxy';
import { HttpErrorHandler } from './http-utils';
import { IWorkspace, IWorkspaceDataset, IWorkspaceOverview } from 'models/IWorkspaces';
import { EDATASETUSAGE_TYPE } from 'src/models/IGetDataset';

/**
 * Transforms a request that gets a workspace overview.
 *
 * @param workspaceId
 * @param includeScreenshots
 */
const getWorkspaceOverview = (workspaceId: string, includeScreenshots = false): Promise<IWorkspaceOverview> => {
  return WorkspaceProxy.getWorkspaceOverview(workspaceId, includeScreenshots)
    .then((res) => {
      const workspace = res.data;

      return {
        ...workspace,
        description: workspace.description || '',
      };
    })
    .catch((error) => HttpErrorHandler('Failed to get workspace overview.', error));
};

/**
 * Transforms a request that gets a workspace.
 *
 * @param workspaceId
 */
const getWorkspace = (workspaceId: string): Promise<IWorkspace> => {
  return WorkspaceProxy.getWorkspace(workspaceId)
    .then((res) => {
      const workspace = res.data;

      return {
        ...workspace,
        description: workspace.description || '',
      };
    })
    .catch((error) => HttpErrorHandler('Failed to get workspace.', error));
};

export const mapDatasets = (datasets: Array<IWorkspaceDataset>) => {
  const keys = Object.keys(EDATASETUSAGE_TYPE).filter((x) => Number.isNaN(Number(x)));
  let data = datasets.map((ds: IWorkspaceDataset) => {
    return { ...ds, usageType: keys[ds.usageType] };
  });
  // To create geometry and variable in one go we need to post same datasets twice
  const varsAndGeoms = data.filter(
    (ds: IWorkspaceDataset) => ds.usageType === keys[EDATASETUSAGE_TYPE.GeometryAndVariable],
  );
  if (varsAndGeoms.length > 0) {
    data = data.filter((ds: IWorkspaceDataset) => ds.usageType !== keys[EDATASETUSAGE_TYPE.GeometryAndVariable]);
    data = data.concat(
      varsAndGeoms.map((ds: IWorkspaceDataset) => {
        return { ...ds, usageType: keys[EDATASETUSAGE_TYPE.Geometry] };
      }),
    );
    data = data.concat(
      varsAndGeoms.map((ds: IWorkspaceDataset) => {
        return { ...ds, usageType: keys[EDATASETUSAGE_TYPE.Variable] };
      }),
    );
  }
  return data;
};

/**
 * Transforms a request that creates a workspace.
 *
 * @param projectId
 * @param name
 * @param description
 * @param epsgCode
 * @param datasets
 */
const createWorkspace = (
  projectId: string,
  name: string,
  description?: string,
  epsgCode?: number,
  datasets: Array<IWorkspaceDataset> = [],
): Promise<IWorkspace> => {
  const data = mapDatasets(datasets);
  const workspace = {
    projectId,
    name,
    description,
    datasets: data,
    epsgCode,
  };

  return WorkspaceProxy.createWorkspace(workspace)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to create workspace.', error));
};

/**
 * Transforms a request that deletes a workspace.
 *
 * @param workspaceId The workspace to delete.
 *
 * @return { Promise<Object> }
 */
const deleteWorkspace = (workspaceId) => {
  return WorkspaceProxy.deleteWorkspace(workspaceId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to delete workspace.', error));
};

const createSnapshot = (workspaceId: string, isSupportSnapshot = true) => {
  return WorkspaceProxy.createWorkspaceSnapshot(workspaceId, isSupportSnapshot)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to create workspace snapshot.', error));
};

/**
 * Check if a workspace exists.
 *
 * @param workspaceId
 *
 * @returns AxiosResponse<boolean>.
 */
const doesWorkspaceExist = async (workspaceId: string) => {
  return WorkspaceProxy.doesWorkspaceExist(workspaceId);
};

/**
 * Check if an item exists within a workspace.
 *
 * @param workspaceId
 * @param itemId
 *
 * @returns AxiosResponse<boolean>.
 */
const doesWorkspaceItemExist = async (workspaceId: string, itemId: string) => {
  return WorkspaceProxy.doesWorkspaceItemExist(workspaceId, itemId);
};

const self = {
  getWorkspaceOverview,
  getWorkspace,
  createWorkspace,
  deleteWorkspace,
  doesWorkspaceExist,
  doesWorkspaceItemExist,
  createSnapshot,
};

export default self;
