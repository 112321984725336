import { all } from 'redux-saga/effects';
import watchExportableVariables from './exportableVariablesSagas';

/**
 * Exposes all available sagas for variables
 *
 * todo hevo: consider if aný of the other root saga patterns is better?:  https://redux-saga.js.org/docs/advanced/RootSaga.html
 */
export default function* variableSagas() {
  yield all([...watchExportableVariables()]);
}
