export enum EWorkspaceQueryActionType {
  LOAD = 'workspace/queries/LOAD',
  LOAD_SUCCESS = 'workspace/queries/LOAD_SUCCESS',
  ITEMS_DELETED = 'workspace/queries/ITEMS_DELETED',
  ITEM_ADDED_OR_UPDATED = 'workspace/queries/ITEM_ADDED_OR_UPDATED',
  SELECTION_GEOMETRY_ADD = 'workspace/queries/SELECTION_GEOMETRY_ADD',
  SELECTION_RESULT_ADD = 'workspace/queries/SELECTION_RESULT_ADD',
  SELECTION_RESULT_SHOW = 'workspace/queries/SELECTION_RESULT_SHOW',
  SELECTION_RESULT_AND_GEOMETRY_SHOW = 'workspace/queries/SELECTION_RESULT_AND_GEOMETRY_SHOW',
  SHOWN_SELECTION_GEOMETRY_ADD = 'workspace/queries/SHOWN_SELECTION_GEOMETRY_ADD',
  SELECTION_GEOMETRY_REMOVE = 'workspace/queries/SELECTION_GEOMETRY_REMOVE',
  SHOWN_SELECTION_GEOMETRY_REMOVE = 'workspace/queries/SHOWN_SELECTION_GEOMETRY_REMOVE',
  SELECTION_RESULT_TOGGLE_VISIBILITY = 'workspace/queries/SELECTION_RESULT_TOGGLE_VISIBILITY',
  OTHER_SELECTIONS_HIDE = 'workspace/queries/OTHER_SELECTIONS_HIDE',
  OTHER_SELECTIONS_RESHOW = 'workspace/queries/OTHER_SELECTIONS_RESHOW',
  SELECTIONS_HIDE_TEMPORARELY = 'workspace/queries/SELECTIONS_HIDE_TEMPORARELY',
  SELECTIONS_HIDE_DUE_TO_INVISIBLE_PARENT = 'workspace/queries/SELECTIONS_HIDE_DUE_TO_INVISIBLE_PARENT',
  SELECTIONS_RESHOW_DUE_TO_REVISIBLE_PARENT = 'workspace/queries/SELECTIONS_RESHOW_DUE_TO_REVISIBLE_PARENT',
  SELECTIONS_RE_ADD_LABELS = 'workspace/queries/SELECTIONS_RE_ADD_LABELS',
  SELECTIONS_RE_ADD_LABEL = 'workspace/queries/SELECTIONS_RE_ADD_LABEL',
  QUERY_COMPLETED = 'workspace/query/COMPLETED',
}
