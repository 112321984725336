import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES } from 'src/app/routes';
import { IGlobalState } from 'store/store';
import { t } from 'src/translations/i18n';
import { css } from 'emotion';

import { logger } from 'managers/http-utils';
import { logDebugProperties } from './app-util';

import ConfigurationManager, { IMmgConfiguration } from 'managers/ConfigurationManager';

import { MmgTopbar } from 'src/app/topbar/topbar';
import { MmgOfflineOverlay } from 'src/app/offline/offline';
import { MmgShell } from 'src/app/Shell';
import MmgConnectedWorkspaceList from 'src/workspaces/list/workspace-list';
import MmgConnectedWorkspace from 'src/workspaces/workspace';
// import { MmgConnectedWorkspaceSettingsPersitor } from 'src/workspaces/localSettings/workspace-settings-persistor';
import LandingPage from 'src/app/login/LandingPage';
import { MmgAnimatedIcon } from './animated-icon';
import { MmgConnectedHomePage } from 'src/app/homepage/homepage';
import { MmgConnectedErrorPage } from 'src/app/errorpage/errorpage';
import { MmgConnectedNotFoundPage } from 'src/app/notfoundpage/notfoundpage';
import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { INotification } from '@mike/mike-shared-frontend/mike-notification-list/INotification';
import { MmgProjectReadOnlyBanner } from 'src/projects/permissions/project-readonly-banner';
import { IProject } from 'src/models/IProject';
import { IApplications } from '@mike/mike-shared-frontend/mike-subscribed-apps/model/IApplications';
import { loadApplications } from 'store/actions/applications';
import { IApplicationsState } from 'src/store/reducers/ApplicationsReducer';
import { INotificationState } from 'src/store/reducers/NotificationReducer';
import MmgConnectedWorkspaceEditPage from 'src/workspaces/edit/workspace-edit-page';

import { MmgConnectedSnackbar } from 'src/shared/snackbar/snackbar';
import MikeButton from '@mike/mike-shared-frontend/mike-button/MikeButton';
import { useSelector } from 'react-redux';
import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc';
import { IUser } from 'src/models/IUser';
import { loadTenants } from 'src/store/actions/iam';
import AuthenticationCallback from './login/AuthenticationCallback';
import { IUserState } from 'src/store/reducers/UserReducer';
import qs from 'qs';

const ShellRender = (RouteComponent) => (route) => <MmgShell RouteComponent={RouteComponent} route={route} />;

const LoginPage = () => {
  return <LandingPage />;
};

const ToastyJSX = () => <MmgConnectedSnackbar />;

const ShellErrorPage = ShellRender(MmgConnectedErrorPage);
const ShellLoginPage = ShellRender(LoginPage);
const ShellHomePage = ShellRender(MmgConnectedHomePage);
const ShellMmgConnectedWorkspaceList = ShellRender(MmgConnectedWorkspaceList);
const ShellWorkspaceEditPage = ShellRender(MmgConnectedWorkspaceEditPage);
const ShellMmgConnectedWorkspace = ShellRender(MmgConnectedWorkspace);
const ShellNotFoundPage = ShellRender(MmgConnectedNotFoundPage);

const configMessageStyle = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const commonComponentsStyle = css`
  width: 100vw;
`;

const messageComponentsStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ConnectedApp = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, login } = useOidc();
  const { idToken, idTokenPayload } = useOidcIdToken();
  const { search, pathname } = useLocation();

  const progressItems: IProgressItem[] = useSelector((state: IGlobalState) => state.ProgressItemsReducer);
  const notifications: INotification[] = useSelector(
    (state: IGlobalState) => (state.NotificationReducer as INotificationState).notifications,
  );
  const hideBlueDot: boolean = useSelector(
    (state: IGlobalState) => (state.NotificationReducer as INotificationState).hideBlueDot,
  );
  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const projectViewOnlyWarningDismissed = useSelector(
    (state: IGlobalState) => state.ProjectReducer.projectViewOnlyWarningDismissed,
  );
  const applications: IApplications = useSelector(
    (state: IGlobalState) => (state.ApplicationsReducer as IApplicationsState).applications,
  );
  const loadingApplications: boolean = useSelector(
    (state: IGlobalState) => (state.ApplicationsReducer as IApplicationsState).loadingApplications,
  );

  useEffect(() => {
    if (document.location.hostname.endsWith('mike-cloud.com')) {
      const _paq = (window._paq = window._paq || []);
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['setDocumentTitle', document.location.hostname + '/' + document.title]);
      _paq.push(['setCookieDomain', document.location.hostname]);
      _paq.push(['setDomains', [document.location.hostname]]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      const u = 'https://dhigroup.matomo.cloud/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '8']);
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = '//cdn.matomo.cloud/dhigroup.matomo.cloud/matomo.js';
      s.parentNode.insertBefore(g, s);
    }
  }, []);

  const user: IUser = useSelector((state: IGlobalState) => (state.UserReducer as IUserState).user);

  const [, setConfigurationLoading] = useState(false);
  const [configurationLoadingFailed, setConfigurationLoadingFailed] = useState(false);
  const [configuration, setConfiguration] = useState<IMmgConfiguration | null>(null);

  const getConfiguration = () => {
    setConfigurationLoading(true);

    return ConfigurationManager.getConfiguration()
      .then((config) => {
        dispatch({ type: 'app/SET_CONFIGURATION', configuration: config });
        setConfiguration(config);

        logDebugProperties();
        // we log the entire configuration to make them searchable in application insights
        logger.info('Configuration loaded', config);
      })
      .catch(() => {
        setConfigurationLoadingFailed(true);

        const toast = {
          text: t('FAILED_TO_LOAD_CONFIGURATION_DESC'),
          operationId: 'CONF',
        };

        dispatch({ type: 'toast/ADD/ERROR', toast });
      })
      .finally(() => {
        setConfigurationLoading(false);
      });
  };

  const onDismissProjectReadOnlyWarning = () => {
    const dismiss = true;
    dispatch({ type: 'project/DISMISS_VIEWONLY_WARNUNG', dismiss });
  };

  useEffect(() => {
    getConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (user) {
        if (search) {
          const pathAndSearch = search.length > 1 ? pathname + search : pathname;
          const parameters = qs.parse(search.replace('?', '')) || {};
          if (parameters.tenantId && parameters.tenantId !== user.tenantId) {
            const extras = {
              prompt: 'none',
              tenantId: parameters.tenantId.toString(),
            };
            login(pathAndSearch, extras);
          }
        }
      }
    },
    [user, login, search, pathname],
  );

  useEffect(
    () => {
      if (user) {
        const _paq = (window._paq = window._paq || []);
        _paq.push(['setUserId', user.email]);
        _paq.push(['trackPageView']);
        _paq.push(['enableHeartBeatTimer']);
      }
    },
    [user],
  );

  useEffect(
    () => {
      function getUserProfile(userData: any) {
        const userProfile: IUser = {
          id: userData.oid,
          tenantId: userData.TenantId,
          tenantName: userData.TenantName,
          name: userData.sub,
          initials: userData.sub
            .split(' ')
            .map((n) => n[0])
            .join(''),
          email: userData.email,
          roles: userData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
            ? [userData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']]
            : [],
        };
        return userProfile;
      }
      if (idToken) {
        const userProfile = getUserProfile(idTokenPayload);
        dispatch({ type: 'user/LOGIN', user: userProfile });
      }
    },
    [dispatch, idToken, idTokenPayload],
  );

  useEffect(
    () => {
      if (applications === null && loadingApplications === false) {
        if (isAuthenticated) {
          dispatch(loadTenants());
          dispatch(loadApplications());
        }
      }
    },
    [isAuthenticated, applications, loadingApplications, dispatch],
  );

  const CommonAppComponents = (
    <div className={commonComponentsStyle}>
      {isAuthenticated && (
        <MmgTopbar
          configuration={configuration}
          hideBlueDot={hideBlueDot}
          notifications={notifications}
          progressItems={progressItems}
          applications={applications}
          loadingApplications={loadingApplications}
        />
      )}

      <MmgProjectReadOnlyBanner
        project={project}
        dismissed={projectViewOnlyWarningDismissed}
        onDismissCallBack={onDismissProjectReadOnlyWarning}
      />

      <ToastyJSX />
      <MmgOfflineOverlay />
      {/* <MmgConnectedWorkspaceSettingsPersitor /> */}
    </div>
  );

  if (!configuration) {
    if (!configurationLoadingFailed) {
      return (
        <section className={configMessageStyle}>
          {CommonAppComponents}

          <MmgAnimatedIcon />
        </section>
      );
    }

    if (configurationLoadingFailed) {
      return (
        <section className={configMessageStyle}>
          {CommonAppComponents}
          <div className={messageComponentsStyle}>
            <h4>{t('FAILED_TO_LOAD_CONFIGURATION')}</h4>
            <p>{t('FAILED_TO_LOAD_CONFIGURATION_DESC')}</p>
            <MikeButton onClick={getConfiguration}>{t('RETRY')}</MikeButton>
          </div>
          <div />
        </section>
      );
    }
  }

  if (!isAuthenticated) {
    return (
      <div>
        {CommonAppComponents}
        <Switch>
          <Route path={ROUTES.error.path} exact component={ShellErrorPage} />
          <Route component={ShellLoginPage} />
        </Switch>
      </div>
    );
  }

  return (
    <div>
      {CommonAppComponents}

      <Switch>
        <Route path={ROUTES.home.path} exact component={ShellHomePage} />
        <Route path="/authentication/callback" exact component={AuthenticationCallback} />
        <Route path={ROUTES.error.path} exact component={ShellErrorPage} />
        <Route path={ROUTES.login.path} exact component={ShellLoginPage} />
        <Route path={ROUTES.workspaceList.path} exact component={ShellMmgConnectedWorkspaceList} />
        <Route path={ROUTES.workspaceEdit.path} exact component={ShellWorkspaceEditPage} />
        <Route path={ROUTES.workspace.path} component={ShellMmgConnectedWorkspace} />
        <Route component={ShellNotFoundPage} />
      </Switch>
    </div>
  );
};
