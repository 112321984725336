import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { t } from 'src/translations/i18n';
import { IGlobalState, store } from 'store/store';
import { orderBy, omit } from 'lodash-es';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import TextField from '@material-ui/core/TextField';
import { MmgPanelHeader } from 'src/shared/panels/panel-header';
import { MikeStickyPanelHeaderContainer } from '@mike/mike-shared-frontend/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import { MmgPanelSubsection } from 'src/shared/panels/panel-subsection';
import { MmgProjectPermissionsMessage } from 'src/projects/permissions/project-permission-message';
import { MmgConnectedProjectSelector } from 'src/projects/project-selector';
import MikeStickyPanel from '@mike/mike-shared-frontend/mike-sticky-panel/MikeStickyPanel';

import { IWorkspaceDataset, IWorkspace, WORKSPACE_DEFAULT_PROJECTION } from 'models/IWorkspaces';
import WorkspaceManager from 'managers/WorkspaceManager';
import WorkspaceUtils from 'src/workspaces/workspace-utils';
import { ROUTES } from 'src/app/routes';
import MikeStickyPanelContent from '@mike/mike-shared-frontend/mike-sticky-panel/MikeStickyPanelContent';
import MmgWorkspaceCreateDataSetType from './workspace-create-dataset-types';
import { MikeStickyPanelBottomActions } from '@mike/mike-shared-frontend/mike-sticky-panel/MikeStickyPanelBottomActions';
import MikeButton from '@mike/mike-shared-frontend/mike-button';
import { IProjection } from '../../models/IProjections';
import MmgProjectContentButton from 'src/project-datasets/project-content-button';
import { useSelector } from 'react-redux';
import { IGetProject } from '@mike/mike-shared-frontend/mike-project-selector/model';
import { getProjectAndParent } from 'src/store/actions/projectContent';

interface ICreateWorkspaceState extends IWorkspace {
  epsgCodeHasBeenSelectedByUser: boolean;
}

const { routeToPanel } = WorkspaceUtils;

type WorkspaceCreatePanelProps = {
  projectId: string;
  onPanelExit?: () => void;
  loadingProjectionSystems: boolean;
  searchProjectionSystemsById: boolean;
  selectedProjectionSystems: IProjection[];
};

/**
 * @name MmgWorkspaceCreatePanel
 * @summary A panel for creating a new workspace.
 *
 * @param props
 */
export const MmgWorkspaceCreatePanel = (props: WorkspaceCreatePanelProps) => {
  const {
    onPanelExit: onPanelExitProp,
    projectId,
    loadingProjectionSystems,
    searchProjectionSystemsById,
    selectedProjectionSystems,
  } = props;

  const { workspaceCreating } = useSelector((state: IGlobalState) => state.WorkspaceReducer);

  const [projectListLoaded, setProjectListLoaded] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(projectId);
  const [selectedProjectDatasets, setSelectedProjectDatasets] = useState([] as Array<IWorkspaceDataset>);

  const [selectedProjectionCounts, setSelectedProjectionCounts] = useState<{
    [code: string]: number;
  }>({});

  const [workspace, setWorkspace] = useState({
    name: '',
    description: '',
    epsgCode: WORKSPACE_DEFAULT_PROJECTION,
    epsgCodeHasBeenSelectedByUser: false,
  } as ICreateWorkspaceState);

  const [isDatasetConfigurationComplete, setIsDatasetConfigurationComplete] = useState(true);

  const UNKNOWN_PROJECTION = 'UNKNOWN';

  const project: IGetProject | null = useSelector((state: IGlobalState) => state.ProjectContentReducer.project);

  useEffect(
    () => {
      setSelectedProjectId(projectId);
    },
    [projectId],
  );

  // on change of selected projectId load project and parent
  useEffect(
    () => {
      selectedProjectId && store.dispatch(getProjectAndParent(selectedProjectId));
    },
    [selectedProjectId],
  );

  const isImportAllowed = project && project.capabilities && project.capabilities.canCreateContent;

  const isProjectionOk = Boolean(workspace.epsgCode);

  const isCreateAllowed = !workspaceCreating && isDatasetConfigurationComplete && isImportAllowed && isProjectionOk;

  const shouldHideConfiguration = !selectedProjectId || !projectListLoaded || !isImportAllowed;

  /**
   * Callback for when a dataset type is updated or a dataset is deleted.
   * Also gets called when a dataset is added via project eplorer.
   */
  const onDatasetTypesUpdated = useCallback(
    (datasets: Array<IWorkspaceDataset>) => {
      // When dataset types are updated, the workspace epsg code is suggested.
      // We suggest the epsg code used by the majority of the selected datasets, omitting unknown pojections
      // If several epsg codes are equaly used, we suggest the lowest epsg code

      // Group and count per epsg code
      const projectionCounts = datasets.reduce(
        (counts, dataset) => {
          let spatialReference: string | number = null;
          if (dataset.spatialInformation) {
            spatialReference = dataset.spatialInformation.srid
              ? dataset.spatialInformation.srid
              : dataset.spatialInformation.primarySpatialReference;
          }
          const epsgCode = spatialReference ? spatialReference || UNKNOWN_PROJECTION : UNKNOWN_PROJECTION;

          return {
            ...counts,
            [epsgCode]: (counts[epsgCode] || 0) + 1,
          };
        },
        {} as { [code: string]: number },
      );

      setSelectedProjectionCounts(projectionCounts);

      // sort counts to be able to pick the most used. Omit unknown projections
      // sort decending by number of projections then ascending by projection
      const sorted = orderBy(Object.entries(omit(projectionCounts, UNKNOWN_PROJECTION)), [1, 0], ['desc', 'asc']);

      // The first epsg coden will have highest count (or lowest epsg code). Fallback to default epsg (4326)
      const suggestedEpsgCode = sorted && sorted.length > 0 ? Number(sorted[0][0]) : WORKSPACE_DEFAULT_PROJECTION;

      !workspace.epsgCodeHasBeenSelectedByUser && setWorkspace({ ...workspace, epsgCode: suggestedEpsgCode });
      setSelectedProjectDatasets(datasets);
      setIsDatasetConfigurationComplete(datasets.findIndex((dataset) => !dataset.usageType) === -1);
    },
    [workspace],
  );

  /**
   * Callback for when the workspace is submitted for creation.
   */
  const onWorkspaceCreate = () => {
    store.dispatch({ type: 'workspace/CREATE' });

    WorkspaceManager.createWorkspace(
      selectedProjectId,
      workspace.name,
      workspace.description,
      workspace.epsgCode,
      selectedProjectDatasets,
    )
      .then((createdWorkspace) => {
        store.dispatch({ type: 'workspace/CREATE_SUCCESS' });
        routeToPanel(ROUTES.workspacePanel.path, {
          workspaceId: createdWorkspace.id,
          projectId: selectedProjectId,
        });
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, {
            action: t('CREATE', 1, { thing: t('WORKSPACE') }),
          }),
          operationId: error.operationId,
        };

        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        store.dispatch({ type: 'workspace/CREATE_FAILED' });
      });
  };

  /**
   * Callback for when a project is selected
   *
   * @param newSelectedProjectId
   */
  const onProjectSelected = (newSelectedProjectId: string) => {
    setSelectedProjectId(newSelectedProjectId);
  };

  /**
   * Callback for when projects have been retrieved in the project selector.
   */
  const onProjectListLoaded = () => {
    setProjectListLoaded(true);
  };

  /**
   * Callback for when any workspace text property has changed.
   *
   * @param event
   */
  const onInputChanged = (event: React.ChangeEvent) => {
    const eventTarget = event.target as HTMLInputElement;

    setWorkspace({
      ...workspace,
      [eventTarget.name]: eventTarget.value,
    });
  };

  /**
   * Callback for when a projection is selected.
   * Updates workspace state.
   *
   * @param epsgCode
   */
  const onProjectionSelected = (epsgCode: number) =>
    setWorkspace((currentWorkspace: ICreateWorkspaceState) => {
      return { ...currentWorkspace, epsgCode, epsgCodeHasBeenSelectedByUser: true };
    });

  const onPanelExit = () => {
    if (onPanelExitProp) {
      onPanelExitProp();
    }
  };

  const selectedDatasetsHaveUnknown = useMemo(() => Boolean(selectedProjectionCounts[UNKNOWN_PROJECTION]), [
    selectedProjectionCounts,
  ]);

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <MmgPanelHeader
          panelTitle={t('WORKSPACE_CREATE_TITLE')}
          panelHeaderActions={
            <>
              <IconButton onClick={onPanelExit}>
                <CloseIcon />
              </IconButton>
            </>
          }
        />
      </MikeStickyPanelHeaderContainer>

      <MikeStickyPanelContent>
        <MmgPanelSubsection>
          <MmgConnectedProjectSelector
            selectedProjectId={selectedProjectId}
            onProjectSelected={onProjectSelected}
            onProjectListLoaded={onProjectListLoaded}
          />

          {project && !isImportAllowed && <MmgProjectPermissionsMessage />}

          <TextField
            id="name"
            label={t(`WORKSPACE_NAME_LABEL`)}
            autoComplete="off"
            fullWidth={true}
            name="name"
            value={workspace.name}
            onChange={onInputChanged}
          />

          <TextField
            id="description"
            label={t(`WORKSPACE_DESCRIPTION_LABEL`)}
            autoComplete="off"
            fullWidth={true}
            name="description"
            value={workspace.description}
            onChange={onInputChanged}
          />
          <MmgProjectContentButton selectedProjectId={selectedProjectId} />
        </MmgPanelSubsection>

        <MmgWorkspaceCreateDataSetType
          shouldHideConfiguration={shouldHideConfiguration}
          onDatasetTypesUpdated={onDatasetTypesUpdated}
          epsgCode={workspace.epsgCode}
          onProjectionSelected={onProjectionSelected}
          selectedDatasetsHaveUnknown={selectedDatasetsHaveUnknown}
          selectedProjectId={selectedProjectId}
          isCreateAllowed={isCreateAllowed}
          workspaceCreateWorking={workspaceCreating}
          onWorkspaceCreate={onWorkspaceCreate}
          loadingProjectionSystems={loadingProjectionSystems}
          searchProjectionSystemsById={searchProjectionSystemsById}
          selectedProjectionSystems={selectedProjectionSystems}
        />
        <MikeStickyPanelBottomActions>
          <MikeButton fullWidth disabled={!isCreateAllowed} active={workspaceCreating} onClick={onWorkspaceCreate}>
            {t('WORKSPACE_CREATE_BUTTON')}
          </MikeButton>
        </MikeStickyPanelBottomActions>
      </MikeStickyPanelContent>
    </MikeStickyPanel>
  );
};
