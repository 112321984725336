import { all } from 'redux-saga/effects';
import watchExportableMeshes from './exportableMeshesSagas';
import watchEditMeshes from './editMeshSagas.ts';

/**
 * Exposes all available sagas for meshes
 *
 * todo hevo: consider if aný of the other root saga patterns is better?:  https://redux-saga.js.org/docs/advanced/RootSaga.html
 */
export default function* meshSagas() {
  yield all([...watchExportableMeshes()]);
  yield all([...watchEditMeshes()]);
}
