import { endpoints, paths } from 'proxies/config';

const getConfigUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return '/config/app.config-dev.json';
    default:
      return `${endpoints.meshAppService}/${paths.configuration}`;
  }
};

async function fetchConfig() {
  const configPath = getConfigUrl();
  const resp = await fetch(configPath);
  const config = await resp.json();
  return config;
}

export default fetchConfig;
