import {
  IColorRange,
  IDrawnDataGradientSettings,
} from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';
import { IRepresentation } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/models/IRepresentation';
import { IDrawnDataItem } from 'src/models/IWorkspaceData';
import { store } from 'src/store';
import { EWorkspaceDataActionType } from './WorkspaceDataActionType';

export interface IUpdateWSItemColor {
  elementId: string;
  edgeColor: Array<number>;
  surfaceColor: Array<number>;
}

export const updateWorkspaceDataItemColor = (options: IUpdateWSItemColor, save = true, isTiled = false) => {
  const { elementId, edgeColor, surfaceColor } = options;
  store.dispatch({
    type: EWorkspaceDataActionType.UPDATE_ITEM_COLOR,
    data: {
      workspaceDataEdgeColor: edgeColor,
      workspaceDataSurfaceColor: surfaceColor,
      elementId,
      save,
      isTiled,
    },
  });
};

export const removeGradient = (elementId: string, isTiled: boolean) => {
  store.dispatch({
    type: EWorkspaceDataActionType.REMOVE_GRADIENT,
    data: { elementId, isTiled },
  });
};

export const updateWorkspaceDataZAttributeName = (workspaceItemId: string, zAttributeName: string, save = true) => {
  store.dispatch({
    type: EWorkspaceDataActionType.UPDATE_ITEM_ZATTRIBUTENAME,
    workspaceItemId,
    zAttributeName,
    save,
  });
};

export const updateWorkspaceDataStatisticAttributeName = (
  workspaceItemId: string,
  statisticsAttributeName: string,
  save = true,
  isTiled = false,
) => {
  store.dispatch({
    type: EWorkspaceDataActionType.UPDATE_ITEM_STATISTICATTRIBUTENAME,
    data: { workspaceItemId, statisticsAttributeName, save, isTiled },
  });
};

export const updateWorkspaceDataLabelAttributeName = (
  elementId: string,
  labelsAttribute: string,
  workspaceId: string,
  save = true,
) => {
  store.dispatch({
    type: EWorkspaceDataActionType.UPDATE_ITEM_LABELATTRIBUTE,
    elementId,
    labelsAttribute,
    workspaceId,
    save,
  });
};

export const fetchAttributeLabels = (elementId: string, labelsAttribute: string, workspaceId: string) => {
  store.dispatch({
    type: EWorkspaceDataActionType.FETCH_ATTRIBUTE_LABELS,
    elementId,
    labelsAttribute,
    workspaceId,
  });
};

export const updateWorkspaceDataItemRepresentation = (
  workspaceItemId: string,
  workspaceRepresentation: IRepresentation,
  save = true,
  isTiled = false,
  ignoreOverview = false,
) => {
  store.dispatch({
    type: EWorkspaceDataActionType.UPDATE_ITEM_REPRESENTATION,
    data: { workspaceRepresentation, workspaceItemId, save, isTiled, ignoreOverview },
  });
};

export const updateWorkspaceDataItemPointSize = (
  workspaceItemId: string,
  pointSize: number,
  isTiled = false,
  ignoreOverview = false,
) => {
  store.dispatch({
    type: EWorkspaceDataActionType.UPDATE_ITEM_POINTSIZE,
    data: { pointSize, workspaceItemId, isTiled, ignoreOverview },
  });
};

export interface IUpdateWSItemGradient {
  workspaceGradientId: string;
  workspaceGradientAttributeName: string;
  workspaceGradientSettings: IDrawnDataGradientSettings | null;
  colorRange?: IColorRange | null;
  isTiled?: boolean;
}
export const updateWorkspaceDataItemGradient = (options: IUpdateWSItemGradient) => {
  const {
    workspaceGradientId,
    workspaceGradientAttributeName,
    workspaceGradientSettings,
    colorRange,
    isTiled,
  } = options;
  store.dispatch({
    type: EWorkspaceDataActionType.UPDATE_GRADIENT,
    data: {
      workspaceGradientId,
      workspaceGradientAttributeName,
      workspaceGradientSettings,
      colorRange,
      isTiled,
    },
  });
};

export interface IUpdateWorkspaceOptions {
  itemId?: IDrawnDataItem['id'];
  elementId?: IDrawnDataItem['id'];
  workspaceItemId?: IDrawnDataItem['id'];
  workspaceLength?: IDrawnDataItem['length'];
  workspaceItemDataId?: IDrawnDataItem['dataId'];
  workspaceRepresentation?: IDrawnDataItem['representation'];
  workspacePointSize?: IDrawnDataItem['pointSize'];
  workspaceDataEdgeColor?: IDrawnDataItem['edgeColor'];
  workspaceDataSurfaceColor?: IDrawnDataItem['surfaceColor'];
  workspaceDataArrays?: IDrawnDataItem['dataArrays'];
  workspaceFieldData?: IDrawnDataItem['fieldData'];
  workspaceAttributes?: IDrawnDataItem['attributes'];
  workspaceItemType?: IDrawnDataItem['itemType'];
  workspaceItemDrawn?: IDrawnDataItem['drawn'];
  workspaceItemUpdated?: IDrawnDataItem['updated'];
  statisticsAttributeName?: IDrawnDataItem['statisticsAttributeName'];
  zAttributeName?: IDrawnDataItem['zAttributeName'];
  save?: boolean;
}

/**
 * Update a data item with any attributes.
 * Provide an id to update.
 * @param action
 * @returns
 */
export const updateWorkspaceDataItem = (action: IUpdateWorkspaceOptions) => {
  if (action) {
    const workspaceItemId = action.workspaceItemId || action.elementId || action.itemId;
    if (!workspaceItemId) {
      console.error(`No id provided for 'updateWorkspaceDataItem'`);
      return;
    }
    store.dispatch({
      type: EWorkspaceDataActionType.UPDATE_DATA_ITEM,
      workspaceItemId: action.workspaceItemId || action.elementId || action.itemId,
      ...action,
    });
  }
};
