import { EDATASETUSAGE_TYPE } from 'src/models/IGetDataset';

export const DATASET_TYPES = {
  MESHDATA: 'meshdata',
  THREEDMESH: 'threedmesh',
  TWODGRIDDEDDATA: 'twodgriddeddata',
  THREEDGRID: 'threedgrid',
  GISVECTORDATA: 'gisvectordata',
  FILE: 'file',
};

// Note: usage types have to aligned with the back-end.
export const USAGE_TYPES = {
  GEOMETRY: 'Geometry',
  VARIABLE: 'Variable',
  MESH: 'Mesh',
};

export const DEFAULT_USAGE_TYPE = undefined;

export interface IUsageType {
  datasetId?: string;
  usageType?: string;
  name?: string;
  spatialInformation?: {
    location?: {};
    primarySpatialReference?: string;
    resolution?: string;
    srid?: number;
  };
  projectId?: string;
  datasetUsageTypes: Array<EDATASETUSAGE_TYPE>;
}
