import { isEqual } from 'lodash-es';
import { useEffect, useRef, useState, useCallback } from 'react';

/**
 *
 * @param value Returns the previous value and store the current for next time.
 */
export function usePrevious<T = any>(value: T): T {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 *
 * Returns a function for check if the component is mounted or not
 */
export const useIsMounted = () => {
  const ref = useRef(false);
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  return () => ref.current;
};

/**
 * Perform a deep comparison of props and call the effect function passed as paramter is needed
 *
 * @param effectFunc
 * @param deps
 */
export const useDeepEffect = (effectFunc, deps) => {
  // first time running the hook
  const isFirst = useRef(true);
  // previous dependecies
  const prevDeps = useRef(deps);

  //effect that checks if is the first time and compare the
  useEffect(
    () => {
      if (prevDeps.current) {
        const isSame = prevDeps.current.every((obj, index) => {
          isEqual(obj, deps[index]);
          if (isFirst.current || !isSame) {
            effectFunc();
          }
          isFirst.current = false;
          prevDeps.current = deps;
          return null;
        });
      }
    },
    [deps, effectFunc],
  );
};

/**
 * simple wrapper for console count the numeber of rerender of a component
 *
 * @param label
 */
export const useConsoleCount = (label: string) => {
  useEffect(() => {
    console.count(label);
  });
};

/**
 * Force an update on your component with this hook.
 */
export const useForceUpdate = () => {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
};
