import { betterEncodeURIComponent } from 'src/translations/utils';
import { isString } from 'lodash-es';
import AppRoutes from 'src/app/app.routes';
import WorkspaceRoutes from 'src/workspaces/workspace.routes';
import VariableRoutes from 'src/variables/variable.routes';
import MeshRoutes from 'src/meshes/mesh.routes';
import GeometryRoutes from 'src/geometries/geometry.routes';
import CommentsRoutes from 'src/comments/comment.routes';
import { IRouteObject } from 'models/IRoutes';

export const ROUTES = {
  ...AppRoutes,
  ...WorkspaceRoutes,
  ...GeometryRoutes,
  ...VariableRoutes,
  ...MeshRoutes,
  ...CommentsRoutes,
};

/**
 * 'Builds' a route by path, replacing provided parameters.
 * :routeParam will be replaced by { routeParam: <string> }.
 *
 * If provided a hash-tag will be added to the route
 *
 * @param routePath
 * @param routeParams
 * @param routeHash the route hash - without trailing #
 */
export const getRouteByPath = (
  routePath: string,
  routeParams: { [key: string]: string } = {},
  routeHash?: string,
): string => {
  const routeKey = Object.keys(ROUTES).find((key) => ROUTES[key].path === routePath);
  const route = ROUTES[routeKey];

  if (route) {
    const routeWithParams = Object.keys(routeParams).reduce((r, param) => {
      const paramToReplace = routeParams[param];

      if (isString(paramToReplace)) {
        return r.replace(`:${param}`, betterEncodeURIComponent(routeParams[param]));
      }

      return r;
    }, route.path);

    if (!routeHash || routeHash.length === 0) {
      return routeWithParams;
    }

    return routeWithParams + '#' + routeHash;
  }

  throw new Error('No route found');
};

/**
 * Gets the name of a route by path.
 *
 * @param routePath
 */
export const getRouteNameByPath = (routePath: string) => {
  const routeKey = Object.keys(ROUTES).find((key) => ROUTES[key].path === routePath);

  return routeKey || '<no-name-for-route>';
};

/**
 * In a routes object, checks if any path matches the provided one.
 *
 * @param routes
 * @param path
 */
export const isPathInRouteObject = (routes: IRouteObject, path: string) => {
  return Object.keys(routes).find((key) => {
    const routePath = routes[key].path;
    return path === routePath;
  });
};

/**
 * Checks if a path belongs to a group of routes.
 */
export const isPathA = {
  geometryRoute: (path) => isPathInRouteObject(GeometryRoutes, path),
  meshRoute: (path) => isPathInRouteObject(MeshRoutes, path),
  variableRoute: (path) => isPathInRouteObject(VariableRoutes, path),
  workspaceRoute: (path) => isPathInRouteObject(WorkspaceRoutes, path),
};
