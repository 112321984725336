import React, { useState } from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { IProject } from 'models/IProject';
import { t } from 'src/translations/i18n';
import CloseIcon from '@material-ui/icons/Close';
import MikeButton from '@mike/mike-shared-frontend/mike-button';
import ProjectManager from 'managers/ProjectManager';
import { store } from 'store/store';
import MikeStickyPanel from '@mike/mike-shared-frontend/mike-sticky-panel/MikeStickyPanel';
import MikeStickyPanelContent from '@mike/mike-shared-frontend/mike-sticky-panel/MikeStickyPanelContent';
import { MmgPanelHeader } from 'src/shared/panels/panel-header';
import { MikeStickyPanelHeaderContainer } from '@mike/mike-shared-frontend/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import { MmgPanelSubsection } from 'src/shared/panels/panel-subsection';
import { MikeStickyPanelBottomActions } from '@mike/mike-shared-frontend/mike-sticky-panel/MikeStickyPanelBottomActions';

type ProjectCreatePanelProps = {
  onPanelExit?: () => void;
  onProjectCreated?: (newproject: IProject) => void;
  targetProjectId?: string;
};

/**
 * @name MmgProjectCreatePanel
 * @summary Panel that allows creating a new empty project.
 *
 * @param props
 */
export const MmgProjectCreatePanel = (props: ProjectCreatePanelProps) => {
  const { onPanelExit: onPanelExitProp, onProjectCreated, targetProjectId } = props;

  const [project, setProject] = useState({
    name: '',
    description: '',
  } as IProject);

  const [projectCreateWorking, setProjectCreateWorking] = useState(false);

  const isCreateAllowed = !projectCreateWorking && project.name;

  /**
   * Callback for when any project text property has changed.
   *
   * @param event
   */
  const onInputChanged = (event: React.ChangeEvent) => {
    const eventTarget = event.target as HTMLInputElement;

    setProject({
      ...project,
      [eventTarget.name]: eventTarget.value,
    });
  };

  const onPanelExit = () => {
    if (onPanelExitProp) {
      onPanelExitProp();
    }
  };

  /**
   * Callback for creating a new project
   */
  const onProjectCreate = () => {
    setProjectCreateWorking(true);

    ProjectManager.createProject(project.name, project.description, targetProjectId)
      .then((createdProject) => {
        const projectText = `${t('PROJECT')}: ${createdProject.name}`;
        const toast = {
          text: t('CREATED', 1, { thing: projectText }),
        };

        if (onProjectCreated) {
          onProjectCreated(createdProject);
        }

        store.dispatch({
          type: 'project/CREATE_SUCCESS',
          project: createdProject,
        });
        store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
      })
      .catch((error) => {
        const toast = {
          text: t('FAILED_TO', 1, {
            action: t('CREATE', 1, { thing: t('PROJECT') }),
          }),
          operationId: error.operationId,
        };

        store.dispatch({ type: 'toast/ADD/ERROR', toast });
        store.dispatch({ type: 'project/CREATE_FAILED' });
      })
      .finally(() => {
        setProjectCreateWorking(false);
      });
  };

  return (
    <>
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <MmgPanelHeader
            panelTitle={t('PROJECT_CREATE_TITLE')}
            panelHeaderActions={
              <>
                <IconButton onClick={onPanelExit}>
                  <CloseIcon />
                </IconButton>
              </>
            }
          />
        </MikeStickyPanelHeaderContainer>
        <MikeStickyPanelContent>
          <MmgPanelSubsection>
            <TextField
              id="name"
              label={t(`PROJECT_NAME_LABEL`)}
              required={true}
              autoComplete="off"
              fullWidth={true}
              name="name"
              value={project.name}
              onChange={onInputChanged}
            />

            <TextField
              id="description"
              label={t(`PROJECT_DESCRIPTION_LABEL`)}
              autoComplete="off"
              fullWidth={true}
              name="description"
              value={project.description}
              onChange={onInputChanged}
            />
          </MmgPanelSubsection>
        </MikeStickyPanelContent>
        <MikeStickyPanelBottomActions>
          <MikeButton fullWidth disabled={!isCreateAllowed} active={projectCreateWorking} onClick={onProjectCreate}>
            {t('PROJECT_CREATE_CONTINUE_BUTTON')}
          </MikeButton>
        </MikeStickyPanelBottomActions>
      </MikeStickyPanel>
    </>
  );
};
