import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { css } from 'emotion';
import { IGlobalState, store } from 'store/store';
import { IWorkspaceOverview } from 'models/IWorkspaces';
import { t } from 'src/translations/i18n';
import { MmgMetas } from 'src/shared/metas/metas';
import { AuditCompareRouteParams } from 'src/app/topbar/topbar';
import { MmgConnectedViewerBounding } from './viewer/viewer-bounding';
import { MmgConnectedViewerProjection } from './viewer/viewer-projection';
import { getWorkspaceOverview } from 'src/store/selectors/WorkspaceSelectors';
import { WorkspaceActionType } from './store/WokspaceActionType';
import { theme } from 'src/shared/styles/theme';
import { MenuBarHeight, ViewerZindex } from 'src/shared/styles/styleConsts';
import { getProjectPath } from 'src/store/actions/project';
import { useDefaultHiddenItems } from './useDefaultHiddenItems';

const LazyMmgConnectedWorkspacePanelRoutes = React.lazy(() => import('src/workspaces/workspace-panel-routes'));
const LazyMmgViewer = React.lazy(() => import('src/workspaces/viewer/viewer-react'));

/* ${theme.spacing(4)}px is the foother height; see file mesh-viewer-info-bars.ts
To override vtk default cursor [style] attrs !important is used */
const getViewerStyle = (working: boolean, waiting: boolean, crosshair: boolean) => css`
  position: relative;
  width: 100vw;
  height: calc(100vh - ${MenuBarHeight} - ${theme.spacing(4)}px);
  z-index: ${ViewerZindex};
  overflow: hidden;
  & div {
    cursor: ${working
      ? 'progress!important'
      : waiting
        ? 'wait!important'
        : crosshair
          ? 'crosshair!important'
          : 'inherit'};
  }
`;

/**
 * @name MmgWorkspace Contains all workspace components.
 * @summary Parent of the viewer and panels.
 */
const MmgConnectedWorkspace = () => {
  const workspace: IWorkspaceOverview = useSelector(getWorkspaceOverview);
  const match = useRouteMatch<AuditCompareRouteParams>({
    path: '/project/:id/workspace/:workspaceId',
    strict: true,
    sensitive: true,
  });
  const projectId = match && match.params.id;
  const workspaceId = match && match.params.workspaceId;

  const { viewerWorking, viewerWaiting, viewerCrosshair } = useSelector(
    (state: IGlobalState) => state.ViewerCursorReducer,
  );

  useEffect(
    () => {
      if (projectId && workspaceId) {
        const data = { pId: projectId, wsId: workspaceId };
        store.dispatch({ type: WorkspaceActionType.WORKSPACE_LOAD_METADATA, data });
      }
    },
    [projectId, workspaceId],
  );

  useEffect(
    () => {
      store.dispatch(getProjectPath(projectId, workspace));
    },
    [projectId, workspace],
  );

  useEffect(
    () => {
      if (workspaceId) {
        store.dispatch({ type: WorkspaceActionType.SOCKET_INIT });
      }
    },
    [workspaceId],
  );

  useDefaultHiddenItems(workspaceId);

  const renderMetas = (workspaceOverview: IWorkspaceOverview) => {
    if (workspaceOverview) {
      const metaTitle = `${workspaceOverview.name} | ${t('WORKSPACE')}`;
      return <MmgMetas metaTitle={metaTitle} metaDescription={workspaceOverview.description} />;
    }
    return <MmgMetas metaTitle={t('WORKSPACE')} />;
  };

  /**
   * Reset highlighted element id when mouse entering the viewer.
   */
  const onViewerEnter = () => {
    store.dispatch({
      type: 'workspace/element/HIGHLIGHT',
      highlightedWorkspaceElementId: null,
    });
  };

  return (
    <>
      {renderMetas(workspace)}
      <main className={getViewerStyle(viewerWorking, viewerWaiting, viewerCrosshair)} onMouseEnter={onViewerEnter}>
        <Suspense fallback={<></>}>
          <LazyMmgViewer />
        </Suspense>

        {/* Set correct bounding and projection on MikeVisualizer */}
        <MmgConnectedViewerProjection />
        <MmgConnectedViewerBounding />
      </main>
      <Suspense fallback={<></>}>
        <LazyMmgConnectedWorkspacePanelRoutes />
      </Suspense>
    </>
  );
};

export default MmgConnectedWorkspace;
