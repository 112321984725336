import { css } from 'emotion';
import { PanelHeaderZindex } from '../styles/styleConsts';
import { theme } from '../styles/theme';

export const PanelBodyStyle = css`
  background-color: white;
`;

export const PanelFooterButtonsCss = css`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

export const HEADER_ICON_SIZE = '40px';
export const PANEL_HEADER_SIZE = '72px'; // todo hevo Need to find a better way to handle tabs and steppers in sticky panels.

export const PanelHeaderStyle = css`
  background: white;
  position: sticky;
  top: 0;
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
  min-height: ${PANEL_HEADER_SIZE};

  z-index: ${PanelHeaderZindex};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PanelHeaderNoBorderStyle = css`
  border: 0;
`;

export const PanelHeaderWithBackArrowStyle = css`
  padding: ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px;
`;

export const PanelHeaderTitleStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PanelHeaderBackStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  outline: 0;

  background: 0;
  border: 0;
  padding: 0;
  transition: opacity 0.3s;

  height: ${HEADER_ICON_SIZE};
  width: ${HEADER_ICON_SIZE};

  padding-right: ${theme.spacing(1)}px;

  :hover,
  :focus {
    outline: 0;
    border: 0;
    opacity: 0.7;
    transition: opacity 0.3s;
  }
`;

export const PanelHeaderBackIconStyle = css`
  transition: transform 0.3s;
  display: flex;

  :hover,
  :focus {
    transform: translateX(-10px);
  }
`;
