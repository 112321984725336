/**
 * Exposes operation REST endpoints related to the API OpenDataProvidersController.
 *
 * @module OpenDataProvidersProxy
 * @version 2.0.0
 * @requires Proxy
 *
 */

import { endpoints, paths } from 'proxies/config';
import { IOpenDataExtraction } from 'src/models/IOpenDataProvidersApi';
import Proxy from './Proxy';

const { workspaces, opendataproviders, extract } = paths;

/**
 * Get metadata for available open data providers.
 *
 * @param workspaceId
 */
const getOpenDataProviders = async (workspaceId: string) => {
  return await Proxy.http.get(`${endpoints.meshAppService}/${workspaces}/${workspaceId}/${opendataproviders}`);
};

/**
 * Executes an extraction for selected geometry, provider and dataset.
 *
 * @param workspaceId
 * @param openDataExtraction
 */
const extractData = async (workspaceId: string, openDataExtraction: IOpenDataExtraction) =>
  await Proxy.http.post(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${opendataproviders}/${extract}`,
    openDataExtraction,
  );

export const OpenDataProvidersProxy = {
  getOpenDataProviders,
  extractData,
};
