import { IDrawnDataItem } from 'models/IWorkspaceData';

export const getAttributes = (itemId: string, drawnDataItems: Array<IDrawnDataItem>) => {
  if (!itemId || !drawnDataItems) {
    return [];
  }
  const data: IDrawnDataItem = drawnDataItems.find((item) => item.id === itemId);

  if (!data || !data.attributes) {
    return [];
  }

  const attributes = data.attributes;

  return attributes;
};

const self = {
  getAttributes,
};
export default self;
