import React, { useMemo, useState } from 'react';
import { css } from 'emotion';

import { Tooltip } from '@material-ui/core';
import WorkspaceQuerySelectors from 'src/store/selectors/WorkspaceQuerySelectors';
import { ReactComponent as EyeVisible } from '@mike/mike-shared-frontend/media/icons/EyeVisible';
import { ReactComponent as EyeHidden } from '@mike/mike-shared-frontend/media/icons/EyeHidden';
import { IGlobalState } from 'src/store';
import { useSelector } from 'react-redux';
import { IOperationMetadata } from 'src/models/IOperations';
import { isLayerFailed, isLayerProcessing, isLayerScheduled } from 'src/shared/layers/layer-utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

export const LAYER_ICON_SIZE = '30px';
export const LAYER_HEIGHT = '40px';

export const LayerStyle = css`
  display: flex;
  margin-left: 0;
  width: 100%;
  height: ${LAYER_HEIGHT};
  overflow: hidden;
  background-color: white;
  align-items: center;
`;

export const LayerNameAndDescriptionStyle = css`
  display: flex;
  flex-direction: column;
  text-align: left;
  min-width: 0;
  width: 100%;
  margin-left: 4px;
`;

export const LayerNameStyle = css`
  display: inline;
  color: var(--BrandBlue--default);
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
  padding: 0 calc(var(--rem) * 2) 0 0;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const LayerDescriptionStyle = css`
  font-size: 0.75rem; /* 12px */
  line-height: 1rem;
  color: var(--DarkGrey--default);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const LayerContentStyle = css`
  border-bottom: 1px solid var(--LightGrey--default);
`;

export const LayerIconStyle = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 30px;
  height: ${LAYER_ICON_SIZE};
  width: ${LAYER_ICON_SIZE};
  color: var(--BrandBlue--default);

  &:focus {
    outline: 0;
  }
`;

export const LayerButtonStyle = css`
  background: 0;
  border: 0;
  padding: 0;
`;

export const LayerWithoutBorderStyle = css`
  border-bottom: none;
`;

const LayerStyleHover = css`
  transition: var(--anim-time-default) background-color;

  :hover {
    transition: var(--anim-time-default) background-color;
    outline: 0;
    background-color: var(--LightGrey--default);
  }
`;

const LayerRightActionsContainerStyle = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
`;

const LayerLeftActionsContainerStyle = css`
  position: relative;
`;

const LayerHiddenStyle = css`
  opacity: 0.5;
`;

const LayerTransparentStyle = css`
  opacity: 0;
`;

const LayerDisabledStyle = css`
  opacity: 0.5;
  pointer-events: none;
`;

export interface ILayerProps {
  leftIcon?: React.ReactNode;
  leftComplications?: React.ReactNode;
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  layerName: string; // | React.ReactNode;
  layerDescription?: string | React.ReactNode;
  layerId: string;
  /*  onLayerNamePressed?: (layerId: string) => void;*/
  onLayerMouseEnter?: (layerId: string) => void;
  onLayerMouseLeave?: (layerId: string) => void;
  onLayerHide?: (layerId: string) => void;
  onLayerShow?: (layerId: string) => void;
  className?: string;
  showTooltip?: boolean;
  isParentHidden?: boolean;
  showVisibilityIcon: boolean;
}

export const PROGRESS_SIZE = 24;

/**
 * @name MikeLayer
 * @summary A generic layer that can display text and an icon.
 * Customizable with various left and right actions.
 *
 * @param props
 */
export function SpatialSelectionLayer(props: ILayerProps) {
  const shownSelectionIds = useSelector((state: IGlobalState) => state.WorkspaceQueryReducer.shownSelectionIds);

  const {
    layerId,
    layerName,
    layerDescription,
    leftIcon,
    leftActions,
    leftComplications,
    rightActions,
    className,
    showTooltip = false,
    isParentHidden,
    showVisibilityIcon,
  } = props;

  const getQueryOperationSelectorInstance = WorkspaceQuerySelectors.makeGetQueryOperation();

  const latestOperation: IOperationMetadata | null = useSelector((state: IGlobalState) =>
    getQueryOperationSelectorInstance(state, {
      queryId: layerId,
    }),
  );

  const { working, icon } = useMemo(
    () => {
      const m = latestOperation && latestOperation.state ? latestOperation.state : '';
      const isWorking = isLayerProcessing(latestOperation) || isLayerScheduled(latestOperation);
      const isFailed = isLayerFailed(latestOperation);
      return {
        working: isWorking,
        icon: isWorking ? (
          <CircularProgress style={{ width: PROGRESS_SIZE, height: PROGRESS_SIZE }} color="secondary" />
        ) : isFailed ? (
          <WarningRoundedIcon style={{ width: 30, height: 30 }} />
        ) : (
          leftIcon
        ),
      };
    },
    [latestOperation, leftIcon],
  );

  const [isHovering, setIsHovering] = useState(false);

  const isLayerFaded = useMemo(
    () => {
      return !shownSelectionIds.includes(layerId);
    },
    [layerId, shownSelectionIds],
  );

  const handleLayerMouseEnter = () => () => {
    setIsHovering(true);
  };

  const handleLayerMouseLeave = () => () => {
    setIsHovering(false);
  };

  const onVisibleIconClick = (id: string) => {
    if (props.onLayerHide) {
      props.onLayerHide(id);
    }
  };

  const onNotVisibleIconClick = (id: string) => {
    if (props.onLayerShow) {
      props.onLayerShow(id);
    }
  };

  const hidden = useMemo(
    () => {
      return isParentHidden || isLayerFaded;
    },
    [isLayerFaded, isParentHidden],
  );

  return (
    <article
      className={[LayerStyle, !isLayerFaded && LayerStyleHover, className && className].join(' ')}
      onMouseEnter={handleLayerMouseEnter()}
      onMouseLeave={handleLayerMouseLeave()}
      id="layer"
    >
      {leftActions && <span className={LayerLeftActionsContainerStyle}>{leftActions}</span>}

      {icon &&
        !leftActions && (
          <button
            className={[
              LayerButtonStyle,
              LayerIconStyle,
              hidden && LayerHiddenStyle,
              isParentHidden && LayerDisabledStyle,
            ].join(' ')}
          >
            {icon}
            {leftComplications}
          </button>
        )}
      {icon &&
        leftActions && (
          <span
            className={[
              LayerButtonStyle,
              LayerIconStyle,
              hidden && LayerHiddenStyle,
              isParentHidden && LayerDisabledStyle,
            ].join(' ')}
          >
            {icon}
            {leftComplications}
          </span>
        )}

      <button style={{ display: 'contents' }}>
        <div
          className={[
            LayerNameAndDescriptionStyle,
            hidden && LayerHiddenStyle,
            isParentHidden && LayerDisabledStyle,
          ].join(' ')}
        >
          {showTooltip ? (
            <Tooltip title={layerName}>
              <div className={LayerNameStyle}>{layerName}</div>
            </Tooltip>
          ) : (
            <div
              className={[LayerNameStyle, hidden && LayerHiddenStyle, isParentHidden && LayerDisabledStyle].join(' ')}
            >
              {layerName}
            </div>
          )}
          <div className={LayerDescriptionStyle}>{layerDescription}</div>
        </div>
      </button>
      {!working &&
        showVisibilityIcon &&
        isLayerFaded && (
          <span
            onClick={() => {
              onNotVisibleIconClick(layerId);
            }}
            className={[
              LayerRightActionsContainerStyle,
              hidden ? (isHovering ? LayerHiddenStyle : LayerTransparentStyle) : '',
              isParentHidden && LayerDisabledStyle,
            ].join(' ')}
          >
            <EyeHidden />
          </span>
        )}
      {!working &&
        !isLayerFaded &&
        showVisibilityIcon && (
          <span
            onClick={() => {
              onVisibleIconClick(layerId);
            }}
            className={[LayerRightActionsContainerStyle, !isHovering ? LayerTransparentStyle : ''].join(' ')}
          >
            <EyeVisible />
          </span>
        )}
      <span className={LayerRightActionsContainerStyle}>{rightActions}</span>
    </article>
  );
}
