export enum EConfirmPanelIds {
  EXTRACT_DATA = 'extract-data',
  GEOMETRY_EDIT = 'geometry-edit',
  GEOMETRY_SELECTION = 'geometry-selection',
  GEOMETRY_DRAW_NEW = 'geometry-draw-new',
  GEOMETRY_MULTIPLE_EXTRACT = 'geometry-multiple-extract',
  VARIABLE_DRAW_NEW = 'variable-draw-new',
  VARIABLE_PROPERTY_EDIT = 'variable-property-edit',
  VARIABLE_EDIT = 'variable-edit',
  MESH_SELECTION = 'mesh-selection',
  MESH_EDIT_NODES = 'mesh-edit-nodes',
  COMMENT_ADD = 'comment-add',
}

export const CONFIRM_PANEL_IDS = {
  EXTRACT_DATA: EConfirmPanelIds.EXTRACT_DATA,
  GEOMETRY_DRAW_NEW: EConfirmPanelIds.GEOMETRY_DRAW_NEW,
  GEOMETRY_MULTIPLE_EXTRACT: EConfirmPanelIds.GEOMETRY_MULTIPLE_EXTRACT,
  GEOMETRY_EDIT: EConfirmPanelIds.GEOMETRY_EDIT,
  GEOMETRY_SELECTION: EConfirmPanelIds.GEOMETRY_SELECTION,
  VARIABLE_DRAW_NEW: EConfirmPanelIds.VARIABLE_DRAW_NEW,
  VARIABLE_PROPERTY_EDIT: EConfirmPanelIds.VARIABLE_PROPERTY_EDIT,
  VARIABLE_EDIT: EConfirmPanelIds.VARIABLE_EDIT,
  MESH_SELECTION: EConfirmPanelIds.MESH_SELECTION,
  MESH_EDIT_NODES: EConfirmPanelIds.MESH_EDIT_NODES,
  COMMENT_ADD: EConfirmPanelIds.COMMENT_ADD,
};
