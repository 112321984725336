/* eslint-disable */
import settings from 'config/shared-env';

// Setup polyfills.
// import 'react-app-polyfill/ie11'; // We do not support IE at the moment. NB: this needs to be loaded before anything else.

// Load other top-level application deps.
import 'normalize.css';

// Setup i18n. NB: should happen before other modules are loaded.
import { en } from './translations/translations.js';
import { load, turnOnDebug } from './translations/i18n';

if (process.env.NODE_ENV === settings.ENVIORNMENTS.DEVELOPMENT) {
  turnOnDebug();
}
load('en', en);
