/**
 * Exposes methods to handle workspace variables requests.
 *
 * @module WorkspaceVariableManager
 * @version 2.0.0
 * @requires WorkspaceVariableProxy
 */
import { FeatureCollection } from 'geojson';

import * as WorkspaceVariablesProxy from 'proxies/WorkspaceVariablesProxy';
import { IWorkspaceVariable } from 'models/IVariables';
import { USAGE_TYPES } from 'src/project-datasets/mesh-project-dataset-constants';

import { HttpErrorHandler } from './http-utils';

/**
 * Transforms a request that updates a list of variables.
 *
 * @param workspaceId
 * @param variables
 */
export const updateVariables = (workspaceId: string, variables: Array<IWorkspaceVariable>): Promise<any> => {
  return WorkspaceVariablesProxy.updateVariables(workspaceId, variables).catch((error) =>
    HttpErrorHandler('Failed to update variables.', error),
  );
};

/**
 * Transforms a request that renames a variable.
 *
 * @param workspaceId
 * @param variableId
 * @param variableName
 */
const renameVariable = (workspaceId: string, variableId: string, variableName: string) =>
  self
    .updateVariables(workspaceId, [{ id: variableId, name: variableName }])
    .catch((error) => HttpErrorHandler('Failed to rename variable.', error));

/**
 * Transforms a request that deletes a list of variables.
 *
 * @param workspaceId
 * @param variableIds
 */
export const deleteVariables = (workspaceId: string, variableIds: Array<string>) => {
  if (!variableIds || variableIds.length < 1) {
    return Promise.resolve();
  }
  return WorkspaceVariablesProxy.deleteVariables(workspaceId, variableIds)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to delete variables.', error)) as Promise<void>;
};

/**
 * Transforms a request that duplicates a variable.
 *
 * @param workspaceId
 * @param variableId
 */
export const duplicateVariable = (workspaceId: string, variableId: string) => {
  return WorkspaceVariablesProxy.duplicateVariable(workspaceId, variableId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to duplicate variable.', error));
};

/**
 * Transforms a request that duplicates a list of variables.
 *
 * @param workspaceId
 * @param variableIds
 */
export const batchDuplicateVariables = (workspaceId: string, variableIds: Array<string>) => {
  if (!variableIds) {
    return Promise.resolve();
  }

  return Promise.all(variableIds.map((variableId) => self.duplicateVariable(workspaceId, variableId))).catch((error) =>
    HttpErrorHandler('Failed to bulk duplicate variables.', error),
  );
};

/**
 * Transforms a request that creates a variable from a feature collection.
 *
 * @param workspaceId Workspace to create the variable in
 * @param featureCollection
 * @param name
 * @param properties Arbitrary object with key: value pairs of global properties.
 */
export const createVariable = (
  workspaceId: string,
  featureCollection: FeatureCollection<any, any>,
  name: string,
  properties: { [key: string]: string } = {},
) => {
  return WorkspaceVariablesProxy.createVariable(
    workspaceId,
    name,
    USAGE_TYPES.VARIABLE,
    featureCollection,
    properties,
  ).catch((error) => HttpErrorHandler('Failed to create variable.', error));
};

/**
 * Transforms a request that gets a variable as GeoJSON.
 *
 * @param workspaceId
 * @param variableId
 */
export const getVariableGeojson = (workspaceId: string, variableId: string): Promise<FeatureCollection<any, any>> => {
  return WorkspaceVariablesProxy.getVariableGeojson(workspaceId, variableId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get variable geojson data.', error));
};

/**
 * Transforms a request that replaces the data of a variable with the provided featureCollection.
 *
 * @param workspaceId
 * @param variableId
 * @param featureCollection
 */
export const updateVariableData = (
  workspaceId: string,
  variableId: string,
  featureCollection: FeatureCollection<any, any>,
) => {
  return WorkspaceVariablesProxy.updateVariableData(workspaceId, variableId, featureCollection).catch((error) =>
    HttpErrorHandler('Failed to update variable data with geojson.', error),
  );
};

/**
 * Transforms a request that exports a list of variables.
 *
 * @param workspaceId
 * @param variableIds
 */
export const exportVariables = (workspaceId: string, variableIds: Array<string>) => {
  if (!variableIds || variableIds.length < 1) {
    return Promise.resolve();
  }
  return WorkspaceVariablesProxy.exportVariables(workspaceId, variableIds)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to export variables.', error)) as Promise<void>;
};

/**
 * Transforms a request that gets a list of variable ids that allows export to the platform.
 *
 * @param workspaceId
 */
export const getExportableVariables = (workspaceId: string): Promise<Array<string> | void> => {
  return WorkspaceVariablesProxy.getExportableVariables(workspaceId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get exportable variables.', error));
};

const self = {
  updateVariables,
  renameVariable,
  deleteVariables,
  duplicateVariable,
  batchDuplicateVariables,
  createVariable,
  getVariableGeojson,
  updateVariableData,
  exportVariables,
  getExportableVariables,
};

export default self;
