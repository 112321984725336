import { createTheme } from '@material-ui/core/styles';

import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme';
import { inputFieldsSharedTheme } from '@mike/mike-shared-frontend/lab/mike-shared-styles/inputFieldsTheme';
import { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { SelectProps } from '@material-ui/core/Select';
import { ThemeOptions } from '@material-ui/core/styles/createTheme';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';

const baseTheme = inputFieldsSharedTheme as IMikeTheme;

const MeshTheme = {
  ...baseTheme,
  // Add special mesh styles to the theme if needed
  overrides: {
    ...baseTheme.overrides,
    MuiButton: {
      ...baseTheme.overrides.MuiButton,
    },
    MuiFormControlLabel: {
      // todo hevo move to shared ?
      root: { marginLeft: 0 },
      label: { fontSize: '0.875rem' },
    },
    MuiSwitch: {
      // todo hevo should be moved to shared theme
      // todo hevo handle disabled also when moving to shared theme
      root: {
        width: 36,
        height: 18,
        margin: 0,
        padding: 0,
      },
      switchBase: {
        padding: 4,
      },
      thumb: {
        width: 10,
        height: 10,
        boxShadow: 'none',
      },
      track: {
        borderRadius: 20 / 2,
        border: `2px solid ${MIKE_COLORS.DARKGREY_DEFAULT}`,
      },
      colorSecondary: {
        color: MIKE_COLORS.DARKGREY_DEFAULT,
        '& + $track': {
          opacity: 1,
          backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT,
          borderColor: MIKE_COLORS.DARKGREY_DEFAULT,
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$checked': {
          // transform: 'translateX(18px)', TODO used by DataLink ?
          color: MIKE_COLORS.GREEN_DARK,
          '& + $track': {
            opacity: 1,
            backgroundColor: MIKE_COLORS.GREEN_LIGHT,
            borderColor: MIKE_COLORS.GREEN_LIGHT,
          },

          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTooltip: {
      ...baseTheme.overrides.MuiTooltip,
      // todo hevo should be moved to shared theme
      tooltip: {
        ...baseTheme.overrides.MuiTooltip.tooltip,
        maxHeight: '100%',
      },
    },
    MuiDialogContent: {
      //TODO(masm): add some more responsive width
      root: {
        maxWidth: '75vw',
        wordBreak: 'break-all',
        overflow: 'hidden',
      },
    },
  },
  // Add default mesh props to the theme if needed
  props: {
    ...baseTheme.props,
    MuiLinearProgress: {
      color: 'secondary', //todo hevo Maybe move this to shared theme?
    } as LinearProgressProps,
    MuiCircularProgress: {
      size: '1.5rem',
    } as CircularProgressProps,
    MuiSelect: {
      //todo hevo Maybe move this to shared theme?
      native: true,
    } as SelectProps,
  },
} as ThemeOptions;

export const theme = createTheme(MeshTheme) as IMikeTheme;
