export enum EWorkspaceDataActionType {
  ADD_EDITING_GEOMETRY = 'workspace/data/item/ADD_EDITING_GEOMETRY',
  REMOVE_EDITING_GEOMETRY = 'workspace/data/item/REMOVE_EDITING_GEOMETRY',
  CLEAR_EDITING_GEOMETRY = 'workspace/data/item/CLEAR_EDITING_GEOMETRY',
  ADD_EDITING_MESH = 'workspace/data/item/ADD_EDITING_MESH',
  REMOVE_EDITING_MESH = 'workspace/data/item/REMOVE_EDITING_MESH',
  CLEAR_EDITING_MESH = 'workspace/data/item/CLEAR_EDITING_MESH',
  ADD_EDITING_VARIABLE = 'workspace/data/item/ADD_EDITING_VARIABLE',
  REMOVE_EDITING_VARIABLE = 'workspace/data/item/REMOVE_EDITING_VARIABLE',
  CLEAR_EDITING_VARIABLE = 'workspace/data/item/CLEAR_EDITING_VARIABLE',
  ADD_DATA_ITEM = 'workspace/data/item/ADD',
  ADD_FAILED_ITEM = 'workspace/data/item/failed/add',
  UPDATE_DATA_ITEM = 'workspace/data/item/UPDATE',
  DELETE_DATA_ITEM = 'workspace/data/item/DELETE',
  DATA_ITEMS_DELETED = 'workspace/data/ITEMS_DELETED',
  REMOVE_GRADIENT = 'workspace/data/REMOVE_GRADIENT',
  UPDATE_ITEM_DATAARRAYS = 'workspace/data/item/DATAARRAYS_UPDATE',
  ITEM_DATAARRAY_DELETED = 'workspace/data/item/DATAARRAY_DELETED',
  UPDATE_GRADIENT = 'workspace/data/gradient/UPDATE',
  UPDATE_ITEM_REPRESENTATION = 'workspace/data/representation/UPDATE',
  UPDATE_ITEM_COLOR = 'workspace/data/color/UPDATE',
  UPDATE_ITEM_POINTSIZE = 'workspace/data/pointsize/UPDATE',
  UPDATE_ITEM_ZATTRIBUTENAME = 'workspace/data/zAttributeName/UPDATE',
  UPDATE_ITEM_STATISTICATTRIBUTENAME = 'workspace/data/statisticAttributeName/UPDATE',
  ADAPT_COLORS_TO_BASEMAP = 'workspace/data/color/ADAPT',
  UPDATE_USER_SETTINGS = 'workspace/data/settings/UPDATE',
  SET_UPDATED_USER_SETTINGS = 'workspace/data/settings/SET_UPDATED_USER_SETTINGS',
  UPDATE_ITEM_LABELATTRIBUTE = 'workspace/data/LABEL_ATTRIBUTE/UPDATE',
  ADD_LABELS = 'workspace/data/labels/ADD',
  FETCH_ATTRIBUTE_LABELS = 'workspace/data/labels/FETCH',
  QUERY_COMPLETED = 'workspace/query/COMPLETED',
}
