/**
 * Exposes utility methods for variableicons.
 *
 * @module MeshIconUtils
 * @version 1.0.0
 */
import React from 'react';

import { ReactComponent as Mesh } from '@mike/mike-shared-frontend/media/icons/Mesh';
import { ReactComponent as MeshSettings } from 'src/shared/Icons/MeshSettings.svg';
import { ReactComponent as Mystery } from '@mike/mike-shared-frontend/media/icons/Mystery';
import WarningIcon from '@material-ui/icons/Warning';

import { MESH_ITEM_TYPES } from 'models/IMeshes';
import { IWorkspaceEnrichedMesh } from 'models/IMeshes';
import { OPERATION_STATES } from 'models/IOperations';
import { theme } from 'src/shared/styles/theme';
import { t } from 'src/translations/i18n';
import { isLayerConflicting, isLayerOutdated } from 'src/shared/layers/layer-utils';

const getIcon = (mesh: IWorkspaceEnrichedMesh) => {
  const iconName = self.getIconName(mesh);
  const iconDescription = getIconDescription(mesh);
  const iconType = getIconType(mesh);

  return getIconByName(iconName, iconDescription, iconType);
};

const getIconDescription = (mesh: IWorkspaceEnrichedMesh) => {
  if (!mesh) {
    return '';
  }

  if (isLayerConflicting(mesh)) {
    return t('MESH_HAS_SOME_FAILED_OPERATIONS');
  }

  if (isLayerOutdated(mesh)) {
    return t('OP_MESH_OUTDATED');
  }

  return '';
};

const getIconName = (mesh: IWorkspaceEnrichedMesh) => {
  if (mesh && isLayerConflicting(mesh)) {
    return MESH_ITEM_TYPES.MESH_CONFLICTING;
  }

  if (mesh && mesh.dataId && mesh.state !== OPERATION_STATES.DRAFT) {
    return MESH_ITEM_TYPES.MESH;
  } else {
    return MESH_ITEM_TYPES.MESH_SETTINGS;
  }
};

const getIconType = (mesh: IWorkspaceEnrichedMesh): 'default' | 'warning' | 'error' => {
  if (isLayerConflicting(mesh)) {
    if (mesh.conflictingState && mesh.conflictingState.state === OPERATION_STATES.FAILED) {
      return 'error';
    }
    return 'warning';
  }

  if (isLayerOutdated(mesh)) {
    return 'warning';
  }

  return 'default';
};

const getWarningAddOn = (iconDescription?: string, iconType: 'default' | 'warning' | 'error' = 'default') => {
  if (iconType === 'default') {
    return null;
  }
  return (
    <span
      title={iconDescription}
      style={{
        position: 'absolute',
        zIndex: 2,
        bottom: 4,
        right: 4,
        width: 16,
        height: 16,
        background: 'white',
        borderRadius: '50%',
      }}
    >
      <WarningIcon
        style={{
          width: 16,
          height: 16,
          color: iconType === 'error' ? theme.palette.error.main : theme.palette.warning.main,
        }}
      />
    </span>
  );
};

const getIconByName = (
  iconName: string,
  iconDescription?: string,
  iconType: 'default' | 'warning' | 'error' = 'default',
): React.ReactNode => {
  switch (iconName) {
    case MESH_ITEM_TYPES.MESH:
      return (
        <>
          <Mesh />
          {getWarningAddOn(iconDescription, iconType)}
        </>
      );

    case MESH_ITEM_TYPES.MESH_SETTINGS:
      return <MeshSettings />;

    case MESH_ITEM_TYPES.MESH_CONFLICTING:
      return (
        <>
          <Mesh />
          {getWarningAddOn(iconDescription, iconType)}
        </>
      );

    default: {
      console.debug(`No mesh icon found for ${iconName} `);
      return <Mystery />;
    }
  }
};

const self = {
  getIcon,
  getIconName,
  getIconByName,
};

export default self;
