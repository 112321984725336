import { IRepresentation } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/models/IRepresentation';
import { REPRESENTATION } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConstants';

export enum EViewerDataTypes {
  GEOMETRY = 'geometry',
  VARIABLE = 'variable',
  MESH = 'mesh',
  MESH_TILE = 'meshTile',
}

export const DATA_TYPES = {
  GEOMETRY: EViewerDataTypes.GEOMETRY,
  VARIABLE: EViewerDataTypes.VARIABLE,
  MESH: EViewerDataTypes.MESH,
  MESH_TILE: EViewerDataTypes.MESH_TILE,
};

export const DEFAULT_DATA_TYPE_REPRESENTATIONS: {
  [dataType: string]: IRepresentation;
} = {
  [DATA_TYPES.MESH]: REPRESENTATION.SURFACE_WITH_EDGES,
  [DATA_TYPES.GEOMETRY]: REPRESENTATION.WIREFRAME,
  [DATA_TYPES.VARIABLE]: REPRESENTATION.SURFACE,
  [DATA_TYPES.MESH_TILE]: REPRESENTATION.SURFACE_WITH_EDGES,
};
