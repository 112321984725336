/**
 * Exposes workspace variables REST endpoints to managers.
 *
 * @module WorkspaceVariableProxy
 * @version 2.0.0
 * @requires Proxy
 */
import * as qs from 'query-string';
import { FeatureCollection, Point } from 'geojson';

import { endpoints, paths } from './config';
import Proxy from './Proxy';
import { IWorkspaceVariable } from 'models/IVariables';

const { meshAppService } = endpoints;
const { workspaces, variables, duplicate, data } = paths;

/**
 * Update variables in a workspace.
 *
 * @param workspaceId
 * @param modifiedVariables
 */
export const updateVariables = (workspaceId: string, modifiedVariables: Array<IWorkspaceVariable>) =>
  Proxy.http.patch(`${meshAppService}/${workspaces}/${workspaceId}/${variables}`, modifiedVariables);

/**
 * Delete multiple variables
 *
 * @param workspaceId
 * @param variableIds
 */
export const deleteVariables = (workspaceId: string, variableIds: Array<string>) => {
  return Proxy.http.delete(`${meshAppService}/${workspaces}/${workspaceId}/${variables}`, { data: variableIds });
};

/**
 * Duplicate a variable
 *
 * @param workspaceId
 * @param variableId
 */
export const duplicateVariable = (workspaceId: string, variableId: string) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${variables}/${variableId}/${duplicate}`, {});

/**
 * Create a variable from GeoJSON
 *
 * @param workspaceId
 * @param name
 * @param variableTypeName Variable type. For now, currently supported types are: VariableOther, VariableElevation, VariablePressure, VariableWaterDepth. We might get rid of variable types altogether in the near future though.
 * @param featureCollection A collection of point features.
 * @param _properties TODO dan: not implemented in API => Arbitrary object with key: value pairs of global properties.
 */
export const createVariable = (
  workspaceId: string,
  name: string,
  variableTypeName: string,
  featureCollection: FeatureCollection<Point, any>,
  _properties: { [key: string]: string },
) => {
  const encodedProperties = qs.stringify({
    name,
    variableTypeName,
    // TODO dan: not implemented in api
    // properties: JSON.stringify(properties)
  });

  return Proxy.http.post(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${variables}?${encodedProperties}`,
    featureCollection,
  );
};

export const createVariableDataWithCachedEdits = (
  workspaceId: string,
  name: string,
  variableTypeName: string,
  featureCollections: Array<FeatureCollection<Point, any>>,
  _properties: { [key: string]: string },
) => {
  const encodedProperties = qs.stringify({
    name,
    variableTypeName,
    // TODO dan: not implemented in api
    // properties: JSON.stringify(properties)
  });

  return Proxy.http.post(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${variables}?${encodedProperties}`,
    featureCollections,
  );
};

/**
 * Gets a variable as GeoJSON.
 *
 * @param workspaceId
 * @param variableId
 */
export const getVariableGeojson = (workspaceId: string, variableId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${variables}/${variableId}/${data}`);

/**
 * Updates a variable's data from GeoJSON.
 *
 * @param workspaceId
 * @param variableId
 * @param featureCollection
 */
export const updateVariableData = (
  workspaceId: string,
  variableId: string,
  featureCollection: FeatureCollection<any, any>,
) =>
  Proxy.http.patch(
    `${meshAppService}/${workspaces}/${workspaceId}/${variables}/${variableId}/${data}`,
    featureCollection,
  );

/**
 * Updates a variable's data from GeoJSON.
 *
 * @param workspaceId
 * @param variableId
 * @param featureCollections
 */
export const updateVariableDataWithcachedEdits = (
  workspaceId: string,
  variableId: string,
  featureCollections: Array<FeatureCollection<any, any>>,
) =>
  Proxy.http.patch(
    `${meshAppService}/${workspaces}/${workspaceId}/${variables}/${variableId}/${data}`,
    featureCollections,
  );

/**
 * Export variables to the platform.
 *
 * @param workspaceId
 * @param variableIds
 */
export const exportVariables = (workspaceId: string, variableIds: Array<string>) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${variables}/export`, variableIds);

/**
 * For a given workspace, gets all variables that are allowed to be exported to the platform.
 *
 * @param workspaceId
 */
export const getExportableVariables = (workspaceId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${variables}/exportables`);
