import { FeatureCollection } from 'geojson';
import { FeatCollAny } from './IGeometryUtils';
import { IPatchFeature } from './IQueryCompleted';

export const QUERY_DEFINITION_TYPES = {
  SPATIAL: 'SpatialQueryDefinition2',
  ATTRIBUTE_RANGE: 'AttributeRangeQueryDefinition2',
  SELECT_ALL: 'SelectAllQueryDefinition2',
  PERSISTED_SELECTION: 'PersistedQueryDefinition',
};

export const QUERY_RESULT_TYPES = {
  NOTSET: 0,
  GEOMETRY: 1, // Mix of all result geometries
  GEOMETRYITEM: 2, // TODO HEVO when is this to be used?
  POINT: 3,
  POLYLINE: 4,
  POLYGON: 5,
  NODEPATCH: 6,
};

export enum EQueryResultTypes {
  NOTSET = QUERY_RESULT_TYPES.NOTSET,
  GEOMETRY = QUERY_RESULT_TYPES.GEOMETRY,
  GEOMETRYITEM = QUERY_RESULT_TYPES.GEOMETRYITEM,
  POINT = QUERY_RESULT_TYPES.POINT,
  POLYLINE = QUERY_RESULT_TYPES.POLYLINE,
  POLYGON = QUERY_RESULT_TYPES.POLYGON,
  NODEPATCH = QUERY_RESULT_TYPES.NODEPATCH,
}

export const QUERY_RELATIONS = {
  UNKNOWN: 0,
  INTERSECTS: 1,
  CONTAINS: 2,
  ISWITHIN: 3,
  NEAREST: 4,
};

export enum EQueryRelations {
  UNKNOWN = QUERY_RELATIONS.UNKNOWN,
  INTERSECTS = QUERY_RELATIONS.INTERSECTS,
  CONTAINS = QUERY_RELATIONS.CONTAINS,
  ISWITHIN = QUERY_RELATIONS.ISWITHIN,
  NEAREST = QUERY_RELATIONS.NEAREST,
}

export interface IQueryDefinitionApi {
  type: string; // Actually required
  resultType?: EQueryResultTypes | string;
  targetItems?: Array<string>;
  queryId?: string;
  sourceItemId?: string;
  resultReturnType?: string;
  featureCollection?: FeatCollAny;
}

export interface IAttributeQueryDefinitionApi extends IQueryDefinitionApi {
  name: string; // the name of the attribute
}

export interface IAttributeQueryDefinitionRangeApi extends IAttributeQueryDefinitionApi {
  targetItems: Array<string>;
  resultReturnType: string;
  resultTablePropertyNames: Array<string>;
  SortOrder: string;
  ResultReturnLimit: number;
  ResultReturnOffset: number;
  SortProperty: string;
  resultType: number;
}

export interface ISelectionQueryApi {
  type: string;
  relation: EQueryRelations;
  featureCollection: FeatureCollection<any, any>;
  targetItems: Array<string>;
  resultReturnType: string;
  resultType: string; // EQueryResultTypes;
  intermediateFeatureCollections?: Array<FeatureCollection<any, any>>;
  editedFeatures?: Array<IPatchFeature>;
}

export interface IAttributeRangeQueryDefinitionApi extends IAttributeQueryDefinitionApi {
  fromValue: number;
  toValue: number;
}

export interface ISpatialQueryDefinitionApi extends IPersistedQueryDefinitionApi {
  relation: EQueryRelations;
  featureCollection: FeatureCollection<any, any>;
  sourceItemId?: string;
}

export type ISelectAllQueryDefinitionApi = IQueryDefinitionApi;

export interface IPersistedQueryDefinitionApi extends IQueryDefinitionApi {
  queryId: string;
}

export interface IQueryOptionValue {
  value: string | number;
  displayName: string;
}
