import ITenant from 'src/models/ITenant';
import { IUser } from 'src/models/IUser';
import { IAction } from '../actions/Action';
import { EIAMActionType } from '../actions/IAMActionType';

const initialState = {
  user: null,
  userLoggedIn: null,
  userSessionExpired: false,
  tenants: new Array<ITenant>(),
  loadingTenants: false,
};

type UserState = typeof initialState;
export interface IUserState extends UserState {
  user: IUser | null;
  userLoggedIn: boolean | null;
  tenants: Array<ITenant>;
  loadingTenants: boolean;
}

export interface IUserAction extends IUserState, IAction<any, string> {}

/**
 * User Reducer.
 * - returns new states for matched user actions.
 *
 * @name UserReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default (state: IUserState = initialState, action: IUserAction): IUserState => {
  switch (action.type) {
    case EIAMActionType.SET_MY_TENANTS:
      return {
        ...state,
        tenants: action.data,
      };
    case EIAMActionType.LOADING_MY_TENANTS:
      return {
        ...state,
        loadingTenants: action.data,
      };
    case 'user/LOGIN':
      return {
        ...state,
        user: action.user,
        userLoggedIn: true,
        userSessionExpired: false,
      };

    case 'user/SET_SESSION_EXPIRED':
      return {
        ...state,
        userSessionExpired: action.userSessionExpired,
        userLoggedIn: false,
      };

    case 'user/LOGOUT':
      return {
        ...state,
        user: null,
        userLoggedIn: false,
      };

    default:
      return state;
  }
};
