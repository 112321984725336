import React from 'react';
import { useSelector } from 'react-redux';

import { t } from 'src/translations/i18n';
import { css } from 'emotion';

import { Typography } from '@material-ui/core';

import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';

import { ROUTES } from 'src/app/routes';

import { theme } from 'src/shared/styles/theme';
import { IGlobalState } from 'store/store';
import { Link } from 'react-router-dom';
import { MmgMetas } from 'src/shared/metas/metas';

const NotFoundPageSectionStyle = css`
  max-width: 500px;
  padding-top: ${theme.spacing(2)}px;
`;

const ErrorPageTitleStyle = css`
  padding-bottom: ${theme.spacing(3)}px;
`;

const NotFoundPageStyle = css`
  $(NotFoundPageSectionStyle);
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column; 
`;

const NotFoundPageColorStyle = css`
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  font-size: 0.875rem;
  text-decoration: underline;
`;

/**
 * @name MmgConnectedNotFoundPage
 * @summary Connected not found / 404 page.
 *
 */
export const MmgConnectedNotFoundPage = () => {
  const { userLoggedIn, userSessionExpired, user } = useSelector((state: IGlobalState) => state.UserReducer);
  return (
    <>
      <MmgMetas metaTitle={t('NOT_FOUND')} />
      <div className={NotFoundPageStyle}>
        <Typography className={ErrorPageTitleStyle} variant="h1">
          {'404'}
        </Typography>
        <Typography variant="body2">
          {t('NOT_FOUND')}
          {user && user.tenantName ? ' ' + user.tenantName : ''}
        </Typography>
        <div className={NotFoundPageSectionStyle}>
          {userLoggedIn && !userSessionExpired ? (
            <Link className={NotFoundPageColorStyle} to={ROUTES.home.path}>
              {t('GO_TO', 1, { thing: t('HOME_PAGE') })}
            </Link>
          ) : (
            <Link className={NotFoundPageColorStyle} to={ROUTES.home.path}>
              {t('GO_TO', 1, { thing: t('LOGIN_PAGE') })}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
