import { ISettingsMap, IUserSettings } from 'src/models/IUserSettings';
import { EMapToolActionType } from './MapToolActionType';
import { EWorkspaceDataActionType } from './WorkspaceDataActionType';

export const setSettings = (settings: IUserSettings, save = true) => ({
  type: EWorkspaceDataActionType.UPDATE_USER_SETTINGS,
  data: { settings, save },
});

export const setUpdatedSettings = (settings: IUserSettings) => ({
  type: EWorkspaceDataActionType.SET_UPDATED_USER_SETTINGS,
  data: settings,
});

export const initViewerModeByUserSettings = (map: ISettingsMap) => ({
  type: EMapToolActionType.SET_VIEWER_MODE_USER_SETTINGS,
  data: map,
});
