import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { AuditCompareRouteParams } from 'src/app/topbar/topbar';
import { t } from 'src/translations/i18n';
import { IGlobalState, store } from 'src/store';
import { IGetProject } from '@mike/mike-shared-frontend/mike-project-explorer/models/IGetProject';
import { getProjectAndParent, getProjectContent, setProjectContentDialogOpen } from 'src/store/actions/projectContent';
import { MikeButton } from '@mike/mike-shared-frontend';

type IProps = {
  /**
   * In Create workspace panel shown on home / projects page a project can be selected
   */
  selectedProjectId?: string; //
};

/**
 * @name MmgProjectContentButton
 * @summary Button to open project explorer
 * @param props
 */
const MmgProjectContentButton = (props: IProps) => {
  const { selectedProjectId } = props;

  const match = useRouteMatch<AuditCompareRouteParams>({
    path: '/project/:id',
    strict: true,
    sensitive: true,
  });

  const project: IGetProject | null = useSelector((state: IGlobalState) => state.ProjectContentReducer.project);

  const currentProjectId = match && match.params.id;

  // on change of projectId in url load project and parent
  useEffect(
    () => {
      currentProjectId && store.dispatch(getProjectAndParent(currentProjectId));
    },
    [currentProjectId],
  );

  // on change of selected projectId load project and parent
  useEffect(
    () => {
      selectedProjectId && store.dispatch(getProjectAndParent(selectedProjectId));
    },
    [selectedProjectId],
  );

  const handleImportFromPlatform = useCallback(
    () => {
      if (project) {
        store.dispatch(getProjectContent(project));
        store.dispatch(setProjectContentDialogOpen(true, true));
      }
    },
    [project],
  );

  const canUpdateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;

  return (
    <MikeButton
      buttontype="secondary"
      disabled={!canUpdateWorkspace}
      onClick={handleImportFromPlatform}
      fullWidth={true}
    >
      {t('WORKSPACE_IMPORT_FROM_PLATFORM')}
    </MikeButton>
  );
};

export default MmgProjectContentButton;
