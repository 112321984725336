import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import {
  IDrawnDataGradientSettings,
  IElementDataArray,
  IThreeDRenderElement,
  IVtkColorObject,
} from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';
import { t } from 'src/translations/i18n';
import { DATA_TYPES, DEFAULT_DATA_TYPE_REPRESENTATIONS } from 'src/models/IViewerModels';
import { getAttributeGradientSettings, getItemPointSize } from 'src/shared/visualization-settings/visualization-utils';
import { store } from 'src/store';
import { EMeshItemTypes, IWorkspaceMesh } from 'src/models/IMeshes';
import { EGeometryItemTypes } from 'src/models/IGeometries';
import { EVariableItemTypes, IWorkspaceVariable } from 'src/models/IVariables';
import {
  EOtherItemTypes,
  IDrawnData,
  IDrawnDataGradient,
  IDrawnDataItem,
  OTHER_ITEM_TYPES,
} from 'src/models/IWorkspaceData';
import { getItemType } from 'src/store/selectors/WorkspaceDataItemUtils';
import { ELEMENT_CATEGORIES } from 'src/shared/panels/mesh-panel-constants';
import {
  DATA_ARRAY_DATA_TYPES,
  REPRESENTATION,
} from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConstants';
import { difference, isEqual } from 'lodash-es';
import { INotificationAttributeUpdatedMsg, INotificationItemDataStructure } from 'src/models/IWorkspaceNotifications';
import { WorkspaceActionType } from 'src/workspaces/store/WokspaceActionType';
import { ATTRIBUTE_DATA_TYPES, IWorkspaceAttribute } from 'src/models/IWorkspaceAttributes';
import { appendDataV2, updateDataV2 } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerIO';
import { IRepresentation } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/models/IRepresentation';
import { MMG_BASE_MAPS } from 'src/workspaces/viewer/tools/viewer-tool-constants';
import { DEFAULTS, _getRgbHighlightColorAdaptedToBaseMap } from 'src/workspaces/viewer/viewer-utils';
import { EWorkspaceDataActionType } from 'src/store/actions/WorkspaceDataActionType';
import { ERepresentation, ISettingsLayer } from 'src/models/IUserSettings';
import { updateWorkspaceDataStatisticAttributeName } from 'src/store/actions/workspaceDataActions';
import { EWorkspaceMeshActionType } from 'src/store/actions/WorkspaceMeshActionType';
import { EMapToolActionType } from 'src/store/actions/MapToolActionType';

const {
  selectElements,
  deleteData,
  deselectElements,
  getConfiguration: getVisualizerConfiguration,
  getGradientAttributeName,
  hideElements,
  highlight,
  removeDataArray,
  showElements,
  updateDataArray,
  updateElementGradient,
} = MikeVisualizerLib;

export const HIGHLIGHT_SELECTION_COLOR: IVtkColorObject = DEFAULTS.colorsAdaptedToBaseMap.highlightSelection;
export const GEOMETRY_COLOR_LIGHT_BASEMAP: IVtkColorObject = DEFAULTS.colorsAdaptedToBaseMap.defaultGeometryLight;
export const GEOMETRY_COLOR_DARK_BASEMAP: IVtkColorObject = DEFAULTS.colorsAdaptedToBaseMap.defaultGeometryDark;
export const VARIABLE_COLOR: IVtkColorObject = {
  ...DEFAULTS.colors.default,
  surface: [...DEFAULTS.colors.default.surface.slice(0, 3), DEFAULTS.opacity], // Transparent
};
export const MESH_COLOR_LIGHT_BASEMAP: IVtkColorObject = {
  edge: DEFAULTS.colorsAdaptedToBaseMap.defaultMeshLight.edge,
  surface: [...DEFAULTS.colorsAdaptedToBaseMap.defaultMeshLight.surface, DEFAULTS.opacity], // Transparent
};
export const MESH_COLOR_DARK_BASEMAP: IVtkColorObject = {
  edge: DEFAULTS.colorsAdaptedToBaseMap.defaultMeshDark.edge,
  surface: [...DEFAULTS.colorsAdaptedToBaseMap.defaultMeshDark.surface, DEFAULTS.opacity], // Transparent
};

/**
 * Shows or hides elements.
 * Checks if the length of hidden els has changed and then figures out the difference (shown elements).
 * Similar to select/deselect.
 *
 * @param hiddenElements
 * @param previousHiddenElements
 * @param meshes
 */
export const _showOrHideElements = (
  hiddenElements: Array<string>,
  previousHiddenElements: Array<string>,
  meshes: Array<IWorkspaceMesh>,
) => {
  const shown = difference(previousHiddenElements, hiddenElements);
  const hidden = hiddenElements;
  showElements(shown);
  hideElements(hidden);
  const tiledMeshes = meshes.filter((m: IWorkspaceMesh) => m.isTiled);
  if (tiledMeshes.length > 0) {
    const tilesToHide = tiledMeshes.filter((m: IWorkspaceMesh) => hidden.includes(m.id));
    tilesToHide &&
      store.dispatch({
        type: EWorkspaceMeshActionType.HIDE_MESH_TILES,
        data: tilesToHide.map((m: IWorkspaceMesh) => m.id),
      });
    const tilesToShow = tiledMeshes.filter((m: IWorkspaceMesh) => shown.includes(m.id));
    tilesToShow &&
      store.dispatch({
        type: EWorkspaceMeshActionType.SHOW_MESH_TILES,
        data: tilesToShow.map((m: IWorkspaceMesh) => m.id),
      });
  }
};

/**
 * Selects or deselects elements.
 * Checks if the length of selected els has changed and then figures out the difference (deselected elements).
 * Similar to show/hide.
 *
 * @param selectedElements
 * @param previouslySelectedElements
 */
export const _selectOrDeselectElements = (
  selectedElements: Array<string>,
  previouslySelectedElements: Array<string>,
) => {
  if (
    previouslySelectedElements.length !== selectedElements.length ||
    !isEqual(previouslySelectedElements, selectedElements)
  ) {
    const deselected = difference(previouslySelectedElements, selectedElements);
    const selected = selectedElements;

    selectElements(selected);
    deselectElements(deselected);
    return true;
  }
  return false;
};

/**
 * Gets the representation for a given item.
 *
 * @param itemId
 * @param dataType
 * @param drawnWSGeometries
 * @param drawnWSVariables
 * @param drawnWSMeshes
 */
export const _getRepresentation = (
  itemId: string,
  dataType: string,
  drawnWSGeometries: Array<IDrawnDataItem>,
  drawnWSVariables: Array<IDrawnDataItem>,
  drawnWSMeshes: Array<IDrawnDataItem>,
) => {
  let drawnItems = [];

  switch (dataType) {
    case DATA_TYPES.MESH: {
      drawnItems = drawnWSMeshes;
      break;
    }
    case DATA_TYPES.VARIABLE: {
      drawnItems = drawnWSGeometries;
      break;
    }
    case DATA_TYPES.GEOMETRY: {
      drawnItems = drawnWSVariables;
      break;
    }

    default:
      drawnItems = [];
      break;
  }

  const drawnItem = drawnItems.find(({ id }) => id === itemId);

  return drawnItem && drawnItem.representation
    ? drawnItem.representation
    : DEFAULT_DATA_TYPE_REPRESENTATIONS[dataType] || REPRESENTATION.SURFACE;
};

/**
 * Gets the itemType of a given item.
 *
 * @param itemId
 * @param dataType
 */
export const _getItemType = (
  itemId: string,
  dataType: string,
): EMeshItemTypes | EGeometryItemTypes | EVariableItemTypes | EOtherItemTypes => {
  switch (dataType) {
    case DATA_TYPES.MESH_TILE:
    case DATA_TYPES.MESH: {
      return getItemType(itemId, ELEMENT_CATEGORIES.MESH);
    }

    case DATA_TYPES.VARIABLE: {
      return getItemType(itemId, ELEMENT_CATEGORIES.VARIABLE);
    }

    case DATA_TYPES.GEOMETRY: {
      return getItemType(itemId, ELEMENT_CATEGORIES.GEOMETRY);
    }

    default:
      return OTHER_ITEM_TYPES.UNKNOWN;
  }
};

/**
 * Gets the point size for a given item type.
 *
 * @param dataType
 * @param itemType
 */
export const _getPointSize = (
  dataType: string,
  itemType: EMeshItemTypes | EGeometryItemTypes | EVariableItemTypes | EOtherItemTypes,
): number | undefined => {
  switch (dataType) {
    case DATA_TYPES.MESH_TILE:
    case DATA_TYPES.MESH: {
      return getItemPointSize(ELEMENT_CATEGORIES.MESH, itemType);
    }

    case DATA_TYPES.VARIABLE: {
      return getItemPointSize(ELEMENT_CATEGORIES.VARIABLE, itemType);
    }

    case DATA_TYPES.GEOMETRY: {
      return getItemPointSize(ELEMENT_CATEGORIES.GEOMETRY, itemType);
    }

    default:
      return getVisualizerConfiguration().pointSize;
  }
};

export const _getSendToBottom = (dataType: string) => {
  return dataType === DATA_TYPES.VARIABLE;
};

export const _getDefaultGradientSettings = (attributeName): IDrawnDataGradientSettings => {
  return getAttributeGradientSettings(attributeName);
};

export const _onBaseMapProjectionNotSupported = (toastSent: boolean) => {
  if (!toastSent) {
    store.dispatch({
      type: 'toast/ADD/NOTICE',
      toast: { text: t('BASEMAP_PROJECTION_NOT_SUPPORTED') },
    });
  }
  // TODO: joel; Move to SRS check, because ideally whether maptools work or not, is not related to if the base-map fails.
  store.dispatch({
    type: EMapToolActionType.DISALLOW_MAPTOOLS,
  });
  store.dispatch({
    type: EMapToolActionType.SET_VIEWER_BASEMAP_SUPPORTED,
    viewerBaseMapSupported: false,
  });
  // Set to no base-map to avoid attributions, errors etc:
  store.dispatch({
    type: EMapToolActionType.SET_BASEMAP,
    viewerBaseMapId: '',
  });
  // Use returned true to show  warning toast only once:
  return true;
};

export const _onBaseMapProjectionFetchFailed = () => {
  store.dispatch({
    type: 'toast/ADD/NOTICE',
    toast: { text: t('BASEMAP_PROJECTION_DEF_FAILED') },
  });
};

export const loadDataArrays = (rawDataItem: INotificationItemDataStructure, workspaceId: string) => {
  if (!rawDataItem) {
    return; // nothing to load
  }

  const { itemId, dataId, cellDataFields, pointDataFields, updated } = rawDataItem as INotificationItemDataStructure;

  const createAttributeNotification = (
    attributeName: string,
    isCellData: boolean,
  ): INotificationAttributeUpdatedMsg => {
    // todo hevo consider moving the logic for loading all attributes to a saga eg by dispatching workspace/data/LOAD_ITEM_ATTRIBUTES

    const attributeNotification: INotificationAttributeUpdatedMsg = {
      itemId,
      dataId,
      propertyName: attributeName,
      updated,
      isCellData,
    };

    return attributeNotification;
  };

  if (cellDataFields) {
    cellDataFields.forEach(({ Name: attributeName }) => {
      store.dispatch({
        type: WorkspaceActionType.LOAD_DATA_ATTRIBUTE,
        identity: { itemId, attributeName },
        workspaceId,
        attributeNotification: createAttributeNotification(attributeName, true),
      });
    });
  }

  if (pointDataFields) {
    pointDataFields.forEach(({ Name: attributeName }) => {
      store.dispatch({
        type: WorkspaceActionType.LOAD_DATA_ATTRIBUTE,
        identity: { itemId, attributeName },
        workspaceId,
        attributeNotification: createAttributeNotification(attributeName, false),
      });
    });
  }
};

/**
 * Given a data item id (dataId / pieceId), checks if it's a mesh, geometry or variable.
 *
 * @param dataItemId
 * @param totalVariablesToLoad
 * @param totalMeshesToLoad
 */
const _getDataIdType = (
  dataItemId: string,
  totalVariablesToLoad: Array<IWorkspaceVariable>,
  totalMeshesToLoad: Array<IWorkspaceMesh>,
) => {
  if (
    totalVariablesToLoad &&
    totalVariablesToLoad.length &&
    totalVariablesToLoad.findIndex(({ id }) => id === dataItemId) !== -1
  ) {
    return DATA_TYPES.VARIABLE;
  }

  if (
    totalMeshesToLoad &&
    totalMeshesToLoad.length &&
    totalMeshesToLoad.findIndex(({ id }) => id === dataItemId) !== -1
  ) {
    return DATA_TYPES.MESH;
  }

  if (dataItemId.includes('_')) {
    return DATA_TYPES.MESH_TILE;
  }

  return DATA_TYPES.GEOMETRY;
};

export const getElementAttributes = (dataArrays: Array<IElementDataArray>): Array<IWorkspaceAttribute> => {
  const attributes: Array<IWorkspaceAttribute> = dataArrays.map((dataArray: IElementDataArray) => {
    const attributeDataType = (dataArrayDataType) => {
      switch (dataArrayDataType) {
        case DATA_ARRAY_DATA_TYPES.DOUBLE:
        case DATA_ARRAY_DATA_TYPES.FLOAT:
          return ATTRIBUTE_DATA_TYPES.DOUBLE;
        case DATA_ARRAY_DATA_TYPES.INT:
        case DATA_ARRAY_DATA_TYPES.UNSIGNED_INT:
          return ATTRIBUTE_DATA_TYPES.INT32;
        default:
          return ATTRIBUTE_DATA_TYPES.STRING;
      }
    };
    return {
      name: dataArray.id,
      dataType: attributeDataType(dataArray.dataType),
      range: dataArray.range,
    };
  });
  return attributes;
};

export const mapRepresentation = (rep: ERepresentation) => {
  switch (rep) {
    case ERepresentation.POINTS:
      return { edgeVisibility: false, representation: 0 };
    case ERepresentation.WIREFRAME:
      return { edgeVisibility: false, representation: 1 };
    case ERepresentation.SURFACE:
      return { edgeVisibility: false, representation: 2 };
    case ERepresentation.SURFACEWITHWIREFRAME:
      return { edgeVisibility: true, representation: 2 };
    default:
      return { edgeVisibility: false, representation: 0 };
  }
};

export const handleTileDataItemCreatedOrUpdated = (
  data,
  partId: string,
  meshId: string,
  shouldUpdate: boolean,
  shouldSkipUpdate: boolean,
  viewerBaseMapId: string,
  drawnWorkspaceMesh: IDrawnDataItem,
  settingsLayer?: ISettingsLayer,
  colorMappingRange?: Array<number>,
) => {
  const debugOn = localStorage.getItem('DEBUG_ON') && localStorage.getItem('DEBUG_ON') === 'true';
  if (debugOn) {
    const pointNames = data
      .getPointData()
      .getArrays()
      .map((da) => da.getName());
    const fieldNames = data
      .getFieldData()
      .getArrays()
      .map((da) => da.getName());
    const cellNames = data
      .getCellData()
      .getArrays()
      .map((da) => da.getName());
    console.log('field names: ' + fieldNames, 'point names: ' + pointNames, 'cell names: ' + cellNames);
    const elevation = data
      .getPointData()
      .getArrays()
      .find((arr) => arr.getName() === 'Elevation');
    if (elevation) {
      const d = elevation.getData();
      console.log(d);
    }
  }

  const { appendData, updateData, showElement } = MikeVisualizerLib;
  let edgeColor;
  let surfaceColor;

  const representation: IRepresentation =
    settingsLayer && settingsLayer.representation
      ? mapRepresentation(settingsLayer.representation)
      : _getRepresentation(meshId, DATA_TYPES.MESH, [], [], drawnWorkspaceMesh ? [drawnWorkspaceMesh] : []);

  const pointSize = 4;

  const settingsEdgeColor =
    settingsLayer &&
    settingsLayer.noneRenderer &&
    settingsLayer.noneRenderer.wireframeSymbol &&
    settingsLayer.noneRenderer.wireframeSymbol.colors
      ? settingsLayer.noneRenderer.wireframeSymbol.colors
      : drawnWorkspaceMesh !== undefined
        ? drawnWorkspaceMesh.edgeColor
        : null;
  const settingsSurfaceColor =
    settingsLayer &&
    settingsLayer.noneRenderer &&
    settingsLayer.noneRenderer.surfaceSymbol &&
    settingsLayer.noneRenderer.surfaceSymbol.colors
      ? settingsLayer.noneRenderer.surfaceSymbol.colors
      : drawnWorkspaceMesh !== undefined
        ? drawnWorkspaceMesh.surfaceColor
        : null;
  const gradientAttributeName =
    settingsLayer && settingsLayer.renderedAttribute ? settingsLayer.renderedAttribute : null;
  const zAttributeName = settingsLayer && settingsLayer.zAttribute ? settingsLayer.zAttribute : null;
  const applyGradient = false; // gradientAttributeName !== null;
  const gradientSettings: IDrawnDataGradientSettings = _getDefaultGradientSettings(gradientAttributeName);
  if (!shouldSkipUpdate) {
    edgeColor = settingsEdgeColor
      ? settingsEdgeColor
      : viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id
        ? MESH_COLOR_DARK_BASEMAP.edge
        : MESH_COLOR_LIGHT_BASEMAP.edge;
    surfaceColor = settingsSurfaceColor
      ? settingsSurfaceColor
      : viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id
        ? MESH_COLOR_DARK_BASEMAP.surface
        : MESH_COLOR_LIGHT_BASEMAP.surface;

    let dataCreatedOrUpdated;
    if (shouldUpdate) {
      dataCreatedOrUpdated = updateData(
        data,
        partId,
        edgeColor,
        surfaceColor,
        representation,
        pointSize,
        applyGradient,
        gradientSettings,
        gradientAttributeName,
        true,
        false,
        zAttributeName === null ? 'n u l l' : zAttributeName,
      );
    } else {
      dataCreatedOrUpdated = appendData(
        data,
        partId,
        edgeColor,
        surfaceColor,
        representation,
        pointSize,
        applyGradient,
        gradientSettings,
        gradientAttributeName,
        true,
        false,
        zAttributeName === null ? 'n u l l' : zAttributeName,
      );
    }
  }
  showElement(partId);
  if (gradientAttributeName && colorMappingRange) {
    updateElementGradient(
      partId,
      gradientAttributeName,
      gradientAttributeName ? getAttributeGradientSettings(gradientAttributeName) : null,
      { colorMappingRange },
    );
  }
};

/**
 * Given a vtk object (`vtk({...})`) or vtk xml string, appends (new data) or updates existing viewer data.
 *
 * @param _drawnWorkspaceData
 * @param drawnWorkspaceVariables
 * @param drawnWorkspaceGeometries
 * @param drawnWorkspaceMeshes
 * @param totalVariablesToLoad
 * @param totalMeshesToLoad
 * @param viewerBaseMapId
 * @param _workspaceDataGradients
 * @param data
 * @param dataLength
 * @param updated
 * @param itemId
 * @param dataId
 * @param settingsLayer
 */
export const handleDataItemCreatedOrUpdated = (
  _drawnWorkspaceData: IDrawnData,
  drawnWorkspaceVariables: Array<IDrawnDataItem>,
  drawnWorkspaceGeometries: Array<IDrawnDataItem>,
  drawnWorkspaceMeshes: Array<IDrawnDataItem>,
  totalVariablesToLoad: Array<IWorkspaceVariable>,
  totalMeshesToLoad: Array<IWorkspaceMesh>,
  viewerBaseMapId: string,
  _workspaceDataGradients: Array<IDrawnDataGradient>,
  data,
  dataLength: number,
  updated: string,
  itemId: string,
  dataId: string,
  settingsLayer?: ISettingsLayer,
) => {
  // TODO dan: this method should be split. It can either handle items separately and/or handle draw/update separately.
  if (!data) {
    // Data can be undefined for some states, i.e. 'pending', 'scheduled'. There is no reason to draw anything in those cases.
    return null;
  }

  let edgeColor;
  let surfaceColor;
  let gradientAttributeName;
  let gradientSettings: IDrawnDataGradientSettings;
  let attributeLabelName;

  const existingWorkspaceData = _drawnWorkspaceData[itemId];

  // Find current zAttributeName for variable item:
  let zAttributeName = settingsLayer && settingsLayer.zAttribute ? settingsLayer.zAttribute : null;
  if (!zAttributeName) {
    const variable = drawnWorkspaceVariables.find(({ id }) => id === itemId);
    if (variable !== undefined) {
      zAttributeName = variable.zAttributeName;
    }
  }

  const dataType = _getDataIdType(itemId, totalVariablesToLoad, totalMeshesToLoad);
  // Make sure to update element if zAttributeName has changed:
  let existingWorkspaceDataUpdated = (existingWorkspaceData || {}).updated;
  if (dataType === DATA_TYPES.VARIABLE) {
    existingWorkspaceDataUpdated += zAttributeName !== undefined ? zAttributeName : '';
  }

  // TODO dan: Consider using dates. Consier using > to determine if shouldUpdateData (the data might also be older in some case).
  const shouldUpdateData = existingWorkspaceData && existingWorkspaceDataUpdated !== updated; // if this was a date comparison: updated > existingWorkspaceData.updated
  const shouldSkipUpdate = existingWorkspaceData && existingWorkspaceDataUpdated === updated; // if this was a date comparison: updated <= existingWorkspaceData.updated
  const representation: IRepresentation =
    settingsLayer && settingsLayer.representation
      ? mapRepresentation(settingsLayer.representation)
      : _getRepresentation(itemId, dataType, drawnWorkspaceGeometries, drawnWorkspaceVariables, drawnWorkspaceMeshes);
  const sendToBottom = _getSendToBottom(dataType);
  const itemType = _getItemType(itemId, dataType);
  const pointSize = _getPointSize(dataType, itemType);

  // If an item already exists, but data is unchanged, don't do anything
  if (shouldSkipUpdate) {
    return null;
  }

  const settingsEdgeColor =
    settingsLayer &&
    settingsLayer.noneRenderer &&
    settingsLayer.noneRenderer.wireframeSymbol &&
    settingsLayer.noneRenderer.wireframeSymbol.colors
      ? settingsLayer.noneRenderer.wireframeSymbol.colors
      : null;
  const settingsSurfaceColor =
    settingsLayer &&
    settingsLayer.noneRenderer &&
    settingsLayer.noneRenderer.surfaceSymbol &&
    settingsLayer.noneRenderer.surfaceSymbol.colors
      ? settingsLayer.noneRenderer.surfaceSymbol.colors
      : null;
  gradientAttributeName = settingsLayer && settingsLayer.renderedAttribute ? settingsLayer.renderedAttribute : null;
  const applyGradient = gradientAttributeName !== null;
  if (dataType === DATA_TYPES.GEOMETRY) {
    // Set specific color properties to geometries if they don't exist in the back-end (color is undefined).
    edgeColor = settingsEdgeColor
      ? settingsEdgeColor
      : viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id
        ? GEOMETRY_COLOR_DARK_BASEMAP.edge
        : GEOMETRY_COLOR_LIGHT_BASEMAP.edge;
    surfaceColor = settingsSurfaceColor
      ? settingsSurfaceColor
      : viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id
        ? GEOMETRY_COLOR_DARK_BASEMAP.surface
        : GEOMETRY_COLOR_LIGHT_BASEMAP.surface;
    attributeLabelName = settingsLayer && settingsLayer.labelAttribute;
  }

  if (dataType === DATA_TYPES.VARIABLE) {
    // Set specific color properties to variables if they don't exist in the back-end (color is undefined).
    edgeColor = settingsEdgeColor ? settingsEdgeColor : VARIABLE_COLOR.edge;
    surfaceColor = settingsSurfaceColor ? settingsSurfaceColor : VARIABLE_COLOR.surface;
  }

  // If a data gradient is present, use that one to apply gradients to meshes.
  // By default, meshes don't have a gradient.
  if (dataType === DATA_TYPES.MESH || dataType === DATA_TYPES.MESH_TILE) {
    // const meshDataGradient = workspaceDataGradients.find(({ elementId }) => elementId === itemId);

    // Set specific color properties to meshes if they don't exist in the back-end (color is undefined).
    edgeColor = settingsEdgeColor
      ? settingsEdgeColor
      : viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id
        ? MESH_COLOR_DARK_BASEMAP.edge
        : MESH_COLOR_LIGHT_BASEMAP.edge;
    surfaceColor = settingsSurfaceColor
      ? settingsSurfaceColor
      : viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id
        ? MESH_COLOR_DARK_BASEMAP.surface
        : MESH_COLOR_LIGHT_BASEMAP.surface;
  }

  // todo hevo - also get the settings in case of colormap (orange selections...)
  if (applyGradient && !gradientSettings) {
    const setting = _getDefaultGradientSettings(gradientAttributeName);
    gradientSettings = setting;
  }

  // If an item already exists, update its data instead of drawing new data.
  if (shouldUpdateData) {
    const dataUpdated = updateDataV2({
      vtkObjectOrXmlString: data,
      elementId: itemId,
      elementEdgeColor: edgeColor,
      elementSurfaceColor: surfaceColor,
      representation,
      pointSize,
      applyGradient,
      gradientSettings,
      gradientAttributeName,
      sendToBottom: true, // Always try to use points as Z index.
      zAttributeName: zAttributeName === null ? 'n u l l' : zAttributeName,
    });

    let drawnDataItem: IDrawnDataItem = null;
    if (dataUpdated) {
      const { dataArrays, fieldData } = dataUpdated as IThreeDRenderElement;

      // TODO hevo use attributes instead of dataarrays all over! Or maybe better, the otherway around?
      const attributes: Array<IWorkspaceAttribute> = getElementAttributes(dataArrays);

      if (applyGradient && !gradientAttributeName) {
        gradientAttributeName = getGradientAttributeName(itemId);
      }

      drawnDataItem = {
        id: itemId,
        dataId,
        length: dataLength,
        representation,
        pointSize,
        edgeColor,
        surfaceColor,
        dataArrays,
        fieldData,
        attributes,
        itemType,
        drawn: true,
        updated,
      };

      store.dispatch({
        type: EWorkspaceDataActionType.UPDATE_DATA_ITEM,
        workspaceLength: dataLength,
        workspaceItemId: itemId,
        workspaceItemDataId: dataId,
        workspaceRepresentation: representation,
        workspacePointSize: pointSize,
        workspaceDataEdgeColor: edgeColor,
        workspaceDataSurfaceColor: surfaceColor,
        workspaceDataArrays: dataArrays,
        workspaceFieldData: fieldData,
        workspaceAttributes: attributes,
        workspaceItemType: itemType,
        workspaceItemDrawn: true,
        workspaceItemUpdated: updated,
        statisticsAttributeName: gradientAttributeName,
        attributeLabelName,
      });
      if (gradientAttributeName) {
        updateWorkspaceDataStatisticAttributeName(itemId, gradientAttributeName, false);
      }
    }

    // return dataUpdated;
    return drawnDataItem;
  }

  // Draw new data by default.
  const dataCreated = appendDataV2({
    vtkObjectOrXmlString: data,
    elementId: itemId,
    elementEdgeColor: edgeColor,
    elementSurfaceColor: surfaceColor,
    representation,
    pointSize,
    applyGradient,
    gradientSettings,
    gradientAttributeName,
    usePointsAsZIndex: true, // Always try to use points as Z index.
    sendToBottom,
    zAttributeName,
  });

  let drawnDataItem: IDrawnDataItem = null;
  if (dataCreated) {
    const { zAttributeName: appliedZAttributeName } = dataCreated;
    const { dataArrays, fieldData } = dataCreated as IThreeDRenderElement;

    // TODO hevo use attributes instead of dataarrays all over!
    const attributes: Array<IWorkspaceAttribute> = getElementAttributes(dataArrays);

    if (applyGradient && !gradientAttributeName) {
      gradientAttributeName = getGradientAttributeName(itemId);
    }

    drawnDataItem = {
      id: itemId,
      dataId,
      length: dataLength,
      representation,
      pointSize,
      edgeColor,
      surfaceColor,
      dataArrays,
      fieldData,
      attributes,
      itemType,
      drawn: true,
      updated,
    };
    store.dispatch({
      type: EWorkspaceDataActionType.ADD_DATA_ITEM,
      workspaceLength: dataLength,
      workspaceItemId: itemId,
      workspaceItemDataId: dataId,
      workspaceRepresentation: representation,
      workspacePointSize: pointSize,
      workspaceDataEdgeColor: edgeColor,
      workspaceDataSurfaceColor: surfaceColor,
      workspaceDataArrays: dataArrays,
      workspaceFieldData: fieldData,
      workspaceAttributes: attributes,
      workspaceItemType: itemType,
      workspaceItemDrawn: true,
      workspaceItemUpdated: updated,
      zAttributeName: appliedZAttributeName,
      statisticsAttributeName: gradientAttributeName,
    });
    if (gradientAttributeName) {
      updateWorkspaceDataStatisticAttributeName(itemId, gradientAttributeName, false);
    }
  }

  return drawnDataItem;
};

const getDataItemId = (_drawnWorkspaceData: IDrawnData, itemDataId: string) => {
  const drawnDataKey = Object.keys(_drawnWorkspaceData).find((key) => {
    if (_drawnWorkspaceData[key].dataId === itemDataId) {
      return true;
    }

    return false;
  });

  if (!drawnDataKey) {
    return null;
  }

  return _drawnWorkspaceData[drawnDataKey].id;
};

export const handleDataItemDeleted = (_drawnWorkspaceData: IDrawnData, itemDataId: string) => {
  const itemId = getDataItemId(_drawnWorkspaceData, itemDataId);
  if (itemId) {
    const dataDeleted = deleteData(itemId);

    if (dataDeleted) {
      const { [itemId]: omit, ...drawnWorkspaceData } = _drawnWorkspaceData;
      _drawnWorkspaceData = drawnWorkspaceData;
    }
  }
  return _drawnWorkspaceData;
};

export const handleDataItemsDeleted = (_drawnWorkspaceData: IDrawnData, itemDataIds: Array<string>) => {
  const itemIds = itemDataIds.map((itemDataId: string) => getDataItemId(_drawnWorkspaceData, itemDataId));
  return deleteDataItems(_drawnWorkspaceData, itemIds);
};

export const deleteDataItems = (_drawnWorkspaceData: IDrawnData, itemDataIds: Array<string>) => {
  itemDataIds.forEach((itemId: string) => {
    const dataDeleted = deleteData(itemId);
    if (dataDeleted) {
      delete _drawnWorkspaceData[itemId];
    }
  });

  return _drawnWorkspaceData;
};

export const handleDataArrayAddedOrUpdated = (
  _drawnWorkspaceData: IDrawnData,
  totalVariablesToLoad: Array<IWorkspaceVariable>,
  totalMeshesToLoad: Array<IWorkspaceMesh>,
  data,
  dataId: string,
  updated: string,
) => {
  if (!data || !dataId) {
    return null;
  }

  const drawnDataKey = Object.keys(_drawnWorkspaceData).find((key) => {
    return _drawnWorkspaceData[key].dataId === dataId;
  });

  // If the item (mesh, geometry, variable) have not been added yet, the data array cannot be updated
  if (!drawnDataKey) {
    console.warn('Trying to update cell or point data for item not added yet', dataId);
    return null;
  }

  const drawnData: IDrawnDataItem = _drawnWorkspaceData[drawnDataKey];

  const { id: itemId } = drawnData;

  // todo hevo Do we need similar logic regrding shouldUpdateData as for updateData ?

  const dataType = _getDataIdType(itemId, totalVariablesToLoad, totalMeshesToLoad);
  const sendToBottom = _getSendToBottom(dataType);

  const usePointsAsZIndex = true; // Always try to use points as Z index.
  const renderedElement = updateDataArray(itemId, data, usePointsAsZIndex, sendToBottom);

  if (renderedElement) {
    const { dataArrays } = renderedElement as IThreeDRenderElement;

    const attributes: Array<IWorkspaceAttribute> = getElementAttributes(dataArrays);

    const nextDrawndata: IDrawnDataItem = {
      ...drawnData,
      // length: drawnData.length, // todo hevo Should be updated, how do we know? maybe need some information from API?, Not sure if it is used anymore?
      dataArrays,
      attributes,
      updated,
    };

    store.dispatch({
      type: 'workspace/data/item/DATAARRAYS_UPDATE',
      itemId: nextDrawndata.id,
      dataId: nextDrawndata.dataId,
      dataArrays: nextDrawndata.dataArrays,
      attributes: nextDrawndata.attributes,
      length: nextDrawndata.length, // todo hevo
      itemUpdated: nextDrawndata.updated,
    });
    _drawnWorkspaceData[drawnDataKey] = nextDrawndata; // this._drawnWorkspaceData[drawnDataKey] = nextDrawndata;
  }
  return _drawnWorkspaceData;
};

export const handleDataArrayDeleted = (
  _drawnWorkspaceData: IDrawnData,
  itemId: string,
  dataId: string,
  attributeName: string,
  updated: string,
) => {
  if (!itemId || !dataId || !attributeName) {
    return;
  }

  const drawnDataKey = Object.keys(_drawnWorkspaceData).find((key) => {
    return _drawnWorkspaceData[key].dataId === dataId;
  });

  // If the item (mesh, geometry, variable) have not been added yet, the data array cannot be deleted
  if (!drawnDataKey) {
    console.warn('Trying to delete cell or point data for item not added yet', dataId);
    return;
  }

  removeDataArray(itemId, attributeName);

  store.dispatch({
    type: 'workspace/data/item/DATAARRAY_DELETED',
    itemId,
    dataId,
    attributeName,
    // length: nextDrawndata.length, // todo hevo
    itemUpdated: updated,
  });
};

/**
 * Highlights the element passed in.
 *
 * @param elementIdToHighlight
 * @param selectedItems
 * @param rendererdElement
 * @param viewerBaseMapId
 */
export const _highlightElement = (
  elementIdToHighlight: string,
  selectedItems: Array<string>,
  rendererdElement: IThreeDRenderElement,
  viewerBaseMapId: string,
) => {
  if (elementIdToHighlight) {
    const existingSelectionData = selectedItems.find((id: string) => id === elementIdToHighlight);
    if (existingSelectionData) {
      return highlight(elementIdToHighlight, {
        edgeHighlightColor: HIGHLIGHT_SELECTION_COLOR.edge,
        surfaceHighlightColor: HIGHLIGHT_SELECTION_COLOR.surface,
      });
    }

    if (rendererdElement) {
      const isLightBasemap = viewerBaseMapId !== MMG_BASE_MAPS.MAPBOX_SATELLITE.id;
      return highlight(elementIdToHighlight, {
        edgeHighlightColor: _getRgbHighlightColorAdaptedToBaseMap(rendererdElement.edgeColor, isLightBasemap),
        surfaceHighlightColor: _getRgbHighlightColorAdaptedToBaseMap(rendererdElement.surfaceColor, isLightBasemap),
      });
    }
    return highlight(elementIdToHighlight);
  }

  return false;
};
