import { createSelector } from 'reselect';

import { IOperationMetadata, OPERATION_RESULT_TYPES } from 'models/IOperations';
import WorkspaceOperationUtils from 'store/selectors/WorkspaceOperationUtils';
import { IWorkspaceVariable } from 'models/IVariables';
import { IGlobalState } from '../store';

const workspaceVariables = (state: IGlobalState) => state.WorkspaceVariableReducer.workspaceVariables;

const workspaceOperations = (state: IGlobalState) => state.WorkspaceOperationReducer.workspaceOperations;

/**
 * Get operations that have Variable as a result type.
 */
const getVariableOperations = createSelector([workspaceOperations], (operations: Array<IOperationMetadata>) => {
  if (!operations) {
    return [];
  }

  return operations.filter(({ resultType }) => resultType === OPERATION_RESULT_TYPES.VARIABLE);
});

/**
 * Gets operations only containing the operation matching the last state of each variable.
 */
const getLatestVariableOperations = createSelector(
  [getVariableOperations, workspaceVariables],
  (operations: Array<IOperationMetadata>, variables: Array<IWorkspaceVariable>) => {
    if (!variables) {
      return [];
    }

    return variables
      .map(({ id }) => {
        return WorkspaceOperationUtils.getLatestOperation(
          WorkspaceOperationUtils.filterOperationsByOutputId(operations, id),
        );
      })
      .filter((operation) => operation);
  },
);

/**
 * Gets operations that do not have `outputIds`, that have Variable as `resultType`.
 * NB: it is expected that the operation state only contains the latest updated operation; there aren't multiple operations with the same id.
 */
const getVariableOperationsWithoutOutput = createSelector(
  [getVariableOperations],
  (operations: Array<IOperationMetadata>) => {
    if (!operations) {
      return [];
    }

    const operationsWithoutOutput = WorkspaceOperationUtils.filterOperationsWithoutOutputIds(operations);

    // todo hevo sort operations by created date?

    return operationsWithoutOutput;
  },
);

const self = {
  getVariableOperations,
  getLatestVariableOperations,
  getVariableOperationsWithoutOutput,
};

export default self;
