import { uniq } from 'lodash-es';
import { IWorkspaceGeometry } from 'models/IGeometries';
import { IAction } from '../actions/Action';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EWorkspaceGeometryActionType } from '../actions/WorkspaceGeometryActionType';

const initialState: IWorkspaceGeometryState = {
  workspaceGeometries: [] as Array<IWorkspaceGeometry>,
  workspaceGeometriesLoading: false,
  hiddenWorkspaceGeometries: [],
  selectedWorkspaceGeometries: [],
  submittedForDeleteWorkspaceGeometries: [],
  userHiddenGeometryIds: [],
  updatingGeometryData: false,
  creatingGeometryData: false,
};

export interface IWorkspaceGeometryState {
  workspaceGeometries: Array<IWorkspaceGeometry>;
  workspaceGeometriesLoading: boolean;
  hiddenWorkspaceGeometries: Array<string>; // Holds the currently hidden geometry ids. TODO hevo Find better name - maybe hiddenGeometryIds ?
  selectedWorkspaceGeometries: Array<string>;
  submittedForDeleteWorkspaceGeometries: Array<string>;
  userHiddenGeometryIds: Array<string>; // Holds the geoemtries hidden in the workspace by the user
  updatingGeometryData: boolean;
  creatingGeometryData: boolean;
}

export interface IWorkspaceGeometryAction
  extends IAction<any, EWorkspaceGeometryActionType | EWorkspaceActionType.CLOSE>,
    IWorkspaceGeometryState {
  // Don't use reducer actions like this, use `action.data`:
  workspaceGeometry: IWorkspaceGeometry;
  geometryIds: Array<string>;
  workspaceGeometryIds: Array<string>;
  workspaceGeometryId: string;
  updateUserSettings: boolean;
  geometryId: string;
  unselectOthers: boolean;
}

/**
 * Workspace Geometries Reducer.
 * - returns new states for matched workspace geometry actions.
 *
 * @name WorkspaceGeometryReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IWorkspaceGeometryState = initialState, action: IWorkspaceGeometryAction) {
  const updateHiddenGeometryIds = (hiddenGeometryIds: Array<string>, updateUserSettings: boolean) => {
    return {
      ...state,
      hiddenWorkspaceGeometries: hiddenGeometryIds,
      userHiddenGeometryIds: updateUserSettings ? hiddenGeometryIds : state.userHiddenGeometryIds,
    };
  };

  switch (action.type) {
    case EWorkspaceGeometryActionType.UPDATING: {
      return { ...state, updatingGeometryData: action.data };
    }
    case EWorkspaceGeometryActionType.CREATING: {
      return { ...state, creatingGeometryData: action.data };
    }
    case EWorkspaceGeometryActionType.LOAD: {
      return { ...state, workspaceGeometriesLoading: true };
    }

    case EWorkspaceGeometryActionType.LOAD_SUCCESS: {
      const geometries = action.workspaceGeometries;
      return { ...state, workspaceGeometriesLoading: false, workspaceGeometries: geometries };
    }

    case EWorkspaceGeometryActionType.CLEAR:
      return { ...state, workspaceGeometries: initialState.workspaceGeometries };

    case EWorkspaceActionType.CLOSE: {
      return { ...state, ...initialState };
    }

    case EWorkspaceGeometryActionType.DELETE: {
      const submittedForDeleteWorkspaceGeometries = state.workspaceGeometries.filter(() => true);
      // (g) => action.workspaceGeometries.indexOf(g.id) === -1, // TS error & always true
      return { ...state, submittedForDeleteWorkspaceGeometries };
    }

    case EWorkspaceGeometryActionType.ITEMS_DELETED: {
      const idsToDelete = action.geometryIds;
      if (!idsToDelete || idsToDelete.length === 0) {
        return state;
      }
      const workspaceGeometries = state.workspaceGeometries.filter((g) => idsToDelete.indexOf(g.id) === -1);
      return { ...state, workspaceGeometries };
    }

    case EWorkspaceGeometryActionType.DELETE_SUCCESS: {
      const submittedForDeleteWorkspaceGeometries = [];
      const workspaceGeometries = state.workspaceGeometries.filter(() => true);
      // (g) => action.workspaceGeometries.indexOf(g.id) === -1, // TS error & always true
      return { ...state, workspaceGeometries, submittedForDeleteWorkspaceGeometries };
    }

    case EWorkspaceGeometryActionType.DELETE_FAILED: {
      const submittedForDeleteWorkspaceGeometries = [];
      return { ...state, submittedForDeleteWorkspaceGeometries };
    }

    case EWorkspaceGeometryActionType.ITEM_ADDED_OR_UPDATED: {
      const { workspaceGeometry } = action;
      if (!workspaceGeometry) {
        return state;
      }
      const geometryIndex = state.workspaceGeometries.findIndex((g) => g.id === workspaceGeometry.id);
      // Add new geometry if not found
      if (geometryIndex === -1) {
        return {
          ...state,
          workspaceGeometries: [...state.workspaceGeometries, workspaceGeometry],
        };
      }
      // replace existing geometry if found
      return {
        ...state,
        workspaceGeometries: [
          ...state.workspaceGeometries.slice(0, geometryIndex),
          workspaceGeometry,
          ...state.workspaceGeometries.slice(geometryIndex + 1),
        ],
      };
    }

    case EWorkspaceGeometryActionType.HIDE_ITEMS: {
      const hiddenGeometryIds = uniq([...state.hiddenWorkspaceGeometries, ...action.workspaceGeometryIds]);
      return updateHiddenGeometryIds(hiddenGeometryIds, action.updateUserSettings);
    }

    case EWorkspaceGeometryActionType.HIDE_ITEM: {
      const hiddenGeometryIds = uniq([...state.hiddenWorkspaceGeometries, action.workspaceGeometryId]);
      return updateHiddenGeometryIds(hiddenGeometryIds, action.updateUserSettings);
    }

    case EWorkspaceGeometryActionType.HIDE_ALL: {
      const geometryIds = state.workspaceGeometries.map(({ id }) => id);
      const hiddenGeometryIds = [...geometryIds];
      return updateHiddenGeometryIds(hiddenGeometryIds, action.updateUserSettings);
    }

    case EWorkspaceGeometryActionType.SHOW_ITEM: {
      const hiddenGeometryIds = state.hiddenWorkspaceGeometries.filter((mId) => mId !== action.workspaceGeometryId);
      return updateHiddenGeometryIds(hiddenGeometryIds, action.updateUserSettings);
    }

    case EWorkspaceGeometryActionType.SHOW_ITEM_ONLY: {
      const hiddenGeometryIds = state.workspaceGeometries
        .map((wg: IWorkspaceGeometry) => wg.id)
        .filter((id: string) => id !== action.geometryId);
      const userHidden = action.updateUserSettings
        ? state.userHiddenGeometryIds.filter((id: string) => id !== action.geometryId)
        : state.userHiddenGeometryIds;
      return {
        ...state,
        hiddenWorkspaceGeometries: hiddenGeometryIds,
        userHiddenGeometryIds: userHidden,
      };
    }

    case EWorkspaceGeometryActionType.SHOW_ALL: {
      return updateHiddenGeometryIds([], action.updateUserSettings);
    }

    case EWorkspaceGeometryActionType.SHOW_ALL_BUT_USER_HIDDEN: {
      const hiddenGeometryIds = state.userHiddenGeometryIds;
      return updateHiddenGeometryIds(hiddenGeometryIds, false);
    }

    case EWorkspaceGeometryActionType.UPDATE_USER_HIDDEN: {
      const hiddenGeometryIds = uniq([...state.userHiddenGeometryIds, ...action.userHiddenGeometryIds]);
      return { ...state, userHiddenGeometryIds: hiddenGeometryIds };
    }

    case EWorkspaceGeometryActionType.SELECT: {
      if (action.unselectOthers) {
        return { ...state, selectedWorkspaceGeometries: [action.workspaceGeometryId] };
      } else {
        return {
          ...state,
          selectedWorkspaceGeometries: [...state.selectedWorkspaceGeometries, action.workspaceGeometryId],
        };
      }

      /* return {
        ...state,
        selectedWorkspaceGeometries: action.unselectOthers
          ? [action.workspaceGeometryId]
          : [...state.selectedWorkspaceGeometries, action.workspaceGeometryId],
      }; */
    }

    case EWorkspaceGeometryActionType.DESELECT: {
      const selectedWorkspaceGeometries = state.selectedWorkspaceGeometries.filter(
        (el) => el !== action.workspaceGeometryId,
      );
      return { ...state, selectedWorkspaceGeometries };
    }

    case EWorkspaceGeometryActionType.RESET_SELECTIONS:
    case EWorkspaceGeometryActionType.DESELECT_ALL: {
      return { ...state, selectedWorkspaceGeometries: [] };
    }

    case EWorkspaceGeometryActionType.SELECT_MULTIPLE:
      return {
        ...state,
        selectedWorkspaceGeometries: uniq([...state.selectedWorkspaceGeometries, ...action.workspaceGeometryIds]),
      };

    default:
      return state;
  }
}
