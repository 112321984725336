import { IGlobalState, store } from '../store';
import { createSelector } from 'reselect';
import WorkspaceOperationUtils from './WorkspaceOperationUtils';

interface IQueryId {
  queryId: string;
}

// selects the queryId from props
const queryIdProp = (_state, props: IQueryId) => props.queryId;

export const getWorkspaceQueries = (state: IGlobalState) => state.WorkspaceQueryReducer.workspaceQueries;
export const getVolatileOperations = (state: IGlobalState) => state.WorkspaceQueryReducer.volatileOperations;

/**
 * Selector to return all operations having the query defined by the queryId prop as input.
 */
export const _getLatestOperationForQuery = createSelector(
  [getVolatileOperations, queryIdProp],
  (queryOperations, queryId) => {
    if (!queryOperations || queryOperations.length === 0 || !queryId) {
      return null;
    }

    const operations = WorkspaceOperationUtils.filterOperationsByInputId(queryOperations, queryId);
    const latestOperation = WorkspaceOperationUtils.getLatestOperation(operations);
    return latestOperation;
  },
);

/**
 * Returns an instance of a selector for getting last operation for the query based on the queryId prop.
 */
const makeGetQueryOperation = () => {
  return createSelector([_getLatestOperationForQuery], (operation) => {
    return operation;
  });
};

export const justGetWorkspaceQueries = () => {
  const state = store.getState();
  if (!state) {
    console.info(`No state`);
    return [];
  }
  return (state.WorkspaceQueryReducer || {}).workspaceQueries || [];
};

export const justGetWorkspaceQueriesForGeometry = (geometryId: string) => {
  return justGetWorkspaceQueries().filter((q) => q.parentItemId === geometryId);
};

const self = {
  makeGetQueryOperation,
  _getLatestOperationForQuery,
};

export default self;
