import React, { useEffect, useState } from 'react';

import { IProject } from 'models/IProject';

import MikeTable from '@mike/mike-shared-frontend/mike-table';

import { columns, tableConfig } from './projectsTableConfig';
import { store } from '../../store/store';
import { ROUTES, getRouteByPath } from 'src/app/routes';
import { t } from 'src/translations/i18n';
import { useHistory } from 'react-router-dom';

type IProjectsTableProps = {
  filter: string;
  projectList: Array<IProject>;
  projectListLoading: boolean;
  projectListLoadingFailed?: boolean;
};

/**
 * @name MmgProjectsTable
 * @param props
 * @summary Table rendering the list of projects
 *
 */
const MmgProjectsTable = (props: IProjectsTableProps) => {
  const { filter, projectList, projectListLoading } = props;
  const history = useHistory();
  const [filteredRows, setFilteredRows] = useState([]);
  useEffect(
    () => {
      if (filter === '') {
        setFilteredRows(projectList);
      }
      const filterRows: Array<IProject> = [];
      for (const row of projectList) {
        let contains = false;
        for (const col of columns) {
          if (contains || !col.filtrable) {
            break;
          }
          contains = false;
          const objValues: Array<string> = Object.values(row);
          for (const value of objValues) {
            if (
              value &&
              value
                .toString()
                .toLowerCase()
                .includes(filter.toLowerCase())
            ) {
              filterRows.push(row);
              contains = true;
              break;
            }
          }
        }
      }
      setFilteredRows(filterRows);
    },
    [filter, projectList],
  );

  const handleProjectSelect = (project: IProject) => {
    if (project.capabilities && project.capabilities.canReadContent) {
      const workspaceListRoute = getRouteByPath(ROUTES.workspaceList.path, {
        projectId: project.id,
      });

      history.push(workspaceListRoute);
    } else {
      store.dispatch({
        type: 'toast/ADD/NOTICE',
        toast: { text: t('PROJECTS_NO_ACCESS') },
      });
    }
  };

  return (
    <MikeTable
      rows={filteredRows}
      config={tableConfig}
      loading={projectListLoading}
      columns={columns}
      onSelect={handleProjectSelect}
    />
  );
};

export default MmgProjectsTable;
