import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MikeButton from '@mike/mike-shared-frontend/mike-button/MikeButton';
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles';
import { ReactComponent as Close } from '@mike/mike-shared-frontend/media/icons/Close';
import { ReactComponent as Alert } from '@mike/mike-shared-frontend/media/icons/Alert';
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme';
import { css } from 'emotion';
import { t } from 'src/translations/i18n';
import { IProject } from 'src/models/IProject';

interface IProps {
  /**
   * Banner has been dismissed.
   */
  dismissed?: boolean;
  /**
   * Dismiss callback.
   */
  onDismissCallBack: () => void;
  /**
   * Project.
   */
  project: IProject;
}

const iconStyle = (disabled = false) => {
  const color = disabled ? mikePalette.secondary.light : mikePalette.secondary.main;
  return css`
    path {
      fill: ${color};
    }
  `;
};

// TODO: Use emotion css styling iso JSS and useStyles / makeStyles.
const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    root: {
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: theme.spacing(6),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      position: 'absolute',
      zIndex: Math.round(theme.zIndex.appBar * 0.98),
      borderBottom: `1px solid ${theme.palette.mediumGrey.main}`,
      boxShadow: `0 0 5px ${theme.palette.darkGrey.main}`,
    },
    warningContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(2.5),
    },
    warningText: {
      paddingLeft: theme.spacing(1.5),
    },
    button: {
      color: theme.palette.primary.main,
    },
    alertContainer: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      borderRadius: theme.spacing(1.5),
      backgroundColor: theme.palette.error.main,
      margin: 'auto',
    },
    alertSvg: {
      '& path': {
        fill: '#fff',
        transform: 'translate(-8px, -8px)',
      },
      '& path:first-of-type': {
        fill: 'transparent',
        stroke: '#fff',
        strokeWidth: '2px',
        strokeJoin: 'miter',
      },
    },
  });
});

/**
 * @name MmgProjectReadOnlyBanner
 * @summary The support bottom, opening the support menu.
 *
 *  @param props
 */
export const MmgProjectReadOnlyBanner = (props: IProps) => {
  const match = useRouteMatch({
    path: '/project/:id',
    strict: true,
    sensitive: true,
  });

  const classes = useStyles();
  const { onDismissCallBack, dismissed = false, project } = props;
  const isAccessAllowed = project && project.capabilities && project.capabilities.canUpdateContent;

  if (dismissed || !project) {
    return <></>;
  }

  return (
    match &&
    !isAccessAllowed && (
      <div className={classes.root}>
        <div className={classes.warningContainer}>
          <div className={classes.alertContainer}>
            <Alert
              className={classes.alertSvg}
              width={undefined} // overrules the default 40 value
              height={undefined} // overrules the default 40 value
              viewBox="-6.5 -5.5 37 37"
            />
          </div>

          <Typography className={classes.warningText} variant="body2">
            {t('PROJECTS_READONLY_ACCESS')}
          </Typography>
        </div>

        <MikeButton buttontype="text" onClick={onDismissCallBack}>
          {t('DISMISS')}
          <Close className={iconStyle()} />
        </MikeButton>
      </div>
    )
  );
};

export default MmgProjectReadOnlyBanner;
