import { IWorkspaceQuery } from 'models/IQueries';
import { IAction } from '../actions/Action';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EWorkspaceQueryActionType } from '../actions/WorkspaceQueryActionType';
import { uniq } from 'lodash-es';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { FeatureCollection } from 'geojson';
import { convertGeojsonToVtp } from 'src/workspaces/viewer/extensions/viewer-geojson-converter';
import {
  SELECTION_GEOMETRY_COLOR,
  SELECTION_RESULT_COLOR,
  SELECTION_SOURCE_COLOR,
} from 'src/queries/spatial-selections/spatial-selection-utils';
import { addLabel, getSelectionLabelStyle, ILabel } from 'src/workspaces/viewer/viewer-utils';
import { Fill, Style } from 'ol/style';
import { IQueryCompleted } from 'src/models/IQueryCompleted';
import { REPRESENTATION } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConstants';
import { EOperationActionType } from '../actions/OperationActionType';
import { IOperationMetadata } from 'src/models/IOperations';

const {
  hideElements,
  showElement,
  showElements,
  hide2DElements,
  show2DElements,
  appendData,
  getState,
} = MikeVisualizerLib;

const initialState: IWorkspaceQueryState = {
  workspaceQueries: new Array<IWorkspaceQuery>(),
  workspaceQueriesLoading: false,
  selectionResultIds: new Array<string>(),
  selectionGeometryIds: new Array<string>(),
  selectionLabels: new Array<ILabel>(),
  shownSelectionIds: new Array<string>(),
  selectionIdsToBeReshown: new Array<string>(),
  selectionIdsHiddenDueToInvisibleParent: new Array<string>(),
  queryResponse: null,
  volatileOperations: [],
};

export interface IWorkspaceQueryState {
  workspaceQueries: Array<IWorkspaceQuery>;
  workspaceQueriesLoading: boolean;
  selectionResultIds: Array<string>;
  selectionGeometryIds: Array<string>;
  selectionLabels: Array<ILabel>;
  shownSelectionIds: Array<string>;
  selectionIdsToBeReshown: Array<string>;
  selectionIdsHiddenDueToInvisibleParent: Array<string>;
  queryResponse: IQueryCompleted | null;
  volatileOperations: Array<IOperationMetadata>;
}

export interface IWorkspaceQueryAction
  extends IAction<
      any,
      EOperationActionType.VOLATILE_CREATE_OR_UPDATE | EWorkspaceQueryActionType | EWorkspaceActionType.CLOSE
    >,
    IWorkspaceQueryState {
  queryIds?: Array<string>;
  workspaceQuery?: IWorkspaceQuery;
  id?: string;
  ids?: Array<string>;
  featureCollection?: FeatureCollection<any, any>;
  labelName?: string;
  labelId?: string;
  selectionId?: string;
  sourceItemId?: string;
}

const SELECTORLABEL = '_selectorLabel';
export const getSelectionGeometryId = (selectionId: string) => `${selectionId}_selectorGeom`;
export const getSelectionLabelId = (selectionId: string) => `${selectionId}` + SELECTORLABEL;

const getSelectionParentId = (queries: Array<IWorkspaceQuery>, id: string) => {
  const workspaceQuery = queries.find((wq: IWorkspaceQuery) => id.startsWith(wq.id));
  return workspaceQuery !== undefined && workspaceQuery.parentItemId ? workspaceQuery.parentItemId : '';
};

/**
 * Workspace Query Reducer.
 * - returns new states for matched workspace query actions.
 *
 * @name WorkspaceQueryReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action: IWorkspaceQueryAction) {
  switch (action.type) {
    case EOperationActionType.VOLATILE_CREATE_OR_UPDATE: {
      // ToDo: Check if operation belongs to any queryId (mesh or geometry selection layer id)
      const operationMetadata = action.data;
      if (!operationMetadata) {
        return state;
      }

      const existingIndex = state.volatileOperations.findIndex((item) => item.id === operationMetadata.id);

      // Add new operation if not found
      if (existingIndex === -1) {
        return {
          ...state,
          volatileOperations: [...state.volatileOperations, operationMetadata],
        };
      }

      const existingOperation = state.volatileOperations[existingIndex];

      if (operationMetadata.state === 'Cancelled') {
        const wOp = state.volatileOperations.filter((op) => {
          return op.id !== operationMetadata.id;
        });
        return {
          ...state,
          volatileOperations: wOp,
        };
      }
      // Replace only if it was updated more recently
      if (existingOperation.updated <= operationMetadata.updated) {
        return {
          ...state,
          volatileOperations: [
            ...state.volatileOperations.slice(0, existingIndex),
            operationMetadata,
            ...state.volatileOperations.slice(existingIndex + 1),
          ],
        };
      }
      return state;
    }
    case EWorkspaceQueryActionType.QUERY_COMPLETED: {
      return { ...state, queryResponse: action.data };
    }
    case EWorkspaceQueryActionType.SELECTIONS_RESHOW_DUE_TO_REVISIBLE_PARENT: {
      const ids = action.ids;
      const idsHiddenDueToInvisibleParent = state.selectionIdsHiddenDueToInvisibleParent;
      const hiddenDueToParentsVisibility = ids.filter((id: string) => idsHiddenDueToInvisibleParent.includes(id));
      if (hiddenDueToParentsVisibility.length > 0) {
        const geomIds = hiddenDueToParentsVisibility.map(getSelectionGeometryId);
        const labelIds = hiddenDueToParentsVisibility.map(getSelectionLabelId);
        showElements(hiddenDueToParentsVisibility.concat(geomIds));
        show2DElements(labelIds);
        return {
          ...state,
          selectionIdsHiddenDueToInvisibleParent: uniq(
            idsHiddenDueToInvisibleParent.filter((id: string) => !hiddenDueToParentsVisibility.includes(id)),
          ),
          shownSelectionIds: uniq(state.shownSelectionIds.concat(hiddenDueToParentsVisibility)),
        };
      } else {
        return state;
      }
    }
    case EWorkspaceQueryActionType.SELECTIONS_HIDE_DUE_TO_INVISIBLE_PARENT: {
      const ids = action.ids;
      if (ids && ids.length > 0) {
        const shownSelectionIds = state.shownSelectionIds;
        const idsToHide = ids.filter((id: string) => shownSelectionIds.includes(id));
        if (idsToHide.length > 0) {
          const { hidden2DElementIds, hiddenElementIds } = getState();
          const geomIds = idsToHide.map(getSelectionGeometryId);
          const labelIds = idsToHide.map(getSelectionLabelId);
          hideElements(uniq(hiddenElementIds.concat(idsToHide).concat(geomIds)));
          hide2DElements(uniq(hidden2DElementIds.concat(labelIds)));
          return {
            ...state,
            selectionIdsHiddenDueToInvisibleParent: uniq(
              state.selectionIdsHiddenDueToInvisibleParent.concat(idsToHide),
            ),
            shownSelectionIds: uniq(state.shownSelectionIds.filter((id: string) => !idsToHide.includes(id))),
          };
        } else {
          return state;
        }
      } else {
        return state;
      }
    }
    case EWorkspaceQueryActionType.OTHER_SELECTIONS_RESHOW: {
      const selectionIdsToBeReshown = state.selectionIdsToBeReshown;
      if (selectionIdsToBeReshown.length === 0) {
        return state;
      } else {
        const selectionGeometryIds = state.selectionGeometryIds;
        const selectionResultIds = state.selectionResultIds;
        const ids = selectionIdsToBeReshown.filter((id: string) => selectionResultIds.includes(id));
        const geometryIds = ids.map(getSelectionGeometryId);
        const geomIds = geometryIds.filter((id: string) => selectionGeometryIds.includes(id));
        const labelIds = ids.map(getSelectionLabelId);
        const selectionsToBeReshown = ids.concat(geomIds);
        showElements(selectionsToBeReshown);
        show2DElements(labelIds);
        return {
          ...state,
          selectionIdsToBeReshown: new Array<string>(),
          shownSelectionIds: uniq(state.shownSelectionIds.concat(selectionsToBeReshown)),
        };
      }
    }

    case EWorkspaceQueryActionType.OTHER_SELECTIONS_HIDE: {
      const ids = action.ids;
      const otherIds = state.shownSelectionIds.filter((id: string) => !ids.includes(id));
      if (otherIds.length === 0) {
        return state;
      } else {
        const { hidden2DElementIds, hiddenElementIds } = getState();
        const geomIds = otherIds.map(getSelectionGeometryId);
        const labelIds = geomIds.map(getSelectionLabelId);
        hideElements(uniq(hiddenElementIds.concat(otherIds).concat(geomIds)));
        hide2DElements(uniq(hidden2DElementIds.concat(labelIds)));
        return {
          ...state,
          selectionIdsToBeReshown: otherIds,
          shownSelectionIds: uniq(state.shownSelectionIds.filter((id: string) => ids.includes(id))),
        };
      }
    }

    case EWorkspaceQueryActionType.SELECTIONS_HIDE_TEMPORARELY: {
      const { hidden2DElementIds, hiddenElementIds } = getState();
      const shownSelectionIds = state.shownSelectionIds;
      const geomIds = shownSelectionIds.map(getSelectionGeometryId);
      const labelIds = geomIds.map(getSelectionLabelId);
      hideElements(uniq(hiddenElementIds.concat(shownSelectionIds).concat(geomIds)));
      hide2DElements(uniq(hidden2DElementIds.concat(labelIds)));
      return {
        ...state,
        selectionIdsToBeReshown: shownSelectionIds,
        shownSelectionIds: new Array<string>(),
      };
    }

    case EWorkspaceQueryActionType.SHOWN_SELECTION_GEOMETRY_REMOVE: {
      const { hidden2DElementIds, hiddenElementIds } = getState();
      const selectionId = action.data;
      const ids = [selectionId, getSelectionGeometryId(selectionId)];
      hideElements(uniq(hiddenElementIds.concat(ids)));
      hide2DElements(uniq(hidden2DElementIds.concat(getSelectionLabelId(selectionId))));
      return {
        ...state,
        shownSelectionIds: uniq(state.shownSelectionIds.filter((id: string) => id !== selectionId)),
      };
    }

    case EWorkspaceQueryActionType.SELECTION_GEOMETRY_REMOVE: {
      return {
        ...state,
        selectionResultIds: uniq(state.selectionResultIds.filter((id: string) => id !== action.id)),
        shownSelectionIds: uniq(state.shownSelectionIds.filter((id: string) => id !== action.id)),
        selectionLabels: state.selectionLabels.filter((l: ILabel) => l.labelId !== action.id),
      };
    }

    case EWorkspaceQueryActionType.SHOWN_SELECTION_GEOMETRY_ADD: {
      return { ...state, shownSelectionIds: uniq([...state.shownSelectionIds, action.data]) };
    }

    case EWorkspaceQueryActionType.SELECTION_GEOMETRY_ADD: {
      const { id, selectionId, featureCollection, labelName, labelId, sourceItemId } = action.data;
      let vtpData;
      if (sourceItemId) {
        const { renderer } = getState();
        const actor = renderer.getActors().find((a) => a.getActorId() === sourceItemId);
        if (actor) {
          vtpData = actor.getMapper().getInputData();
        }
      }

      const vtk = vtpData ? vtpData : featureCollection ? convertGeojsonToVtp(featureCollection) : null;
      if (vtk) {
        const appended = appendData(
          vtk,
          id,
          SELECTION_SOURCE_COLOR.edge,
          featureCollection ? SELECTION_SOURCE_COLOR.surface : SELECTION_SOURCE_COLOR.edge,
          featureCollection ? REPRESENTATION.SURFACE : REPRESENTATION.WIREFRAME,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          // true,  send to bottom
        );

        let label: ILabel = null;

        if (appended && labelName) {
          const feature =
            featureCollection && featureCollection.features && featureCollection.features.length > 0
              ? featureCollection.features[0]
              : null;
          const properties = feature && feature.properties ? feature.properties : null;
          if (properties && properties.labelPointX && properties.lablePointY) {
            label = addLabel(
              labelName,
              labelId,
              properties.labelPointX,
              properties.lablePointY,
              getSelectionLabelStyle(labelName, getSelectionParentId(state.workspaceQueries, id)),
            );
          }
        }
        if (appended) {
          if (label) {
            const labels = state.selectionLabels.filter((l: ILabel) => l.labelId !== labelId);
            return {
              ...state,
              selectionGeometryIds: uniq(state.selectionGeometryIds.concat(id)),
              shownSelectionIds: uniq(state.shownSelectionIds.concat(selectionId)),
              selectionLabels: [...labels, label],
            };
          } else {
            return {
              ...state,
              selectionGeometryIds: uniq(state.selectionGeometryIds.concat(id)),
              shownSelectionIds: uniq(state.shownSelectionIds.concat(selectionId)),
            };
          }
        }
      }
      return {
        ...state,
        shownSelectionIds: uniq(state.shownSelectionIds.concat(selectionId)),
      };
    }

    case EWorkspaceQueryActionType.SELECTIONS_RE_ADD_LABELS: {
      const shownSelectionIds = state.shownSelectionIds;
      if (shownSelectionIds.length > 0) {
        const { hidden2DElementIds } = getState();
        const labels = state.selectionLabels;
        const hiddenLabels = state.selectionLabels.filter(
          (label: ILabel) => !shownSelectionIds.includes(label.labelId.replace(SELECTORLABEL, '')),
        );
        const hiddenLabelIds = hiddenLabels.map((l: ILabel) => l.labelId);
        labels.map((l: ILabel) =>
          addLabel(
            l.labelName,
            l.labelId,
            l.xCoord,
            l.yCoord,
            getSelectionLabelStyle(l.labelName, getSelectionParentId(state.workspaceQueries, l.labelId)),
          ),
        );
        hide2DElements(uniq(hidden2DElementIds.concat(hiddenLabelIds)));
      }
      return state;
    }

    case EWorkspaceQueryActionType.SELECTIONS_RE_ADD_LABEL: {
      const { labelId } = action;
      const l = state.selectionLabels.find((label: ILabel) => label.labelId === labelId);
      if (l !== undefined) {
        addLabel(
          l.labelName,
          l.labelId,
          l.xCoord,
          l.yCoord,
          getSelectionLabelStyle(l.labelName, getSelectionParentId(state.workspaceQueries, labelId)),
        );
      }
      return state;
    }

    case EWorkspaceQueryActionType.SELECTION_RESULT_ADD: {
      const vtp = action.data.vtp;

      if (vtp) {
        const selectionId = action.data.selectionId;
        appendData(
          vtp,
          action.data.selectionId,
          SELECTION_RESULT_COLOR.edge,
          SELECTION_RESULT_COLOR.surface,
          REPRESENTATION.WIREFRAME,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          // true,  send to bottom
        );
        showElement(selectionId);

        const ids = state.selectionResultIds.concat(selectionId);
        const uniqIds = uniq(ids);
        return { ...state, selectionResultIds: uniqIds };
      }
      return state;
    }

    case EWorkspaceActionType.CLOSE: {
      return { ...state, ...initialState };
    }

    case EWorkspaceQueryActionType.LOAD: {
      return { ...state, workspaceQueriesLoading: true };
    }

    case EWorkspaceQueryActionType.LOAD_SUCCESS: {
      const queries = action.workspaceQueries;
      return { ...state, workspaceQueries: queries, workspaceQueriesLoading: false };
    }

    case EWorkspaceQueryActionType.ITEMS_DELETED: {
      const idsToDelete = action.queryIds;
      if (!idsToDelete || idsToDelete.length === 0) {
        return state;
      }
      const workspaceQueries = state.workspaceQueries.filter((m) => idsToDelete.indexOf(m.id) === -1);
      return { ...state, workspaceQueries };
    }

    case EWorkspaceQueryActionType.ITEM_ADDED_OR_UPDATED: {
      const { workspaceQuery } = action;
      if (!workspaceQuery) {
        return state;
      }
      const queryIndex = state.workspaceQueries.findIndex((q) => q.id === workspaceQuery.id);
      // Add new query if not found
      if (queryIndex === -1) {
        return {
          ...state,
          workspaceQueries: [...state.workspaceQueries, workspaceQuery],
        };
      }
      // replace existing query if found
      return {
        ...state,
        workspaceQueries: [
          ...state.workspaceQueries.slice(0, queryIndex),
          workspaceQuery,
          ...state.workspaceQueries.slice(queryIndex + 1),
        ],
      };
    }

    default:
      return state;
  }
}
