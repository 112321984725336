/**
 * Exposes reselect-selectors methods for generic dataItems.
 *
 * @module WorkspaceDataItemSelectors
 * @version 1.0.0
 */

import { createSelector } from 'reselect';
import { EGeometryItemTypes, IWorkspaceGeometry } from 'src/models/IGeometries';
import { EMeshItemTypes, IWorkspaceMesh } from 'src/models/IMeshes';
import { EVariableItemTypes, IWorkspaceVariable } from 'src/models/IVariables';
import { IWorkspaceDataItem } from 'src/models/IWorkspaceData';
import { IWorkspaceGeometryState } from '../reducers/WorkspaceGeometryReducer';
import { IWorkspaceMeshState } from '../reducers/WorkspaceMeshReducer';
import { IWorkspaceVariableState } from '../reducers/WorkspaceVariableReducer';
import { IGlobalState, store } from '../store';

const workspaceGeometries = (state: IGlobalState) =>
  (state.WorkspaceGeometryReducer as IWorkspaceGeometryState).workspaceGeometries;

const workspaceMeshes = (state: IGlobalState) => (state.WorkspaceMeshReducer as IWorkspaceMeshState).workspaceMeshes;

const workspaceVariables = (state: IGlobalState) =>
  (state.WorkspaceVariableReducer as IWorkspaceVariableState).workspaceVariables;

export interface IDataItem
  extends Omit<Partial<IWorkspaceGeometry>, 'itemType'>,
    Omit<Partial<IWorkspaceVariable>, 'itemType'>,
    Omit<Partial<IWorkspaceMesh>, 'itemType'> {
  itemType: EGeometryItemTypes | EVariableItemTypes | EMeshItemTypes;
}
export type IDataItemsArray = Array<IDataItem>;

/**
 * Selector to return all workspace items in one array
 * @returns `[...geometries, ...variables, ...meshes]`
 */
export const getWorkspaceDataItems = createSelector(
  [workspaceGeometries, workspaceVariables, workspaceMeshes],
  (geometries, variables, meshes): IDataItemsArray => {
    if ((!geometries || !geometries.length) && (!variables || !variables.length) && (!meshes || !meshes.length)) {
      return [];
    }
    return [...geometries, ...variables, ...meshes] as IDataItemsArray;
  },
);

export const simplyGetWorkspaceDataItems = () => {
  const state = store.getState();
  const meshes = workspaceMeshes(state) || [];
  const geometries = workspaceGeometries(state) || [];
  const variables = workspaceVariables(state) || [];
  return [...geometries, ...variables, ...meshes] as IDataItemsArray;
};

export interface IDataItemsKVP {
  geometries: Array<IWorkspaceGeometry>;
  variables: Array<IWorkspaceVariable>;
  meshes: Array<IWorkspaceMesh>;
}

/**
 * Selector to return all workspace items as a key-value pair (KVP).
 * @returns `{geometries, variables, meshes}`
 */
const getWorkspaceDataItemsKVP = createSelector(
  [workspaceGeometries, workspaceVariables, workspaceMeshes],
  (
    geometries: Array<IWorkspaceGeometry>,
    variables: Array<IWorkspaceVariable>,
    meshes: Array<IWorkspaceMesh>,
  ): IDataItemsKVP => {
    if ((!geometries || !geometries.length) && (!variables || !variables.length) && (!meshes || !meshes.length)) {
      return { geometries: [], variables: [], meshes: [] };
    }
    return { geometries, variables, meshes };
  },
);

/**
 *  Selector to return true if the workspace have any data items (geometries, variables or  meshes), otherwise false
 */
export const hasAnyWorkspaceDataItems = createSelector(
  [getWorkspaceDataItems],
  (dataItems: Array<IWorkspaceDataItem>) => {
    return dataItems && dataItems.length > 0;
  },
);

const self = {
  getWorkspaceDataItems,
  getWorkspaceDataItemsKVP,
  hasAnyWorkspaceDataItems,
};

export default self;
