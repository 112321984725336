import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { css } from 'emotion';

import Drawer from '@material-ui/core/Drawer';

import { IGlobalState, store } from 'store/store';
import { loadProjectionSystems } from 'store/actions/projectionSystems';

import { MmgProjectCreatePanel } from 'src/projects/project-create-panel';
import { MmgWorkspaceCreatePanel } from './workspace-create-panel';
import { MmgWorkspaceCreateButtons } from './workspace-create-buttons';
import { IProjection } from '../../models/IProjections';
import { IProject } from 'src/models/IProject';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { MikeButton } from '@mike/mike-shared-frontend';
import WorkspaceManager from 'src/managers/WorkspaceManager';
import { t } from 'src/translations/i18n';
import { ROUTES } from 'src/app/routes';
import { routeToPanel } from '../workspace-utils';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { useParams } from 'react-router-dom';
import { IProjectRouteParams } from 'src/models/IRoutes';
const WorkspaceCreateStyle = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const WorkspaceCreateDrawerContentStyle = css`
  width: 100%;

  @media (min-width: 460px) {
    width: 400px;
  }
`;

/**
 * @name MmgConnectedWorkspaceCreate
 * @summary Allows creating a new workspace.
 *
 */
export const MmgConnectedWorkspaceCreate = () => {
  const { projectId } = useParams<IProjectRouteParams>();
  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const projectSelectedForDelete: Array<string> = useSelector(
    (state: IGlobalState) => state.ProjectReducer.projectSelectedForDelete,
  );
  const loadingProjectionSystems: boolean = useSelector(
    (state: IGlobalState) => state.ProjectionsReducer.loadingProjectionSystems,
  );
  const searchProjectionSystemsById: boolean = useSelector(
    (state: IGlobalState) => state.ProjectionsReducer.searchProjectionSystemsById,
  );
  const selectedProjectionSystems: IProjection[] = useSelector(
    (state: IGlobalState) => state.ProjectionsReducer.selectedProjectionSystems,
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(projectId);
  const canCreateWorkspace = project && project.capabilities && project.capabilities.canUpdateContent;
  const canDeleteWorkspace = project && project.capabilities && project.capabilities.canDeleteContent;

  // on mount load Projections
  useEffect(() => {
    store.dispatch(loadProjectionSystems());
  }, []);

  /**
   * Toggles create workspace modal.
   */
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  /**
   * Callback for start creating a workspace in existing project
   */
  const onCreateInExistingProject = () => {
    setCreateProjectOpen(false);
    setDrawerOpen(true);
  };

  const cancelCreateWorkspace = () => {
    setDrawerOpen(false);
    setCreateProjectOpen(false);
    setSelectedProjectId(projectId);
  };
  /**
   * Callback for creating a new  project
   * @param newProject
   */
  const onProjectCreated = (newProject) => {
    setSelectedProjectId(newProject.id);
    setCreateProjectOpen(false);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteClick = () => {
    try {
      for (let i = 0; i < projectSelectedForDelete.length; i++) {
        WorkspaceManager.deleteWorkspace(projectSelectedForDelete[i])
          .then(() => {
            const toast = {
              text: t('DELETED', 1, { thing: projectSelectedForDelete[i] }),
            };

            store.dispatch({ type: 'workspace/recent/DELETE', workspaceId: projectSelectedForDelete[i] });
            store.dispatch({ type: 'toast/ADD/SUCCESS', toast });
            routeToPanel(ROUTES.workspaceList.path, { projectId });
          })
          .catch(() => {})
          .finally(() => {
            store.dispatch({ type: WorkspaceActionType.WORKSPACE_LOAD_OVERVIEW, data: projectId });
            setDeleteDialogOpen(false);
          });
      }
    } catch (ex) {
      console.error('inner', ex.message);
    } finally {
    }
  };

  return (
    <div className={WorkspaceCreateStyle}>
      <MmgWorkspaceCreateButtons
        canCreateWorkspace={canCreateWorkspace}
        onCreateInExistingProject={onCreateInExistingProject}
        canDeleteWorkspace={canDeleteWorkspace}
        handleDialogClose={handleDialogClose}
        handleDialogOpen={handleDialogOpen}
        projectSelectedForDelete={projectSelectedForDelete}
      />
      {deleteDialogOpen && (
        <Dialog onClose={handleDialogClose} aria-labelledby="simple-dialog-title" open={deleteDialogOpen}>
          <DialogTitle id="simple-dialog-title">Delete confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MikeButton onClick={handleDialogClose} color="primary">
              Cancel
            </MikeButton>
            <MikeButton onClick={handleDeleteClick} autoFocus>
              Delete
            </MikeButton>
          </DialogActions>
        </Dialog>
      )}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <div className={WorkspaceCreateDrawerContentStyle}>
          <div hidden={createProjectOpen}>
            <MmgWorkspaceCreatePanel
              projectId={selectedProjectId}
              onPanelExit={cancelCreateWorkspace}
              loadingProjectionSystems={loadingProjectionSystems}
              searchProjectionSystemsById={searchProjectionSystemsById}
              selectedProjectionSystems={selectedProjectionSystems}
            />
          </div>

          {createProjectOpen && (
            <MmgProjectCreatePanel onProjectCreated={onProjectCreated} onPanelExit={toggleDrawer} />
          )}
        </div>
      </Drawer>
    </div>
  );
};
