import { all } from 'redux-saga/effects';
import watchStreaming from './streamingSagas';
import { watchWorkspaceConnect, watchWorkspaceUpdated } from './workspaceConnectSagas';
import watchFetchProjectionSystems from './projectionSystems';
import watchWorkspaceExports from './workspaceExportSagas';
import watchSetGridOverlay from './gridOverlaySagas';
// import watchFetchApplications from './applications';
import watchWorkspaceDataReducer from './workspaceDataItemsSagas';
import watchWorkspaceEdit from './workspaceEditSagas';
import watchWorkspaceOpenData from './workspaceOpenDataSagas';
import watchSetupSocket from './socketSagas';
import watchWorkspaceQuery from './workspaceQuerySagas';
import watchMeshTiles from './meshTilesSagas';

/**
 * Exposes all available sagas for workspaces
 *
 * todo hevo: consider if aný of the other root saga patterns is better?:  https://redux-saga.js.org/docs/advanced/RootSaga.html
 */
export default function* workspaceSagas() {
  yield all([
    watchSetupSocket(),
    watchMeshTiles(),
    ...watchWorkspaceConnect(),
    ...watchWorkspaceUpdated(),
    ...watchStreaming(),
    ...watchSetGridOverlay(),
    ...watchWorkspaceDataReducer(),
    ...watchWorkspaceEdit(),
    watchFetchProjectionSystems(),
    watchWorkspaceExports(),
    // watchFetchApplications(),
    watchWorkspaceOpenData(),
    watchWorkspaceQuery(),
  ]);
}
