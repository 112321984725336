import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { EProgressItemsActionType } from './ProgressItemsActionType';
import { IAction } from './Action';

export const removeProgressItem = (progressItem: IProgressItem): IAction<IProgressItem> => ({
  type: EProgressItemsActionType.REMOVE_PROGRESS_ITEM,
  data: progressItem,
});

export const updateOrAddProgressItem = (progressItem: IProgressItem): IAction<IProgressItem> => ({
  type: EProgressItemsActionType.UPDATE_OR_ADD_PROGRESS_ITEM,
  data: progressItem,
});
