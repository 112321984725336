import React, { useState } from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import { getRouteByPath, ROUTES } from 'src/app/routes';
import { getShortDate } from 'src/translations/utils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { ReactComponent as MeshGenerator } from '@mike/mike-shared-frontend/media/icons/MeshGenerator';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { typography } from '@mike/mike-shared-frontend/mike-shared-styles/mike-css-overrides';
import Typography from '@material-ui/core/Typography';
import { CARD_SPACING, CARD_HEIGHT, CARD_WIDTH, CARD_MEDIA_HEIGHT } from './workspace-card-styles';
import { theme } from 'src/shared/styles/theme';
import { IWorkspaceOverview } from 'models/IWorkspaces';
import { IProject } from 'models/IProject';
import { t } from 'src/translations/i18n';
import { useIsMounted } from 'src/shared/hooks/hooks';
import { CardActions, Checkbox } from '@material-ui/core';
import { store } from 'src/store';

const WorkspaceCardStyle = css`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: ${CARD_HEIGHT}px;
  width: ${CARD_WIDTH}px;
  margin: 0 ${CARD_SPACING}px 0 0;
`;

const WorkspaceCardMediaStyle = css`
  display: block;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  opacity: 0.9;
  background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  max-height: ${CARD_MEDIA_HEIGHT}px;
`;

const WorkspaceCardActionStyle = css`
  margin-left: auto;
  flex-shrink: 0;
  flex-grow: 0;
`;

const WorkspaceCardActionContainerStyle = css`
  padding: 0 0 0 8px;
  flex-shrink: 0;
  flex-grow: 0;
`;
const WorkspaceCardContentStyle = css`
  display: block;
  padding: 2px;
  & > * {
    padding-bottom: ${theme.spacing(0.5)}px;
  }

  & > *:last-child {
    padding-bottom: 0;
  }
`;

const WorkspaceCardTextStyle = css`
  flex-grow: 1;

  /* Visually align bottom & top text margins */
  padding-bottom: 0;
  margin-bottom: ${-theme.spacing(1)}px;
`;

const WorkspaceCardEditTextStyle = css`
  flex-grow: 1;
  font-weight: bold;
  color: #00a4ec;
  /* Visually align bottom & top text margins */
  padding-bottom: 0;
  margin-bottom: ${-theme.spacing(1)}px;
`;

type WorkspaceCardProps = {
  workspace: IWorkspaceOverview;
  project: IProject;
  projectSelectedForDelete?: Array<string>;
};

/**
 * @name MmgWorkspaceCard
 * @summary Shows a workspace in a card.
 *
 * @param props
 */
export const MmgWorkspaceCard = (props: WorkspaceCardProps) => {
  const { workspace, project, projectSelectedForDelete = [] } = props;
  const [elevation, setElevation] = useState(1);
  const isMounted = useIsMounted();

  const isEditAllowed = project && project.capabilities && project.capabilities.canUpdateContent;

  if (!workspace) {
    return <></>;
  }

  const workspaceRoutePath =
    workspace && workspace.projectId
      ? getRouteByPath(ROUTES.workspace.path, {
          projectId: workspace.projectId,
          workspaceId: workspace.id,
        })
      : '';

  const workspaceEditRoutePath =
    workspace && workspace.projectId
      ? getRouteByPath(ROUTES.workspaceEdit.path, {
          projectId: workspace.projectId,
          workspaceId: workspace.id,
        })
      : '';

  const workspaceName = (workspace && workspace.name) || t('UNTITLED');

  const handleWorkspaceDelete = (event) => {
    if (event.target.checked) {
      store.dispatch({
        type: 'project/WORKSPACE_DELETE_ADD',
        id: workspace.id,
      });
    } else {
      store.dispatch({
        type: 'project/WORKSPACE_DELETE_REMOVE',
        id: workspace.id,
      });
    }
  };

  return (
    <Card
      className={WorkspaceCardStyle}
      onMouseOver={() => isMounted() && setElevation(4)}
      onMouseOut={() => isMounted() && setElevation(1)}
      elevation={elevation}
    >
      <Link to={workspaceRoutePath} style={{ display: 'contents' }}>
        {workspace.image ? (
          <CardMedia
            component="div"
            image={workspace.image}
            title={workspaceName}
            className={WorkspaceCardMediaStyle}
          />
        ) : (
          <div className={WorkspaceCardMediaStyle}>
            <MeshGenerator
              style={{
                width: 100,
                height: 100,
              }}
              viewBox="0 0 40 40"
            />
          </div>
        )}
      </Link>

      <CardContent className={WorkspaceCardContentStyle}>
        <Link to={workspaceRoutePath} className={WorkspaceCardTextStyle}>
          <Typography>{project ? project.name : '...'}</Typography>

          <Typography>
            <b>{workspaceName}</b>
          </Typography>

          <Typography className={typography.subtext1}>{getShortDate(workspace.updated)}</Typography>
        </Link>
      </CardContent>
      <CardActions className={WorkspaceCardActionContainerStyle}>
        {isEditAllowed && (
          <Link to={workspaceEditRoutePath} className={WorkspaceCardEditTextStyle}>
            <Typography style={{ fontWeight: 'bold', color: '#00A4EC' }}>Edit workspace</Typography>
          </Link>
        )}
        {isEditAllowed && (
          <div className={WorkspaceCardActionStyle}>
            <Checkbox onChange={handleWorkspaceDelete} checked={projectSelectedForDelete.includes(workspace.id)} />
          </div>
        )}
      </CardActions>
    </Card>
  );
};
