import { all, put, select, takeEvery, takeLatest, call } from 'redux-saga/effects';
import { EWorkspaceDataActionType } from 'src/store/actions/WorkspaceDataActionType';
import { IWorkspaceDataAction } from 'src/store/reducers/WorkspaceDataReducer';
import * as WorkspaceProxy from 'src/proxies/WorkspaceProxy';
import { IDrawnDataItem } from 'src/models/IWorkspaceData';
import { DARKMAP_COLORS, LIGHTMAP_COLORS, _getRgbColorAdaptedToBaseMap } from '../viewer/viewer-utils';
import {
  drawnWorkspaceGeometries,
  drawnWorkspaceMeshes,
  drawnWorkspaceVariables,
  getViewerBaseMapId,
} from 'src/store/selectors/ViewerSelectors';
import { getWorkspaceQueries } from 'src/store/selectors/WorkspaceSelectors';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { EBaseMapIds } from '@mike/mike-shared-frontend/lab/mike-visualizer/MikeBaseMapConfigurations';
import { MMG_BASE_MAPS } from '../viewer/tools/viewer-tool-constants';
import {
  removeGradient,
  updateWorkspaceDataItemGradient,
  updateWorkspaceDataItemPointSize,
  updateWorkspaceDataItemRepresentation,
} from 'src/store/actions/workspaceDataActions';
import {
  getAttributeGradientSettings,
  getAttributePointSize,
  getAttributeRepresentation,
} from 'src/shared/visualization-settings/visualization-utils';
import { getElementCategory, getItemCategory, getItemType } from 'src/store/selectors/WorkspaceDataItemUtils';
import {
  getSavedUserSettings,
  getUserHiddenElements,
  getUserSettings,
  getUserSettingsInitialized,
} from 'src/store/selectors/WorkspaceSelectors';
import {
  EBASEMAP,
  ERepresentation,
  ILayerColor,
  ISettingsLayer,
  ITableOfContent,
  IUserSettings,
} from 'src/models/IUserSettings';
import { setSettings, setUpdatedSettings } from 'src/store/actions/settings';
import { IRepresentation } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/models/IRepresentation';
import {
  getDrawnWorkspaceGeometriesByIds,
  getWorkspaceData,
  getWorkspaceDataArrayStatisticsById,
  getWorkspaceDataStatistics,
} from 'src/store/selectors/WorkspaceDrawnDataSelectors';
import { getWorkspaceRouteParams } from 'src/store/selectors/AppSelectors';
import { getWorkspaceDataItems, IDataItem } from 'src/store/selectors/WorkspaceDataItemSelectors';
import { EWorkspaceGeometryActionType } from 'src/store/actions/WorkspaceGeometryActionType';
import { EWorkspaceMeshActionType } from 'src/store/actions/WorkspaceMeshActionType';
import { EWorkspaceVariableActionType } from 'src/store/actions/WorkspaceVariableActionType';
import { getWorkspaceGeometries } from 'src/store/selectors/WorkspaceGeometrySelectors';
import { getWorkspaceMeshes } from 'src/store/selectors/WorkspaceMeshSelectors';
import { getWorkspaceVariables } from 'src/store/selectors/WorkspaceVariableSelectors';
import { DEFAULT_DATA_TYPE_REPRESENTATIONS } from 'src/models/IViewerModels';
import { REPRESENTATION } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConstants';
import {
  GEOMETRY_COLOR_DARK_BASEMAP,
  GEOMETRY_COLOR_LIGHT_BASEMAP,
  MESH_COLOR_LIGHT_BASEMAP,
  MESH_COLOR_DARK_BASEMAP,
  VARIABLE_COLOR,
} from 'src/viewer/viewer-utilities';
import { EElementCategories } from 'src/shared/panels/mesh-panel-constants';
import { isEqual } from 'lodash-es';
import { EWorkspaceActionType } from 'src/store/actions/WorkspaceActionType';
import socketManager from 'src/managers/WorkspaceSocketManager';
import { IWorkspaceQuery } from 'src/models/IQueries';
import WorkspaceQueryUtils from 'src/store/selectors/WorkspaceQueryUtils';
import { EWorkspaceQueryActionType } from 'src/store/actions/WorkspaceQueryActionType';
import { getGeometryLabels } from 'src/proxies/WorkspaceGeometriesProxy';
import { t } from 'src/translations/i18n';
import { EOperationActionType } from 'src/store/actions/OperationActionType';
import { EOperationResultTypes, EOperationStates } from 'src/models/IOperations';
import { EMapToolActionType } from 'src/store/actions/MapToolActionType';

/**
 * Defines sagas for changes to `WorkspaceDataReducer`.
 */
export default function* watchWorkspaceDataReducer() {
  yield takeLatest(EWorkspaceDataActionType.UPDATE_USER_SETTINGS, saveSettings);
  yield takeEvery(EWorkspaceDataActionType.UPDATE_ITEM_STATISTICATTRIBUTENAME, saveStatisticAttribute);
  yield takeEvery(EWorkspaceDataActionType.UPDATE_ITEM_LABELATTRIBUTE, saveAttributeLabels);
  yield takeEvery(EWorkspaceDataActionType.FETCH_ATTRIBUTE_LABELS, addAttributeLabels);

  yield takeEvery(EWorkspaceDataActionType.ADAPT_COLORS_TO_BASEMAP, updateItemColorsToBasemap);
  yield takeEvery(EMapToolActionType.SET_BASEMAP, adaptColorsToBaseMap);
  yield takeEvery(WorkspaceActionType.SET_BASEMAP_TO_DEFAULT, adaptColorsToBaseMap);
  yield takeEvery(EWorkspaceDataActionType.UPDATE_ITEM_REPRESENTATION, saveRepresentation);
  yield takeEvery(EWorkspaceDataActionType.UPDATE_ITEM_COLOR, saveItemColor);
  yield takeEvery(EWorkspaceDataActionType.UPDATE_ITEM_ZATTRIBUTENAME, saveZAttribute);
  yield takeEvery(EWorkspaceGeometryActionType.SHOW_ITEM, saveShowItem);
  yield takeEvery(EWorkspaceGeometryActionType.SHOW_ITEM_ONLY, saveShowItem);
  yield takeEvery(EWorkspaceGeometryActionType.HIDE_ITEM, saveHideItem);
  yield takeEvery(EWorkspaceGeometryActionType.HIDE_ALL, saveHideAllGeometries);
  yield takeEvery(EWorkspaceGeometryActionType.SHOW_ALL, saveShowAllGeometries);
  yield takeEvery(EWorkspaceMeshActionType.SHOW_ITEM, saveShowItem);
  yield takeEvery(EWorkspaceMeshActionType.SHOW_ITEM_ONLY, saveShowItem);
  yield takeEvery(EWorkspaceMeshActionType.HIDE_ITEM, saveHideItem);
  yield takeEvery(EWorkspaceMeshActionType.HIDE_ALL, saveHideAllMeshes);
  yield takeEvery(EWorkspaceMeshActionType.SHOW_ALL, saveShowAllMeshes);
  yield takeEvery(EWorkspaceVariableActionType.SHOW_ITEM, saveShowItem);
  yield takeEvery(EWorkspaceVariableActionType.SHOW_ITEM_ONLY, saveShowItem);
  yield takeEvery(EWorkspaceVariableActionType.HIDE_ITEM, saveHideItem);
  yield takeEvery(EWorkspaceVariableActionType.HIDE_ALL, saveHideAllVariables);
  yield takeEvery(EWorkspaceVariableActionType.SHOW_ALL, saveShowAllVariables);
  yield takeEvery(WorkspaceActionType.SELECTIONS_HIDE_ALL, hideAllSelections);
  yield takeEvery(WorkspaceActionType.SELECTIONS_SHOW_ALL, showAllSelections);
  yield takeEvery(EOperationActionType.CREATE_OR_UPDATE, saveDifferentColor);
}

function* saveDifferentColor(action) {
  const { operationMetadata } = action;
  if (
    operationMetadata &&
    operationMetadata.outputIds &&
    operationMetadata.inputIds &&
    operationMetadata.state &&
    operationMetadata.state === EOperationStates.SUCCEEDED &&
    operationMetadata.resultType &&
    operationMetadata.resultType === EOperationResultTypes.GEOMETRY
  ) {
    const outputIds = operationMetadata.outputIds;
    const inputIds = operationMetadata.inputIds;

    if (outputIds && outputIds.length > 0 && inputIds && inputIds.length > 0 && !isEqual(inputIds, outputIds)) {
      const drawnGeometries = yield select(getDrawnWorkspaceGeometriesByIds);
      const viewerBaseMapId = yield select(getViewerBaseMapId);
      const inputDrawnItems = drawnGeometries.filter((di: IDrawnDataItem) => inputIds.includes(di.id));
      if (inputDrawnItems.length > 0) {
        const inputColors = inputDrawnItems.map((di: IDrawnDataItem) => di.surfaceColor);
        const colors = viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id ? DARKMAP_COLORS : LIGHTMAP_COLORS;
        const differentColors = colors.filter((c: number[]) => {
          return inputColors.some((ic: number[]) => {
            return !isEqual([...c, 1], ic);
          });
        });

        if (differentColors.length > 0) {
          const color = [...differentColors[0], 1];
          const settings = yield select(getUserSettings);
          const otherLayers = getSettingsLayers(settings);
          const tableOfContents = getTableOfContents(settings);
          const layersToUpdate = outputIds.map((id: string) => {
            const layer = createSettingsLayer(
              id,
              null,
              null,
              [],
              viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
              REPRESENTATION.WIREFRAME,
            );

            return {
              ...layer,
              noneRenderer: {
                ...layer.noneRenderer,
                wireframeSymbol: { ...layer.noneRenderer.wireframeSymbol, colors: color },
                surfaceSymbol: { ...layer.noneRenderer.surfaceSymbol, colors: color },
              },
            };
          });

          if (settings) {
            yield put(
              setSettings({
                ...settings,
                tableOfContents: {
                  ...tableOfContents,
                  layers: [...otherLayers, ...layersToUpdate],
                },
              }),
            );
          }
        }
      }
    }
  }
}

function* saveShowAllVariables(action) {
  const { updateUserSettings } = action;
  if (updateUserSettings) {
    const variables = yield select(getWorkspaceVariables);
    const itemIds = variables.map((item: IDrawnDataItem) => item.id);
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const otherLayers = layers.filter((layer: ISettingsLayer) => !itemIds.includes(layer.vtkItemId));
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const layersToUpdate = itemIds.map((id: string) => {
      const currentLayer = getSettingsLayer(
        id,
        layers,
        drawnDataItems,
        dataItems,
        viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      );
      return {
        ...currentLayer,
        visible: true,
      };
    });
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, ...layersToUpdate],
        },
      }),
    );
  }
}

function* saveHideAllVariables(action) {
  const { updateUserSettings } = action;
  if (updateUserSettings) {
    const drawnVariables = yield select(drawnWorkspaceVariables);
    const itemIds = drawnVariables.map((item: IDrawnDataItem) => item.id);
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const otherLayers = layers.filter((layer: ISettingsLayer) => !itemIds.includes(layer.vtkItemId));
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const layersToUpdate = itemIds.map((id: string) => {
      const currentLayer = getSettingsLayer(
        id,
        layers,
        drawnDataItems,
        dataItems,
        viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      );
      return {
        ...currentLayer,
        visible: false,
      };
    });
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, ...layersToUpdate],
        },
      }),
    );
  }
}

function* saveHideAllMeshes(action) {
  const { updateUserSettings } = action;
  const drawnMeshes = yield select(drawnWorkspaceMeshes);
  const itemIds = drawnMeshes.map((item: IDrawnDataItem) => item.id);
  yield put({ type: WorkspaceActionType.SELECTIONS_HIDE_ALL, itemIds });

  if (updateUserSettings) {
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const otherLayers = layers.filter((layer: ISettingsLayer) => !itemIds.includes(layer.vtkItemId));
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const layersToUpdate = itemIds.map((id: string) => {
      const currentLayer = getSettingsLayer(
        id,
        layers,
        drawnDataItems,
        dataItems,
        viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      );
      return {
        ...currentLayer,
        visible: false,
      };
    });
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, ...layersToUpdate],
        },
      }),
    );
  }
}

function* saveShowAllMeshes(action) {
  const { updateUserSettings } = action;
  const meshes = yield select(getWorkspaceMeshes);
  const itemIds = meshes.map((item: IDrawnDataItem) => item.id);
  yield put({ type: WorkspaceActionType.SELECTIONS_SHOW_ALL, itemIds });

  if (updateUserSettings) {
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const otherLayers = layers.filter((layer: ISettingsLayer) => !itemIds.includes(layer.vtkItemId));
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const layersToUpdate = itemIds.map((id: string) => {
      const currentLayer = getSettingsLayer(
        id,
        layers,
        drawnDataItems,
        dataItems,
        viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      );
      return {
        ...currentLayer,
        visible: true,
      };
    });
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, ...layersToUpdate],
        },
      }),
    );
  }
}

function* saveShowAllGeometries(action) {
  const { updateUserSettings } = action;
  const geometries = yield select(getWorkspaceGeometries);
  const itemIds = geometries.map((item: IDrawnDataItem) => item.id);
  yield put({ type: WorkspaceActionType.SELECTIONS_SHOW_ALL, itemIds });

  if (updateUserSettings) {
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const otherLayers = layers.filter((layer: ISettingsLayer) => !itemIds.includes(layer.vtkItemId));
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const layersToUpdate = itemIds.map((id: string) => {
      const currentLayer = getSettingsLayer(
        id,
        layers,
        drawnDataItems,
        dataItems,
        viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      );
      return {
        ...currentLayer,
        visible: true,
      };
    });
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, ...layersToUpdate],
        },
      }),
    );
  }
}

function* hideAllSelections(action) {
  const { itemIds } = action;
  const queries: Array<IWorkspaceQuery> = yield select(getWorkspaceQueries);
  const filteredQueries: Array<IWorkspaceQuery> = WorkspaceQueryUtils.filterQueriesByTargetItems(queries, itemIds);
  const ids = filteredQueries.map((query: IWorkspaceQuery) => query.id);
  yield put({ type: EWorkspaceQueryActionType.SELECTIONS_HIDE_DUE_TO_INVISIBLE_PARENT, ids });
}

function* showAllSelections(action) {
  const { itemIds } = action;
  const queries: Array<IWorkspaceQuery> = yield select(getWorkspaceQueries);
  const filteredQueries: Array<IWorkspaceQuery> = WorkspaceQueryUtils.filterQueriesByTargetItems(queries, itemIds);
  const ids = filteredQueries.map((query: IWorkspaceQuery) => query.id);
  yield put({ type: EWorkspaceQueryActionType.SELECTIONS_RESHOW_DUE_TO_REVISIBLE_PARENT, ids });
}

function* saveHideAllGeometries(action) {
  const { updateUserSettings } = action;
  const drawnGeometries = yield select(drawnWorkspaceGeometries);
  const itemIds: Array<string> = drawnGeometries.map((item: IDrawnDataItem) => item.id);
  yield put({ type: WorkspaceActionType.SELECTIONS_HIDE_ALL, itemIds });

  if (updateUserSettings) {
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const otherLayers = layers.filter((layer: ISettingsLayer) => !itemIds.includes(layer.vtkItemId));
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const layersToUpdate = itemIds.map((id: string) => {
      const currentLayer = getSettingsLayer(
        id,
        layers,
        drawnDataItems,
        dataItems,
        viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      );
      return {
        ...currentLayer,
        visible: false,
      };
    });
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, ...layersToUpdate],
        },
      }),
    );
  }
}

const getSettingsLayers = (settings: IUserSettings) => {
  return settings && settings.tableOfContents && settings.tableOfContents.layers ? settings.tableOfContents.layers : [];
};

const getTableOfContents = (settings: IUserSettings) => {
  return settings && settings.tableOfContents ? settings.tableOfContents : null;
};

function* saveShowItem(action) {
  const { updateUserSettings } = action;
  const elementId =
    action.workspaceGeometryId ||
    action.workspaceMeshId ||
    action.workspaceVariableId ||
    action.geometryId ||
    action.meshId ||
    action.variableId;
  if (updateUserSettings && elementId) {
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const userHiddenElementIds = yield select(getUserHiddenElements);
    const currentLayer = getSettingsLayer(
      elementId,
      layers,
      drawnDataItems,
      dataItems,
      viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      userHiddenElementIds,
    );
    const otherLayers = layers.filter((layer: ISettingsLayer) => layer.vtkItemId !== elementId);
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [
            ...otherLayers,
            {
              ...currentLayer,
              visible: true,
            },
          ],
        },
      }),
    );
  }
}

function* saveHideItem(action) {
  const { updateUserSettings } = action;
  const elementId = action.workspaceGeometryId
    ? action.workspaceGeometryId
    : action.workspaceMeshId
      ? action.workspaceMeshId
      : action.workspaceVariableId;
  if (updateUserSettings) {
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const userHiddenElementIds = yield select(getUserHiddenElements);
    const currentLayer = getSettingsLayer(
      elementId,
      layers,
      drawnDataItems,
      dataItems,
      viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      userHiddenElementIds,
    );

    const otherLayers = layers.filter((layer: ISettingsLayer) => layer.vtkItemId !== elementId);

    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [
            ...otherLayers,
            {
              ...currentLayer,
              visible: false,
            },
          ],
        },
      }),
    );
  }
}

const mapRepresentation = (rep: IRepresentation) => {
  if (rep.edgeVisibility === true && rep.representation === 2) {
    return ERepresentation.SURFACEWITHWIREFRAME;
  } else {
    switch (rep.representation) {
      case 0:
        return ERepresentation.POINTS;
      case 1:
        return ERepresentation.WIREFRAME;
      case 2:
        return ERepresentation.SURFACE;
      default:
        return ERepresentation.SURFACE;
    }
  }
};

function* saveRepresentation(action) {
  const { workspaceItemId, workspaceRepresentation, save } = action.data;
  if (save) {
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const userHiddenElementIds = yield select(getUserHiddenElements);
    const currentLayer = getSettingsLayer(
      workspaceItemId,
      layers,
      drawnDataItems,
      dataItems,
      viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      userHiddenElementIds,
    );
    const otherLayers = layers.filter((layer: ISettingsLayer) => layer.vtkItemId !== workspaceItemId);
    const mappedRepresentation = mapRepresentation(workspaceRepresentation);
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, { ...currentLayer, representation: mappedRepresentation }],
        },
      }),
    );
  }
}

function* saveItemColor(action) {
  const { workspaceDataEdgeColor, workspaceDataSurfaceColor, elementId, save } = action.data;
  if (save) {
    const settings = yield select(getUserSettings);
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const userHiddenElementIds = yield select(getUserHiddenElements);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const currentLayer = getSettingsLayer(
      elementId,
      layers,
      drawnDataItems,
      dataItems,
      viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      userHiddenElementIds,
    );

    const otherLayers = layers.filter((layer: ISettingsLayer) => layer.vtkItemId !== elementId);

    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [
            ...otherLayers,
            {
              ...currentLayer,
              noneRenderer: {
                surfaceSymbol: {
                  symbolType: 'SimpleSymbol',
                  colors: workspaceDataSurfaceColor,
                },
                wireframeSymbol: {
                  symbolType: 'SimpleSymbol',
                  colors: workspaceDataEdgeColor,
                },
                pointSymbol: currentLayer.noneRenderer.pointSymbol,
              },
            },
          ],
        },
      }),
    );
  }
}

function* saveSettings(action) {
  if (action.data.save) {
    const initialized: boolean = yield select(getUserSettingsInitialized);
    if (initialized) {
      const { workspaceId } = yield select(getWorkspaceRouteParams);
      /* const project: IProject | null = yield select(getProject);
      if (
        project &&
        project.id &&
        project.id === projectId &&
        project.capabilities &&
        project.capabilities.canUpdateContent
      ) { */
      const savedUserSettings: IUserSettings | null = yield select(getSavedUserSettings);
      const settings = action.data.settings;
      if (!isEqual(settings, savedUserSettings)) {
        try {
          yield call(WorkspaceProxy.saveWorkspaceUserSettings, workspaceId, settings);
        } catch (error) {
          console.log(error);
        }
      }
      yield put(setUpdatedSettings(settings));
    }
    // }
  }
}

function* adaptColorsToBaseMap() {
  const viewerBaseMapId = yield select(getViewerBaseMapId);
  if (viewerBaseMapId !== EBaseMapIds.NONE) {
    yield put({
      type: WorkspaceActionType.ADAPT_COLORS_TO_BASEMAP,
      toLightBackgroundMap: viewerBaseMapId !== MMG_BASE_MAPS.MAPBOX_SATELLITE.id,
    });
  } else {
    const settings = yield select(getUserSettings);
    yield put(
      setSettings({
        ...settings,
        map: {
          ...settings.map,
          backgroundMap: EBASEMAP.NONE,
        },
      }),
    );
  }
}

const getEdgeColor = (dataItem: IDataItem, darkBaseMap: boolean) => {
  const itemType = dataItem ? dataItem.itemType : null;
  const category = getElementCategory(itemType);
  switch (category) {
    case EElementCategories.GEOMETRY:
      return darkBaseMap ? GEOMETRY_COLOR_DARK_BASEMAP.edge : GEOMETRY_COLOR_LIGHT_BASEMAP.edge;
    case EElementCategories.MESH:
      return darkBaseMap ? MESH_COLOR_DARK_BASEMAP.edge : MESH_COLOR_LIGHT_BASEMAP.edge;
    case EElementCategories.VARIABLE:
      return VARIABLE_COLOR.edge;
    default:
      VARIABLE_COLOR.edge;
  }
};

const getSurfaceColor = (dataItem: IDataItem, darkBaseMap: boolean) => {
  const itemType = dataItem ? dataItem.itemType : null;
  const category = getElementCategory(itemType);
  switch (category) {
    case EElementCategories.GEOMETRY:
      return darkBaseMap ? GEOMETRY_COLOR_DARK_BASEMAP.surface : GEOMETRY_COLOR_LIGHT_BASEMAP.surface;
    case EElementCategories.MESH:
      return darkBaseMap ? MESH_COLOR_DARK_BASEMAP.surface : MESH_COLOR_LIGHT_BASEMAP.surface;
    case EElementCategories.VARIABLE:
      return VARIABLE_COLOR.surface;
    default:
      VARIABLE_COLOR.surface;
  }
};

const getDefaultRepresentation = (dataItem: IDataItem) => {
  const itemType = dataItem ? dataItem.itemType : null;
  if (itemType) {
    const defaultRepresentation = DEFAULT_DATA_TYPE_REPRESENTATIONS[itemType.toLowerCase()];
    if (defaultRepresentation) {
      return defaultRepresentation;
    } else {
      return REPRESENTATION.SURFACE;
    }
  } else {
    return REPRESENTATION.SURFACE;
  }
};

const createSettingsLayer = (
  itemId: string,
  existingDataItem: IDrawnDataItem,
  dataItem: IDataItem,
  userHiddenItems: Array<string>,
  darkBaseMap = false,
  initialRepresentation?: IRepresentation,
) => {
  const representation = initialRepresentation
    ? initialRepresentation
    : existingDataItem
      ? existingDataItem.representation
      : getDefaultRepresentation(dataItem);

  const edgeColor = existingDataItem ? existingDataItem.edgeColor : getEdgeColor(dataItem, darkBaseMap);
  const surfaceColor = existingDataItem ? existingDataItem.surfaceColor : getSurfaceColor(dataItem, darkBaseMap);

  const layer: ISettingsLayer = {
    vtkItemId: itemId,
    visible: !userHiddenItems.includes(itemId),
    labelAttribute: '',
    renderedAttribute:
      existingDataItem && existingDataItem.statisticsAttributeName ? existingDataItem.statisticsAttributeName : '',
    zAttribute: existingDataItem && existingDataItem.zAttributeName ? existingDataItem.zAttributeName : '',
    noneRenderer: {
      surfaceSymbol: {
        symbolType: 'SimpleSymbol',
        colors: surfaceColor,
      },
      wireframeSymbol: {
        symbolType: 'SimpleSymbol',
        colors: edgeColor,
      },
      pointSymbol: {
        symbolType: 'SimpleSymbol',
        colors: edgeColor,
      },
    },
    attributeRenderer: [],
    representation: mapRepresentation(representation),
  };
  return layer;
};

/**
 * For `UPDATE_ITEM_ZATTRIBUTENAME` related actions, ie stream the dataItem again when a new zAttributeName is dispatched.
 * Logic in mesh-viewer will apply the new elevation attribute when
 * the data arrives through the socket connection.
 * @param action
 */
function* saveZAttribute(action: IWorkspaceDataAction) {
  const { zAttributeName } = action;
  const itemId = action.workspaceItemId || action.elementId || action.itemId;
  const socketManagerInstance = socketManager.getInstance();
  if (socketManagerInstance) {
    socketManagerInstance.streamVtkFileData(itemId);
  }
  const settings: IUserSettings = yield select(getUserSettings);
  const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
  const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
  const viewerBaseMapId = yield select(getViewerBaseMapId);
  const userHiddenElementIds = yield select(getUserHiddenElements);
  const layers = getSettingsLayers(settings);
  const tableOfContents = getTableOfContents(settings);
  const currentLayer = getSettingsLayer(
    itemId,
    layers,
    drawnDataItems,
    dataItems,
    viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
    userHiddenElementIds,
  );
  const otherLayers = layers.filter((layer: ISettingsLayer) => layer.vtkItemId !== itemId);
  yield put(
    setSettings({
      ...settings,
      tableOfContents: {
        ...tableOfContents,
        layers: [...otherLayers, { ...currentLayer, zAttribute: zAttributeName }],
      },
    }),
  );
}

function* addAttributeLabels(action: IWorkspaceDataAction) {
  const { elementId, labelsAttribute, workspaceId } = action;

  const res = yield call(getGeometryLabels, workspaceId, elementId, labelsAttribute);
  if (res.status === 200 || res.status === 202) {
    yield put({
      type: 'toast/ADD/SUCCESS',
      toast: {
        text: t('LABELS_SUBMITTED_SUCCESSFULLY'),
      },
    });
  } else {
    yield put({
      type: 'toast/ADD/ERROR',
      toast: {
        text: t('LABELS_SUBMITTED_ERROR'),
      },
    });
  }
}

function* saveAttributeLabels(action: IWorkspaceDataAction) {
  const { elementId, labelsAttribute, save } = action;

  if (save) {
    const settings = yield select(getUserSettings);
    const layers = getSettingsLayers(settings);
    const tableOfContents = getTableOfContents(settings);
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const userHiddenElementIds = yield select(getUserHiddenElements);
    const currentLayer = getSettingsLayer(
      elementId,
      layers,
      drawnDataItems,
      dataItems,
      viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      userHiddenElementIds,
    );

    const otherLayers = layers.filter((layer: ISettingsLayer) => layer.vtkItemId !== elementId);

    const layerToUpdate = {
      ...currentLayer,
      labelAttribute: labelsAttribute,
    };
    const newSettings = {
      ...settings,
      tableOfContents: {
        ...tableOfContents,
        layers: [...otherLayers, layerToUpdate],
      },
    };

    yield put(setSettings(newSettings));
  }
}

function* saveStatisticAttribute(action: IWorkspaceDataAction) {
  const { statisticsAttributeName, save, isTiled, workspaceItemId } = action.data;
  let attributeRepresentation;
  if (statisticsAttributeName) {
    const dataGradient = {
      workspaceGradientId: workspaceItemId,
      workspaceGradientAttributeName: statisticsAttributeName,
      workspaceGradientSettings: statisticsAttributeName ? getAttributeGradientSettings(statisticsAttributeName) : null,
    };
    if (isTiled) {
      const allStatistics = yield select(getWorkspaceDataStatistics);
      const dataStatistic = allStatistics && allStatistics[workspaceItemId]; // const dataStatistic = yield select(getWorkspaceDataArrayStatisticsById, { itemId: workspaceItemId });
      if (dataStatistic) {
        const dataArray = dataStatistic.find((ds) => ds.id === statisticsAttributeName);
        if (dataArray && dataArray.range && dataArray.range.length === 2) {
          updateWorkspaceDataItemGradient({
            ...dataGradient,
            isTiled,
            colorRange: { colorMappingRange: dataArray.range },
          });
        }
        /*         else {
          updateWorkspaceDataItemGradient({ ...dataGradient, isTiled });
        } */
      }
    } else {
      updateWorkspaceDataItemGradient(dataGradient);
    }

    const elementCategory = getItemCategory(workspaceItemId);
    attributeRepresentation = getAttributeRepresentation(statisticsAttributeName, elementCategory);
    attributeRepresentation &&
      updateWorkspaceDataItemRepresentation(workspaceItemId, attributeRepresentation, false, isTiled, isTiled);
    const itemType = getItemType(workspaceItemId, elementCategory);
    const attributePointSize = getAttributePointSize(statisticsAttributeName, elementCategory, itemType);
    updateWorkspaceDataItemPointSize(workspaceItemId, attributePointSize, isTiled, isTiled);
  } else {
    removeGradient(workspaceItemId, isTiled);
    yield put({ type: EWorkspaceActionType.CHART_CLEAR });
  }
  if (save) {
    const settings: IUserSettings = yield select(getUserSettings);
    const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
    const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
    const viewerBaseMapId = yield select(getViewerBaseMapId);
    const userHiddenElementIds = yield select(getUserHiddenElements);
    const layers: ISettingsLayer[] = getSettingsLayers(settings);
    const tableOfContents: ITableOfContent = getTableOfContents(settings);
    const currentLayer = getSettingsLayer(
      workspaceItemId,
      layers,
      drawnDataItems,
      dataItems,
      viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      userHiddenElementIds,
    );
    const otherLayers = layers.filter((layer: ISettingsLayer) => layer.vtkItemId !== workspaceItemId);
    const layerToUpdate = {
      ...currentLayer,
      renderedAttribute: statisticsAttributeName,
      representation: attributeRepresentation
        ? mapRepresentation(attributeRepresentation)
        : currentLayer.representation,
    };
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, layerToUpdate],
        },
      }),
    );
  }
}

const mapBaseMapId = (id: string) => {
  switch (id) {
    case EBaseMapIds.NONE:
      return EBASEMAP.NONE;
    case EBaseMapIds.MAPBOX_SATELLITE:
      return EBASEMAP.MAPBOXSATTELITE;
    case EBaseMapIds.MAPTILER_STREET:
      return EBASEMAP.MAPTILERSTREET;
    default:
      return EBASEMAP.NONE;
  }
};

const getSettingsLayer = (
  elementId: string,
  layers: ISettingsLayer[],
  drawnDataItems: IDrawnDataItem[],
  dataItems: IDataItem[],
  darkBaseMap = true,
  hiddenItemIds?: Array<string>,
) => {
  const currentLayer = layers.find((layer: ISettingsLayer) => layer.vtkItemId === elementId);
  if (currentLayer === undefined) {
    const drawnDataItem = drawnDataItems.find((d: IDrawnDataItem) => d.id === elementId);
    const dataItem = dataItems.find((d: IDataItem) => d.id === elementId);
    return createSettingsLayer(elementId, drawnDataItem, dataItem, hiddenItemIds ? hiddenItemIds : [], darkBaseMap);
  } else {
    return currentLayer;
  }
};

function* updateItemColorsToBasemap(action: IWorkspaceDataAction) {
  const drawnGeometries = yield select(drawnWorkspaceGeometries);
  const drawnMeshes = yield select(drawnWorkspaceMeshes);
  const drawnGeometriesAndMeshes = drawnGeometries.concat(drawnMeshes);
  const items: Array<ILayerColor> = drawnGeometriesAndMeshes
    .filter((dataItem: IDrawnDataItem) => !dataItem.statisticsAttributeName)
    .map((dataItem: IDrawnDataItem) => {
      return {
        wireFrameColor:
          dataItem.representation.edgeVisibility === true
            ? _getRgbColorAdaptedToBaseMap(dataItem.edgeColor, action.toLightBackgroundMap)
            : dataItem.edgeColor,
        surfaceColor: _getRgbColorAdaptedToBaseMap(dataItem.surfaceColor, action.toLightBackgroundMap),
        elementId: dataItem.id,
      };
    });
  yield all(
    items.map((item: ILayerColor) =>
      put({
        type: EWorkspaceDataActionType.UPDATE_ITEM_COLOR,
        data: {
          workspaceDataEdgeColor: item.wireFrameColor,
          workspaceDataSurfaceColor: item.surfaceColor,
          elementId: item.elementId,
          save: false, // we batch save the settings with the following code:
        },
      }),
    ),
  );
  const itemIds = items.map((item: ILayerColor) => item.elementId);
  const viewerBaseMapId = yield select(getViewerBaseMapId);
  const settings = yield select(getUserSettings);
  const layers = getSettingsLayers(settings);
  const tableOfContents = getTableOfContents(settings);
  const otherLayers = layers.filter((layer: ISettingsLayer) => !itemIds.includes(layer.vtkItemId));
  const drawnDataItems: IDrawnDataItem[] = yield select(getWorkspaceData);
  const dataItems: IDataItem[] = yield select(getWorkspaceDataItems);
  const userHiddenElementIds = yield select(getUserHiddenElements);
  const layersToUpdate = items.map((item: ILayerColor) => {
    const currentLayer = getSettingsLayer(
      item.elementId,
      layers,
      drawnDataItems,
      dataItems,
      viewerBaseMapId === EBaseMapIds.MAPBOX_SATELLITE,
      userHiddenElementIds,
    );
    return {
      ...currentLayer,
      noneRenderer: {
        ...currentLayer.noneRenderer,
        wireframeSymbol: { ...currentLayer.noneRenderer.wireframeSymbol, colors: item.wireFrameColor },
        surfaceSymbol: { ...currentLayer.noneRenderer.surfaceSymbol, colors: item.surfaceColor },
      },
    };
  });
  if (settings) {
    yield put(
      setSettings({
        ...settings,
        tableOfContents: {
          ...tableOfContents,
          layers: [...otherLayers, ...layersToUpdate],
        },
        map: {
          ...settings.map,
          backgroundMap: mapBaseMapId(viewerBaseMapId),
        },
      }),
    );
  }
}
