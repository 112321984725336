import { takeLatest, put, call, takeEvery, select } from 'redux-saga/effects';
import { EWorkspaceMeshActionType } from 'src/store/actions/WorkspaceMeshActionType';
import * as MeshMoveNodeOperationsProxy from 'proxies/MeshMoveNodeOperationsProxy';
import { IGlobalState } from 'src/store';
import { getRouteParams } from 'src/workspaces/sagas/selectors';
import { EWorkspaceActionType } from 'src/store/actions/WorkspaceActionType';
import { t } from 'src/translations/i18n';
import { IChangedNode } from 'src/store/reducers/MeshMapToolReducer';
import { EMapToolActionType } from 'src/store/actions/MapToolActionType';
import { ESelectForEditingModeIds } from 'src/shared/edit-modes/select-for-editing-modes';
import { changedNodesToNodePatchFeatures } from 'src/workspaces/sagas/meshEditHelpers';
import { IPatchFeature } from 'src/models/IQueryCompleted';

const getMeshId = (state: IGlobalState) => state.MeshMapToolReducer.meshEditNodesTargetId;
export const getNodesToUpdate = (state: IGlobalState) => state.EditReducer.nodesToUpdate;

/**
 * Defines sagas for loading which items are exportable
 */
export default function* watchEditMeshes() {
  yield takeEvery(EWorkspaceMeshActionType.SAVE_NODE_UPDATES, handleSaveNodeUpdates);
}

function* handleSaveNodeUpdates() {
  const changedNodes: Array<IChangedNode> = yield select(getNodesToUpdate);
  const { workspaceId } = yield select(getRouteParams);
  const meshId = yield select(getMeshId);

  const nodePatchFeatures: Array<IPatchFeature> = changedNodesToNodePatchFeatures(changedNodes);
  try {
    yield call(MeshMoveNodeOperationsProxy.updateNodes, workspaceId, meshId, nodePatchFeatures);
    yield put({ type: EWorkspaceMeshActionType.SAVE_NODE_UPDATES_SUCCEEDED });
    yield put({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
    yield put({ type: 'toast/ADD/SUCCESS', toast: { text: t('MESH_EDIT_NODES_SUBMITTED') } });
  } catch (error) {
    yield put({ type: EWorkspaceMeshActionType.SAVE_NODE_UPDATES_FAILED });
  } finally {
    yield put({
      type: EMapToolActionType.SET_SELECTION_MODE,
      data: ESelectForEditingModeIds.SELECT_BY_POINT,
    });
  }
}
