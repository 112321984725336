/**
 * @namespace Store
 * @version 2.0.0
 */
import * as combinedReducers from 'store/reducers';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import settings from 'config/shared-env';
import rootSaga from './rootSaga';

const browserHistory = createBrowserHistory();

//todo hevo: consider better error handling for unexpected errors
const sagaMiddleware = createSagaMiddleware({
  onError: (err) => console.error(err),
});

const middlewares = [sagaMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composeWithDevToolsSettings = composeWithDevTools({
  trace: process.env.NODE_ENV !== settings.ENVIORNMENTS.PRODUCTION ? true : false,
  traceLimit: 25,
});
const composedEnhancers = composeWithDevToolsSettings(...enhancers);

const initialState = {};

export const store = createStore(
  combineReducers({
    ...combinedReducers,
  }),
  initialState,
  composedEnhancers as any,
);

export type IGlobalState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);

export const preloadedState = initialState;
