import { EApplicationsActionType } from '../actions/ApplicationsActionType';
import { IAction } from '../actions/Action';
import { IApplications } from '@mike/mike-shared-frontend/mike-subscribed-apps/model/IApplications';

export interface IApplicationsState {
  applications: IApplications;
  loadingApplications: boolean;
}

const initState: IApplicationsState = {
  applications: null,
  loadingApplications: false,
};

export default function(state: IApplicationsState = initState, action: IAction<IApplications | boolean>) {
  switch (action.type) {
    case EApplicationsActionType.LOADING_APPLICATIONS:
      return { ...state, loadingApplications: action.data };
    case EApplicationsActionType.SET_APPLICATIONS:
      return { ...state, applications: action.data };
    default:
      return state;
  }
}
