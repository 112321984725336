import { ENotificationActionType } from './NotificationActionType';
import { INotification } from '@mike/mike-shared-frontend/mike-notification-list/INotification';

export const hideBlueDotOnTopbarAlert = (hide = true) => ({
  type: ENotificationActionType.HIDE_BLUE_DOT,
  data: hide,
});

export const setNotification = (notifications: INotification[]) => ({
  type: ENotificationActionType.SET_NOTIFICATION,
  data: notifications,
});
