import { createSelector } from 'reselect';

import { IOperationMetadata, OPERATION_RESULT_TYPES } from 'models/IOperations';
import { IWorkspaceGeometry } from 'models/IGeometries';
import WorkspaceOperationUtils from 'store/selectors/WorkspaceOperationUtils';
import { IGlobalState } from '../store';

const workspaceGeometries = (state: IGlobalState) => state.WorkspaceGeometryReducer.workspaceGeometries;

const workspaceOperations = (state: IGlobalState) => state.WorkspaceOperationReducer.workspaceOperations;

/**
 * Get operations that have Geometry as a result type.
 */
const getGeometryOperations = createSelector([workspaceOperations], (operations: Array<IOperationMetadata>) => {
  if (!operations) {
    return [];
  }

  return operations.filter(({ resultType }) => resultType === OPERATION_RESULT_TYPES.GEOMETRY);
});

/**
 * Gets operations only containing the operation matching the last state of each geometry.
 */
const getLatestGeometryOperations = createSelector(
  [getGeometryOperations, workspaceGeometries],
  (operations: Array<IOperationMetadata>, geometries: Array<IWorkspaceGeometry>) => {
    if (!geometries) {
      return [];
    }

    return geometries
      .map(({ id }) => {
        return WorkspaceOperationUtils.getLatestOperation(
          WorkspaceOperationUtils.filterOperationsByOutputId(operations, id),
        );
      })
      .filter((operation) => operation);
  },
);

/**
 * Gets operations that do not have `outputIds`, that have Geometry as `resultType`.
 * NB: it is expected that the operation state only contains the latest updated operation; there aren't multiple operations with the same id.
 */
const getGeometryOperationsWithoutOutput = createSelector(
  [getGeometryOperations],
  (operations: Array<IOperationMetadata>) => {
    if (!operations) {
      return [];
    }

    const operationsWithoutOutput = WorkspaceOperationUtils.filterOperationsWithoutOutputIds(operations);

    // todo hevo sort operations by created date?

    return operationsWithoutOutput;
  },
);

const self = {
  getGeometryOperations,
  getLatestGeometryOperations,
  getGeometryOperationsWithoutOutput,
};

export default self;
