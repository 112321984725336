import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getAllProjectionSystems } from '../../store/selectors/ProjectionsSelectors';
import { getProjections } from '../../proxies/ProjectionProxy';
import { HttpErrorHandler } from '../../managers/http-utils';

import { EProjectionActionType } from '../../store/actions/ProjectionActionType';
import { setProjectionSystems, loadingProjectionSystems } from '../../store/actions/projectionSystems';

export default function* watchFetchProjectionSystems() {
  yield takeLatest(EProjectionActionType.LOAD_PROJECTION_SYSTEMS, fetchAllProjectionSystems);
}

export function* fetchAllProjectionSystems() {
  const appProjectionSystems = yield select(getAllProjectionSystems);

  if (!appProjectionSystems || appProjectionSystems.length === 0) {
    try {
      yield put(loadingProjectionSystems());

      const response = yield call(getProjections);

      if (response && response.status === 200 && response.data) {
        yield put(setProjectionSystems(response.data));
      }
    } catch (error) {
      HttpErrorHandler('Failed to fetch projections.', error);
    } finally {
      yield put(loadingProjectionSystems(false));
    }
  }
}
