import { actionChannel, call, fork, select, take, takeLatest } from 'redux-saga/effects';
import { IWorkspaceSocketManager } from 'src/models/ISocketManager';
import { getAppConfiguration } from 'store/selectors/AppSelectors';
import { streamingPriorityBuffer } from './streamingPriorityBuffer';
import { handleStreamDataRequest } from './streamData';
import { handleStreamDataAttributeRequest } from './streamDataAttribute';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import WorkspaceSocketManager from 'src/managers/WorkspaceSocketManager';

const DEFAULT_NO_OF_CONCURRENT_STREAMS = 1;

/**
 * Defines sagas for loading data by streaming
 */
export default function* watchStreaming() {
  // will not start listening for streaming event untill socket is setup.

  /**
   * todo hevo: could the delay in loading be because of this part????
   */

  yield takeLatest(WorkspaceActionType.SOCKET_SETUP, watchStreamingRequests);
}

/**
 * Will listen for STREAM actions and queue to stream
 * @param _action
 */
export function* watchStreamingRequests(_action) {
  // const socketManager = action.data;
  const socketManager = WorkspaceSocketManager.getInstance();

  // Create a channel to queue incoming streaming requests. Data will be taken from the queue in the order defined by the priority buffer.
  const streamDataChan = yield actionChannel(
    [WorkspaceActionType.LOAD_DATA, WorkspaceActionType.LOAD_DATA_ATTRIBUTE],
    streamingPriorityBuffer(),
  );

  // create a worker 'thread' per concurrent stream allowed
  const noOfConcurrentStreams = yield select(getNumberOfConcurrentStreams);

  for (let i = 0; i < noOfConcurrentStreams; i++) {
    yield fork(handleStreamingRequests, streamDataChan, socketManager);
  }
}

/**
 * handle streaming requests by pulling the next action from the channel
 * @param streamDataChan
 * @param socketManager
 */
function* handleStreamingRequests(streamDataChan, socketManager: IWorkspaceSocketManager) {
  while (true) {
    // take from the channel
    // todo hevo - should not start taking fron channel untill mananger is ready!!!
    const payload = yield take(streamDataChan);
    const { type } = payload;

    if (type === WorkspaceActionType.LOAD_DATA) {
      yield call(handleStreamDataRequest, payload, socketManager);
    } else if (type === WorkspaceActionType.LOAD_DATA_ATTRIBUTE) {
      yield call(handleStreamDataAttributeRequest, payload, socketManager);
    } else {
      yield console.warn('Unhandled streaming request', payload);
    }
  }
}

const getNumberOfConcurrentStreams = (state) => {
  const { connection } = getAppConfiguration(state) || {};

  if (connection) {
    return connection.concurrentStreams || DEFAULT_NO_OF_CONCURRENT_STREAMS;
  }

  return DEFAULT_NO_OF_CONCURRENT_STREAMS;
};
