/**
 * HTTP interceptor
 * Intercepts requests to see if a user is still logged in or has access to a certain endpoint (and more).
 *
 * @version 2.0.0
 */

import { stripIndents } from 'common-tags';
import { store } from 'store/store';
import { history } from 'src';
import { Proxy } from 'proxies';
import { t } from 'src/translations/i18n.ts';

import { ROUTES } from 'src/app/routes';

Proxy.http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { UserReducer } = store.getState();
    const response = error.response || {};
    const responseData = response.data;
    const additionalDebugText = responseData
      ? Object.keys(responseData).reduce(
          (acc, cur) => stripIndents`
            ${acc}
            ${cur}: ${responseData[cur]}
          `,
          '',
        )
      : '';

    switch (response.status) {
      case 401:
        if (response.config.url.indexOf('capabilities') > -1) {
          store.dispatch({
            type: 'app/tenant/SET_CAPABILITIES',
            capabilities: {
              canCreateContent: false,
              canDelete: false,
              canDeleteContent: false,
              canEdit: false,
              canEditAccessLevel: false,
              canGrantAccess: false,
              canListContent: true,
              canReadContent: true,
              canUpdateContent: false,
            },
          });
        }
        break;
      case 403: {
        if (response.config.url.indexOf('capabilities') > -1) {
          store.dispatch({
            type: 'app/tenant/SET_CAPABILITIES',
            capabilities: {
              canCreateContent: false,
              canDelete: false,
              canDeleteContent: false,
              canEdit: false,
              canEditAccessLevel: false,
              canGrantAccess: false,
              canListContent: true,
              canReadContent: true,
              canUpdateContent: false,
            },
          });
        }
        if (UserReducer.userLoggedIn) {
          store.dispatch({
            type: 'app/error/SET',
            applicationErrorTitle: response.status,
            applicationErrorDesc: t('APPLICATION_FORBIDDEN_TITLE'),
            applicationError: t('APPLICATION_FORBIDDEN_ERROR'),
            applicationErrorDebugText: additionalDebugText,
          });

          history.push(ROUTES.error.path);
        }

        break;
      }

      case 417: {
        if (UserReducer.userLoggedIn) {
          store.dispatch({
            type: 'app/error/SET',
            applicationErrorTitle: 417,
            applicationErrorDesc: t('APPLICATION_EXPECTATION_FAILED_TITLE'),
            applicationError: t('APPLICATION_EXPECTATION_FAILED_ERROR'),
            applicationErrorDebugText: additionalDebugText,
          });

          history.push(ROUTES.error.path);
        }

        break;
      }

      default:
        break;
    }

    return Promise.reject(error);
  },
);
