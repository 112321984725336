import { EWorkspaceActionType } from '../actions/WorkspaceActionType';

const initialState = {
  comments: [],
  commentsLoading: false,
  commentsLoadingFailed: false,
  showComments: false,
  focusedCommentId: null,
};

/**
 * Comment Reducer.
 * - returns new states for matched comment actions.
 *
 * @name WorkspaceCommentReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case 'comments/LIST_LOAD':
      return {
        ...state,
        comments: [],
        commentsLoading: true,
        commentsLoadingFailed: false,
      };

    case 'comments/LIST_SUCCESS':
      return {
        ...state,
        commentsLoading: false,
        commentsLoadingFailed: false,
        comments: action.comments,
      };

    case 'comments/LIST_FAILED':
      return {
        ...state,
        commentsLoading: false,
        commentsLoadingFailed: true,
      };

    case 'comments/SHOW':
      return {
        ...state,
        showComments: true,
      };

    case 'comments/HIDE':
      return {
        ...state,
        showComments: false,
      };

    case 'comments/ADD': {
      const commentToAdd = action.comment;

      if (!commentToAdd) {
        return state;
      }

      return {
        ...state,
        comments: [...state.comments.filter(({ id }) => id !== commentToAdd.id), commentToAdd],
      };
    }

    case 'comments/UPDATE': {
      const commentToUpdate = action.comment;

      if (!commentToUpdate) {
        return state;
      }

      const commentIndex = state.comments.findIndex((item) => item.id === commentToUpdate.id);

      // Add as a new comment if not found
      if (commentIndex === -1) {
        return {
          ...state,
          comments: [...state.comments, commentToUpdate],
        };
      }

      // replace exisitng comment
      return {
        ...state,
        comments: [
          ...state.comments.slice(0, commentIndex),
          commentToUpdate,
          ...state.comments.slice(commentIndex + 1),
        ],
      };
    }

    case 'comments/DELETE': {
      const comments = state.comments.filter((item) => item.id !== action.commentId);

      return {
        ...state,
        comments,
      };
    }

    case 'comments/SET_FOCUSED_COMMENT': {
      return {
        ...state,
        focusedCommentId: action.commentId,
      };
    }

    case 'comments/RESET_FOCUSED_COMMENT': {
      return {
        ...state,
        focusedCommentId: initialState.focusedCommentId,
      };
    }

    default:
      return state;
  }
}
