/**
 * Exposes utility methods for models.
 *
 * TODO: Should this module be placed somewhere else?
 *
 * @module ModelUtils
 * @version 2.0.0
 */

import { orderBy } from 'lodash-es';
import { IWorkspaceGeometry } from 'models/IGeometries';
import { IWorkspaceMesh } from 'models/IMeshes';
import { IWorkspaceVariable } from 'models/IVariables';
import { IWorkspaceQuery } from 'models/IQueries';
import { IWorkspaceComment } from 'models/IComments';
import { IOperationMetadata } from 'models/IOperations';
import { IWorkspaceDataItem } from 'models/IWorkspaceData';

const sortKeyId = 'id';
const sortKeyName = (item) => ((item || {}).name || '').toLowerCase();
const sortKeyCreated = (item) => new Date((item || {}).created);
const sortKeyOperationType = (operation) => (operation || {}).operationType;

// include id to ensure consistent sorting of items.
const dataItemSortKeys = [sortKeyCreated, sortKeyName, sortKeyId];
const dataItemSortOrder = ['desc', 'asc', 'asc'];
type OrderByOrder = ReadonlyArray<boolean | 'desc' | 'asc'>;

const isSortNeeded = (list: Array<any>) => list && list.length > 1;

/**
 * Returns a list of dataItems sorted by created date then by name
 * @param dataItems
 */
export const sortDataItems = (dataItems: Array<IWorkspaceDataItem>) => {
  if (!dataItems) {
    return [];
  }

  if (!isSortNeeded(dataItems)) {
    return dataItems;
  }

  return orderBy(dataItems, dataItemSortKeys, dataItemSortOrder as OrderByOrder);
};

/**
 * Returns a list of geometries sorted by name
 * @param geometries
 */
export const sortGeometries = (geometries: Array<IWorkspaceGeometry>) => {
  if (!geometries) {
    return [];
  }

  return self.sortDataItems(geometries) as Array<IWorkspaceGeometry>;
};

/**
 * Returns a list of variables sorted by name
 * @param variables
 */
export const sortVariables = (variables: Array<IWorkspaceVariable>) => {
  if (!variables) {
    return [];
  }

  return self.sortDataItems(variables) as Array<IWorkspaceVariable>;
};

/**
 * Returns a list of meshes sorted by name, then by updated-date (desc)
 * @param meshes
 */
export const sortMeshes = (meshes: Array<IWorkspaceMesh>) => {
  if (!meshes) {
    return [];
  }

  return self.sortDataItems(meshes) as Array<IWorkspaceMesh>;
};

/**
 * Returns a list of queries sorted by created date, then by name
 * @param queries
 */
export const sortQueries = (queries: Array<IWorkspaceQuery>) => {
  if (!queries) {
    return [];
  }

  if (!isSortNeeded(queries)) {
    return queries;
  }

  // include id to ensure consistent sorting of items.
  const querySortKeys = [sortKeyCreated, sortKeyName, sortKeyId];
  const querySortOrders = ['desc', 'asc', 'asc'];

  return orderBy(queries, querySortKeys, querySortOrders as OrderByOrder);
};

/**
 * Returns a list of operations sorted by created date
 * @param operations
 */
export const sortOperationsByCreated = (operations: Array<IOperationMetadata>) => {
  if (!operations) {
    return [];
  }

  if (!isSortNeeded(operations)) {
    return operations;
  }
  // include id to ensure consistent sorting of items.
  const sortKeys = [sortKeyCreated, sortKeyId];
  const sortOrders = ['desc', 'asc'];

  return orderBy(operations, sortKeys, sortOrders as OrderByOrder);
};

/**
 * Returns a list of operations sorted by operationType then by created date
 * @param operations
 */
export const sortOperationsByOperationType = (operations: Array<IOperationMetadata>) => {
  if (!operations) {
    return [];
  }
  if (!isSortNeeded(operations)) {
    return operations;
  }

  // include id to ensure consistent sorting of items
  const sortKeys = [sortKeyOperationType, sortKeyCreated, sortKeyId];
  const sortOrders = ['asc', 'desc', 'asc'];

  return orderBy(operations, sortKeys, sortOrders as OrderByOrder);
};

/**
 * Returns a list of operations sorted by the input items
 * @param operations
 * @param operationInputItems
 */
export const sortOperationsByInputItems = (
  operations: Array<IOperationMetadata>,
  operationInputItems: {
    [operationId: string]: Array<IWorkspaceGeometry | IWorkspaceMesh | IWorkspaceVariable>;
  },
) => {
  if (!operations) {
    return [];
  }
  if (!isSortNeeded(operations)) {
    return operations;
  }

  // todo hevo not sure if this fallback makes sense
  if (!operationInputItems) {
    return self.sortOperationsByOperationType(operations);
  }

  const inputItemsToSort = operations.map((operation) => {
    const inputItems = (operationInputItems || {})[operation.id];

    // todo hevo Currently we handle only one inputItem per operation.
    // In case there are no inputs we use the operation id to get consitent sorting
    const inputItem = inputItems && inputItems.length > 0 ? inputItems[0] : { id: operation.id };

    // include operation, so we can pick it later
    return { ...inputItem, operation };
  });

  const sorted = self.sortDataItems(inputItemsToSort);

  // get the operation correspondingto the sorted items
  const sortedOperations = sorted.map(({ operation }) => operation);

  return sortedOperations;
};

/**
 * Returns a list of comments sorted by created date
 * @param comments
 */
export const sortComments = (comments: Array<IWorkspaceComment>) => {
  if (!comments) {
    return [];
  }

  if (!isSortNeeded(comments)) {
    return comments;
  }

  // include id to ensure consistent sorting of items
  const sortKeys = [sortKeyCreated, sortKeyId];
  const sortOrders = ['desc', 'asc'];

  return orderBy(comments, sortKeys, sortOrders as OrderByOrder);
};

const self = {
  sortDataItems,
  sortGeometries,
  sortVariables,
  sortMeshes,
  sortQueries,
  sortComments,
  sortOperationsByCreated,
  sortOperationsByOperationType,
  sortOperationsByInputItems,
};

export default self;
