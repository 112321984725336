/* eslint-disable */
// Http interceptors, other setup should be done first.
import './app-setup.ts'; // NB: needs to be loaded before anything else.
import 'proxies/http-interceptor.js';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router';
import { store } from 'store/store';
import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { t } from 'src/translations/i18n';
import { OidcProvider, useOidc } from '@axa-fr/react-oidc';
import { OidcConfiguration } from '@axa-fr/react-oidc/dist/vanilla/oidc';
import settings from 'config/shared-env';
import './jsx-intrinsic-patch'; // Needed to allow any custom element.
import fetchConfig from './proxies/fetchConfig';
import init from './proxies/PlatformProxy';

if (
  process.env.NODE_ENV === settings.ENVIORNMENTS.PRODUCTION ||
  process.env.NODE_ENV === settings.ENVIORNMENTS.DEVELOPMENT ||
  process.env.NODE_ENV === settings.ENVIORNMENTS.TEST
) {
  console.info(`Application running in ${process.env.NODE_ENV} mode 🚀`);
} else {
  console.warn(
    `Application running in an unkown mode.
    NODE_ENV is set to ${process.env.NODE_ENV}.

    Is this a mistake?`,
  );
}

/*
 * Setup theme & other global css.
 */
import './shared/styles/mesh-shared.css';
import { Global, css } from '@emotion/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

/*
 * Render root element & connect router to redux store.
 */
import { ConnectedApp } from './app/App';
import { theme } from './shared/styles/theme';
import '@mike/mike-shared-frontend/mike-shared-styles/mike-scrollbars.css';
import OpenIdStatusPage from './app/login/OpenIdStatusPage';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

type IndexPropType = {
  openIdConfig: OidcConfiguration;
};

const Index = (props: IndexPropType) => {
  const { login } = useOidc();
  const { openIdConfig } = props;

  return (
    <ReduxProvider store={store}>
      <Global
        styles={css`
          body {
            background: ${MIKE_COLORS.XLIGHTGREY} !important; /* Theme incorrectly makes background grey */
            font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important; /* Theme incorrectly sets fonts to Roboto only, which means if font load fails it will use a serif browser default - not what we want */
          }

          /* Resets for i.e. older Edge versions */
          button {
            border: 0;
            background: 0;
            padding: 0;
          }
        `}
      />
      <OidcProvider
        configuration={openIdConfig}
        onSessionLost={() => {
          login('/');
        }}
        authenticatingComponent={() => (
          <OpenIdStatusPage headline={t('LOGIN_AUTHENTICATING')} body={t('LOGIN_PLEASE_WAIT')} />
        )}
        callbackSuccessComponent={() => (
          <OpenIdStatusPage headline={t('LOGIN_AUTHENTICATING')} body={t('LOGIN_PLEASE_WAIT')} />
        )}
        authenticatingErrorComponent={() => (
          <OpenIdStatusPage
            headline={t('LOGIN_AUTHENTION_FAILED')}
            body={t('LOGIN_TRY_AGAIN')}
            showRetryButton={true}
          />
        )}
        loadingComponent={() => <OpenIdStatusPage headline={t('LOGIN_LOADING')} body={t('LOGIN_PLEASE_WAIT')} />}
      >
        <Router history={history}>
          <MuiThemeProvider theme={theme}>
            <ConnectedApp />
          </MuiThemeProvider>
        </Router>
      </OidcProvider>
    </ReduxProvider>
  );
};

fetchConfig().then((config) => {
  const openIdConfig = {
    client_id: config.oAuth2.clientName,
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
    scope: 'openid offline_access',
    authority: config.oAuth2.url,
    service_worker_relative_url: '/OidcServiceWorker.js',
    service_worker_only: false,
    refresh_time_before_token_expiration_in_second: 120,
  };
  init(config.platformApiUrl);
  navigator.serviceWorker.getRegistration().then(function(reg) {
    // On a hard refresh (shift + refresh) there's an active SW, but no controller for this tab.
    // https://stackoverflow.com/questions/51597231/register-service-worker-after-hard-refresh
    if (reg!.active && !navigator.serviceWorker.controller) {
      // Perform a soft reload to load everything from the SW and get
      // a consistent set of resources.
      window.location.reload();
    }
  });
  ReactDOM.render(<Index openIdConfig={openIdConfig} />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
