import React, { useEffect, useState } from 'react';

import { IProject } from 'models/IProject';

import MikeTable from '@mike/mike-shared-frontend/mike-table';

import { columns, tableConfig } from './subprojectsTableConfig';
import { store } from '../../store/store';
import { ROUTES, getRouteByPath } from 'src/app/routes';
import { t } from 'src/translations/i18n';
import { useHistory } from 'react-router-dom';

type ISubprojectsTableProps = {
  subprojectList: Array<IProject>;
  subprojectListLoading: boolean;
  subprojectListLoadingFailed?: boolean;
  filter: string;
};

/**
 * @name MmgProjectsTable
 * @param props
 * @summary Table rendering the list of subrojects
 *
 */
const MmgSubprojectsTable = (props: ISubprojectsTableProps) => {
  const { subprojectList, subprojectListLoading, filter } = props;

  const filterSubprojects = subprojectList.filter((project) => {
    if (filter === '') {
      return project;
    } else if (project.name.toLowerCase().includes(filter.toLowerCase())) {
      return project;
    } else {
      return null;
    }
  });
  const history = useHistory();

  const handleSubprojectSelect = (project: IProject) => {
    if (project.capabilities && project.capabilities.canReadContent) {
      const workspaceListRoute = getRouteByPath(ROUTES.workspaceList.path, {
        projectId: project.id,
      });

      history.push(workspaceListRoute);
    } else {
      store.dispatch({
        type: 'toast/ADD/NOTICE',
        toast: { text: t('PROJECTS_NO_ACCESS') },
      });
    }
  };

  return (
    <MikeTable
      rows={filterSubprojects}
      config={tableConfig}
      loading={subprojectListLoading}
      columns={columns}
      onSelect={handleSubprojectSelect}
    />
  );
};

export default MmgSubprojectsTable;
