import { takeLatest, put, call } from 'redux-saga/effects';
import { getExportableGeometries } from 'src/managers/WorkspaceGeometriesManager';

/**
 * Defines sagas for loading which items are exportable
 */
export default function* watchExportableGeometries() {
  yield takeLatest('workspace/export/LOAD_EXPORTABLE_GEOMETRIES', loadExportableGeometries);
}
/**
 * Generator function for loading exportable geometries
 * @param action
 */
function* loadExportableGeometries(action) {
  yield put({ type: 'workspace/export/EXPORTABLE_GEOMETRIES_LOADING' });

  try {
    const exportableGeometryIds = yield call(getExportableGeometries, action.workspaceId);

    yield put({
      type: 'workspace/export/LOAD_EXPORTABLE_GEOMETRIES_SUCCESS',
      exportableGeometryIds,
    });
  } catch (error) {
    yield put({ type: 'workspace/export/LOAD_EXPORTABLE_GEOMETRIES_FAILED' });
  }
}
