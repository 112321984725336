import React, { ReactNode } from 'react';
import { css } from 'emotion';

import { PanelBodyStyle } from 'src/shared/panels/panel-styles';
import { theme } from '../styles/theme';

export const PanelSubsectionStyle = css`
  padding: ${theme.spacing(2)}px;
`;

type MmgPanelSubsectionProps = {
  children?: ReactNode;
  className?: string; // todo hevo type string will maybe not work if to be used with mui styles?
};

/**
 * @name MmgPanelSubsection
 * @summary A generic container of a panel with the correct box model. Typically holds short messages like 'Mesh deleted', 'Loading', etc.
 * It fits items into the correct box model, gives them padding, margin, etc.
 *
 * @param props
 */
export function MmgPanelSubsection(props: MmgPanelSubsectionProps) {
  return (
    <section className={[PanelBodyStyle, PanelSubsectionStyle, props.className].join(' ')}>{props.children}</section>
  );
}
