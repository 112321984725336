import React, { ChangeEvent } from 'react';

import { t } from 'src/translations/i18n';
import { css } from 'emotion';
import { Input, InputAdornment } from '@material-ui/core';

import { ReactComponent as Close } from '@mike/mike-shared-frontend/media/icons/Close';
import { ReactComponent as Search } from '@mike/mike-shared-frontend/media/icons/Search';

const FilterInputStyle = css`
  width: 320px;
  height: 40px;
  background: #ffffff;
  border-radius: 25px;
  border: 1px solid #dbe4e9 !important;
`;

type FilterInputProps = {
  value: string;
  onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
  onResetValue: () => void;
};

/**
 * @name FilterInput
 * @param props
 * @summary Controlled input for handling filters and search operations
 *
 */
const MmgFilterInput = (props: FilterInputProps) => {
  const { value, onChangeValue, onResetValue } = props;
  return (
    <Input
      type="input"
      autoFocus
      onChange={onChangeValue}
      className={FilterInputStyle}
      disableUnderline={true}
      placeholder={t('PROJECTS_FILTER_PLACEHOLDER')}
      value={value}
      startAdornment={
        <InputAdornment position="start">
          <Search />
        </InputAdornment>
      }
      endAdornment={
        value !== '' ? (
          <InputAdornment position="end" onClick={onResetValue}>
            <Close />
          </InputAdornment>
        ) : null
      }
    />
  );
};

export default MmgFilterInput;
