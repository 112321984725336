import AppRoutes from 'src/app/app.routes';

const workspace = AppRoutes.workspace.path;
const meshbase = `${workspace}/mesh`;
const meshbaseWithId = `${meshbase}/:meshId`;

export default {
  meshDetailsPanel: {
    path: meshbaseWithId,
  },
  meshCreateConfigurePanel: {
    path: `${meshbase}/create/configure`,
  },
  meshEditConfigurePanel: {
    path: `${meshbaseWithId}/configure`,
  },
  meshEditConfigurePanelAddGeometry: {
    path: `${meshbaseWithId}/addgeometry/:geometryId/configure`,
  },
  meshCreateInterpolationPanel: {
    path: `${meshbaseWithId}/interpolation/create`,
  },
  meshEditInterpolationPanel: {
    path: `${meshbaseWithId}/interpolation/:operationId/edit`,
  },
  meshPostOperationPanel: {
    path: `${meshbaseWithId}/postoperation`,
  },
  meshAddGeometrySelectMeshPanel: {
    path: `${meshbase}/addgeometry/:geometryId/selectmesh`,
  },
  meshesDeletePanel: {
    path: `${workspace}/meshes/delete`,
  },
};
