import { EWorkspaceActionType } from '../actions/WorkspaceActionType';

const initialState = {
  chartDataArrayId: null, // Only 1 chart type at a time
  chartElementId: null, // Only 1 chart at a time
};

/**
 * Workspace Chart Reducer.
 * - returns new states for matched chart workspace actions.
 *
 * @name WorkspaceChartReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }
    case EWorkspaceActionType.CHART_CLEAR:
      return {
        ...state,
        chartDataArrayId: initialState.chartDataArrayId,
        chartElementId: initialState.chartElementId,
      };

    case EWorkspaceActionType.CHART_SET:
      return {
        ...state,
        chartDataArrayId: action.chartDataArrayId,
        chartElementId: action.chartElementId,
      };

    default:
      return state;
  }
}
