/**
 * Exposes configuration REST endpoints to managers.
 *
 * @module ConfigurationProxy
 * @version 2.0.0
 * @requires Proxy
 *
 */
import { endpoints, paths } from 'proxies/config';
import Proxy from './Proxy';

/**
 * Get all configuration.
 */
export const getConfiguration = () => Proxy.http.get(`${endpoints.meshAppService}/${paths.configuration}`);
