import React, { ReactNode, useState } from 'react';
import { css } from 'emotion';
import { t } from 'src/translations/i18n';
import { theme } from 'src/shared/styles/theme';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import { parseParamsToLabels } from '../parameters/parameter-utils';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { TextFontSize, TitleFontSize } from '../styles/styleConsts';

const MessageBannerStyle = css`
  display: flex;
  width: 100%;
  padding: ${theme.spacing(2)}px 0;

  svg {
    margin: ${theme.spacing(1)}px 0 0 ${theme.spacing(2)}px;
    transform: scale(1.5) translateX(3px);
    transform-origin: top center;
  }

  .message__content {
    width: 100%;
    color: ${MIKE_COLORS.DARKGREY_DEFAULT};
    font-size: ${TextFontSize};
    margin-left: ${theme.spacing(2)}px;
  }

  .message__text > *:last-child {
    margin-bottom: 0;
  }

  .message__content > *:last-child {
    margin-bottom: 0;
  }

  .title {
    font-weight: 600;
    font-size: ${TitleFontSize};
    line-height: 1.4em;
    margin: ${theme.spacing(1)}px 0;
  }

  .more-button {
    width: 100%;
    font-weight: 600;
    color: ${MIKE_COLORS.ACTIONBLUE_DEFAULT};
    margin: 0;
    padding: 0;
    padding-bottom: ${theme.spacing(2)}px;
    background: 0;
    border: 0;
    border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
    text-align: right;
  }

  .more-button:hover {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }

  .more-button:focus {
    outline: 0;
  }

  .details {
    margin-top: ${theme.spacing(2)}px;
  }
`;

const MessageBannerFailedStyle = css`
  border-bottom: 1px solid ${theme.palette.error.main};
  svg {
    color: ${theme.palette.error.main};
  }
`;

const MessageBannerWarningCss = css`
  border-bottom: 1px solid ${theme.palette.warning.main};
  svg {
    color: ${theme.palette.warning.main};
  }
`;

export type MessageBannerTypes = 'failed' | 'warning' | 'info';

type MessageBannerProps = {
  messageTitle: string;
  messageType?: MessageBannerTypes;
  MessageIcon?: React.ComponentType<any>;
  message: string;
  messageDetails?: string;
  additionalMessageDetails?: ReactNode;

  children?: React.ReactNode;
};

// todo dan refactor: this should probably become a few composable components instead
// todo hevo refactor : I think Banner would be a good candidate for this when available in mui
export const MmgMessageBanner = (props: MessageBannerProps) => {
  const { message, messageType, messageTitle, messageDetails, additionalMessageDetails, MessageIcon, children } = props;

  const [detailsShown, setDetailsShown] = useState(false);

  const canShowDetails = messageDetails != null || additionalMessageDetails != null;

  let style = MessageBannerStyle;

  let StateIcon = null;

  switch (messageType) {
    case 'failed':
      style = css`
        ${MessageBannerStyle} ${MessageBannerFailedStyle};
      `;
      StateIcon = WarningIcon;
      break;

    case 'warning':
      style = css`
        ${MessageBannerStyle} ${MessageBannerWarningCss};
      `;
      StateIcon = WarningIcon;
      break;

    case 'info':
      StateIcon = InfoIcon;
      break;

    default:
      style = MessageBannerStyle;

      break;
  }

  const Icon = MessageIcon ? MessageIcon : StateIcon;
  const iconStyle = { width: 20, height: 20 };

  return (
    <div className={style}>
      {Icon && (
        <span>
          <Icon style={iconStyle} />
        </span>
      )}

      <section className="message__content">
        <p className="title">{messageTitle}</p>

        <div className="message__text">
          <p>{parseParamsToLabels(message)}</p>

          {children}
        </div>

        {canShowDetails && (
          <button onClick={() => setDetailsShown(!detailsShown)} className="more-button">
            {detailsShown ? t('SHOW_LESS') : t('SHOW_MORE')}
          </button>
        )}

        {detailsShown && (
          <>
            <p className="details">{messageDetails}</p>
            {additionalMessageDetails}
          </>
        )}
      </section>
    </div>
  );
};
