import { t } from 'src/translations/i18n';
import { store } from 'store/store';
import WorkspaceQueryManager from 'managers/WorkspaceQueryManager';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import tinycolor from 'tinycolor2';
import { DEFAULTS } from 'src/workspaces/viewer/viewer-utils';

const { deleteData, setColorToElement, getConfiguration, hideElement } = MikeVisualizerLib;

const backgrObj = tinycolor(getConfiguration().drawColors.background).toRgb();
const backgrArr = [backgrObj.a, backgrObj.g, backgrObj.b];
export const SELECTION_RESULT_COLOR = {
  edge: [0, 0, 0, 0.9], // dummy color; not rendered
  surface: DEFAULTS.colorsAdaptedToBaseMap.selectionResultColor.surface,
};
export const SELECTION_SOURCE_COLOR = {
  edge: DEFAULTS.colorsAdaptedToBaseMap.selectionSourceColor.edge,
  surface: DEFAULTS.colorsAdaptedToBaseMap.selectionSourceColor.surface,
};
export const SELECTION_RESULT_HIGHLIGHT_COLOR = {
  edge: [0, 0, 0, 0.9], // dummy color; not rendered
  surface: DEFAULTS.colorsAdaptedToBaseMap.selectionResultHighlightColor.surface,
};
export const SELECTION_GEOMETRY_COLOR = {
  surface: [...backgrArr, 0.2],
};

/**
 * Highlights a selection, changing its color.
 *
 * @param selectionId
 */
export const highlightSelection = (selectionId: string) => {
  setColorToElement(selectionId, SELECTION_RESULT_HIGHLIGHT_COLOR.edge, SELECTION_RESULT_HIGHLIGHT_COLOR.surface);
};

/**
 * Unhighlights a selection, changing its color to the original.
 *
 * @param selectionId
 */
export const unhighlightSelection = (selectionId: string) => {
  setColorToElement(selectionId, SELECTION_RESULT_COLOR.edge, SELECTION_RESULT_COLOR.surface);
};

/**
 * @param selectionId Removes the selection result from the viewer
 */
export const hideSelectionResult = (selectionId: string) => {
  hideElement(selectionId);
};

/**
 * Delete a spatial selection query.
 * Sends an api request and displays relevant toasts.
 *
 * @param workspaceId
 * @param selectionId
 */
export const deleteSelection = (workspaceId: string, selectionId: string) =>
  WorkspaceQueryManager.deleteQuery(workspaceId, selectionId)
    .then(() => {
      store.dispatch({ type: 'toast/ADD/SUCCESS', toast: { text: t('SELECTION_DELETED_SUCCESSFULLY') } });
      deleteData(selectionId);
    })
    .catch((error) => {
      store.dispatch({ type: 'toast/ADD/ERROR', toast: { text: t('SELECTION_DELETE_FAILED') } });
      throw error;
    });

const self = {
  highlightSelection,
  unhighlightSelection,
  hideSelectionResult,
  deleteSelection,
};

export default self;
