import { EViewerModes, VIEWER_MODES } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';
import { isBoolean, merge, uniq } from 'lodash-es';
import { EMapDataTypes, MAP_DATA_TYPES } from 'src/models/IMaps';
import { ESubmenus } from 'src/workspaces/viewer/tools/viewer-tool-constants';
import { IAction } from '../actions/Action';
import { EMapToolActionType } from '../actions/MapToolActionType';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';

/**
 * Some tools are not allowed in certain states. They will be hidden from the toolbar. For example, in a geometry details panel there won't be any extract feature tool.
 * Allowed tools are controlled in mount hooks of routes or panels. This allows each panel to control what tools should be allowed while active.
 * If a panel allows other tools that default during mount, it must reset the tools when unmounting.
 */

/**
 * Defines wich tools are allowed by default and which are not.
 */
const defaultAllowedTools = {
  // By default measuring tools are allowed
  measureToolAllowed: true,

  // By default 3D tools are allowed
  mapDataAllowed: true, // Allows showing 3D map data such as elevation
  exaggarationAllowed: true, // Allows showing 3D exaggerated z-values

  // By default drawing tools are not allowed
  geometryDrawingAllowed: false, // Allows drawing, but does not have an equivalent property to enable. Instead, individual drawing tools can be enabled while this is allowed: point, polyline & polygon.
  polygonDrawingAllowed: false,
  polylineDrawingAllowed: false,
  pointDrawingAllowed: false,
  circleDrawingAllowed: false,
  boxDrawingAllowed: false,
  variableDrawingAllowed: false,
  variablePropertyEditingAllowed: false,
  extractFeaturesAllowed: false,
  featureInfoAllowed: false, // Should only be allowed during extractions (for now, later should be there for edit as well).

  // By default adding comments are not allowed
  addCommentAllowed: false, // allows adding comment pins
};

const initialState = {
  // Drawing tools & feature info can be enabled / disabled one at a time.
  polygonDrawingEnabled: false,
  polylineDrawingEnabled: false,
  pointDrawingEnabled: false,
  circleDrawingEnabled: false,
  boxDrawingEnabled: false,
  featureInfoEnabled: false,
  addCommentEnabled: false,
  lineMeasurementEnabled: false,
  areaMeasurementEnabled: false,
  variableDrawingEnabled: false,
  variablePropertyEditingEnabled: false,

  mapToolsSupportedByProjection: true, // Map projection is assumed to support map tools by default
  ...defaultAllowedTools,

  // This controls which tool submenu is visible at a given time.
  openSubmenu: null,
  viewerMode: null,

  //This controls which data is shown by the 3d-map-data tool
  mapDataType: MAP_DATA_TYPES.NONE,
  resetViewCount: 0,
};

type MapToolState = typeof initialState;
export interface IMapToolState extends MapToolState {
  mapDataType: EMapDataTypes;
  openSubmenu: ESubmenus | null;
  viewerMode: EViewerModes | null;
}

/**
 * Returns a full IMapToolState object with all the 2D and 3D tools not supported by projection disallowed.
 * Eventually this will hide the tools completely in the toolbar.
 *
 * @param state - IMapToolState
 */
const disallowNotSupportedToolsOnState = (state: IMapToolState): IMapToolState => {
  console.warn('NB: Map tools not supported.');
  return {
    ...state,
    // 2D tools relying on projection
    measureToolAllowed: false,
    geometryDrawingAllowed: false,
    polygonDrawingAllowed: false,
    polylineDrawingAllowed: false,
    pointDrawingAllowed: false,
    circleDrawingAllowed: false,
    boxDrawingAllowed: false,
    featureInfoAllowed: false,
    variableDrawingAllowed: false,
    variablePropertyEditingAllowed: false,
    extractFeaturesAllowed: false,
    addCommentAllowed: false,
    //3D tools relying on projection
    mapDataAllowed: false,
  };
};

/**
 * Disables 3D tools that should not be available in 2D.
 */
const disable3DTools = () => {
  // Currently no 3D tools to disable:
  return {
    // mapDataAllowed: false,
    // exaggarationAllowed: false,
  };
};

/**
 * Enables 3D tools.
 */
const enable3DTools = () => {
  return {
    mapDataAllowed: true,
    exaggarationAllowed: true,
  };
};

interface IMapToolActionData {
  openSubmenu?: ESubmenus;
  viewerMode?: string;
  mapDataType?: EMapDataTypes;
  pointDrawingAllowed: boolean;
  variablePropertyEditingAllowed: boolean;
  data?: any;
}
interface IMapToolAction extends IAction<IMapToolActionData, EMapToolActionType | EWorkspaceActionType.CLOSE> {
  // TODO: joel; The values below should all be on IMapToolActionData instead but too risky to change it now.
  openSubmenu?: ESubmenus;
  viewerMode?: EViewerModes;
  mapDataType?: EMapDataTypes;
  pointDrawingAllowed: boolean;
  polygonDrawingAllowed: boolean;
  polylineDrawingAllowed: boolean;
  variablePropertyEditingAllowed: boolean;
}

/**
 * Map Tool Reducer.
 * - returns new states for matched MapTool message actions. They are typically related to drawing, view modes or selections.
 * @name MapToolReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IMapToolState = initialState, action): IMapToolState {
  switch (action.type) {
    case EWorkspaceActionType.CLOSE: {
      return { ...state, ...initialState };
    }

    case EMapToolActionType.RESET_ALLOWED_TOOLS: {
      const nextState = { ...state, ...defaultAllowedTools };
      // disallow those not supported by projection
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(nextState);
      }
      return nextState;
    }

    case EMapToolActionType.ALLOW_MAPTOOLS: {
      return { ...state, mapToolsSupportedByProjection: true };
    }

    case EMapToolActionType.DISALLOW_MAPTOOLS: {
      return {
        ...disallowNotSupportedToolsOnState(state),
        mapToolsSupportedByProjection: false,
      };
    }

    case EMapToolActionType.ENABLE_FEATURE_INFO:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: false,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: true,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_FEATURE_INFO:
      return { ...state, featureInfoEnabled: false };

    case EMapToolActionType.ENABLE_POLYGON_DRAWING:
      return {
        ...state,
        polygonDrawingEnabled: true,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: false,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_POLYGON_DRAWING:
      return { ...state, polygonDrawingEnabled: false };

    case EMapToolActionType.ENABLE_POLYLINE_DRAWING:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: true,
        pointDrawingEnabled: false,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_POLYLINE_DRAWING:
      return { ...state, polylineDrawingEnabled: false };

    case EMapToolActionType.ENABLE_POINT_DRAWING:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: true,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_POINT_DRAWING:
      return { ...state, pointDrawingEnabled: false };

    case EMapToolActionType.ENABLE_CIRCLE_DRAWING:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: false,
        circleDrawingEnabled: true,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_CIRCLE_DRAWING:
      return { ...state, circleDrawingEnabled: false };

    case EMapToolActionType.ENABLE_BOX_DRAWING:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: false,
        circleDrawingEnabled: false,
        boxDrawingEnabled: true,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_BOX_DRAWING:
      return { ...state, boxDrawingEnabled: false };

    case EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: false,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
      };

    case EMapToolActionType.ENABLE_ADD_COMMENT:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: true,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: true,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_ADD_COMMENT:
      return { ...state, addCommentEnabled: false };

    case EMapToolActionType.ENABLE_LINE_MEASUREMENT:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: false,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: true,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_LINE_MEASUREMENT:
      return { ...state, lineMeasurementEnabled: false };

    case EMapToolActionType.ENABLE_AREA_MEASUREMENT:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: false,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: true,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.DISABLE_AREA_MEASUREMENT:
      return { ...state, areaMeasurementEnabled: false };

    case EMapToolActionType.ENABLE_VARIABLE_DRAWING:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: true,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: true,
        variablePropertyEditingEnabled: false,
      };

    case EMapToolActionType.ENABLE_VARIABLE_PROPERTY_EDITING:
      return {
        ...state,
        polygonDrawingEnabled: false,
        polylineDrawingEnabled: false,
        pointDrawingEnabled: false,
        circleDrawingEnabled: false,
        boxDrawingEnabled: false,
        featureInfoEnabled: false,
        addCommentEnabled: false,
        lineMeasurementEnabled: false,
        areaMeasurementEnabled: false,
        variableDrawingEnabled: false,
        variablePropertyEditingEnabled: true,
      };

    case EMapToolActionType.DISABLE_VARIABLE_DRAWING:
      return { ...state, variableDrawingEnabled: false };

    case EMapToolActionType.SET_OPEN_SUBMENU:
      return { ...state, openSubmenu: action.openSubmenu };

    case EMapToolActionType.CLEAR_OPEN_SUBMENU:
      return { ...state, openSubmenu: null };

    case EMapToolActionType.ALLOW_EXTRACT_FEATURES:
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }
      return { ...state, extractFeaturesAllowed: true };

    case EMapToolActionType.DISALLOW_EXTRACT_FEATURES:
      return { ...state, extractFeaturesAllowed: false };

    case EMapToolActionType.ALLOW_GEOMETRY_DRAWING: {
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }
      // TODO: joel; shall we fix that the removed part below didn't work as intented?
      const { polygonDrawingAllowed, polylineDrawingAllowed, pointDrawingAllowed } = action; // `state` used to be `action` here, hence the bug.
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }
      return {
        ...state,
        geometryDrawingAllowed: true,
        polygonDrawingAllowed: isBoolean(polygonDrawingAllowed) ? polygonDrawingAllowed : true,
        polylineDrawingAllowed: isBoolean(polylineDrawingAllowed) ? polylineDrawingAllowed : true,
        pointDrawingAllowed: isBoolean(pointDrawingAllowed) ? pointDrawingAllowed : true,
        circleDrawingAllowed: isBoolean(polygonDrawingAllowed) ? polygonDrawingAllowed : true,
        boxDrawingAllowed: isBoolean(polygonDrawingAllowed) ? polygonDrawingAllowed : true,
      };
      /* 
      return {
        ...state,
        polygonDrawingAllowed: state.polygonDrawingEnabled,
        polylineDrawingAllowed: state.polylineDrawingEnabled,
        pointDrawingAllowed: state.pointDrawingEnabled,
        circleDrawingAllowed: state.circleDrawingEnabled,
        boxDrawingAllowed: state.boxDrawingEnabled,
        geometryDrawingAllowed: true,
      }; */
    }

    case EMapToolActionType.ALLOW_FEATURE_INFO:
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }
      return { ...state, featureInfoAllowed: true };

    case EMapToolActionType.ALLOW_ADD_COMMENT:
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }
      return { ...state, addCommentAllowed: true };

    case EMapToolActionType.ALLOW_VARIABLE_DRAWING: {
      const { pointDrawingAllowed, variablePropertyEditingAllowed } = action;
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }
      return {
        ...state,
        variableDrawingAllowed: true,
        pointDrawingAllowed: isBoolean(pointDrawingAllowed) ? pointDrawingAllowed : true,
        variablePropertyEditingAllowed: isBoolean(variablePropertyEditingAllowed)
          ? variablePropertyEditingAllowed
          : true,
      };
    }

    case EMapToolActionType.ALLOW_VARIABLE_PROPERTY_EDITING:
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }
      return { ...state, variablePropertyEditingAllowed: true };

    case EMapToolActionType.DISALLOW_VARIABLE_PROPERTY_EDITING:
      return { ...state, variablePropertyEditingAllowed: false };

    case EMapToolActionType.SET_MAPDATA_TYPE: {
      // TODO: joel; Should be `const { mapDataType } = action.data` but change too risky now.
      const { mapDataType } = action;
      return { ...state, mapDataType: mapDataType || MAP_DATA_TYPES.NONE };
    }

    // mapdata must be cleared in 2D mode
    case EMapToolActionType.SET_VIEWER_MODE_TO_2D:
      return { ...state, ...disable3DTools(), mapDataType: MAP_DATA_TYPES.NONE };

    case EMapToolActionType.SET_VIEWER_MODE_TO_3D:
      return { ...state, ...enable3DTools() };

    case EMapToolActionType.SET_VIEWER_MODE: {
      // TODO: joel; Should be `const { viewerMode } = action.data` but ViwerModeReducer & MapToolReducer relies on this.
      const viewerMode = action.viewerMode;
      if (viewerMode === VIEWER_MODES.TWO_D) {
        return { ...state, ...disable3DTools(), mapDataType: MAP_DATA_TYPES.NONE };
      }
      if (viewerMode === VIEWER_MODES.THREE_D) {
        return { ...state, ...enable3DTools() };
      }
      return state;
    }

    // This action iterates dummy value `resetViewCount` so you can subscribe
    // to it although the value is meaningless (introduced for cube widget issue):
    case EMapToolActionType.RESET_VIEW:
      const { resetViewCount } = state;
      return { ...state, resetViewCount: resetViewCount + 1 };

    default:
      return state;
  }
}
