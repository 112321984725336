import React from 'react';
import { css } from 'emotion';
import { theme } from '../styles/theme';
import { Typography } from '@material-ui/core';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';

const PanelTitleContainerStyle = css`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  min-width: 0;
  margin: ${theme.spacing(1)}px 0;
`;

const PanelTitleStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PanelDescriptionStyle = css`
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  line-height: 1rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type PanelTitleProps = {
  panelTitle: string;
  panelDescription?: string;
};

/**
 * @name MmgPanelTitle
 * @summary Panels can have one optional title and description at the top, for example the name of the current item (mesh, geometry etc).
 * Usually, a panel title goes under a panel header.
 *
 * @param props
 */
export function MmgPanelTitle(props: PanelTitleProps) {
  const { panelTitle, panelDescription } = props;

  return (
    <div className={PanelTitleContainerStyle}>
      <Typography variant="h3" className={PanelTitleStyle}>
        {panelTitle}
      </Typography>
      {panelDescription && <p className={PanelDescriptionStyle}>{panelDescription}</p>}
    </div>
  );
}
