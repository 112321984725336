import { all } from 'redux-saga/effects';
import watchExportableGeometries from './exportableGeometriesSagas';

/**
 * Exposes all available sagas for geometries
 *
 * todo hevo: consider if aný of the other root saga patterns is better?:  https://redux-saga.js.org/docs/advanced/RootSaga.html
 */
export default function* geometrySagas() {
  yield all([...watchExportableGeometries()]);
}
