import AppRoutes from 'src/app/app.routes';

const workspace = AppRoutes.workspace.path;
const geometrybase = `${workspace}/geometry`;
const geometrybaseWithId = `${geometrybase}/:geometryId`;

export default {
  geometryDetailsPanel: {
    path: geometrybaseWithId,
  },
  geometryOperationPanel: {
    path: `${geometrybaseWithId}/operation`,
  },
  geometriesOperationPanel: {
    path: `${workspace}/geometries/operation`,
  },
  geometriesDeletePanel: {
    path: `${workspace}/geometries/delete`,
  },
  geometriesOperationSelectItemsPanel: {
    path: `${workspace}/geometries/operation/select`,
  },
};
