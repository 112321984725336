import React from 'react';
import { Helmet } from 'react-helmet';

interface IMmgMetasProps {
  metaTitle: string;
  metaDescription?: string;
  metaUrl?: string;
}

const defaultMetaTitle = 'MIKE Mesh Builder';
const defaultMetaDesc =
  'The MIKE Mesh Builder provides tools to generate 2D quality meshes for Flood, Marine and Coastal problems.';

export const MmgMetas = (props: IMmgMetasProps) => {
  const { metaTitle, metaDescription, metaUrl } = props;

  const title = metaTitle || defaultMetaTitle;
  const desc = metaDescription || defaultMetaDesc;
  const url = metaUrl || document.location.href;
  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta data-custom-meta name="description" content={title} />
        <meta data-custom-meta name="description" content={desc} />
        <meta data-custom-meta content={title} />
        <meta data-custom-meta content={desc} />
        <meta data-custom-meta name="twitter:card" content="summary" />
        <meta data-custom-meta name="twitter:title" content={title} />
        <meta data-custom-meta name="twitter:description" content={desc} />
        <meta data-custom-meta name="og:title" content={title} />
        <meta data-custom-meta name="og:description" content={desc} />
        <meta data-custom-meta name="og:url" content={url} />
        <meta data-custom-meta name="og:site_name" content={title} />
      </Helmet>
    </div>
  );
};
