import { Feature, FeatureCollection } from 'geojson';
import { IPatchFeature } from 'src/models/IQueryCompleted';
import { EDIT_ID, EDIT_MODE, EDIT_MODE_ADDED } from 'src/store/reducers/EditReducer';
import { IChangedNode } from 'src/store/reducers/MeshMapToolReducer';

export const previousEditsToPatchFeatures = (previousEdits: FeatureCollection<any, any>[]) => {
  let nodePatchFeatures = Array<IPatchFeature>();
  previousEdits.forEach((previousFeatureColl: FeatureCollection<any, any>) => {
    const features = previousFeatureColl.features;
    features.forEach((fc: Feature<any>) => {
      const props = { ...fc.properties };
      if (props && props[EDIT_MODE] !== undefined) {
        const editMode = props[EDIT_MODE];
        const index = editMode === EDIT_MODE_ADDED ? -1 : props[EDIT_ID];
        delete props[EDIT_MODE];
        editMode !== EDIT_MODE_ADDED && delete props[EDIT_ID];
        const feature = { ...fc, properties: props };
        nodePatchFeatures = [
          ...nodePatchFeatures,
          {
            editState: editMode,
            geoJsonFeature: JSON.stringify(feature),
            vtuIndex: index,
          },
        ];
      }
    });
  });
  return nodePatchFeatures;
};

export const patchFeaturesToFeatures = (patchFeatures: IPatchFeature[]) => {
  const nodePatchFeatures: Array<Feature<any>> = patchFeatures.map((patchFeature: IPatchFeature) => {
    const feat = JSON.parse(patchFeature.geoJsonFeature);
    const feature: Feature<any> = {
      ...feat,
      properties: { ...feat.properties, [EDIT_MODE]: patchFeature.editState, [EDIT_ID]: patchFeature.vtuIndex },
    };
    return feature;
  });
  return nodePatchFeatures;
};

export const changedNodesToNodePatchFeatures = (previousNodesToUpdate: IChangedNode[]) => {
  const nodePatchFeatures: Array<IPatchFeature> = previousNodesToUpdate.map((node: IChangedNode) => {
    const feature = {
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [node.nodeX, node.nodeY] },
      properties: node.nodeProperties,
    };
    return {
      editState: node.editMode,
      geoJsonFeature: JSON.stringify(feature),
      vtuIndex: node.nodeIndex,
    };
  });
  return nodePatchFeatures;
};

export const changedNodesToFeatures = (nodesToUpdate: IChangedNode[]) => {
  const nodePatchFeatures: Array<Feature<any>> = nodesToUpdate.map((node: IChangedNode) => {
    const feature: Feature<any> = {
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [node.nodeX, node.nodeY] },
      properties: { [EDIT_MODE]: node.editMode },
    };
    return feature;
  });
  return nodePatchFeatures;
};
