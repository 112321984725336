/**
 * Feature flags are meant for being able to turn new development off untill it is ready.
 * These flags should be deleted once the implementation is ready, at the latest when the feature have been seen working in production.
 *
 * Note: would be nice to not have more than 5 of these at a time :)
 */
export const FeatureFlags = {
  // if 'TURN_ON_3D_BUILDINGS' is truthy in localStorage, a button for 3d buildings will be shown.
  allowSecret3dBuildings: !!localStorage.getItem('TURN_ON_3D_BUILDINGS'),

  // if USE_SPERIMENTAL_GRID is truthy use that version of the grid
  useSperimentalGrid: !!localStorage.getItem('USE_SPERIMENTAL_GRID'),

  /**
   * @property SPIKE! Not ready for prodution!.
   * Will use streaming endpoint that only returns the structure ('geoemetry) of an item, excluding the attribute.
   * Attributes must be streamed individually.
   *
   * Lacking: Handling of multiple chunks. Prevent try loading all attribute at once.
   */
  useStreamVtkGeometry: !!localStorage.getItem('TURN_ON_STREAM_VTKGEOMETRY'),

  /**
   * @property Not ready for production! If true, will reuse webworkers for reading vtu data.
   * TODO HEVO Should be turned on in the beginning of a sprint, to see if it reveals any problems on DEV during the sprint
   * Important!  reusing the webworker is causing this error: https://dhigroup.visualstudio.com/MIKE/_workitems/edit/36418
   */
  reuseWebWorker: false,
};

export default FeatureFlags;
