import { call, put, takeLatest, select, takeEvery, delay } from 'redux-saga/effects';

import { WorkspaceActionType } from '../store/WokspaceActionType';
import { workspaceSocketManager } from 'src/managers/WorkspaceSocketManager';
import { IAction } from 'src/store/actions/Action';
import { IGlobalState } from 'src/store';
import { IMmgConfiguration } from 'src/managers/ConfigurationManager';
import * as WorkspaceProxy from 'src/proxies/WorkspaceProxy';
import { t } from 'src/translations/i18n';
import { routeToPanel } from '../workspace-utils';
import { ROUTES } from 'src/app/routes';

import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import {
  IMeshStreamInfo,
  INotificationAttributeData,
  INotificationAttributeUpdatedMsg,
  INotificationItemData,
  INotificationItemDataStructure,
} from 'src/models/IWorkspaceNotifications';
import { IDrawnData, IDrawnDataItem } from 'src/models/IWorkspaceData';
import {
  getDrawnWorkspaceGeometriesByIds,
  getDrawnWorkspaceMeshesByIds,
  getDrawnWorkspaceVariablesByIds,
  getWorkspaceDataStatistics,
  getWorkspaceMeshTiles,
} from 'src/store/selectors/WorkspaceDrawnDataSelectors';
import WorkspaceGeometrySelectors from 'src/store/selectors/WorkspaceGeometrySelectors';
import WorkspaceVariableSelectors from 'src/store/selectors/WorkspaceVariableSelectors';
import WorkspaceMeshSelectors from 'src/store/selectors/WorkspaceMeshSelectors';
import { EMeshItemTypes, IWorkspaceMesh } from 'src/models/IMeshes';
import { readVtuPolyDataAsString } from '../viewer/extensions/viewer-vtu-reader';
import {
  deleteDataItems,
  handleDataArrayAddedOrUpdated,
  handleDataArrayDeleted,
  handleDataItemCreatedOrUpdated,
  handleDataItemDeleted,
  handleDataItemsDeleted,
  loadDataArrays,
  handleTileDataItemCreatedOrUpdated,
  MESH_COLOR_DARK_BASEMAP,
  MESH_COLOR_LIGHT_BASEMAP,
  mapRepresentation,
  _getRepresentation,
} from 'src/viewer/viewer-utilities';
import { logger } from 'src/managers/http-utils';
import FeatureFlags from 'src/app/feature-flags';
import { OPERATION_STATES } from 'src/models/IOperations';
import { EWorkspaceActionType } from 'src/store/actions/WorkspaceActionType';
import { PlatformProxy } from 'src/proxies/PlatformProxy';
import { getUserSettings } from 'src/store/selectors/WorkspaceSelectors';
import { ISettingsLayer, IUserSettings } from 'src/models/IUserSettings';
import { IWorkspaceMeshTiles } from 'src/store/reducers/WorkspaceMeshTilesReducer';
import { uniq } from 'lodash-es';
import { MMG_BASE_MAPS } from '../viewer/tools/viewer-tool-constants';
import { EWorkspaceDataActionType } from 'src/store/actions/WorkspaceDataActionType';
import { DATA_TYPES } from 'src/models/IViewerModels';
import { updateWorkspaceDataStatisticAttributeName } from 'src/store/actions/workspaceDataActions';
import { EWorkspaceMeshActionType } from 'src/store/actions/WorkspaceMeshActionType';

const { destroy, captureScreenshot } = MikeVisualizerLib;

const getConfiguration = (state: IGlobalState) => state.AppReducer.configuration;

const getListenerErrorCount = (state: IGlobalState) => state.SocketReducer.workspaceListenerErrorCount;

const getAutomaticReconnect = (state: IGlobalState) => state.SocketReducer.workspaceAutomaticReconnect;

const getSocketManagers = (state: IGlobalState) => state.SocketReducer.workspaceSocketManager;

const getRouteParams = (state: IGlobalState) =>
  state.AppReducer.routeParams as { projectId: string; workspaceId: string };

const getWorkspaceDataGradients = (state: IGlobalState) => state.WorkspaceDataReducer.workspaceDataGradients;

const getViewerBaseMapId = (state: IGlobalState) => state.ViewerModeReducer.viewerBaseMapId;

const getDrawnWorkspaceData = (state: IGlobalState) => state.SocketReducer.drawnWorkspaceData;

const getProject = (state: IGlobalState) => state.ProjectReducer.project;

const getWorkspace = (state: IGlobalState) => state.WorkspaceReducer.workspace;

const MAXRETRIES = 5;

export default function* watchSetupSocket() {
  yield takeLatest(WorkspaceActionType.SOCKET_INIT, setupSocket);
  yield takeEvery(WorkspaceActionType.SOCKET_TRY_RECONNECT, tryReconnect);
  yield takeEvery(WorkspaceActionType.SOCKET_CLOSE, closeSocket);
  yield takeEvery(WorkspaceActionType.SOCKET_RAW_DATA_LOADED, handleRawDataLoaded);
  yield takeEvery(WorkspaceActionType.SOCKET_TILE_DATA_LOADED, handleTileDataLoaded);
  yield takeEvery(WorkspaceActionType.SOCKET_ALL_TILE_DATA_LOADED, handleAllTileDataLoaded);
  yield takeEvery(WorkspaceActionType.SOCKET_RAW_DATA_LOADED_EXCL_DATAARRAYS, handleRawDataLoadedExclDataArrays);
  yield takeEvery(WorkspaceActionType.SOCKET_DATA_ATTRIBUTE_LOADED, handleDataAttributeLoaded);
  yield takeEvery(WorkspaceActionType.SOCKET_DATA_DELETED, handleDataDeleted);
  yield takeEvery(WorkspaceActionType.SOCKET_DATA_ITEMS_DELETED, handleItemsDeleted);
  yield takeEvery(WorkspaceActionType.SOCKET_DATA_ATTRIBUTE_DELETED, handleAttributeDeleted);
  yield takeEvery(WorkspaceActionType.SOCKET_GEOMETRIES_DELETED, handleGeometriesDeleted);
  yield takeEvery(WorkspaceActionType.SOCKET_VARIABLES_DELETED, handleVariablesDeleted);
  yield takeEvery(WorkspaceActionType.SOCKET_MESHES_DELETED, handleMeshesDeleted);
}

function* handleAllTileDataLoaded(
  action: IAction<{ streamInfo: IMeshStreamInfo; partIds: Array<string>; size: number }>,
) {
  const { streamInfo, size } = action.data;
  const itemId = streamInfo.itemId;
  const viewerBaseMapId = yield select(getViewerBaseMapId);
  const { workspaceId } = yield select(getRouteParams);
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = yield select(getDrawnWorkspaceMeshesByIds);
  const settings: IUserSettings = yield select(getUserSettings);
  const settingsLayer: ISettingsLayer = settings.tableOfContents.layers.find(
    (l: ISettingsLayer) => l.vtkItemId === itemId,
  );
  const pointSize = 4;
  const settingsEdgeColor =
    settingsLayer &&
    settingsLayer.noneRenderer &&
    settingsLayer.noneRenderer.wireframeSymbol &&
    settingsLayer.noneRenderer.wireframeSymbol.colors
      ? settingsLayer.noneRenderer.wireframeSymbol.colors
      : null;
  const settingsSurfaceColor =
    settingsLayer &&
    settingsLayer.noneRenderer &&
    settingsLayer.noneRenderer.surfaceSymbol &&
    settingsLayer.noneRenderer.surfaceSymbol.colors
      ? settingsLayer.noneRenderer.surfaceSymbol.colors
      : null;
  const edgeColor = settingsEdgeColor
    ? settingsEdgeColor
    : viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id
      ? MESH_COLOR_DARK_BASEMAP.edge
      : MESH_COLOR_LIGHT_BASEMAP.edge;
  const surfaceColor = settingsSurfaceColor
    ? settingsSurfaceColor
    : viewerBaseMapId === MMG_BASE_MAPS.MAPBOX_SATELLITE.id
      ? MESH_COLOR_DARK_BASEMAP.surface
      : MESH_COLOR_LIGHT_BASEMAP.surface;
  const representation =
    settingsLayer && settingsLayer.representation
      ? mapRepresentation(settingsLayer.representation)
      : _getRepresentation(itemId, DATA_TYPES.MESH, [], [], drawnWorkspaceMeshes);
  const gradientAttributeName =
    settingsLayer && settingsLayer.renderedAttribute ? settingsLayer.renderedAttribute : null;
  const zAttributeName = settingsLayer && settingsLayer.zAttribute ? settingsLayer.zAttribute : null;
  if (gradientAttributeName) {
    yield put({ type: EWorkspaceMeshActionType.GET_TILED_MESH_FIELD_STATISTICS, data: itemId }); // updateWorkspaceDataStatisticAttributeName(itemId, gradientAttributeName, false, true);
  }
  yield put({
    type: EWorkspaceDataActionType.ADD_DATA_ITEM,
    workspaceLength: size,
    workspaceItemId: itemId,
    workspaceItemDataId: itemId,
    workspaceRepresentation: representation,
    workspacePointSize: pointSize,
    workspaceDataEdgeColor: edgeColor,
    workspaceDataSurfaceColor: surfaceColor,
    workspaceDataArrays: [],
    workspaceFieldData: [],
    workspaceAttributes: [],
    workspaceItemType: EMeshItemTypes.MESH,
    workspaceItemDrawn: true,
    workspaceItemUpdated: streamInfo.updated,
    zAttributeName,
    statisticsAttributeName: gradientAttributeName,
  });

  // we notifiy to allow streaming of the next item to start.
  yield put({ type: WorkspaceActionType.LOAD_DATA_COMPLETED, identity: itemId, workspaceId, itemId });
}

function* handleTileDataLoaded(action: IAction<{ rawDataItem: INotificationItemData; streamInfo: IMeshStreamInfo }>) {
  const meshTiles: { [key: string]: Array<IWorkspaceMeshTiles> } = yield select(getWorkspaceMeshTiles);
  const viewerBaseMapId = yield select(getViewerBaseMapId);
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = yield select(getDrawnWorkspaceMeshesByIds);
  const settings: IUserSettings = yield select(getUserSettings);
  const { rawDataItem, streamInfo } = action.data;
  const { data, itemId, dataId, dataSize, updated, rawDataType } = rawDataItem;
  const { showElement, hideElements, hideElement, getState, setColorAndRepresentationToElements } = MikeVisualizerLib;
  const meshId = streamInfo.itemId;
  const settingsLayer: ISettingsLayer = settings.tableOfContents.layers.find(
    (l: ISettingsLayer) => l.vtkItemId === meshId,
  );
  const tilesForBounds = meshTiles[meshId];
  let partIds = Array<string>();
  let overviewPolygonId = '';
  if (tilesForBounds && tilesForBounds.length > 0) {
    tilesForBounds.forEach((tile: IWorkspaceMeshTiles) => {
      if (tile.isOverview && tile.partIds && tile.partIds.length > 0) {
        overviewPolygonId = tile.partIds[0];
      }
      partIds = partIds.concat(tile.partIds);
    });
  }
  const isOverviewPolygon = streamInfo.meshStreamRequestReturnType === 0;
  if (isOverviewPolygon) {
    const { hiddenElementIds } = getState();
    hideElements(uniq(hiddenElementIds.concat(partIds)));
  } else {
    overviewPolygonId && hideElement(overviewPolygonId);
  }
  const drawnWorkspaceMesh = drawnWorkspaceMeshes.find((d: IDrawnDataItem) => d.id === streamInfo.itemId);
  if (!data) {
    showElement(itemId); // tile has been loaded with previous stream request
  } else {
    let shouldUpdateData = false;
    let shouldSkipUpdate = false;
    if (tilesForBounds && tilesForBounds.length > 0) {
      shouldUpdateData = partIds.includes(itemId); // && updated > tilesForBounds[0].updated
      shouldSkipUpdate = partIds.includes(itemId) && updated === tilesForBounds[0].updated;
    }
    try {
      const vtkObject = rawDataType === 'vtu' ? yield call(readVtuPolyDataAsString, data) : data;
      const gradientAttributeName =
        settingsLayer && settingsLayer.renderedAttribute ? settingsLayer.renderedAttribute : null;
      let colorMappingRange;
      if (gradientAttributeName) {
        const allStatistics = yield select(getWorkspaceDataStatistics);
        const dataStatistic = allStatistics && allStatistics[streamInfo.itemId];
        if (dataStatistic) {
          const dataArray = dataStatistic.find((ds) => ds.id === gradientAttributeName);
          if (dataArray && dataArray.range && dataArray.range.length === 2) {
            colorMappingRange = dataArray.range;
          }
        }
      }
      handleTileDataItemCreatedOrUpdated(
        vtkObject,
        itemId,
        streamInfo.itemId,
        shouldUpdateData,
        shouldSkipUpdate,
        viewerBaseMapId,
        drawnWorkspaceMesh,
        settingsLayer,
        colorMappingRange,
      );
    } catch (error) {
      logger.error('Failed to process tile data created or updated event.', null, { error, dataId, dataSize });
    }
  }
}

function* handleMeshesDeleted(action: IAction<{ meshIds: Array<string>; operationIds: Array<string> }>) {
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const { meshIds, operationIds } = action.data;
  yield put({
    type: 'workspace/meshes/ITEMS_DELETED',
    meshIds,
  });

  yield put({
    type: 'workspace/operations/ITEMS_DELETED',
    operationIds,
  });

  yield put({
    type: 'workspace/data/ITEMS_DELETED',
    meshIds,
  });

  const drawnData: IDrawnData = deleteDataItems(drawnWorkspaceData, meshIds);
  yield put({
    type: WorkspaceActionType.SOCKET_SET_DRAWNDATA,
    data: drawnData,
  });
}

function* handleVariablesDeleted(action: IAction<{ variableIds: Array<string>; operationIds: Array<string> }>) {
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const { variableIds, operationIds } = action.data;
  yield put({
    type: 'workspace/variables/ITEMS_DELETED',
    variableIds,
  });

  yield put({
    type: 'workspace/operations/ITEMS_DELETED',
    operationIds,
  });

  yield put({
    type: 'workspace/data/ITEMS_DELETED',
    variableIds,
  });

  const drawnData: IDrawnData = deleteDataItems(drawnWorkspaceData, variableIds);
  yield put({
    type: WorkspaceActionType.SOCKET_SET_DRAWNDATA,
    data: drawnData,
  });
}

function* handleGeometriesDeleted(action: IAction<{ geometryIds: Array<string>; operationIds: Array<string> }>) {
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const { geometryIds, operationIds } = action.data;
  yield put({
    type: 'workspace/geometries/ITEMS_DELETED',
    geometryIds,
  });

  yield put({
    type: 'workspace/operations/ITEMS_DELETED',
    operationIds,
  });

  yield put({
    type: 'workspace/data/ITEMS_DELETED',
    geometryIds,
  });

  const drawnData: IDrawnData = deleteDataItems(drawnWorkspaceData, geometryIds);
  yield put({
    type: WorkspaceActionType.SOCKET_SET_DRAWNDATA,
    data: drawnData,
  });
}

function* handleAttributeDeleted(action: IAction<INotificationAttributeUpdatedMsg>) {
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const { itemId, dataId, propertyName, updated } = action.data;
  handleDataArrayDeleted(drawnWorkspaceData, itemId, dataId, propertyName, updated);
}

function* handleItemsDeleted(action: IAction<Array<string>>) {
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const itemDataIds = action.data;
  yield put({
    type: 'workspace/data/item/ITEMS_DELETED',
    workspaceItemId: itemDataIds,
  });

  const drawnData = handleDataItemsDeleted(drawnWorkspaceData, itemDataIds);
  if (drawnData) {
    yield put({
      type: WorkspaceActionType.SOCKET_SET_DRAWNDATA,
      data: drawnData,
    });
  }
}

function* handleDataDeleted(action: IAction<string>) {
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const itemId = action.data;
  yield put({
    type: 'workspace/data/item/DELETE',
    workspaceItemId: itemId,
  });

  const drawnData = handleDataItemDeleted(drawnWorkspaceData, itemId);
  if (drawnData) {
    yield put({
      type: WorkspaceActionType.SOCKET_SET_DRAWNDATA,
      data: drawnData,
    });
  }
}

function* handleDataAttributeLoaded(action: IAction<INotificationAttributeData>) {
  const { workspaceId } = yield select(getRouteParams);
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const totalVariablesToLoad = yield select(WorkspaceVariableSelectors.getWorkspaceVariablesNotFailed);
  const totalMeshes: Array<IWorkspaceMesh> = yield select(WorkspaceMeshSelectors.getWorkspaceMeshesNotFailed);
  const totalMeshesToLoad = totalMeshes.filter(({ dataId }) => Boolean(dataId));
  const { data, itemId, dataId, updated, attributeName } = action.data;
  yield put({
    type: WorkspaceActionType.LOAD_DATA_ATTRIBUTE_COMPLETED,
    identity: { itemId, attributeName },
    workspaceId,
    itemId,
    dataId,
    attributeName,
  });
  const drawnData: IDrawnData = handleDataArrayAddedOrUpdated(
    drawnWorkspaceData,
    totalVariablesToLoad,
    totalMeshesToLoad,
    data,
    dataId,
    updated,
  );

  if (drawnData) {
    yield put({
      type: WorkspaceActionType.SOCKET_SET_DRAWNDATA,
      data: drawnData,
    });
  }
}

function* handleRawDataLoadedExclDataArrays(action: IAction<INotificationItemDataStructure>) {
  if (!FeatureFlags.useStreamVtkGeometry) {
    console.warn(
      'Streaming of raw data excl attribute data not supported. Turn on by adding TURN_ON_STREAM_VTKGEOMETRY = true to localStorage',
    );
    return;
  }
  const { workspaceId } = yield select(getRouteParams);
  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = yield select(getDrawnWorkspaceGeometriesByIds);
  const drawnWorkspaceVariables: Array<IDrawnDataItem> = yield select(getDrawnWorkspaceVariablesByIds);
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = yield select(getDrawnWorkspaceMeshesByIds);
  const workspaceDataGradients = yield select(getWorkspaceDataGradients);
  const totalVariablesToLoad = yield select(WorkspaceVariableSelectors.getWorkspaceVariablesNotFailed);
  const totalMeshes: Array<IWorkspaceMesh> = yield select(WorkspaceMeshSelectors.getWorkspaceMeshesNotFailed);
  const totalMeshesToLoad = totalMeshes.filter(({ dataId }) => Boolean(dataId));
  const viewerBaseMapId = yield select(getViewerBaseMapId);
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const settings: IUserSettings = yield select(getUserSettings);

  const { data, itemId, dataId, dataSize, updated, rawDataType } = action.data;
  // we notifiy before starting to add data to the viewer, allowing streaming of the next item to start.
  yield put({ type: WorkspaceActionType.LOAD_DATA_COMPLETED, identity: itemId, workspaceId, itemId, dataId });
  try {
    const vtkObject = rawDataType === 'vtu' ? yield call(readVtuPolyDataAsString, data) : data;
    const settingsLayer: ISettingsLayer = settings.tableOfContents.layers.find(
      (l: ISettingsLayer) => l.vtkItemId === itemId,
    );
    const drawnDataItem: IDrawnDataItem = handleDataItemCreatedOrUpdated(
      drawnWorkspaceData,
      drawnWorkspaceVariables,
      drawnWorkspaceGeometries,
      drawnWorkspaceMeshes,
      totalVariablesToLoad,
      totalMeshesToLoad,
      viewerBaseMapId,
      workspaceDataGradients,
      vtkObject,
      dataSize,
      updated,
      itemId,
      dataId,
      settingsLayer,
    );
    if (drawnDataItem) {
      // this._drawnWorkspaceData[itemId] =
      const newData = drawnWorkspaceData;
      newData[itemId] = drawnDataItem;
      yield put({
        type: WorkspaceActionType.SOCKET_SET_DRAWNDATA,
        data: newData,
      });
      if (FeatureFlags.useStreamVtkGeometry) {
        // if nothing was rendered, no need to load attributes
        if (drawnDataItem) {
          // todo hevo Consider not loading attribute data untill needed, eg when user enter a mesh/geoemtry/variable details panel if not loaded already
          // if the current logic is kept, the streamingPriorityBuffer must be updated in respect to how to prioritise attributes
          loadDataArrays(action.data as INotificationItemDataStructure, workspaceId);
        }
      }
    }
  } catch (error) {
    logger.error('Failed to process raw data created or updated event.', null, { error, dataId, dataSize });

    // todo hevo better error handling should be added. Eg including the name of failed item, or setting the item in failed state and stop spinner
    yield put({
      type: 'toast/ADD/ERROR',
      toast: { text: t('WORKSPACE_ITEM_READING_FAILED') },
    });
  }
}

function* handleRawDataLoaded(action: IAction<INotificationItemData>) {
  const { workspaceId } = yield select(getRouteParams);
  const drawnWorkspaceGeometries: Array<IDrawnDataItem> = yield select(getDrawnWorkspaceGeometriesByIds);
  const drawnWorkspaceVariables: Array<IDrawnDataItem> = yield select(getDrawnWorkspaceVariablesByIds);
  const drawnWorkspaceMeshes: Array<IDrawnDataItem> = yield select(getDrawnWorkspaceMeshesByIds);
  const workspaceDataGradients = yield select(getWorkspaceDataGradients);
  const totalGeometriesToLoad = yield select(WorkspaceGeometrySelectors.getWorkspaceGeometriesNotFailed);
  const totalVariablesToLoad = yield select(WorkspaceVariableSelectors.getWorkspaceVariablesNotFailed);
  const totalMeshes: Array<IWorkspaceMesh> = yield select(WorkspaceMeshSelectors.getWorkspaceMeshesNotFailed);
  const totalMeshesToLoad = totalMeshes.filter(({ dataId }) => Boolean(dataId));
  const viewerBaseMapId = yield select(getViewerBaseMapId);
  const drawnWorkspaceData = yield select(getDrawnWorkspaceData);
  const settings: IUserSettings = yield select(getUserSettings);

  const { data, itemId, dataId, dataSize, updated, rawDataType } = action.data;

  yield put({ type: WorkspaceActionType.LOAD_DATA_COMPLETED, identity: itemId, workspaceId, itemId, dataId });
  if (data) {
    try {
      const vtkObject = rawDataType === 'vtu' ? yield call(readVtuPolyDataAsString, data) : data;
      const settingsLayer: ISettingsLayer = settings.tableOfContents.layers.find(
        (l: ISettingsLayer) => l.vtkItemId === itemId,
      );

      const drawnDataItem: IDrawnDataItem = handleDataItemCreatedOrUpdated(
        drawnWorkspaceData,
        drawnWorkspaceVariables,
        drawnWorkspaceGeometries,
        drawnWorkspaceMeshes,
        totalVariablesToLoad,
        totalMeshesToLoad,
        viewerBaseMapId,
        workspaceDataGradients,
        vtkObject,
        dataSize,
        updated,
        itemId,
        dataId,
        settingsLayer,
      );
      if (drawnDataItem) {
        const newData = drawnWorkspaceData;
        newData[itemId] = drawnDataItem;
        yield put({
          type: WorkspaceActionType.SOCKET_SET_DRAWNDATA,
          data: newData,
        });
      }
    } catch (error) {
      logger.error('Failed to process raw data created or updated event.', null, { error, dataId, dataSize });
      const itemsToLoad = [].concat(totalGeometriesToLoad, totalVariablesToLoad, totalMeshesToLoad);
      const wsItem: any = itemsToLoad.find((i) => i.id === itemId);
      if (wsItem !== undefined) {
        yield put({
          type: 'workspace/data/item/failed/add',
          failedItemId: wsItem[0].id,
        });
        wsItem.state = OPERATION_STATES.FAILED;
      }
      const message =
        wsItem !== undefined
          ? t('WORKSPACE_ITEM_READING_FAILED_ITEM') + ` ${wsItem.name}.`
          : t('WORKSPACE_ITEM_READING_FAILED');

      // todo hevo better error handling should be added. Eg including the name of failed item, or setting the item in failed state and stop spinner
      yield put({
        type: 'toast/ADD/ERROR',
        toast: { text: message },
      });
    }
  }
}

function* closeSocket() {
  // Try to capture bounds and screensot from viewer before being destroyed
  const project = yield select(getProject);
  if (project && project.capabilities && project.capabilities.canUpdateContent) {
    const workspace = yield select(getWorkspace);
    if (workspace && workspace.id) {
      try {
        const response = yield call(WorkspaceProxy.doesWorkspaceExist, workspace.id);
        const workspaceExists = response && response.status === 200 && response.data === true;
        if (workspaceExists === true) {
          const screenshot = yield call(captureScreenshot);
          if (screenshot) {
            yield call(WorkspaceProxy.updateWorkspace, workspace.id, {
              image: screenshot,
            });
          }
        }
      } catch (error) {
        console.debug('socket closing error', error);
      }
    }
  }

  // Reset workspace and disconnect websocket
  yield put({ type: EWorkspaceActionType.CLOSE });
  const workspaceSocketManagers = yield select(getSocketManagers);
  if (workspaceSocketManagers) {
    workspaceSocketManagers.disconnect();
  }

  // Destroy viewer instance. The viewer should unregister callbacks and event listeners.
  destroy();
  yield put({ type: WorkspaceActionType.SOCKET_RESET });
}

function* setupSocket() {
  const { projectId, workspaceId } = yield select(getRouteParams);
  const response = yield call(PlatformProxy.getSASToken, projectId);
  const configuration = yield select(getConfiguration);
  const { connection } = configuration as IMmgConfiguration;
  const hubConnectionConfiguration = connection || {};
  const socketManager = workspaceSocketManager(workspaceId, response.data.data, hubConnectionConfiguration);
  yield put({ type: WorkspaceActionType.SOCKET_SETUP, data: socketManager });
}

function* tryReconnect(action: IAction<string>) {
  const { projectId, workspaceId } = yield select(getRouteParams);
  if (projectId && workspaceId) {
    const workspaceAutomaticReconnect = yield select(getAutomaticReconnect);
    if (workspaceAutomaticReconnect) {
      const workspaceListenerErrorCount = yield select(getListenerErrorCount);
      const nextErrorCount = workspaceListenerErrorCount + 1;
      yield put({ type: WorkspaceActionType.SOCKET_SET_ERROR_COUNT, data: nextErrorCount });
      // Retry connecting after error count * 1000ms, until max retires reached.
      if (nextErrorCount <= MAXRETRIES) {
        const errorMessage = action.data;
        yield put({ type: 'toast/ADD/WORKING', toast: { text: errorMessage } });
        yield delay(nextErrorCount * 1000);
        // Clear all 'working' toasts before retrying a connection. This make sure reconnection toasts don't stack or are shown together with error toasts.
        yield put({ type: 'toast/CLEAR_ALL_WORKING' });
        // Check if workspace exists, before reconnecting.
        // If the workspace was deleted, don't reconnect anymore; go back to workspace-list:
        let workspaceExists = false;
        try {
          const response = yield call(WorkspaceProxy.doesWorkspaceExist, workspaceId);
          workspaceExists = response && response.status === 200 && response.data === true;
          if (workspaceExists === false) {
            routeToPanel(ROUTES.workspaceList.path, { projectId });
            yield put({
              type: 'toast/ADD/INFO',
              toast: { text: t('WORKSPACE_CURRENT_WS_DELETED') },
            });
            yield put({
              type: 'workspace/recent/DELETE',
              workspaceId,
            });
          }
        } catch (error) {
          console.debug('Workspace reconnectiong aborted', error);
          if (error && error.response && error.response.status === 404) {
            workspaceExists = false;
          }
        }
        if (workspaceExists) {
          yield put({ type: WorkspaceActionType.SOCKET_INIT });
        }
      } else {
        yield put({
          type: 'toast/CLEAR_ALL',
        });

        // Display an error toast after max retries are exceeded.
        yield put({
          type: 'toast/ADD/ERROR',
          toast: { text: t('WORKSPACE_SOCKET_RETRY_STOP') },
        });
      }
    }
  }
}
