export enum EWorkspaceGeometryActionType {
  UPDATE = 'workspace/geometries/UPDATE',
  UPDATING = 'workspace/geometries/UPDATING',
  CREATE = 'workspace/geometries/CREATE',
  CREATING = 'workspace/geometries/CREATING',
  CREATE_FAILED = 'workspace/geometries/CREATE_FAILED',
  UPDATE_FAILED = 'workspace/geometries/UPDATE_FAILED',
  LOAD = 'workspace/geometries/LOAD',
  LOAD_SUCCESS = 'workspace/geometries/LOAD_SUCCESS',
  CLEAR = 'workspace/geometries/CLEAR',
  DELETE = 'workspace/geometries/DELETE',
  ITEMS_DELETED = 'workspace/geometries/ITEMS_DELETED',
  DELETE_SUCCESS = 'workspace/geometries/DELETE_SUCCESS',
  DELETE_FAILED = 'workspace/geometries/DELETE_FAILED',
  ITEM_ADDED_OR_UPDATED = 'workspace/geometries/ITEM_ADDED_OR_UPDATED',
  HIDE_ITEMS = 'workspace/geometries/HIDE_ITEMS',
  HIDE_ITEM = 'workspace/geometries/HIDE_ITEM',
  HIDE_ALL = 'workspace/geometries/HIDE_ALL',
  SHOW_ITEM = 'workspace/geometries/SHOW_ITEM',
  SHOW_ITEM_ONLY = 'workspace/geometries/SHOW_ITEM_ONLY',
  SHOW_ALL = 'workspace/geometries/SHOW_ALL',
  SHOW_ALL_BUT_USER_HIDDEN = 'workspace/geometries/SHOW_ALL_BUT_USER_HIDDEN',
  UPDATE_USER_HIDDEN = 'workspace/geometries/UPDATE_USER_HIDDEN',
  SELECT = 'workspace/geometries/SELECT',
  DESELECT = 'workspace/geometries/DESELECT',
  RESET_SELECTIONS = 'workspace/geometries/RESET_SELECTIONS',
  DESELECT_ALL = 'workspace/geometries/DESELECT_ALL',
  SELECT_MULTIPLE = 'workspace/geometries/SELECT_MULTIPLE',
}
