export enum EProjectContentActionType {
  GET_PROJECTCONTENT = 'PROJECTCONTENT/GET',
  SET_PROJECTCONTENT = 'PROJECTCONTENT/SETPROJECTCONTENT',
  LOADING_PROJECTCONTENT = 'PROJECTCONTENT/LOADING',
  GET_PROJECTANDPARENT = 'PROJECT/GET_PROJECTANDPARENT',
  SET_PROJECTANDPARENT = 'PROJECT/SET_PROJECTANDPARENT',
  LOADING_PROJECTANDPARENT = 'PROJECT/LOADING',
  OPEN_PROJECTCONTENT_DIALOG = 'PROJECTCONTENT/DIALOG_OPEN',
  SET_PROJECTCONTENT_PAGE = 'PROJECTCONTENT/SET_PAGE',
  SET_PROJECTCONTENT_ROWSPERPAGE = 'PROJECTCONTENT/SET_ROWSPERPAGE',
  SET_PROJECTCONTENT_ORDER = 'PROJECTCONTENT/SET_ORDER',
  SET_PROJECTCONTENT_ORDERBY = 'PROJECTCONTENT/SET_ORDERBY',
  REPLACE_WITH_PROJECTCONTENT = 'PROJECTCONTENT/REPLACE_WITH',
}
