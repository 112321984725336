import { uniq } from 'lodash-es';
import { IWorkspaceMesh } from 'models/IMeshes';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EWorkspaceMeshActionType } from '../actions/WorkspaceMeshActionType';

const initialState: IWorkspaceMeshState = {
  workspaceMeshes: [],
  workspaceMeshesLoading: false,
  hiddenWorkspaceMeshes: [],
  selectedWorkspaceMeshes: [],
  submittedForDeleteWorkspaceMeshes: [],
  selectedMeshGeometries: [],
  selectedMeshVariables: [],
  userHiddenMeshIds: [],
  interpolationType: 'elevation',
};

export interface IWorkspaceMeshState {
  workspaceMeshes: Array<IWorkspaceMesh>;
  workspaceMeshesLoading: boolean;
  hiddenWorkspaceMeshes: Array<string>; // Holds the currently hidden mesh ids of the workspace
  selectedWorkspaceMeshes: Array<string>;
  submittedForDeleteWorkspaceMeshes: Array<string>;
  selectedMeshGeometries: Array<string>;
  selectedMeshVariables: Array<string>;
  userHiddenMeshIds: Array<string>; // Holds the meshes actively hidden by the user
  interpolationType: string;
}

/**
 * Workspace Mesh Reducer.
 * - returns new states for matched workspace mesh actions.
 *
 * @name WorkspaceMeshReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IWorkspaceMeshState = initialState, action) {
  const updateHiddenMeshIds = (hiddenMeshIds: Array<string>, updateUserSettings: boolean) => {
    return {
      ...state,
      hiddenWorkspaceMeshes: hiddenMeshIds,
      userHiddenMeshIds: updateUserSettings ? hiddenMeshIds : state.userHiddenMeshIds,
    };
  };

  switch (action.type) {
    case 'workspace/meshes/LOAD': {
      return {
        ...state,
        workspaceMeshesLoading: true,
      };
    }

    case 'workspace/meshes/LOAD_SUCCESS': {
      const meshes = action.workspaceMeshes;

      return {
        ...state,
        workspaceMeshesLoading: false,
        workspaceMeshes: meshes,
      };
    }

    case 'workspace/meshes/CLEAR':
      return {
        ...state,
        workspaceMeshes: initialState.workspaceMeshes,
      };

    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case 'workspace/meshes/DELETE': {
      const submittedForDeleteWorkspaceMeshes = state.workspaceMeshes.filter(
        (m) => action.workspaceMeshes.indexOf(m.id) === -1,
      );

      return {
        ...state,
        submittedForDeleteWorkspaceMeshes,
      };
    }

    case 'workspace/meshes/ITEMS_DELETED': {
      const idsToDelete = action.meshIds as Array<string>;

      if (!idsToDelete || idsToDelete.length === 0) {
        return state;
      }

      const workspaceMeshes = state.workspaceMeshes.filter((m) => idsToDelete.indexOf(m.id) === -1);

      return {
        ...state,
        workspaceMeshes,
      };
    }

    case 'workspace/meshes/DELETE_SUCCESS': {
      const submittedForDeleteWorkspaceMeshes = [];

      const workspaceMeshes = state.workspaceMeshes.filter((m) => action.workspaceMeshes.indexOf(m.id) === -1);

      return {
        ...state,
        workspaceMeshes,
        submittedForDeleteWorkspaceMeshes,
      };
    }

    case 'workspace/meshes/DELETE_FAILED': {
      const submittedForDeleteWorkspaceMeshes = [];

      return {
        ...state,
        submittedForDeleteWorkspaceMeshes,
      };
    }
    case 'workspace/meshes/SET_INTERPOLATION_TYPE': {
      return {
        ...state,
        interpolationType: action.interpolationType,
      };
    }

    case 'workspace/meshes/ITEM_ADDED_OR_UPDATED': {
      const { workspaceMesh } = action;

      if (!workspaceMesh) {
        return state;
      }

      const meshIndex = state.workspaceMeshes.findIndex((m) => m.id === workspaceMesh.id);

      // Add new mesh if not found
      if (meshIndex === -1) {
        return {
          ...state,
          workspaceMeshes: [...state.workspaceMeshes, workspaceMesh],
        };
      }

      // replace existing mesh if found
      return {
        ...state,
        workspaceMeshes: [
          ...state.workspaceMeshes.slice(0, meshIndex),
          workspaceMesh,
          ...state.workspaceMeshes.slice(meshIndex + 1),
        ],
      };
    }

    case EWorkspaceMeshActionType.HIDE_ITEMS: {
      const hiddenMeshIds = uniq([...state.hiddenWorkspaceMeshes, ...action.workspaceMeshIds]);

      return updateHiddenMeshIds(hiddenMeshIds, action.updateUserSettings);
    }

    case EWorkspaceMeshActionType.HIDE_ITEM: {
      const hiddenMeshIds = uniq([...state.hiddenWorkspaceMeshes, action.workspaceMeshId]);

      return updateHiddenMeshIds(hiddenMeshIds, action.updateUserSettings);
    }

    case EWorkspaceMeshActionType.HIDE_ALL: {
      const meshIds = state.workspaceMeshes.map(({ id }) => id);

      return updateHiddenMeshIds([...meshIds], action.updateUserSettings);
    }

    case EWorkspaceMeshActionType.SHOW_ITEM: {
      const hiddenMeshIds = state.hiddenWorkspaceMeshes.filter((mId) => mId !== action.workspaceMeshId);

      return updateHiddenMeshIds(hiddenMeshIds, action.updateUserSettings);
    }

    case EWorkspaceMeshActionType.SHOW_ITEM_ONLY: {
      const hiddenMeshIds = state.workspaceMeshes
        .map((wg: IWorkspaceMesh) => wg.id)
        .filter((id: string) => id !== action.meshId);
      const userHidden = action.updateUserSettings
        ? state.userHiddenMeshIds.filter((id: string) => id !== action.meshId)
        : state.userHiddenMeshIds;
      return {
        ...state,
        hiddenWorkspaceMeshes: hiddenMeshIds,
        userHiddenMeshIds: userHidden,
      };
    }

    case 'workspace/meshes/SHOW_ALL':
      return updateHiddenMeshIds([], action.updateUserSettings);

    case 'workspace/meshes/SHOW_ALL_BUT_USER_HIDDEN': {
      const hiddenMeshIds = [...state.userHiddenMeshIds];
      return updateHiddenMeshIds(hiddenMeshIds, false);
    }

    case EWorkspaceMeshActionType.UPDATE_USER_HIDDEN: {
      const hiddenMeshIds = uniq([...state.userHiddenMeshIds, ...action.userHiddenMeshIds]);

      return { ...state, userHiddenMeshIds: hiddenMeshIds };
    }

    case EWorkspaceMeshActionType.SELECT:
      return {
        ...state,
        selectedWorkspaceMeshes: action.unselectOthers
          ? [action.workspaceMeshId]
          : [...state.selectedWorkspaceMeshes, action.workspaceMeshId],
      };

    case 'workspace/meshes/SELECT_MULTIPLE':
      return {
        ...state,
        selectedWorkspaceMeshes: uniq([...state.selectedWorkspaceMeshes, ...action.workspaceMeshIds]),
      };

    case 'workspace/meshes/DESELECT': {
      const selectedWorkspaceMeshes = state.selectedWorkspaceMeshes.filter((el) => el !== action.workspaceMeshId);

      return {
        ...state,
        selectedWorkspaceMeshes,
      };
    }

    case 'workspace/meshes/DESELECT_ALL':
      return {
        ...state,
        selectedWorkspaceMeshes: [],
      };

    case 'workspace/meshes/RESET_SELECTIONS': {
      return {
        ...state,
        selectedWorkspaceMeshes: [],
        selectedMeshGeometries: [],
        selectedMeshVariables: [],
      };
    }

    default:
      return state;
  }
}
