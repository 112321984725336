import { takeLatest } from 'redux-saga/effects';
import { EMapToolActionType } from 'src/store/actions/MapToolActionType';
import { IViewerModeAction } from 'src/store/reducers/ViewerModeReducer';
import { graticuleHandler } from '../viewer/tools/makeGraticule';

/**
 * Defines sagas for changes to `showGridOverlay`.
 */
export default function* watchSetGridOverlay() {
  yield takeLatest(EMapToolActionType.SET_GRIDOVERLAY, manageGraticule);
}
/**
 * Generator function for `showGridOverlay` related actions.
 * @param action
 */
function* manageGraticule(action: IViewerModeAction) {
  const { showGridOverlay } = action;
  if (graticuleHandler && graticuleHandler.toggle) {
    showGridOverlay ? graticuleHandler.show() : graticuleHandler.hide();
    yield true;
  }
  yield false;
}
