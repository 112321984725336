import { IMeshGenericParameters } from 'models/IMeshOperations';
import { EOperationStates } from 'models/IOperations';
import { EElementCategories } from 'src/shared/panels/mesh-panel-constants';

export enum EGeometryItemTypes {
  POINT = 'Point',
  MULTI_POINT = 'MultiPoint',
  POLYGON = 'Polygon',
  MULTI_POLYGON = 'MultiPolygon',
  LINE_STRING = 'LineString',
  MULTI_LINE_STRING = 'MultiLineString',
}

export const GEOMETRY_ITEM_TYPES = {
  POINT: EGeometryItemTypes.POINT,
  MULTI_POINT: EGeometryItemTypes.MULTI_POINT,
  POLYGON: EGeometryItemTypes.POLYGON,
  MULTI_POLYGON: EGeometryItemTypes.MULTI_POLYGON,
  LINE_STRING: EGeometryItemTypes.LINE_STRING,
  MULTI_LINE_STRING: EGeometryItemTypes.MULTI_LINE_STRING,
};

export interface IWorkspaceGeometry {
  id?: string;
  dataId?: string; // should be replaced by vtkFileId
  // vtkFileId?: string; // will be included ny api soon
  projectId?: string;
  dataset?: string;
  isHole?: boolean;
  itemType?: EGeometryItemTypes;
  name?: string;
  created?: string;
  updated?: string;
  isTiled?: boolean;
}

export interface IWorkspaceEnrichedGeometry extends IWorkspaceGeometry {
  state?: EOperationStates;
  message?: string;
  category?: EElementCategories;
}

// todo IWorkspaceGeometryParameters will be removed in API_V2
export interface IWorkspaceGeometryParameters extends IMeshGenericParameters {
  isHole?: boolean;
}
