import React from 'react';

import { ReactComponent as Hole } from '@mike/mike-shared-frontend/media/icons/Hole';
import { ReactComponent as Point } from '@mike/mike-shared-frontend/media/icons/Point';
import { ReactComponent as Polygon } from '@mike/mike-shared-frontend/media/icons/Polygon';
import { ReactComponent as Polyline } from '@mike/mike-shared-frontend/media/icons/Polyline';
import { ReactComponent as PolygonHole } from '@mike/mike-shared-frontend/media/icons/PolygonHole';
import { ReactComponent as Mystery } from '@mike/mike-shared-frontend/media/icons/Mystery';

import { ITEM_TYPES } from 'models/IWorkspaceData';
import { IWorkspaceEnrichedGeometry } from 'models/IGeometries';

const HolePostFix = '_' + ITEM_TYPES.HOLE;

const getIcon = (item: IWorkspaceEnrichedGeometry) => {
  const iconName = getIconName(item);
  return getIconByName(iconName);
};

const getIconName = (item: IWorkspaceEnrichedGeometry) => {
  if ('isHole' in item) {
    return item.isHole ? item.itemType + HolePostFix : item.itemType;
  }

  return item.itemType;
};

const getIconByName = (iconName: string): React.ReactNode => {
  switch (iconName) {
    case ITEM_TYPES.HOLE:
      return <Hole />;

    case ITEM_TYPES.POINT:
    case ITEM_TYPES.MULTI_POINT:
      return <Point />;

    case ITEM_TYPES.POLYGON:
    case ITEM_TYPES.MULTI_POLYGON: {
      return <Polygon />;
    }

    case ITEM_TYPES.POLYGON + HolePostFix:
    case ITEM_TYPES.MULTI_POLYGON + HolePostFix:
      return <PolygonHole />;

    case ITEM_TYPES.LINE_STRING:
    case ITEM_TYPES.MULTI_LINE_STRING:
      return <Polyline />;

    default: {
      // Use general Hole icon as fallback for geometry types marked as holes
      if (iconName && iconName.endsWith(ITEM_TYPES.HOLE)) {
        return <Hole />;
      }

      console.debug(`No icon found for ${iconName} `);
      return <Mystery />;
    }
  }
};

const self = {
  getIcon,
  getIconName,
  getIconByName,
};

export default self;
