export enum ERepresentation {
  POINTS = 'Points',
  WIREFRAME = 'Wireframe',
  SURFACE = 'Surface',
  SURFACEWITHWIREFRAME = 'SurfaceWithWireframe',
}

export enum EViewType {
  TYPED = 'Typed',
  CUSTOM = 'Custom ',
}

export enum EViewerMode {
  TWOD = 'TwoD',
  THREED = 'ThreeD',
}
export enum EBASEMAP {
  NONE = 'None',
  MAPBOXSATTELITE = 'MapBoxSatellite',
  MAPTILERSTREET = 'MapTilerStreet',
}

export interface ISettingsLayer {
  vtkItemId: string;
  visible: boolean;
  labelAttribute: string;
  renderedAttribute: string;
  zAttribute: string;
  noneRenderer: {
    surfaceSymbol: {
      symbolType: string;
      colors: Array<number>;
    };
    wireframeSymbol: {
      symbolType: string;
      colors: Array<number>;
    };
    pointSymbol: {
      symbolType: string;
      colors: Array<number>;
    };
  };
  attributeRenderer: [];
  representation: ERepresentation;
}

export interface ILayerColor {
  wireFrameColor: Array<number>;
  surfaceColor: Array<number>;
  elementId: string;
}

export interface ISettingsMap {
  mode: EViewerMode;
  backgroundMap: EBASEMAP;
  gridOverlayVisible: boolean;
}

export interface ITableOfContent {
  layers: Array<ISettingsLayer>;
  typedView: {
    groups: Array<ISettingsGroup>;
    viewType: EViewType;
  };
  customView: {
    groups: Array<ISettingsGroup>;
    viewType: EViewType;
  };
  activeView: EViewType;
}

export interface ISettingsGroup {
  name: string;
  order: number;
  layerReferences: [
    {
      vtkItemId: string;
      order: number;
    }
  ];
}

export interface IUserSettings {
  tableOfContents: ITableOfContent;
  map: ISettingsMap;
}
