/**
 * Exposes workspace meshes REST endpoints to managers.
 *
 * @module WorkspaceMeshesProxy
 * @version 2.0.0
 * @requires Proxy
 */
import { endpoints, paths } from './config';
import Proxy from './Proxy';
import { EMeshFormats } from 'models/IWorkspaceData';
import { IWorkspaceMesh } from 'models/IMeshes';

const { meshAppService } = endpoints;
const { workspaces, meshes, duplicate } = paths;

/**
 * Create a mesh metadata object.
 *
 * @param workspaceId
 * @param meshPayload
 */
export const createMesh = (workspaceId: string, meshPayload: IWorkspaceMesh) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}`, meshPayload);

/**
 * Duplicates a mesh
 *
 * @param workspaceId
 * @param meshId
 */
export const duplicateMesh = (workspaceId: string, meshId: string) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}/${meshId}/${duplicate}`, {});

/**
 * Updates an existing mesh
 *
 * @param workspaceId
 * @param meshId
 * @param meshPayload
 *
 */
export const updateMesh = (workspaceId: string, meshId: string, meshPayload: IWorkspaceMesh) =>
  Proxy.http.patch(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}/${meshId}`, meshPayload);

/**
 * Delete multiple meshes
 *
 * @param workspaceId
 * @param meshIds
 */
export const deleteMeshes = (workspaceId: string, meshIds: Array<string>) => {
  return Proxy.http.delete(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}`, { data: meshIds });
};

/**
 * Export a mesh to the Water data platform.
 *
 * @param workspaceId
 * @param meshId
 * @param formatType
 */
export const exportMesh = (workspaceId: string, meshId: string, formatType: EMeshFormats) =>
  Proxy.http.post(
    `${meshAppService}/${workspaces}/${workspaceId}/${meshes}/${meshId}/export?formatType=${formatType}`,
    {},
  );

/**
 * Get field statistics for a tiled mesh.
 *
 * @param workspaceId
 * @param meshId
 */
export const getTiledMeshFieldStatistics = (workspaceId: string, meshId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}/${meshId}/fielddata`);

/**
 * Export meshes to the platform.
 *
 * @param workspaceId
 * @param meshIds
 * @param formatType
 */
export const exportMeshes = (workspaceId: string, meshIds: Array<string>, formatType: EMeshFormats) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}/export?formatType=${formatType}`, meshIds);

/**
 * For a given workspace, gets all meshes that are allowed to be exported to the platform.
 *
 * @param workspaceId
 */
export const getExportableMeshes = (workspaceId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}/exportables`);

/**
 * For a given geometry, gets all meshes that this geometry is currently part of.
 * In other words, gets all latest CreateMeshOperations, that have this geometry as input in a child operation.
 * Notes:
 *  - should not get historical data; if the geometry was in the past used as input as has been removed, those older (historical) meshes will not be returned.
 *  - should return latest meshes no matter their CreateMeshOperations state (so, returned items can be draft, failed, succeeded, etc).
 *
 * @see #22689 For discussion.
 *
 * @param workspaceId
 * @param geometryId
 */
export const getGeometryCurrentMeshIds = (workspaceId: string, geometryId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${meshes}?geometryId=${geometryId}`);
