import { IProject } from 'src/models/IProject';
import { EProjectsActionType } from './ProjectsActionType';

export const getProjectsRecursively = (projectId: string) => ({
  type: EProjectsActionType.GET_PROJECTS_RECURSIVELY,
  data: projectId,
});

export const setProjectsRecursively = (projects: Array<IProject>) => ({
  type: EProjectsActionType.SET_PROJECTS_RECURSIVELY,
  data: projects,
});

export const setLoadingProjectsRecursively = (loading = true) => ({
  type: EProjectsActionType.LOADING_PROJECTS_RECURSIVELY,
  data: loading,
});

export const setLoadingProjectsRecursivelyFailed = (failed = true) => ({
  type: EProjectsActionType.LOADING_PROJECTS_RECURSIVELY_FAILED,
  data: failed,
});
