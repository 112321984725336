/**
 * Defines action types related to workspaces and data loading
 * todo hevo: currently only types for loading have been defined here. Should be extended. Actions for meshes, geoemetries etc should NOR be defined here but in their own enums in the corresponding folders
 */
export enum WorkspaceActionType {
  SOCKET_INIT = 'workspace/socket/INIT',
  SOCKET_SETUP = 'workspace/socket/SETUP',
  SOCKET_TRY_RECONNECT = 'workspace/socket/TRY_RECONNECT',
  SOCKET_SET_TRY_RECONNECT = 'workspace/socket/SET_TRY_RECONNECT',
  SOCKET_SET_ERROR_COUNT = 'workspace/socket/INCREASE_ERROR_COUNT',
  SOCKET_CLOSE = 'workspace/socket/CLOSE',
  SOCKET_RESET = 'workspace/socket/RESET',
  SOCKET_RAW_DATA_LOADED = 'workspace/socket/RAW_DATA_LOADED',
  SOCKET_TILE_DATA_LOADED = 'workspace/socket/SOCKET_TILE_DATA_LOADED',
  SOCKET_ALL_TILE_DATA_LOADED = 'workspace/socket/SOCKET_ALL_TILE_DATA_LOADED',
  SOCKET_RAW_DATA_LOADED_EXCL_DATAARRAYS = 'workspace/socket/RAW_DATA_LOADED_EXCL_DATAARRAYS',
  SOCKET_DATA_ATTRIBUTE_LOADED = 'workspace/socket/DATA_ATTRIBUTE_LOADED',
  SOCKET_SET_DRAWNDATA = 'workspace/socket/SET_DRAWNDATA',
  SOCKET_DATA_DELETED = 'workspace/socket/DATA_DELETED',
  SOCKET_DATA_ITEMS_DELETED = 'workspace/socket/DATA_ITEM_DELETED',
  SOCKET_DATA_ATTRIBUTE_DELETED = 'workspace/socket/DATA_ATTRIBUTE_DELETED',
  SOCKET_GEOMETRIES_DELETED = 'workspace/socket/GEOMETRIES_DELETED',
  SOCKET_VARIABLES_DELETED = 'workspace/socket/VARIABLES_DELETED',
  SOCKET_MESHES_DELETED = 'workspace/socket/MESHES_DELETED',

  WORKSPACE_CONNECTED = 'workspace/CONNECTED',
  STRUCTURE_UPDATED = 'workspace/STRUCTURE_UPDATED',
  OVERVIEW_UPDATED = 'workspace/OVERVIEW_UPDATED',

  GET_RECENTS = 'workspace/GET_RECENTS',
  LOADING_RECENTS = 'workspace/LOADING_RECENTS',
  SET_RECENTS = 'workspace/SET_RECENTS',
  DELETE_RECENT = 'workspace/recent/DELETE',

  ADD_LABELS = 'workspace/ADD_LABELS',

  SET_HOVERING_LAYER_ON = 'workspace/SET_LAYER_HOVERING_ON',
  SET_HOVERING_LAYER_OFF = 'workspace/SET_LAYER_HOVERING_OFF',

  LOAD_WORKSPACE = 'workspace/LOAD',
  WORKSPACE_ADD_PROJ4STRING = 'workspace/ADD_PROJ4STRING',
  WORKSPACE_LOAD_OVERVIEW = 'workspace/LOAD_OVERVIEW',
  WORKSPACE_LOAD_METADATA = 'workspace/LOAD_METADATA',
  WORKSPACE_LOADING = 'workspace/LOADING',
  WORKSPACE_LOAD_SUCCESS = 'workspace/LOAD_SUCCESS',
  UPDATE_WORKSPACE = 'workspace/UPDATE',
  WORKSPACE_UPDATING = 'workspace/UPDATING',
  WORKSPACE_UPDATE_SUCCESS = 'workspace/UPDATING_SUCCESS',

  LOAD_DATA = 'workspace/data/LOAD_DATA',
  LOAD_DATA_COMPLETED = 'workspace/data/LOAD_DATA_COMPLETED',
  LOAD_DATA_FAILED = 'workspace/data/LOAD_DATA_FAILED',

  LOAD_DATA_ATTRIBUTE = 'workspace/data/LOAD_DATA_ATTRIBUTE',
  LOAD_DATA_ATTRIBUTE_COMPLETED = 'workspace/data/LOAD_DATA_ATTRIBUTE_COMPLETED',
  LOAD_DATA_ATTRIBUTE_FAILED = 'workspace/data/LOAD_DATA_ATTRIBUTE_FAILED',

  EXPORT_STARTED_OR_COMPLETED = 'EXPORT_STARTED_OR_COMPLETED',

  CREATE_SNAPSHOT = 'workspace/CREATE_SNAPSHOT',

  SET_BASEMAP_TO_DEFAULT = 'maptools/SET_BASEMAP_TO_DEFAULT',
  ADAPT_COLORS_TO_BASEMAP = 'workspace/data/color/ADAPT',

  HIGHLIGHT_ON_MAP = 'workspace/element/HIGHLIGHTONMAP',
  HIGHLIGHT_SAVE = 'workspace/element/HIGHLIGHTSAVE',

  OPENDATA_PROVIDERS_LOAD = 'workspace/opendataproviders/LOAD',
  OPENDATA_PROVIDERS_LOADING = 'workspace/opendataproviders/LOADING',
  OPENDATA_PROVIDERS_SET = 'workspace/opendataproviders/SET',
  OPENDATA_PROVIDERS_EXTRACT = 'workspace/opendataproviders/EXTRACT',
  OPENDATA_EXTRACTING = 'workspace/opendataproviders/EXTRACTING',

  SELECTIONS_HIDE_ALL = 'workspace/selections/SELECTIONS_HIDE_ALL',
  SELECTIONS_SHOW_ALL = 'workspace/selections/SELECTIONS_SHOW_ALL',
}
