export enum EWorkspaceVariableActionType {
  LOAD = 'workspace/variables/LOAD',
  UPDATE_USER_HIDDEN = 'workspace/variables/UPDATE_USER_HIDDEN',
  SHOW_ITEM_ONLY = 'workspace/variables/SHOW_ITEM_ONLY',
  SHOW_ALL_BUT_USER_HIDDEN = 'workspace/variables/SHOW_ALL_BUT_USER_HIDDEN',
  HIDE_ALL = 'workspace/variables/HIDE_ALL',
  SHOW_ALL = 'workspace/variables/SHOW_ALL',
  SHOW_ITEM = 'workspace/variables/SHOW_ITEM',
  HIDE_ITEM = 'workspace/variables/HIDE_ITEM',
  HIDE_ITEMS = 'workspace/variables/HIDE_ITEMS',
  SELECT = 'workspace/variables/SELECT',
  UPDATE_DATA = 'workspace/variables/UPDATE_DATA',
  UPDATING_DATA = 'workspace/variables/UPDATING_DATA',
  UPDATE_DATA_FAILED = 'workspace/variables/UPDATE_DATA_FAILED',
  CREATE = 'workspace/variables/CREATE',
  CREATING_DATA = 'workspace/variables/CREATING_DATA',
  CREATE_FAILED = 'workspace/variables/CREATE_FAILED',
}
