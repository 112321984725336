import { EOperationResultTypes } from './IOperations';

export interface IContextualOperationDescriptions {
  [itemId: string]: {
    [operationKey: string]: IOperationDescription;
  };
}

export interface IOperationDescription {
  operationType: string;
  resultType: EOperationResultTypes;
  parameterDescriptions: { [parameterKey: string]: IParameterDescription };
  childOperationDescriptions?: {
    [operationKey: string]: IOperationDescription;
  };
  allowedInputIds?: Array<string>;
  supportsQuery?: boolean;
}

/**
 * These key value pairs of operation descriptions
 * consist of properties with names from `EOperationKey` or other strings.
 * But TypeScript doesn't allow for ie:
 * `[keyof EOperationKey | string]: IOperationDescription;`
 */
export interface IOperationDescriptionsKVP {
  [operationKey: string]: IOperationDescription;
}

/**
 * Some possible property names in `IOperationDescriptionsKVP` above.
 */
export enum EOperationKey {
  BUFFER = 'bufferOperationDefinition',
  SIMPLIFY = 'simplifyOperationDefinition',
  CLIP = 'clipOperationDefinition',
  DENSIFY = 'densifyOperationDefinition',
  EXTRACT_GEOMETRIES = 'extractGeometriesOperationDefinition',
  POLYGONIZE = 'polygonizeOperationDefinition',
  SMOOTH = 'smoothOperationDefinition',
  SPLIT = 'splitOperationDefinition',
  UNION = 'unionOperationDefinition',
}

export enum EItemType {
  GEOMETRY = 'Geometry',
  MESH = 'Mesh',
  VARIABLE = 'Variable',
}

/**
 * Parameter descriptions for operations
 */
export interface IParameterDescription {
  type: string;
  name: string;
  valueType: string;
  defaultValue: number | boolean | string | object;
  unit?: string;
  minValue?: number | string; //percentage parameters have string value
  maxValue?: number | string; //percentage parameters have string value
  required?: boolean;
  values?: IParameterValues;
  allowedValues?: Array<string>;
  nameKey?: EParameterNameKey | string;
  order?: number; // UI-only property so far. Might be clever to add this to the API later.
  conditionalParameterNameKey?: EParameterNameKey | string;
  conditonalParameterValue?: number | boolean | string | object;
  conditionalParameterNameKey2?: EParameterNameKey | string;
  conditonalParameterValue2?: number | boolean | string | object;
  itemType?: EItemType | string;
}

export enum EParameterNameKey {
  BUFFER_DISTANCE = 'BufferDistance',
  CLIP_ITEM_ID = 'ClipItemId',
  CONTROL_POINT_COUNT = 'ControlPointCount',
  DENSITY_EDGE_LENGTH = 'DensifyEdgeLength',
  KEEP_POINTS = 'KeepPoints',
  MODE = 'Mode',
  RESOLUTION_METHOD = 'ResolutionMethod',
  SIMPLIFY_TOLERANCE = 'SimplifyTolerance',
  SPLIT_ITEM_ID = 'SplitterItemId',
}

export interface IParameterValues {
  [value: string]: string;
}

export interface IParameterConditionDescription {
  /**
   * @property Array of other parameters required by the parameter
   */
  requires: Array<string>;
}

export enum EParameterValueTypes {
  INT32 = 'Int32', // todo hevo Unsure if this is used in API_V2
  INTEGER = 'Integer',
  DOUBLE = 'Double',
  STRING = 'String',
  BOOLEAN = 'Boolean',
  GUID = 'Guid',
  ENUM = 'Enum',
}

export const PARAMETER_VALUE_TYPES = {
  INT32: EParameterValueTypes.INT32,
  INTEGER: EParameterValueTypes.INTEGER,
  DOUBLE: EParameterValueTypes.DOUBLE,
  STRING: EParameterValueTypes.STRING,
  BOOLEAN: EParameterValueTypes.BOOLEAN,
  GUID: EParameterValueTypes.GUID,
  ENUM: EParameterValueTypes.ENUM,
};

export const DENSITY_FUNCTION_PARAMETERS = {
  APPLY_DENSITY_PARAMETERS: 'applyDensityParameters',
  NUMBER_OF_POINTS: 'numberOfPoints',
  VARIABLE_ID: 'variableId',
  APPLY_GRADIENT: 'applyGradient',
  DENSITY_BUFFER_SIZE: 'densityBufferSize',
  INVERT_DENSITY: 'invertDensity',
};

export enum EParameterDescriptionTypes {
  STRING = 'StringParameterDescription',
  BOOLEAN = 'BooleanParameterDescription',
  INTEGER = 'IntegerParameterDescription',
  DOUBLE = 'DoubleParameterDescription',
  GUID = 'GuidParameterDescription',
  FEATURE_COLLECTION = 'FeatureCollectionParameterDescription',
  PROPERTIES = 'PropertiesParameterDescription',
  ENUM = 'EnumParameterDescription',
}

export const PARAMETER_DESCRIPTION_TYPES = {
  STRING: EParameterDescriptionTypes.STRING,
  BOOLEAN: EParameterDescriptionTypes.BOOLEAN,
  INTEGER: EParameterDescriptionTypes.INTEGER,
  DOUBLE: EParameterDescriptionTypes.DOUBLE,
  GUID: EParameterDescriptionTypes.GUID,
  FEATURE_COLLECTION: EParameterDescriptionTypes.FEATURE_COLLECTION,
  PROPERTIES: EParameterDescriptionTypes.PROPERTIES,
  ENUM: EParameterDescriptionTypes.ENUM,
};
