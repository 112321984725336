/**
 * Exposes methods to transform or combine configuration requests.
 *
 * @module ConfigurationManager
 * @version 2.0.0
 * @requires ConfigurationProxy
 */
import * as ConfigurationProxy from 'proxies/ConfigurationProxy';
import { HttpErrorHandler } from './http-utils';

export interface IHubConnectionConfiguration {
  concurrentStreams?: number;
  keepAliveInterval?: number;
  clientTimeoutInterval?: number;
  handshakeTimeout?: number;
}
export interface IMmgConfiguration {
  oAuth2: {
    clientId: string;
    tenantId: string;
    resource: string;
    sessionId?: string;
  };
  applicationInsights: {
    instrumentationKey: string;
  };
  versionInfo: {
    api: string;
    algorithms: string;
    vtk: string;
  };
  supportUrls: Array<{
    key: string;
    url: string;
  }>;
  adminCenterUri?: string;
  cloudHomeUri?: string;
  cloudHomeBackendUri?: string;
  environment: string;
  connection?: IHubConnectionConfiguration;
  platformApiUrl?: string;
}

export const BACKEND_PROD_ENVIRONMENT_KEY = 'prod';

/**
 * Transforms a request that gets configuration.
 */
const getConfiguration = (): Promise<IMmgConfiguration> => {
  return ConfigurationProxy.getConfiguration()
    .then((res) => {
      const config = res.data;
      return config;
    })
    .catch((error) => HttpErrorHandler('Failed to get configuration.', error));
};

const self = {
  getConfiguration,
};

export default self;
