import { call, put, race, select, take } from 'redux-saga/effects';
import { t } from 'src/translations/i18n';
import { IWorkspaceSocketManager } from 'src/models/ISocketManager';
import { EDataArrayTypes } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConstants';
import { INotificationAttributeUpdatedMsg } from 'src/models/IWorkspaceNotifications';
import { isEqual } from 'lodash-es';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { IDrawnDataItem } from 'src/models/IWorkspaceData';
import { getWorkspaceDataByItemId } from 'src/store/selectors/WorkspaceDrawnDataSelectors';

/**
 * handle a request for streaming data attribute
 * @param payload
 * @param socketManager
 */
export function* handleStreamDataAttributeRequest(payload, socketManager: IWorkspaceSocketManager) {
  const { identity, attributeNotification } = payload;
  const {
    itemId,
    dataId,
    propertyName,
    isCellData,
    updated,
  } = attributeNotification as INotificationAttributeUpdatedMsg;

  const shouldLoad = yield shouldLoadAttribute(itemId, propertyName, updated);
  if (!shouldLoad) {
    return;
  }

  yield call(streamAttribute, socketManager, itemId, dataId, propertyName, isCellData, updated);

  // wait for stream to complete or fail before moving on to the next
  const { failed } = yield race({
    completed: take(
      (act) => act.type === WorkspaceActionType.LOAD_DATA_ATTRIBUTE_COMPLETED && isEqual(identity, payload.identity),
    ),
    failed: take(
      (act) => act.type === WorkspaceActionType.LOAD_DATA_ATTRIBUTE_FAILED && isEqual(identity, payload.identity),
    ),
  });

  if (failed) {
    // todo hevo better error handling should be added. Eg including the name of failed item, or setting the item in failed state and stop spinner
    put({
      type: 'toast/ADD/ERROR',
      toast: { text: t('WORKSPACE_ATTRIBUTE_FAILED_LOADING') },
    });
  }
}

/**
 * Invoke streaming attribute data for a specific item and attributeName
 * @param socketManager
 * @param itemId
 * @param dataId
 * @param attributeName
 * @param isCellData
 * @param updated
 */
function* streamAttribute(
  socketManager: IWorkspaceSocketManager,
  itemId: string,
  dataId: string,
  attributeName: string,
  isCellData: boolean,
  updated: string,
) {
  try {
    if (socketManager) {
      const { streamAttributeData } = socketManager;
      if (streamAttributeData) {
        yield call(
          streamAttributeData,
          itemId,
          dataId,
          attributeName,
          isCellData ? EDataArrayTypes.CELLDATA : EDataArrayTypes.POINTDATA,
          updated,
        );
      }
    }
  } catch (error) {
    // todo hevo how to handle??
    console.error('streaming attribute failed', error);
  }
}

/**
 * Examines if more recent data including the attribute is already loaded.
 * @param itemId
 * @param attributeName
 * @param updated
 */
function* shouldLoadAttribute(itemId: string, attributeName: string, updated: string) {
  const existingDataItem: IDrawnDataItem = yield select(getWorkspaceDataByItemId, {
    itemId,
  });

  // If we don't have the data or don't know when data was updated, just load.
  if (!existingDataItem || !existingDataItem.updated) {
    return true;
  }

  const { attributes } = existingDataItem;
  const hasAttribute =
    (attributes || []).findIndex(({ name }) => {
      return name === attributeName;
    }) !== -1;

  // if attribute is not aleady loaded, load
  if (!hasAttribute) {
    return true;
  }

  const newDate = new Date(updated);
  const existingDate = new Date(existingDataItem.updated);

  // If new data are more recent, load
  if (newDate > existingDate) {
    return true;
  }

  // no reason to laod
  return false;
}
