import { IGetProject } from '@mike/mike-shared-frontend/mike-project-explorer/models/IGetProject';
import { IGetDataset } from 'src/models/IGetDataset';
import { IGetProjectPath } from 'src/models/IGetProjectPath';
import { EProjectContentActionType } from './ProjectContentActionType';
import { IItemToReplace } from '../reducers/ProjectContentReducer';

export const getProjectContent = (project: IGetProject | IGetProjectPath) => ({
  type: EProjectContentActionType.GET_PROJECTCONTENT,
  data: project,
});

export const getProjectAndParent = (projectId: string) => ({
  type: EProjectContentActionType.GET_PROJECTANDPARENT,
  data: projectId,
});

export const setLoadingProjectContent = (loading = true) => ({
  type: EProjectContentActionType.LOADING_PROJECTCONTENT,
  data: loading,
});

export const setLoadingProjectAndParent = (loading = true) => ({
  type: EProjectContentActionType.LOADING_PROJECTANDPARENT,
  data: loading,
});

export const setProjectAndParent = (newProject: IGetProject | null, newProjectParent: IGetProject | null) => ({
  type: EProjectContentActionType.SET_PROJECTANDPARENT,
  data: { project: newProject, projectParent: newProjectParent },
});

export const setProjectContent = (
  newProjectContent: Array<IGetDataset | IGetProject>,
  newProject: IGetProject | null,
  newParent: IGetProject | null,
  newPath: IGetProjectPath[],
) => ({
  type: EProjectContentActionType.SET_PROJECTCONTENT,
  data: { projectContent: newProjectContent, project: newProject, parent: newParent, path: newPath },
});

export const setProjectContentDialogOpen = (isOpen: boolean, isSelectable: boolean, itemToReplace = null) => ({
  type: EProjectContentActionType.OPEN_PROJECTCONTENT_DIALOG,
  data: { open: isOpen, selectable: isSelectable, itemToReplace },
});

export const replaceProjectContent = (datasetId: string, workspaceId: string, itemToReplace: IItemToReplace) => ({
  type: EProjectContentActionType.REPLACE_WITH_PROJECTCONTENT,
  data: { datasetId, workspaceId, itemToReplace },
});

export const setProjectContentPage = (page: number) => ({
  type: EProjectContentActionType.SET_PROJECTCONTENT_PAGE,
  data: page,
});

export const setProjectContentRowsPerPage = (rowsPerPage: number) => ({
  type: EProjectContentActionType.SET_PROJECTCONTENT_ROWSPERPAGE,
  data: rowsPerPage,
});

export const setProjectContentOrder = (order: 'asc' | 'desc') => ({
  type: EProjectContentActionType.SET_PROJECTCONTENT_ORDER,
  data: order,
});

export const setProjectContentOrderBy = (orderBy: string | ((item: any) => string | number)) => ({
  type: EProjectContentActionType.SET_PROJECTCONTENT_ORDERBY,
  data: orderBy,
});
