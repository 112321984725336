export enum EEXPORT_STATUS {
  PROCESSING = 'Processing',
  FAILED = 'Failed',
  SCHEDULED = 'Scheduled',
  SUCCEEDED = 'Succeeded',
}

export enum EEXPORT_TYPE {
  MESH = 'Mesh',
  GEOMETRY = 'Geometry',
  VARIABLE = 'Variable',
}

export interface IExportDataset {
  inputId: string;
  importResults: Array<IImportResults>;
}

export interface IImportResults {
  datasetId: string;
  projectId: string;
  name: string;
}

export interface IExports {
  id: string;
  workspaceId: string;
  status: EEXPORT_STATUS;
  type: EEXPORT_TYPE;
  inputIds: Array<string>;
  datasets: Array<IExportDataset>;
  message: string;
}
