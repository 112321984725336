import React from 'react';
import { IGlobalState, store } from 'store/store';
import { IMmgConfiguration } from 'managers/ConfigurationManager';
import { MmgConnectedBreadcrumbs } from 'src/shared/breadcrumbs/breadcrumbs';

import MikeTopbar from '@mike/mike-shared-frontend/mike-topbar/mike-topbar';
import MikeMyappsButton from '@mike/mike-shared-frontend/mike-myapps-button/mike-myapps-button';
import MikeAppIcon from '@mike/mike-shared-frontend/mike-icon/MikeAppIcon';
import { MmgConnectedSupport } from 'src/app/support/support';
import { t } from 'src/translations/i18n';
import MikeLogin from '../login';
import MikeNotificationContainer from '@mike/mike-shared-frontend/mike-notification-list';
import ProgressSpinner from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { IApplications } from '@mike/mike-shared-frontend/mike-subscribed-apps/model/IApplications';
import MikeSubscribedApps from '@mike/mike-shared-frontend/mike-subscribed-apps';
import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { INotification } from '@mike/mike-shared-frontend/mike-notification-list/INotification';
import { hideBlueDotOnTopbarAlert } from '../../store/actions/notification';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IUserState } from 'src/store/reducers/UserReducer';
import { IUser } from 'src/models/IUser';

export interface AuditCompareRouteParams {
  id: string;
  workspaceId: string;
}

type TopbarProps = {
  readonly configuration: IMmgConfiguration;
  progressItems: IProgressItem[];
  notifications: INotification[];
  hideBlueDot: boolean;
  applications: IApplications;
  loadingApplications: boolean;
};

/**
 * @name MmgTopbar
 * @summary Topbar container that implements <MikeTopbar />.
 * @param props
 * @description
 * This component handles login, breadcrumbs and the app selector.
 * It also handles bad logins, redirecting to the error page.
 */
export const MmgTopbar = (props: TopbarProps) => {
  const match = useRouteMatch<AuditCompareRouteParams>({
    path: '/project/:id',
    strict: true,
    sensitive: true,
  });

  const projectId = match && match.params.id;

  const user: IUser = useSelector((state: IGlobalState) => (state.UserReducer as IUserState).user);

  const onHandleNotificationClickAway = () => {
    store.dispatch(hideBlueDotOnTopbarAlert(true));
  };

  const getSubscribedApps = () => {
    return (
      <MikeSubscribedApps
        currentAppName={t('APP_NAME')}
        applications={props.applications}
        projectId={projectId}
        loading={props.loadingApplications}
        tenantId={user ? user.tenantId : ''}
      />
    );
  };

  const getHomeButton = () => {
    const { configuration } = props;

    return (
      configuration &&
      configuration.cloudHomeUri && (
        <MikeMyappsButton
          loggedIn={true}
          hrefLink={user ? configuration.cloudHomeUri + '?tenantId=' + user.tenantId : configuration.cloudHomeUri}
        />
      )
    );
  };

  const getSupportButton = () => {
    return <MmgConnectedSupport />;
  };

  const getNotificationsDropdown = () => {
    const { notifications, hideBlueDot } = props;

    return (
      <MikeNotificationContainer
        showDot={!hideBlueDot}
        notifications={notifications}
        tooltipPosition={{ top: 0, left: -10 }}
        clickAwayCallback={onHandleNotificationClickAway}
      />
    );
  };

  const getProgressActions = () => {
    const { progressItems } = props;
    return <ProgressSpinner progressItems={progressItems} />;
  };

  const getBreadcrumb = () => {
    return <MmgConnectedBreadcrumbs />;
  };

  const getAppIcon = () => {
    return <MikeAppIcon name="meshBuilder" />;
  };

  return (
    <MikeTopbar
      actionsArea={getProgressActions()}
      alertArea={getNotificationsDropdown()}
      appIcon={getAppIcon()}
      appTitle={t('APP_NAME')}
      breadcrump={getBreadcrumb()}
      homeButton={getHomeButton()}
      supportButton={getSupportButton()}
      loginButton={<MikeLogin />}
      //loginButton={<MmgConnectedLoginWrapper onLoggedInChanged={onLoggedInChanged} onLoginError={onLoginError} />}
      position="relative"
      appsDropdown={projectId ? getSubscribedApps() : null}
    />
  );
};
