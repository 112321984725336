import React, { useCallback, useEffect, useState } from 'react';
import { css } from 'emotion';
import { IProject } from 'models/IProject';
import { t } from 'src/translations/i18n';
import { IGlobalState, store } from 'store/store';
import { orderBy } from 'lodash-es';
import Skeleton from '@material-ui/lab/Skeleton';
import { MmgMetas } from 'src/shared/metas/metas';
import WorkspaceUtils from 'src/workspaces/workspace-utils';
import { MmgConnectedWorkspaceCreate } from 'src/workspaces/create/workspace-create';
import { MmgCardPlaceholders } from '../card/workspace-card-placeholders';
import { MmgWorkspaceCard } from '../card/workspace-card';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { useRouteMatch } from 'react-router-dom';
import { AuditCompareRouteParams } from 'src/app/topbar/topbar';
import { useIsMounted } from 'src/shared/hooks/hooks';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { theme } from 'src/shared/styles/theme';
import { TopPadding } from 'src/shared/styles/styleConsts';
import MmgSubprojectsTable from '../recent/subprojects-table';
import { Grid } from '@material-ui/core';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import MmgFilterInput from 'src/shared/filter-input/filter-input';

const { getProjectName } = WorkspaceUtils;

const WorkspacePageGridStyle = css`
  background-color: ${MIKE_COLORS.XLIGHTGREY};
  margin: ${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(3)}px;
`;

const WorkspaceListGridItemStyle = css`
  padding: ${theme.spacing()}px ${theme.spacing(6)}px 0 ${theme.spacing()}px;
  overflow: auto;
`;

const HeaderLineStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: bottom;
`;

const WorkspaceStyle = css`
  padding: ${TopPadding} ${theme.spacing(3)}px;

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const WorkspacesStyle = css`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

/**
 * @name MmgWorkspaceList
 * @summary Lists workspaces in a project.
 */
const MmgConnectedWorkspaceList = () => {
  const isMounted = useIsMounted();
  const match = useRouteMatch<AuditCompareRouteParams>({
    path: '/project/:id/workspace-list',
    strict: true,
    sensitive: true,
  });

  const [filter, setFilter] = useState('');

  const projectId = match && match.params.id;
  const project: IProject = useSelector((state: IGlobalState) => state.ProjectReducer.project);
  const { workspaceListLoading, workspaces } = useSelector((state: IGlobalState) => state.WorkspaceReducer);
  const { projectSelectedForDelete } = useSelector((state: IGlobalState) => state.ProjectReducer);

  const capabilities = project ? project.capabilities : null;

  const { subprojects } = useSelector((state: IGlobalState) => state.ProjectReducer);

  useEffect(
    () => {
      if (projectId) {
        store.dispatch({ type: WorkspaceActionType.WORKSPACE_LOAD_OVERVIEW, data: projectId });
      }
    },
    [projectId],
  );

  const handleClearFilter = useCallback(() => {
    setFilter('');
    store.dispatch({
      type: 'app/filter/SET',
      filter: '',
    });
  }, []);

  const handleSetFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
    store.dispatch({
      type: 'app/filter/SET',
      filter: e.target.value,
    });
  }, []);

  const renderMetas = (currentProject: IProject) => {
    if (currentProject) {
      const metaTitle = `${t('WORKSPACES_PAGE_TITLE', 1, {
        projectName: getProjectName(currentProject),
      })}`;

      return <MmgMetas metaTitle={metaTitle} />;
    }
    return '';
  };

  const renderNewWorkspace = (workspaceIsListLoading: boolean) => {
    if (workspaceIsListLoading) {
      return <CircularProgress size={14} />;
    }
    return capabilities && capabilities.canCreateContent && <MmgConnectedWorkspaceCreate />;
  };

  const projectName = getProjectName(project);

  if (!project || workspaceListLoading) {
    return (
      <main className={WorkspaceStyle}>
        <Skeleton />
        <Skeleton width="60%" />

        {workspaceListLoading && <MmgCardPlaceholders size={6} />}
      </main>
    );
  }

  return (
    <>
      {renderMetas(project)}
      <Grid container className={WorkspacePageGridStyle}>
        <Grid item xs={12} className={WorkspaceListGridItemStyle}>
          <div className={HeaderLineStyle}>
            <h2>
              {t('WORKSPACES_PAGE_HEADER', 1, {
                projectName,
              })}
            </h2>
            {renderNewWorkspace(workspaceListLoading)}
          </div>
        </Grid>
        {/* <div>{renderNewWorkspace(workspaceListLoading)}</div> */}
        {workspaceListLoading && <MmgCardPlaceholders size={6} />}
        <Grid item xs={12} container wrap="nowrap" className={WorkspaceListGridItemStyle}>
          {isMounted &&
            !workspaceListLoading &&
            workspaces &&
            orderBy(workspaces, ['updated', 'created'], ['desc', 'desc']).map((workspace, id) => (
              <Grid item key={id}>
                <MmgWorkspaceCard
                  key={workspace.id}
                  workspace={workspace}
                  project={project}
                  projectSelectedForDelete={projectSelectedForDelete}
                />
              </Grid>
            ))}
        </Grid>
        {!workspaceListLoading &&
          workspaces &&
          workspaces.length === 0 && (
            <Grid item xs={12} className={WorkspaceListGridItemStyle}>
              <p>{t('NO_WORKSPACES')}</p>
            </Grid>
          )}
        {subprojects.length > 0 && (
          <Grid item xs={6} className={WorkspaceListGridItemStyle}>
            <h2>
              {t('WORKSPACES_PAGE_SUBHEADER', 1, {
                projectName,
              })}
            </h2>
          </Grid>
        )}
        {subprojects.length > 0 && (
          <Grid item xs={12} className={WorkspaceListGridItemStyle}>
            <MmgFilterInput
              value={filter}
              onChangeValue={handleSetFilter}
              onResetValue={handleClearFilter}
              data-testid="mmg-subprojects-filter"
            />
          </Grid>
        )}
        <Grid item xs={12} className={WorkspaceListGridItemStyle}>
          {subprojects.length > 0 && (
            <MmgSubprojectsTable subprojectList={subprojects} subprojectListLoading={false} filter={filter} />
          )}
        </Grid>
        <div className={WorkspacesStyle} />
      </Grid>
    </>
  );
};

export default MmgConnectedWorkspaceList;
