import React, { useState } from 'react';
import { css } from 'emotion';
import {
  LayerNameStyle,
  LayerContentStyle,
  LayerIconStyle,
  LayerButtonStyle,
  LAYER_HEIGHT,
} from '@mike/mike-shared-frontend/mike-layer/layer-styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { theme } from '../styles/theme';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';

const GroupStyle = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(${LAYER_HEIGHT} - 8px);
  background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
`;

const GroupNameStyle = css`
  padding-left: ${theme.spacing(2)}px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
`;

const GroupExpandIconStyle = css`
  margin-left: auto;

  :focus {
    outline: 0;
  }
`;

type GroupProps = {
  groupName: string | React.ReactNode;
  groupTools?: React.ReactNode;
  canBeHidden?: boolean;
  children?: React.ReactNode;
  /**
   * A custom callback for when the group is opened / collapsed.
   * NB: this should be used together with `isOpen`; internal state won't be used if a custom callback is passed.
   */
  toggleOpenCallback?: () => void;
  /**
   * Passing in `isOpen` makes the MmgGroup a 'controlled' component; internal `open` state is overriden by the `isOpen` prop. It is generally used together with `toggleOpenCallback`.
   */
  isOpen?: boolean;
};

/**
 * @name MmgGroup
 * @summary A generic container that can be collapsed or expanded.
 * It typically holds children that are optionally visible (if expanded).
 *
 * @param props
 */
export function MmgGroup(props: GroupProps) {
  const [open, setOpen] = useState(true);
  const { groupName, groupTools, canBeHidden = true, toggleOpenCallback, isOpen } = props;

  const toggleOpen = () => {
    if (canBeHidden) {
      setOpen(!open);
    }
  };

  const isNotCollapsed = isOpen !== undefined ? isOpen : open;
  const onToggleOpen = toggleOpenCallback ? toggleOpenCallback : toggleOpen;

  return (
    <>
      <div className={[LayerButtonStyle, GroupStyle].join(' ')}>
        <button style={{ display: 'contents' }} onClick={onToggleOpen}>
          <p className={[LayerNameStyle, GroupNameStyle].join(' ')}>{groupName}</p>
        </button>

        {groupTools && <span>{groupTools}</span>}

        {canBeHidden && (
          <button className={[LayerIconStyle, LayerButtonStyle, GroupExpandIconStyle].join(' ')} onClick={onToggleOpen}>
            {isNotCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </button>
        )}
      </div>

      {isNotCollapsed ? <section className={LayerContentStyle}>{props.children}</section> : null}
    </>
  );
}
