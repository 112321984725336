import React from 'react';
import { css } from 'emotion';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { MmgPanelTitle } from './panel-title';
import {
  PanelHeaderStyle,
  PanelHeaderWithBackArrowStyle,
  PanelHeaderTitleStyle,
  PanelHeaderBackStyle,
  PanelHeaderBackIconStyle,
  PanelHeaderNoBorderStyle,
} from './panel-styles';
import { theme } from '../styles/theme';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';

const PanelHeaderActionsStyle = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
`;

const PanelHeaderChildrenStyle = css`
  display: flex;
  width: 100%;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: ${theme.spacing(2)}px;
`;

const iconStyle = css`
  path {
    fill: ${MIKE_COLORS.DARKGREY_DEFAULT};
  }
`;

type PanelHeaderProps = {
  panelTitle?: string;
  panelDescription?: string;
  panelHeaderActions?: React.ReactNode;
  noBorder?: boolean;
  children?: React.ReactNode;
  onPanelExit?: Function;
};

const defaultProps = {};

/**
 * @name MmgPanelHeader
 * @summary Panels can have an optional header, usually containg a title, an icon and a backbutton
 *
 * @param props
 */
export function MmgPanelHeader(props: PanelHeaderProps) {
  const { panelTitle, panelHeaderActions, panelDescription, noBorder, onPanelExit } = props;

  const handlePanelExit = (event) => {
    event.preventDefault();
    if (onPanelExit) {
      onPanelExit();
    }
  };

  const hasBackArrow = Boolean(onPanelExit);

  return (
    <header
      className={[
        PanelHeaderStyle,
        noBorder ? PanelHeaderNoBorderStyle : '',
        hasBackArrow ? PanelHeaderWithBackArrowStyle : '',
      ].join(' ')}
    >
      <div className={PanelHeaderTitleStyle}>
        {hasBackArrow && (
          <button className={PanelHeaderBackStyle} onClick={handlePanelExit}>
            <span className={PanelHeaderBackIconStyle}>
              <ArrowBackIcon className={iconStyle} />
            </span>
          </button>
        )}

        {panelTitle && <MmgPanelTitle panelTitle={panelTitle} panelDescription={panelDescription} />}

        {panelHeaderActions && <span className={PanelHeaderActionsStyle}>{panelHeaderActions}</span>}
      </div>

      {props.children && <section className={PanelHeaderChildrenStyle}>{props.children}</section>}
    </header>
  );
}

MmgPanelHeader.defaultProps = defaultProps;
