import React from 'react';
import { Grid } from '@material-ui/core';
import { t } from 'src/translations/i18n';
import { css } from 'emotion';
import MmgConnectedCreateFolder, { ButtonStyle } from 'src/workspaces/create/create-folder';
import { MikeButton } from '@mike/mike-shared-frontend';

type WorkspaceCreateButtonsProps = {
  canCreateWorkspace: boolean;
  onCreateInExistingProject: () => void;
  canDeleteWorkspace?: boolean;
  handleDialogClose: () => void;
  handleDialogOpen: () => void;
  projectSelectedForDelete?: Array<string>;
};

const WorkspaceCreateButtonsCss = css`
  padding: 5px;
`;

export const MmgWorkspaceCreateButtons = (props: WorkspaceCreateButtonsProps) => {
  const { canCreateWorkspace = false, onCreateInExistingProject, handleDialogOpen, projectSelectedForDelete } = props;

  const onDeleteClick = () => {
    handleDialogOpen();
  };

  return (
    <>
      {canCreateWorkspace && (
        <>
          <div className={WorkspaceCreateButtonsCss}>
            <MmgConnectedCreateFolder />
          </div>
          <div className={WorkspaceCreateButtonsCss}>
            <MikeButton className={ButtonStyle} onClick={onCreateInExistingProject} disabled={!canCreateWorkspace}>
              {t('WORKSPACE_CREATE_BUTTON')}
            </MikeButton>
          </div>
          <div className={WorkspaceCreateButtonsCss}>
            <MikeButton
              className={ButtonStyle}
              onClick={onDeleteClick}
              disabled={projectSelectedForDelete && projectSelectedForDelete.length === 0 ? true : false}
            >
              {t('WORKSPACE_DELETE_BUTTON')}
            </MikeButton>
          </div>
        </>
      )}
    </>
  );
};
