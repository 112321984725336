import ConfigurationManager from 'managers/ConfigurationManager';
import settings from 'config/shared-env';

/**
 * Injects application insights in the front-end app.
 */
const appInsights = require('applicationinsights-js').AppInsights;
const parameters = {
  disableAjaxTracking: true,
  disableExceptionTracking: false,
  verboseLogging: true,
};

const applicationInsightsEnabled = process.env.NODE_ENV === settings.ENVIORNMENTS.PRODUCTION;

if (applicationInsightsEnabled) {
  /**
   * Get configuration async and setup app insights.
   * Logging can happen before this (seems like the logged events get stored and set when setup is done).
   */
  ConfigurationManager.getConfiguration()
    .then((config) => {
      if (config && config.applicationInsights) {
        const { instrumentationKey } = config.applicationInsights;
        return appInsights.downloadAndSetup({
          instrumentationKey,
          ...parameters,
        });
      }

      throw new Error('No instrumentation key');
    })
    .catch((error) => {
      console.error('Failed to setup application insights', error);
    });
}

/**
 * Gets current application insights context.
 */
export function getContext() {
  return appInsights.context || {};
}

/**
 * Tracks a page view.
 *
 * @param name Page name
 * @param url Page url
 */
export function trackPageView(name: string, url: string) {
  appInsights.trackPageView(
    name /* (optional) page name */,
    url /* (optional) page url if available */,
    null /* (optional) dimension dictionary */,
    null /* (optional) metric dictionary */,
    null /* page view duration in milliseconds */,
  );
}

/**
 * Sets the authenticated user context. This makes it easier to track errors to a user.
 * @see https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
 *
 * @param userId
 */
export function setAuthenticatedUserContext(userId: string) {
  appInsights.setAuthenticatedUserContext(userId, null, true); // todo hevo: would it make sense to log accountId as tenantId/clientId instead of null ?
}

/**
 * Re-export appInsights methods to be used by other modules.
 */
export const trackException = applicationInsightsEnabled ? appInsights.trackException : () => null;
export const trackEvent = applicationInsightsEnabled ? appInsights.trackEvent : () => null;
export const trackTrace = applicationInsightsEnabled ? appInsights.trackTrace : () => null;
