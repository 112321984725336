import { takeLatest, put, call } from 'redux-saga/effects';
import { getExportableVariables } from 'src/managers/WorkspaceVariablesManager';

/**
 * Defines sagas for loading which items are exportable
 */
export default function* watchExportableVariables() {
  yield takeLatest('workspace/export/LOAD_EXPORTABLE_VARIABLES', loadExportableVariables);
}
/**
 * Generator function for loading exportable variables
 * @param action
 */
function* loadExportableVariables(action) {
  yield put({ type: 'workspace/export/EXPORTABLE_VARIABLES_LOADING' });

  try {
    const exportableVariableIds = yield call(getExportableVariables, action.workspaceId);

    yield put({
      type: 'workspace/export/LOAD_EXPORTABLE_VARIABLES_SUCCESS',
      exportableVariableIds,
    });
  } catch (error) {
    yield put({ type: 'workspace/export/LOAD_EXPORTABLE_VARIABLES_FAILED' });
  }
}
