/**
 * Exposes project REST endpoints to managers.
 * Note: the mesh Front-end API proxies these requests to WDP (metadata service).
 *
 * @module ProjectProxy
 * @version 2.0.0
 * @requires Proxy
 */
import { endpoints, paths } from 'proxies/config';
import Proxy from './Proxy';
import { IProject } from 'models/IProject';

const { project: projectPath, subprojects: subprojectsPath } = paths;
const { meshAppService } = endpoints;

/**
 * Get a list of all projects.
 */
export const getProjectList = () => Proxy.http.get(`${meshAppService}/${projectPath}`);

/**
 * Get a list of all subprojects.
 *
 * @param projectId
 */
export const getSubprojectsList = (projectId: string) =>
  Proxy.http.get(`${meshAppService}/projects/${projectId}/subprojects`);

/**
 * Get a recursive list of all subprojects.
 *
 * @param projectId
 */
export const getRecursiveSubprojectsList = (projectId: string) =>
  Proxy.http.get(`${meshAppService}/projects/${projectId}/recursive`);

/**
 * Get a project.
 *
 * @param projectId
 */
export const getProject = (projectId: string) => Proxy.http.get(`${meshAppService}/${projectPath}/${projectId}`);

/**
 * Create a project.
 *
 * @param project
 */
export const createProject = (project: IProject) => {
  return Proxy.http.post(`${meshAppService}/${projectPath}`, project);
};

/**
 * get a project full path.
 *
 * @param projectId
 */
export const getProjectPath = (projectId: string) => {
  return Proxy.http.get(`${meshAppService}/${projectPath}/${projectId}/path`);
};
