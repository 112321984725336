import React, { useState, useEffect, useCallback } from 'react';
import { store } from 'store/store';
import { filterProjectionSystems, getProjectionSystemsById } from 'store/actions/projectionSystems';
import { isNumeric } from '@mike/mike-shared-frontend/mike-shared-helpers/helpers';
import ProjectionManager from 'managers/ProjectionManager';

import { RECENT_WORKSPACES_KEY } from 'store/reducers/WorkspaceReducer';

import MmgWorkspaceProjectionsList from './workspace-recent-projection-list';
import { isEqual, uniq } from 'lodash-es';
import { usePrevious } from 'src/shared/hooks/hooks';
import { CircularProgress } from '@material-ui/core';
import { MmgWorkspaceProjection } from './workspace-projection-value';
import { useIsMounted } from 'src/shared/hooks/hooks';
import { IProjection } from '../../models/IProjections';
import MikeProjectionSelect from '@mike/mike-shared-frontend/mike-projection-select';

type WorkspaceProjectionSelectorProps = {
  projectId: string;
  epsgCode: number;
  onProjectionSelected: (epsgCode: number) => void;
  recentEpsgs?: any;
  loadingProjectionSystems: boolean;
  searchProjectionSystemsById: boolean;
  selectedProjectionSystems: IProjection[];
};

/**
 * @name MmgWorkspaceProjectionSelector
 * @summary Allows selecting a projection from an autocomplete and last epsg used
 *
 * @param props
 */
export const MmgWorkspaceProjectionSelector = (props: WorkspaceProjectionSelectorProps) => {
  const { epsgCode, projectId } = props;

  const [recentEpsgs, setRecentEpsgs] = useState([]);

  const [projectionList, setProjectionList] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  useEffect(() => {
    const recentWorkspaces = JSON.parse(localStorage.getItem(RECENT_WORKSPACES_KEY));
    const epsgs =
      recentWorkspaces &&
      recentWorkspaces
        .map((ws) => {
          return ws.epsg;
        })
        .filter((epsg) => {
          return Boolean(epsg);
        });
    setRecentEpsgs(epsgs || []);
  }, []);

  const getProjectionsNames = useCallback(
    () => {
      try {
        setLoading(true);
        const uIds = uniq(recentEpsgs);

        const list = uIds.map(async (epsg) => {
          const projectionMetadata = await ProjectionManager.getProjectionByEpsg(epsg);
          const newEntry = {
            id: projectionMetadata.id,
            name: projectionMetadata.name,
          };
          return newEntry;
        });
        Promise.all(list).then((completed) => {
          if (isMounted()) {
            setProjectionList(completed);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted()) {
          setLoading(false);
        }
      }
    },
    [isMounted, recentEpsgs],
  );

  const previuosEpsgs = usePrevious(recentEpsgs);

  useEffect(
    () => {
      if (!isEqual(previuosEpsgs, recentEpsgs)) {
        getProjectionsNames();
      }
    },
    [getProjectionsNames, previuosEpsgs, projectId, recentEpsgs],
  );

  const handleDefaultSelect = (code: string) => {
    props.onProjectionSelected(Number(code));
  };

  const onProjectionSearchTextChanged = (searchText: string) => {
    isNumeric(searchText)
      ? store.dispatch(getProjectionSystemsById(Number(searchText)))
      : store.dispatch(filterProjectionSystems(searchText));
  };

  return (
    <>
      {epsgCode && <MmgWorkspaceProjection epsgCode={epsgCode} />}
      <MikeProjectionSelect
        onProjectionSelected={props.onProjectionSelected}
        onProjectionSearchTextChanged={onProjectionSearchTextChanged}
        projections={props.selectedProjectionSystems}
        projectionsLoading={props.loadingProjectionSystems}
        searchById={props.searchProjectionSystemsById}
      />
      {!loading ? (
        <MmgWorkspaceProjectionsList handleDefaultSelect={handleDefaultSelect} recentProjections={projectionList} />
      ) : (
        <CircularProgress />
      )}
    </>
  );
};
