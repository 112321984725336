import { QUERY_DEFINITION_TYPES } from 'src/models/IQueryDefinitions';
import { EPanelActionType } from '../actions/PanelActionType';

const initialState = {
  workspacePanelSettings: null,
};

export type TPanelSettingsReducer = typeof initialState;

const PANEL_SETTINGS = 'PANEL_SETTINGS';

export const getLayerSettings = (workspaceId: string, layerId: string) => {
  let ls = null;
  const workspaceSettings = localStorage.getItem(`${workspaceId}-${PANEL_SETTINGS}`);
  if (workspaceSettings) {
    const settings = JSON.parse(workspaceSettings);
    if (settings) {
      const layerSettings = settings.find((l) => l.id === layerId);
      if (layerSettings !== undefined) {
        ls = layerSettings;
      }
    }
  }
  return ls;
};

export const getSpatialQueryLayer = (workspaceId: string, layerId: string) => {
  let id = '';
  const layerSettings = getLayerSettings(workspaceId, layerId);
  if (layerSettings && layerSettings.queryDefinition && layerSettings.queryDefinition.queryId) {
    id = layerSettings.queryDefinition.queryId;
  }
  return id;
};

/**
 * Panel Setting Reducer Reducer.
 *
 *
 * @name PanelSettingsReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case 'panel/INIT_PANEL_SETTINGS': {
      const workspaceId = action.workspaceId;
      const itemIds = action.itemsId;
      const settings = localStorage.getItem(`${workspaceId}-${PANEL_SETTINGS}`);
      const workspaceSettings = settings ? JSON.parse(settings) : null;
      const checkedForLayers = workspaceSettings
        ? workspaceSettings.filter((wSett) => {
            return itemIds.includes(wSett.id);
          })
        : [];
      const checkedForLayersAndQueryId = checkedForLayers.filter((s) => {
        if (s.queryDefinition && s.queryDefinition === QUERY_DEFINITION_TYPES.SPATIAL && s.queryDefinition.queryId) {
          return itemIds.includes(s.queryDefinition.queryId);
        } else {
          return s;
        }
      });
      localStorage.setItem(`${action.workspaceId}-${PANEL_SETTINGS}`, JSON.stringify(checkedForLayersAndQueryId));
      return {
        ...state,
        workspacePanelSettings: checkedForLayersAndQueryId,
      };
    }

    case EPanelActionType.REMOVE_QUERYDEF_FROM_PANEL_SETTINGS: {
      const { workspaceId, itemId } = action.data;
      const settings = localStorage.getItem(`${workspaceId}-${PANEL_SETTINGS}`);
      const workspaceSettings = settings ? JSON.parse(settings) : null;
      if (!workspaceSettings) {
        return state;
      }
      workspaceSettings.forEach((setting) => {
        if (setting.id === itemId) {
          delete setting.queryDefinition;
        }
      });
      localStorage.setItem(`${workspaceId}-${PANEL_SETTINGS}`, JSON.stringify(workspaceSettings));
      return {
        ...state,
        workspacePanelSettings: workspaceSettings,
      };
    }

    case 'panel/SET_MESH_ACTIVE_PANEL':
      const workspaceMeshPanelSettings = JSON.parse(localStorage.getItem(action.workspaceId + `-${PANEL_SETTINGS}`));
      const newMeshSettings = { id: action.layerId, meshPanelActive: action.meshPanelActive };
      const oldSettings = workspaceMeshPanelSettings.find((layer) => {
        return layer.id === action.layerId;
      });
      const newPanelActive = newMeshSettings.meshPanelActive;
      const mergedMeshSettings = { ...oldSettings, meshPanelActive: newPanelActive, id: action.layerId };
      const filteredMeshWorkspace = workspaceMeshPanelSettings.filter((sett) => sett.id !== action.layerId);
      filteredMeshWorkspace.push(mergedMeshSettings);
      localStorage.setItem(`${action.workspaceId}-${PANEL_SETTINGS}`, JSON.stringify(filteredMeshWorkspace));
      return {
        ...state,
        workspacePanelSettings: filteredMeshWorkspace,
      };

    case 'panel/SET_GEOMETRY_ACTIVE_PANEL':
      const workspaceGeometryPanelSettings = JSON.parse(
        localStorage.getItem(action.workspaceId + `-${PANEL_SETTINGS}`),
      );
      const newGeometrySettings = { id: action.layerId, geometryPanelActive: action.geometryPanelActive };

      const oldGeometrySettings = workspaceGeometryPanelSettings.find((layer) => {
        return layer.id === action.layerId;
      });

      const newGeometryPanelActive = newGeometrySettings.geometryPanelActive;

      const mergedGeometrySettings = {
        ...oldGeometrySettings,
        geometryPanelActive: newGeometryPanelActive,
        id: action.layerId,
      };

      const filteredGeometryWorkspace = workspaceGeometryPanelSettings.filter((sett) => sett.id !== action.layerId);
      filteredGeometryWorkspace.push(mergedGeometrySettings);

      localStorage.setItem(`${action.workspaceId}-${PANEL_SETTINGS}`, JSON.stringify(filteredGeometryWorkspace));
      return {
        ...state,
        workspacePanelSettings: filteredGeometryWorkspace,
      };

    case EPanelActionType.SET_MESH_QUERY_PANEL:
      const settings = localStorage.getItem(action.workspaceId + `-${PANEL_SETTINGS}`);
      const workspaceQueryPanelSettings = settings ? JSON.parse(settings) : null;
      const newMeshQuerySettings = { id: action.layerId, queryDefinition: action.queryDefinition };
      let settingsToSave = newMeshQuerySettings;
      if (workspaceQueryPanelSettings) {
        const oldLayerSettings = workspaceQueryPanelSettings.find((layer) => {
          return layer.id === action.layerId;
        });
        oldLayerSettings.queryDefinition = action.queryDefinition;
        const mergedMeshQuerySettings = { ...newMeshQuerySettings, ...oldLayerSettings };
        const filteredMeshQueryWorkspace = workspaceQueryPanelSettings.filter((sett) => sett.id !== action.layerId);
        filteredMeshQueryWorkspace.push(mergedMeshQuerySettings);
        settingsToSave = filteredMeshQueryWorkspace;
      }

      localStorage.setItem(`${action.workspaceId}-${PANEL_SETTINGS}`, JSON.stringify(settingsToSave));
      return {
        ...state,
        workspacePanelSettings: settingsToSave,
      };
    default:
      return state;
  }
}
