import React from 'react';
import { store } from 'store/store';
import { RouterChildContext } from 'react-router-dom';
import { getRouteNameByPath } from 'src/app/routes';
import { trackPageView } from 'managers/application-insights';

type ShellProps = {
  readonly RouteComponent: any;
  readonly route: RouterChildContext;
};
type ShellState = {
  prevRouterContext?: RouterChildContext;
};

/**
 * The shell is used as a wrapper that has access to the component route params.
 * Do not put any other component inside except for <RouteComponent/>, there can be a potential performance penalty.
 * Components that should be available on all routes should go into <App>.
 */
export class MmgShell extends React.Component<ShellProps, ShellState> {
  constructor(props) {
    super(props);

    this.state = {
      prevRouterContext: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const routeMatch = props.route.match;

    if (!state.prevRouterContext || routeMatch.url !== state.prevRouterContext.match.url) {
      // This makes route properties available in the AppReducer,
      // allowing any component to function without knowledge of React Router.
      //
      // This helps by removing the need to nest components under a route to access params (HoC).
      // In some cases, that is not possible due to the layout of the application.
      //
      // NB: this does not capture events (or paramters) of sub-routes.
      store.dispatch({
        type: 'app/router/NEW_ROUTE',
        routeParams: routeMatch.params,
      });

      trackPageView(routeMatch.url, getRouteNameByPath(routeMatch.path));
      return {
        prevRouterContext: props.route,
      };
    }

    return null;
  }

  render() {
    const { RouteComponent } = this.props;

    return <RouteComponent />;
  }
}
