import AppRoutes from 'src/app/app.routes';

const workspace = AppRoutes.workspace.path;
const variablebase = `${workspace}/variable`;
const variablebaseWithId = `${variablebase}/:variableId`;

export default {
  variableDetailsPanel: {
    path: variablebaseWithId,
  },
  variablesDeletePanel: {
    path: `${workspace}/variables/delete`,
  },
  variablesOperationPanel: {
    path: `${variablebaseWithId}/operation`,
  },
  variablesOperationsPanel: {
    path: `${workspace}/variables/operation`,
  },
  variablesMultipleOperationPanel: {
    path: `${workspace}/variables/operation/multiple`,
  },
  variablesOperationSelectItemsPanel: {
    path: `${workspace}/variables/operation/select`,
  },
};
