import React from 'react';
import { css } from 'emotion';

import { theme } from 'src/shared/styles/theme';
import { t } from 'src/translations/i18n';
import { getProjectionIdName } from './projection-utils';

type WorkspaceRecentProjectionsListProps = {
  // TODO masm: define model
  recentProjections: any;
  handleDefaultSelect: (code: string) => void;
};

const RecentProjectionStyle = css`
  color: ${theme.palette.secondary.main};
  cursor: pointer;
`;

const RecentProjectionListStyle = css`
  padding-top: ${theme.spacing(2)}px;
`;

/**
 * @name MmgWorkspaceRecentProjectionList
 * @summary Render a list of epsg codes plus projection name and with a custom callback for selection
 *
 * @param props
 */
const MmgWorkspaceRecentProjectionList = (props: WorkspaceRecentProjectionsListProps) => {
  const { recentProjections, handleDefaultSelect } = props;

  const handleSelection = (code: string) => {
    handleDefaultSelect(code);
  };
  return (
    recentProjections &&
    recentProjections.length > 0 && (
      <>
        <section className={RecentProjectionListStyle}>
          <p>{t('WORKSPACE_PROJECTIONS_RECENT_HELP')}</p>
          {recentProjections.map((entry: any, index: number) => {
            return (
              <div
                className={RecentProjectionStyle}
                key={index}
                onClick={() => {
                  handleSelection(entry.id);
                }}
              >
                {`${getProjectionIdName(entry.id)}   (${entry.name})`}
              </div>
            );
          })}
        </section>
      </>
    )
  );
};

export default MmgWorkspaceRecentProjectionList;
