/**
 * Base module that exposes means to make API calls and low-level config.
 * For example, the Proxy would configure HTTP/S calls, i.e. add a common header.
 *
 * The proxy can switch between real API calls and local mock API calls based on the USE_MOCK enviornment variable.
 *
 * @module Proxy
 * @version 2.0.1
 */
// import jwtDecode from 'jwt-decode';
import http from 'axios';
import { endpoints, apiVersion } from './config';
import { AxiosStatic } from 'axios/index.d';
// import { store } from 'store/store';

/* interface IDecodedToken {
  exp: number;
} */

interface IProxy {
  http: AxiosStatic;
  endpoints: object;
}

/**
 * Set the api version header.
 */
http.defaults.headers.common.Accept = `application/json, text/plain, */*;api-version=${apiVersion}`;

http.interceptors.request.use(
  (config) => {
    if (!config.headers['api-version']) {
      config.headers['api-version'] = apiVersion;
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        'content-type': `application/json;charset=utf-8`,
      },
    };
  },
  (error) => Promise.reject(error),
);

let Http;

if (process.env.USE_MOCK) {
  require('./mock/mockBanner');
  Http = require('./mock/mockHttp').mockHttp;
} else {
  Http = http as AxiosStatic;
}

const self: IProxy = {
  /**
   * Exposes HTTP methods equivalent to REST verbs (GET, POST, UPDATE, PUT, DELETE, etc.).
   * > Currently based on axios, see {@link https://github.com/axios/axios github.com/axios} for more information.
   *
   * ```
   * http.get('<url>');
   * http.post('<url>', { <body> });
   * ```
   */
  http: Http,

  /**
   * Contains endpoint URLs for local, preview, develop, test, staging and production slots.
   */
  endpoints,
};

export default self;
