import { takeLatest, put, call } from 'redux-saga/effects';
import { getExportableMeshes } from 'src/managers/WorkspaceMeshesManager';

/**
 * Defines sagas for loading which items are exportable
 */
export default function* watchExportableMeshes() {
  yield takeLatest('workspace/export/LOAD_EXPORTABLE_MESHES', loadExportableMeshes);
}
/**
 * Generator function for loading exportable meshes
 * @param action
 */
function* loadExportableMeshes(action) {
  yield put({ type: 'workspace/export/EXPORTABLE_MESHES_LOADING' });

  try {
    const exportableMeshIds = yield call(getExportableMeshes, action.workspaceId);

    yield put({
      type: 'workspace/export/LOAD_EXPORTABLE_MESHES_SUCCESS',
      exportableMeshIds,
    });
  } catch (error) {
    yield put({ type: 'workspace/export/LOAD_EXPORTABLE_MESHES_FAILED' });
  }
}
