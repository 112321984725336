import React, { useState } from 'react';
import { css } from 'emotion';
import { t } from 'src/translations/i18n';

import ButtonBase from '@material-ui/core/ButtonBase';
import KeyboardOutlinedIcon from '@material-ui/icons/KeyboardOutlined';
import Mouse from '@material-ui/icons/Mouse';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import { theme } from 'src/shared/styles/theme';
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';

const iconStyle = css`
  path {
    fill: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
`;

const HelpKeyStyle = css`
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.8em;
  background: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  padding: ${theme.spacing(1)}px;
  border-radius: 5px;
`;

const HelpKeyPlusStyle = css`
  margin: ${theme.spacing(1)}px;
`;

const HelpKeyContainer = css`
  display: flex;
  align-items: center;
  min-width: 200px;
`;

const HelpKeySectionStyle = css`
  background-color: ${MIKE_COLORS.MEDIUMGREY_DEFAULT} !important;
`;

const KeyboardShortcutStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 60px;
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  height: 100%;

  &:hover {
    background-color: ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
  }
`;

const KeyboardShortcutListItemStyle = css`
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_LIGHT};
`;

const keyboardShortcuts = {
  movement3D: [
    {
      id: 'KEY_MOUSE_LEFT_PRESS_3D',
      description: t('KEY_MOUSE_LEFT_PRESS_3D'),
      Icons: (
        <div className={HelpKeyContainer}>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_SCROLL',
      description: t('KEY_SCROLL'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('SCROLL')}</span>
        </div>
      ),
    },

    {
      id: 'KEY_SHIFT_PRESS',
      description: t('KEY_SHIFT_PRESS'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_CTRL_PRESS',
      description: t('KEY_CTRL_PRESS'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('CTRL')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_ALT_PRESS',
      description: t('KEY_ALT_PRESS'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('ALT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },
  ],

  movement2D: [
    {
      id: 'KEY_MOUSE_LEFT_PRESS_2D',
      description: t('KEY_MOUSE_LEFT_PRESS_2D'),
      Icons: (
        <div className={HelpKeyContainer}>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_SCROLL',
      description: t('KEY_SCROLL'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('SCROLL')}</span>
        </div>
      ),
    },
  ],

  draw2D: [
    {
      id: 'KEY_MOUSE_LEFT_PRESS_DRAW',
      description: t('KEY_MOUSE_LEFT_PRESS_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_MOUSE_DOUBLE_LEFT_PRESS_DRAW',
      description: t('KEY_MOUSE_DOUBLE_LEFT_PRESS_DRAW'),
      Icons: (
        <>
          <div className={HelpKeyContainer}>
            <Mouse fontSize="small" />
            <span className={HelpKeyPlusStyle}>+</span>
            <Mouse fontSize="small" />
          </div>
        </>
      ),
    },
    {
      id: 'KEY_SPACE',
      description: t('KEY_SPACE_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('KEY_SPACE')}</span>
        </div>
      ),
    },

    // while drawing
    {
      id: 'KEY_BACKSPACE',
      description: t('KEY_BACKSPACE_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('KEY_BACKSPACE')}</span>
        </div>
      ),
    },
    {
      id: 'KEY_ESC',
      description: t('KEY_ESC_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('KEY_ESC')}</span>
        </div>
      ),
    },

    {
      id: 'KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW',
      description: t('KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_MOUSE_ALT_SHIFT_DRAG_DRAW',
      description: t('KEY_MOUSE_ALT_SHIFT_DRAG_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('ALT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_SCROLL',
      description: t('KEY_SCROLL'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('SCROLL')}</span>
        </div>
      ),
    },
  ],

  modify2D: [
    // while no ongoing drawing
    {
      id: 'KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING',
      description: t('KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING'),
      Icons: (
        <div className={HelpKeyContainer}>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_DELETE',
      description: t('KEY_DELETE_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('KEY_DELETE')}</span>
        </div>
      ),
    },

    {
      id: 'KEY_MOUSE_CTRL_LEFT_CLICK_DRAW',
      description: t('KEY_MOUSE_CTRL_LEFT_CLICK_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('CTRL')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_MOUSE_SHIFT_LEFT_CLICK_DRAW',
      description: t('KEY_MOUSE_SHIFT_LEFT_CLICK_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },

    {
      id: 'KEY_MOUSE_ALT_LEFT_CLICK_DRAW',
      description: t('KEY_MOUSE_ALT_LEFT_CLICK_DRAW'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('ALT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <Mouse fontSize="small" />
        </div>
      ),
    },
  ],

  visualization: [
    {
      id: 'KEY_ALT_SHIFT_S_PRESS',
      description: t('KEY_ALT_SHIFT_S_PRESS'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('ALT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('S')}</span>
        </div>
      ),
    },

    {
      id: 'KEY_ALT_SHIFT_W_PRESS',
      description: t('KEY_ALT_SHIFT_W_PRESS'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('ALT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('W')}</span>
        </div>
      ),
    },

    {
      id: 'KEY_ALT_SHIFT_P_PRESS',
      description: t('KEY_ALT_SHIFT_P_PRESS'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('ALT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('P')}</span>
        </div>
      ),
    },

    {
      id: 'KEY_ALT_SHIFT_E_PRESS',
      description: t('KEY_ALT_SHIFT_E_PRESS'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('ALT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('E')}</span>
        </div>
      ),
    },

    {
      id: 'KEY_ALT_SHIFT_R_PRESS',
      description: t('KEY_ALT_SHIFT_R_PRESS'),
      Icons: (
        <div className={HelpKeyContainer}>
          <span className={HelpKeyStyle}>{t('ALT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('SHIFT')}</span>
          <span className={HelpKeyPlusStyle}>+</span>
          <span className={HelpKeyStyle}>{t('R')}</span>
        </div>
      ),
    },
  ],
};

const renderShortcuts = ({ id, description, Icons }) => (
  <ListItem key={id} className={KeyboardShortcutListItemStyle}>
    <ListItemIcon>{Icons}</ListItemIcon>
    <ListItemText primary={description} />
  </ListItem>
);

export function MmgKeyboardHelp() {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <ButtonBase onClick={() => setOpen(!open)} className={KeyboardShortcutStyle}>
        <KeyboardOutlinedIcon className={iconStyle} fontSize="small" />
      </ButtonBase>

      <Dialog onClose={() => setOpen(false)} open={open} aria-labelledby="keyboard-shortcuts">
        <DialogTitle id="keyboard-shortcuts">{t('KEYBOARD_SHORTCUTS')}</DialogTitle>

        <>
          <List>
            <ListSubheader color="primary" className={HelpKeySectionStyle}>
              {t('KEY_SECTION_3DMOVEMENT')}
            </ListSubheader>
            {keyboardShortcuts.movement3D.map(renderShortcuts)}

            <ListSubheader color="primary" className={HelpKeySectionStyle}>
              {t('KEY_SECTION_2DMOVEMENT')}
            </ListSubheader>
            {keyboardShortcuts.movement2D.map(renderShortcuts)}

            <ListSubheader color="primary" className={HelpKeySectionStyle}>
              {t('KEY_SECTION_2DDRAW')}
            </ListSubheader>
            {keyboardShortcuts.draw2D.map(renderShortcuts)}

            <ListSubheader color="primary" className={HelpKeySectionStyle}>
              {t('KEY_SECTION_2DMODIFY')}
            </ListSubheader>
            {keyboardShortcuts.modify2D.map(renderShortcuts)}

            <ListSubheader color="primary" className={HelpKeySectionStyle}>
              {t('KEY_SECTION_VISUALIZATION')}
            </ListSubheader>
            {keyboardShortcuts.visualization.map(renderShortcuts)}
          </List>
        </>
      </Dialog>
    </div>
  );
}
