/**
 * Exposes reselect-selectors methods for geometries.
 *
 * @module WorkspaceGeometrySelectors
 * @version 1.0.0
 */

import { createSelector } from 'reselect';
import { IDrawnDataItem } from 'models/IWorkspaceData';
import { IGlobalState } from '..';
import { IFieldStatistic, IWorkspaceMeshTiles } from '../reducers/WorkspaceMeshTilesReducer';

interface IItemId {
  itemId: string;
}

const workspaceGeometries = (state: IGlobalState) => state.WorkspaceGeometryReducer.workspaceGeometries;
const workspaceVariables = (state: IGlobalState) => state.WorkspaceVariableReducer.workspaceVariables;
const workspaceMeshes = (state: IGlobalState) => state.WorkspaceMeshReducer.workspaceMeshes;
export const getWorkspaceMeshTiles = (state: IGlobalState) => state.WorkspaceMeshTilesReducer.meshTiles;
export const getWorkspaceFieldStatistics = (state: IGlobalState) => state.WorkspaceMeshTilesReducer.fieldStatistics;
export const getWorkspaceDataStatistics = (state: IGlobalState) => state.WorkspaceMeshTilesReducer.dataStatistics;
export const getWorkspaceLoadingFieldStatistics = (state: IGlobalState) =>
  state.WorkspaceMeshTilesReducer.loadingFieldStatistics;
export const getViewBounds = (state: IGlobalState) => state.WorkspaceMeshTilesReducer.currentViewBounds;
export const getHiddenMeshTiles = (state: IGlobalState) => state.WorkspaceMeshTilesReducer.hiddenMeshTiles;

// selects the itemId from props
const itemIdProp = (_state, props: IItemId) => props.itemId;

export const getWorkspaceData = (state: IGlobalState): Array<IDrawnDataItem> =>
  state.WorkspaceDataReducer.workspaceData;

export const getStreamingIds = (state: IGlobalState): Array<string> => state.WorkspaceMeshTilesReducer.streamingIds;

/**
 * Returns all drawn items having id corresponding to the given ids. If none is matching an empty array is returned.
 *
 * @param drawnItems
 * @param itemIds
 */
const getDrawnItemsById = (drawnItems: Array<IDrawnDataItem>, itemIds: Array<string>) => {
  if (!drawnItems || !itemIds) {
    const empty = new Array<IDrawnDataItem>();
    return empty;
  }

  const filteredItems = drawnItems.filter(({ id, drawn }) => drawn && itemIds.indexOf(id) !== -1);

  return filteredItems;
};

/**
 * Returns previous mesh tile bounds for a given mesh id.
 *
 * @param meshTiles
 * @param itemId
 */
const getMeshTileBoundsById = (meshTiles: { [key: string]: IWorkspaceMeshTiles }, itemId: string) => {
  if (!meshTiles || !itemId) {
    return undefined;
  }
  return meshTiles[itemId];
};

/**
 * Returns field statistics for a given id of a tiled mesh.
 *
 * @param fieldStatistics
 * @param itemId
 */
const getFieldStatisticsById = (fieldStatistics: { [key: string]: Array<IFieldStatistic> }, itemId: string) => {
  if (!fieldStatistics || !itemId) {
    return undefined;
  }
  return fieldStatistics[itemId];
};

/**
 * Returns data array statistics for a given id of a tiled mesh.
 *
 * @param dataStatistics
 * @param itemId
 */
const getDataStatisticsById = (dataStatistics: { [key: string]: Array<IFieldStatistic> }, itemId: string) => {
  if (!dataStatistics || !itemId) {
    return undefined;
  }
  return dataStatistics[itemId];
};

/**
 * Selector to return size of drawn data.
 */
export const getDrawnDataSize = createSelector([getWorkspaceMeshTiles, getWorkspaceData], (meshTiles, data) => {
  let size = 0;
  data.forEach((d: IDrawnDataItem) => {
    size += d.length;
  });
  const tiledMeshIds = Object.keys(meshTiles);
  tiledMeshIds.forEach((id: string) => {
    const meshBounds = meshTiles[id];
    meshBounds.forEach((mb: IWorkspaceMeshTiles) => {
      if (!mb.isOverview) {
        size += mb.size;
      }
    });
  });

  return size;
});

/**
 * Selector to return drawn workspace geometries.
 */
export const getDrawnWorkspaceGeometriesByIds = createSelector(
  [workspaceGeometries, getWorkspaceData],
  (geometries, data) => {
    const drawnItems = self.getDrawnItemsById(data, geometries.map((g) => g.id));

    return drawnItems;
  },
);

/**
 * Selector to return drawn workspace variables.
 */
export const getDrawnWorkspaceVariablesByIds = createSelector(
  [workspaceVariables, getWorkspaceData],
  (variables, data) => {
    const drawnItems = self.getDrawnItemsById(data, variables.map((g) => g.id));

    return drawnItems;
  },
);

/**
 * Selector to return drawn workspace meshes.
 */
export const getDrawnWorkspaceMeshesByIds = createSelector([workspaceMeshes, getWorkspaceData], (meshes, data) => {
  const drawnItems = self.getDrawnItemsById(data, meshes.map((g) => g.id));

  return drawnItems;
});

/**
 * Selector to return drawn workspace data for a given itemId.
 *
 * @returns The dranDataItem if found, otherwise null
 */
export const getWorkspaceDataByItemId = createSelector([getWorkspaceData, itemIdProp], (data, itemId) => {
  if (!itemId) {
    return null;
  }

  const drawnItems = self.getDrawnItemsById(data, [itemId]);

  return drawnItems.length === 1 ? drawnItems[0] : null;
});

/**
 * Selector to return previous mesh tile bounds for a given itemId.
 *
 * @returns The mesh tile bounds if exists
 */
export const getWorkspaceMeshTilesById = createSelector([getWorkspaceMeshTiles, itemIdProp], (data, itemId) => {
  if (!itemId) {
    return null;
  }

  const previousBounds = self.getMeshTileBoundsById(data, [itemId]);

  return previousBounds;
});

/**
 * Selector to return field statistics for a given itemId of a tiled mesh.
 *
 * @returns The field statistics if exists
 */
export const getWorkspaceFieldDataStatisticsById = createSelector(
  [getWorkspaceFieldStatistics, itemIdProp],
  (data, itemId) => {
    if (!itemId) {
      return null;
    }

    const previousBounds = self.getFieldStatisticsById(data, [itemId]);

    return previousBounds;
  },
);

/**
 * Selector to return data statistics for a given itemId of a tiled mesh.
 *
 * @returns The data statistics if exists
 */
export const getWorkspaceDataArrayStatisticsById = createSelector(
  [getWorkspaceDataStatistics, itemIdProp],
  (data, itemId) => {
    if (!itemId) {
      return null;
    }

    const previousBounds = self.getDataStatisticsById(data, [itemId]);

    return previousBounds;
  },
);

const self = {
  getDrawnItemsById,
  getDrawnWorkspaceGeometriesByIds,
  getDrawnWorkspaceVariablesByIds,
  getDrawnWorkspaceMeshesByIds,
  getWorkspaceDataByItemId,
  getWorkspaceFieldDataStatisticsById,
  getWorkspaceData,
  getMeshTileBoundsById,
  getFieldStatisticsById,
  getDataStatisticsById,
};

export default self;
