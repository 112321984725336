import React, { useState, useEffect } from 'react';
import { IProjection } from 'models/IProjections';
import ProjectionManager from 'managers/ProjectionManager';
import { getProjectionIdName } from './projection-utils';

export type WorkspaceProjectionProps = {
  epsgCode: string;
};

export const MmgWorkspaceProjection = (props) => {
  const { epsgCode } = props;

  const [selectedProjection, setSelectedProjection] = useState<IProjection>(null);

  useEffect(
    () => {
      // load projection if selected does not match already
      if (epsgCode && epsgCode !== (selectedProjection || {}).id) {
        ProjectionManager.getProjectionByEpsg(epsgCode).then((result: IProjection) => {
          setSelectedProjection(result);
        });
      }
    },
    [epsgCode, selectedProjection],
  );

  const name = selectedProjection ? selectedProjection.name : '';

  return (
    epsgCode && (
      <p>
        <small>
          <strong>
            {getProjectionIdName(epsgCode)} {name}
          </strong>
        </small>
      </p>
    )
  );
};
