import AppRoutes from 'src/app/app.routes';

const base = AppRoutes.workspace.path;

export default {
  workspacePanel: {
    path: base,
  },

  workspaceExportPanel: {
    path: `${base}/export`,
  },
  workspaceOperationPanel: {
    path: `${base}/operation`,
  },

  workspaceEditPanel: {
    // NB: ${base}/edit is already in use by the standalone edit route (see app.routes.ts).
    path: `${base}/panel/edit`,
  },
};
