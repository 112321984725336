import {
  IElementDataArray,
  IElementFieldData,
  IDrawnDataGradientSettings,
  IColorRange,
} from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';
import { IWorkspaceAttribute } from './IWorkspaceAttributes';
import { IRepresentation } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/models/IRepresentation';
import { EOperationStates, IItemOperationState } from 'models/IOperations';
import { EMeshItemTypes, MESH_ITEM_TYPES } from 'models/IMeshes';
import { EVariableItemTypes, VARIABLE_ITEM_TYPES } from 'models/IVariables';
import { EGeometryItemTypes, GEOMETRY_ITEM_TYPES } from 'models/IGeometries';
import { EElementCategories } from 'src/shared/panels/mesh-panel-constants'; // todo hevo This should be moved to another place

export enum EOtherItemTypes {
  HOLE = 'Hole',
  UNKNOWN = 'Unknown',
}

export const OTHER_ITEM_TYPES = {
  HOLE: EOtherItemTypes.HOLE,
  UNKNOWN: EOtherItemTypes.UNKNOWN,
};

export const ITEM_TYPES = {
  ...OTHER_ITEM_TYPES,
  ...MESH_ITEM_TYPES,
  ...GEOMETRY_ITEM_TYPES,
  ...VARIABLE_ITEM_TYPES,
};

// todo hevo align with vtkItem from api and let geoemtry,mesh, varaible and query(?) inherit to reflect model in api
export interface IWorkspaceDataItem {
  id?: string;
  dataId?: string; // should be replaced by vtkFileId
  // vtkFileId?: string; // will be included ny api soon
  name?: string;
  created?: string;
  updated?: string;
  isTiled?: boolean;
}

export interface IWorkspaceEnrichedDataItem extends IWorkspaceDataItem {
  state?: EOperationStates;
  message?: string;
  conflictingState?: IItemOperationState;
  category?: EElementCategories;
}

export interface IDataItemIdentity {
  id?: string;
  dataId?: string; // should be replaced by vtkFileId
  // vtkFileId?: string; // will be included ny api soon
}

export interface IDrawnData {
  [key: string]: IDrawnDataItem;
}

export interface IDrawnDataItem {
  id: string;
  dataId: string;
  drawn?: boolean;
  length: number;
  // TODO: (JOEL) Make 'created' required (leads to TS errors in mesh-viewer.ts)
  created?: string;
  updated: string;
  representation: IRepresentation;
  itemType?: EMeshItemTypes | EVariableItemTypes | EGeometryItemTypes | EOtherItemTypes;
  pointSize?: number | undefined;
  edgeColor: Array<number>;
  surfaceColor: Array<number>;
  dataArrays?: Array<IElementDataArray>;
  fieldData?: Array<IElementFieldData>;
  attributes?: Array<IWorkspaceAttribute>;
  zAttributeName?: string;
  statisticsAttributeName?: string;
  attributeLabel?: string;
}

export interface IDrawnDataGradient {
  elementId: string;
  gradientSettings?: IDrawnDataGradientSettings;
  gradientAttributeName: string;
  colorRange?: IColorRange;
  isTiled?: boolean;
}

export enum EMeshFormats {
  VTU = 0,
  DOTMESH = 1,
  DOTMESH_LONGLAT = 2,
  DFSU = 3,
}

export const MESH_FORMATS = {
  VTU: EMeshFormats.VTU,
  DOTMESH: EMeshFormats.DOTMESH,
  DOTMESH_LONGLAT: EMeshFormats.DOTMESH_LONGLAT,
  DFSU: EMeshFormats.DFSU,
};

export interface IDrawnDataLegend {
  elementId: string;
  legendItems: Array<IAnnotatedValue>;
  title: string;
  colorRange?: IColorRange;
  isTiled?: boolean;
  gradientSettings?: IDrawnDataGradientSettings;
  tiledMeshDataRange?: Array<number>;
}

export interface IAnnotatedValue {
  value;
  annotation: string;
  rgba: Array<number>;
}
