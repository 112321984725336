import { isInteger } from 'lodash-es';
import { EMeshItemTypes } from 'models/IMeshes';
import { EGeometryItemTypes } from 'models/IGeometries';
import { EVariableItemTypes } from 'models/IVariables';
import { EOtherItemTypes, ITEM_TYPES } from 'models/IWorkspaceData';
import { IWorkspaceAttributeSettings, ATTRIBUTE_SETTINGS } from 'models/IWorkspaceAttributeSettings';
import { DEFAULT_DATA_TYPE_REPRESENTATIONS } from 'src/models/IViewerModels';
import { IRepresentation } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/models/IRepresentation';
import {
  getConfiguration as getVisualizerConfiguration,
  DEFAULTS,
} from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConfiguration';
import { EElementCategories, ELEMENT_CATEGORIES } from 'src/shared/panels/mesh-panel-constants';
import { IDrawnDataGradientSettings } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';

/**
 * Get configured settings for an attribute name
 * @param attributeName
 */
export const getAttributeSettings = (attributeName: string): IWorkspaceAttributeSettings => {
  if (attributeName) {
    const attributeSettings = ATTRIBUTE_SETTINGS.find((a) => a.name === attributeName);
    return attributeSettings;
  }

  return null;
};

/**
 * Get the default representation configured for a given attribute
 * @param attributeName
 * @param dataType
 */
export const getAttributeRepresentation = (attributeName: string, dataType: EElementCategories): IRepresentation => {
  const attributeSettings = getAttributeSettings(attributeName);

  const { defaultRepresentation } = attributeSettings || {};

  const attributeRepresentation = defaultRepresentation || DEFAULT_DATA_TYPE_REPRESENTATIONS[dataType];

  return attributeRepresentation;
};
/**
 * Get the default representation configured for a given attribute
 * If no default value exists will fallback to the default point size of the item.
 * @param attributeName
 * @param dataType
 * @param itemType
 */
export const getAttributePointSize = (
  attributeName: string,
  dataType: EElementCategories,
  itemType: EMeshItemTypes | EGeometryItemTypes | EVariableItemTypes | EOtherItemTypes,
): number => {
  const attributeSettings = getAttributeSettings(attributeName);

  const { defaultPointSize } = attributeSettings || {};
  if (isInteger(defaultPointSize)) {
    return defaultPointSize;
  }

  return getItemPointSize(dataType, itemType);
};

/**
 * Gets the point size for a given item based on category and item type.
 *
 * @param elementCategory
 * @param itemType
 */
export const getItemPointSize = (
  elementCategory: EElementCategories,
  itemType: EMeshItemTypes | EGeometryItemTypes | EVariableItemTypes | EOtherItemTypes,
): number | undefined => {
  switch (elementCategory) {
    case ELEMENT_CATEGORIES.GEOMETRY: {
      if (itemType === ITEM_TYPES.MULTI_POINT) {
        return 8;
      }

      return 3;
    }

    case ELEMENT_CATEGORIES.VARIABLE: {
      return 6;
    }

    default:
      return getVisualizerConfiguration().pointSize;
  }
};

/**
 * Get the default gradient settings configured for a given attribute
 * If no default settings exists will fallback to the viewer default.
 * @param attributeName
 */ export const getAttributeGradientSettings = (attributeName): IDrawnDataGradientSettings => {
  const attributeSettings = getAttributeSettings(attributeName);

  const { defaultGradientSettings } = attributeSettings || {};

  return defaultGradientSettings || { ...DEFAULTS.gradientSettings };
};
