import {
  IAttributeRangeQueryDefinitionApi,
  IPersistedQueryDefinitionApi,
  ISelectAllQueryDefinitionApi,
  ISpatialQueryDefinitionApi,
} from './IQueryDefinitions';

export enum EQueryItemTypes {
  QUERY = 'Query',
}

export const QUERY_ITEM_TYPES = {
  QUERY: EQueryItemTypes.QUERY,
};

export interface IWorkspaceQuery {
  id?: string;
  itemType?: EQueryItemTypes;
  name?: string;
  parentItemId?: string;
  queryDefinition?:
    | IAttributeRangeQueryDefinitionApi
    | ISpatialQueryDefinitionApi
    | ISelectAllQueryDefinitionApi
    | IPersistedQueryDefinitionApi;
  created?: string;
  updated?: string;
}
