import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import MikeVisualizer from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { IGlobalState } from 'src/store';
const { setEpsgCode } = MikeVisualizer;

/**
 * @name MmgConnectedViewerProjection
 * @summary Sets the viewer projection based on the workspace epsg code.
 *
 */
export const MmgConnectedViewerProjection = () => {
  const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  const epsgCode = useMemo(
    () => {
      return workspace ? workspace.epsgCode : null;
    },
    [workspace],
  );

  useEffect(
    () => {
      setEpsgCode(epsgCode);
    },
    [epsgCode],
  );

  return null;
};
