import { createSelector } from 'reselect';

import {
  IOperationMetadata,
  OPERATION_RESULT_TYPES,
  OPERATION_DEFINITION_TYPES,
  OPERATION_STATES,
} from 'models/IOperations';
import WorkspaceOperationUtils from 'store/selectors/WorkspaceOperationUtils';
import WorkspaceOperationMetadataSelectors from 'store/selectors/WorkspaceOperationMetadataSelectors';
import { IWorkspaceMesh } from 'models/IMeshes';
import { IGlobalState } from '../store';

interface IMeshId {
  meshId: string;
}

const workspaceMeshes = (state: IGlobalState) => state.WorkspaceMeshReducer.workspaceMeshes;

const workspaceOperations = (state: IGlobalState) => state.WorkspaceOperationReducer.workspaceOperations;

// selects the meshId from props
const meshIdProp = (_state, props: IMeshId) => props.meshId;

/**
 * Get operations that have Mesh as a result type.
 */
const getMeshOperations = createSelector([workspaceOperations], (operations: Array<IOperationMetadata>) => {
  if (!operations) {
    return [];
  }

  return operations.filter(({ resultType }) => resultType === OPERATION_RESULT_TYPES.MESH);
});

/**
 * Get operations that have Mesh as a result type and are not marked as superseded.
 */
const getMeshOperationsNotSuperseded = createSelector(
  [WorkspaceOperationMetadataSelectors.getOperationsNotSuperseded],
  (operations: Array<IOperationMetadata>) => {
    if (!operations) {
      return [];
    }

    return operations.filter(({ resultType }) => resultType === OPERATION_RESULT_TYPES.MESH);
  },
);

/**
 * Gets operations only containing the operation matching the last state of each mesh.
 */
const getLatestMeshOperations = createSelector(
  [getMeshOperationsNotSuperseded, workspaceMeshes],
  (operations: Array<IOperationMetadata>, meshes: Array<IWorkspaceMesh>) => {
    if (!meshes) {
      return [];
    }

    return meshes
      .map(({ id }) => {
        return WorkspaceOperationUtils.getLatestOperation(
          WorkspaceOperationUtils.filterOperationsByOutputId(operations, id),
        );
      })
      .filter((operation) => operation);
  },
);

/**
 * Gets the latest CreateMeshOperation of each mesh.
 */
const getLatestCreateMeshOperations = createSelector(
  [getMeshOperationsNotSuperseded, workspaceMeshes],
  (operations: Array<IOperationMetadata>, meshes: Array<IWorkspaceMesh>) => {
    if (!meshes) {
      return [];
    }

    return meshes
      .map(({ id }) => {
        return WorkspaceOperationUtils.getLatestOperation(
          WorkspaceOperationUtils.filterOperationsByOutputIdAndType(
            operations,
            id,
            OPERATION_DEFINITION_TYPES.CREATE_MESH,
          ),
        );
      })
      .filter((operation) => operation);
  },
);

/**
 * Gets the latest CreateMeshOperation of each mesh.
 */
const getLatestProcessingMeshOperations = createSelector(
  [getLatestMeshOperations, workspaceMeshes],
  (operations: Array<IOperationMetadata>, meshes: Array<IWorkspaceMesh>) => {
    if (!meshes || meshes.length === 0) {
      return [];
    }

    const processingStates = [OPERATION_STATES.SCHEDULED, OPERATION_STATES.PROCESSING];

    return operations.filter((operation) => operation.state && processingStates.includes(operation.state));
  },
);

/**
 * Gets operations that do not have `outputIds`, that have Mesh as `resultType`.
 * NB: it is expected that the operation state only contains the latest updated operation; there aren't multiple operations with the same id.
 */
const getMeshOperationsWithoutOutput = createSelector([getMeshOperations], (operations: Array<IOperationMetadata>) => {
  if (!operations) {
    return [];
  }

  const operationsWithoutOutput = WorkspaceOperationUtils.filterOperationsWithoutOutputIds(operations);

  // todo hevo sort operations by created date?

  return operationsWithoutOutput;
});

/**
 * Selector to return all operations having the mesh defined by the meshId prop as output.
 */
const _getMeshOperationsForMesh = createSelector([getMeshOperations, meshIdProp], (meshOperations, meshId) => {
  if (!meshOperations || meshOperations.length === 0 || !meshId) {
    return null;
  }

  return WorkspaceOperationUtils.filterOperationsByOutputId(meshOperations, meshId);
});

/**
 * Selector to return all scheduled and processing operations having the mesh defined by the meshId prop as output.
 */
const getLatestProcessingMeshOperationForMesh = createSelector(
  [getLatestProcessingMeshOperations, meshIdProp],
  (meshOperations, meshId) => {
    if (!meshOperations || meshOperations.length === 0 || !meshId) {
      return null;
    }

    const operations = WorkspaceOperationUtils.filterOperationsByOutputId(meshOperations, meshId);
    if (operations.length > 0) {
      return operations[0];
    }
    return null;
  },
);

/**
 * Returns an instance of a selector for getting all operations having the mesh defined by the meshId prop as output.
 */
const makeGetMeshOperationsForMesh = () => {
  return createSelector([_getMeshOperationsForMesh], (operations) => {
    return operations;
  });
};

const self = {
  getMeshOperations,
  getLatestMeshOperations,
  getLatestCreateMeshOperations,
  getMeshOperationsWithoutOutput,
  getMeshOperationsNotSuperseded,
  getLatestProcessingMeshOperations,
  getLatestProcessingMeshOperationForMesh,
  makeGetMeshOperationsForMesh,

  _getMeshOperationsForMesh,
};

export default self;
