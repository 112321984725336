import ITenant from 'src/models/ITenant';
import { EIAMActionType } from './IAMActionType';

export const loadTenants = () => ({
  type: EIAMActionType.GET_MY_TENANTS,
});

export const loadingTenants = (loading = true) => ({
  type: EIAMActionType.LOADING_MY_TENANTS,
  data: loading,
});

export const setMyTenants = (tenants: Array<ITenant>) => ({
  type: EIAMActionType.SET_MY_TENANTS,
  data: tenants,
});
