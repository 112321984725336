/**
 * Exposes workspace geometries  REST endpoints to managers.
 *
 * @module WorkspaceGeometriesProxy
 * @version 2.0.0
 * @requires Proxy
 */
import * as qs from 'query-string';

import { endpoints, paths } from './config';
import Proxy from './Proxy';
import { IWorkspaceGeometry } from 'models/IGeometries';
import { FeatureCollection } from 'geojson';

const { meshAppService } = endpoints;
const { workspaces, geometries, duplicate, data } = paths;

/**
 * Update geometries in a workspace.
 *
 * @param workspaceId
 * @param modifiedGeometries
 */
export const updateGeometries = (workspaceId: string, modifiedGeometries: Array<IWorkspaceGeometry>) =>
  Proxy.http.patch(`${meshAppService}/${workspaces}/${workspaceId}/${geometries}`, modifiedGeometries);

/**
 * Updates a geometry's data from GeoJSON.
 *
 * @param workspaceId
 * @param geometryId
 * @param featureCollections
 */
export const updateGeometryDataWithcachedEdits = (
  workspaceId: string,
  geometryId: string,
  featureCollections: Array<FeatureCollection<any, any>>,
) =>
  Proxy.http.patch(
    `${meshAppService}/${workspaces}/${workspaceId}/${geometries}/${geometryId}/${data}`,
    featureCollections,
  );

export const createGeometryDataWithCachedEdits = (
  workspaceId: string,
  name: string,
  featureCollections: Array<FeatureCollection<any, any>>,
) => {
  const encodedProperties = qs.stringify({
    name,
  });

  return Proxy.http.post(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${geometries}?${encodedProperties}`,
    featureCollections,
  );
};

/**
 * Delete geometries
 *
 * @param workspaceId
 * @param geometryIds
 */
export const deleteGeometries = (workspaceId: string, geometryIds: Array<string>) => {
  return Proxy.http.delete(`${meshAppService}/${workspaces}/${workspaceId}/${geometries}`, { data: geometryIds });
};

/**
 * Get geometries labels
 *
 * @param workspaceId
 * @param geometryId
 * @param attributeName
 */
export const getGeometryLabels = (workspaceId: string, geometryId: string, attributeName: string) => {
  return Proxy.http.post(
    `${meshAppService}/${workspaces}/${workspaceId}/${geometries}/labels/${attributeName}`,
    `"${geometryId}"`,
  );
};

/**
 * Duplicate a geometry including vtk data
 *
 * @param workspaceId
 * @param geometryId
 */
export const duplicateGeometry = (workspaceId: string, geometryId: string) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${geometries}/${geometryId}/${duplicate}`, {});

/**
 * Create a geometry from GeoJSON
 *
 * @param workspaceId
 * @param name
 * @param featureCollection
 * @param properties Arbitrary object with key: value pairs of global properties.
 */
export const createGeometry = (
  workspaceId: string,
  name: string,
  featureCollection: FeatureCollection<any, any>,
  properties: { [key: string]: string },
) => {
  const encodedProperties = qs.stringify({
    properties: JSON.stringify(properties),
  });

  return Proxy.http.post(
    `${endpoints.meshAppService}/${workspaces}/${workspaceId}/${geometries}?name=${name}&${encodedProperties}`,
    featureCollection,
  );
};

/**
 * Gets a geometry as GeoJSON.
 *
 * @param workspaceId
 * @param geometryId
 */
export const getGeometryGeojson = (workspaceId: string, geometryId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${geometries}/${geometryId}/${data}`);

/**
 * Updates a geometry's data from GeoJSON.
 *
 * @param workspaceId
 * @param geometryId
 * @param featureCollection
 */
export const updateGeometryData = (
  workspaceId: string,
  geometryId: string,
  featureCollection: FeatureCollection<any, any>,
) =>
  Proxy.http.patch(
    `${meshAppService}/${workspaces}/${workspaceId}/${geometries}/${geometryId}/${data}`,
    featureCollection,
  );

/**
 * Gets geometry global properties.
 *
 * @param workspaceId
 * @param geometryId
 */
export const getGeometryProperties = (workspaceId: string, geometryId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${geometries}/${geometryId}/${paths.properties}`);

/**
 * Export geometries to the platform.
 *
 * @param workspaceId
 * @param geometryIds
 */
export const exportGeometries = (workspaceId: string, geometryIds: Array<string>) =>
  Proxy.http.post(`${meshAppService}/${workspaces}/${workspaceId}/${geometries}/export`, geometryIds);

/**
 * For a given workspace, gets all geometries that are allowed to be exported to the platform.
 *
 * @param workspaceId
 */
export const getExportableGeometries = (workspaceId: string) =>
  Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/${geometries}/exportables`);
