import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EWorkspaceMeshActionType } from '../actions/WorkspaceMeshActionType';
import { Feature, Polygon } from 'geojson';
import { EMapToolActionType } from '../actions/MapToolActionType';
import { IAppendProperties } from './EditReducer';

export interface INodeNeighbours {
  nodeIndex: number;
  nodeX: number;
  nodeY: number;
  nodeProperties: IAppendProperties;
  neighbouringFeatures: Array<Feature<Polygon>>;
}

export interface IChangedNode {
  nodeIndex: number;
  nodeX: number;
  nodeY: number;
  nodeProperties: IAppendProperties;
  editMode: number;
}

/**
 * Defines wich mesh tools are allowed by default and which are not.
 */
const defaultAllowedTools = {
  meshSpatialSelectionAllowed: false,
};

const initialState = {
  ...defaultAllowedTools,
  mapToolsSupportedByProjection: true, // Map projection is assumed to support map tools by default

  // The target of the mesh spatial selection.
  meshSpatialSelectionTargetId: null,
  meshEditNodesTargetId: null,
};

export type MeshMapToolState = {
  meshSpatialSelectionAllowed: boolean;
  mapToolsSupportedByProjection: boolean;
  meshSpatialSelectionTargetId: string | null;
  meshEditNodesTargetId: string | null;
};

/**
 * Returns a full state object with all the tools not supported by projection being disallowed.
 * Eventually this will hide the tools completely in the toolbar.
 *
 * @param state - GeometryToolState
 */
const disallowNotSupportedToolsOnState = (state: MeshMapToolState): MeshMapToolState => {
  console.debug('NB: Mesh Map tools not supported.');
  return {
    ...state,
    // ools relying on projection
    meshSpatialSelectionAllowed: false,
  };
};

/**
 * Mesh Map Tool Reducer.
 * - returns new states for matched Mesh Map tool message actions. They are typically related to drawing, selections, editing, etc.
 *
 * @name MeshMapToolReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case EMapToolActionType.RESET_ALLOWED_TOOLS: {
      const nextState = {
        ...state,
        ...defaultAllowedTools,
      };

      // disallow those not supported by projection
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(nextState);
      }

      return nextState;
    }

    case 'maptools/ALLOW/MAPTOOLS': {
      return {
        ...state,
        mapToolsSupportedByProjection: true,
      };
    }

    case EMapToolActionType.DISALLOW_MAPTOOLS: {
      return {
        ...disallowNotSupportedToolsOnState(state),
        mapToolsSupportedByProjection: false,
      };
    }

    case 'maptools/mesh/ALLOW/SPATIAL_SELECTION':
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }

      return {
        ...state,
        meshSpatialSelectionAllowed: state.mapToolsSupportedByProjection,
      };

    case 'maptools/mesh/SET_SPATIAL_SELECTION_TARGET':
      return {
        ...state,
        meshSpatialSelectionTargetId: action.meshSpatialSelectionTargetId,
      };

    case EWorkspaceMeshActionType.EDIT_NODES:
      return {
        ...state,
        meshEditNodesTargetId: action.data,
      };

    default:
      return state;
  }
}
