const initialState = {
  panelHidden: false,
  currentNavGroup: null,
  collapsedGroups: [],
};

export type TPanelReducer = typeof initialState;

/**
 * Panel Reducer.
 * - returns new states for matched panel message actions.
 *
 * @name PanelReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case 'panel/SET_PANEL_OPEN':
      return {
        ...state,
        panelHidden: action.panelHidden,
      };

    case 'panel/SET_CURRENT_NAV_GROUP':
      return {
        ...state,
        currentNavGroup: action.currentNavGroup,
      };

    case 'panel/SET_GROUP_COLLAPSE':
      return {
        ...state,
        collapsedGroups: [...state.collapsedGroups, action.groupId],
      };

    case 'panel/SET_GROUP_SHOW':
      return {
        ...state,
        collapsedGroups: state.collapsedGroups.filter((groupId: string) => groupId !== action.groupId),
      };

    default:
      return state;
  }
}
