import { IGetProject } from '@mike/mike-shared-frontend/mike-project-explorer/models/IGetProject';
import { EDATASETUSAGE_TYPE, IGetDataset } from 'src/models/IGetDataset';
import { IGetProjectPath } from 'src/models/IGetProjectPath';
import { EProjectContentActionType } from '../actions/ProjectContentActionType';

export interface IProjectContentState {
  loadingProjectAndParent: boolean;
  loadingProjectContent: boolean;
  projectContentDialogIsOpen: boolean;
  projectContentDialogIsSelectable: boolean;
  project: IGetProject | null;
  projectParent: IGetProject | null;
  projectContent: Array<IGetProject | IGetDataset>;
  currentNavigationProject: IGetProject;
  currentNavigationParentProject: IGetProject;
  currentNavigationProjectPath: IGetProjectPath[];
  page: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string | ((item: any) => string | number);
  itemToReplace: IItemToReplace | null;
}

interface ICurrenNaviationProject {
  projectContent: Array<IGetProject | IGetDataset>;
  parent: IGetProject;
  project: IGetProject;
  path: IGetProjectPath[];
  loadingProjectContent: boolean;
}

interface IProjectExplorerState {
  open: boolean;
  selectable: boolean;
  itemToReplace: IItemToReplace | null;
}

interface IProjectParent {
  projectParent: IGetProject;
  project: IGetProject;
}

export interface IItemToReplace {
  elementId: string;
  usageType: EDATASETUSAGE_TYPE;
}

const initState = {
  loadingProjectAndParent: false,
  loadingProjectContent: false,
  project: null,
  projectParent: null,
  projectContent: Array<IGetProject | IGetDataset>(),
  currentNavigationProject: null,
  currentNavigationParentProject: null,
  currentNavigationProjectPath: Array<IGetProjectPath>(),
  projectContentDialogIsOpen: false,
  projectContentDialogIsSelectable: false,
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: '',
  itemToReplace: null,
};

// : IAction< boolean | number |  ICurrenNaviationProject | IProjectExplorerState | IProjectParent   >
export default function(state = initState, action) {
  switch (action.type) {
    case EProjectContentActionType.OPEN_PROJECTCONTENT_DIALOG: {
      const payload: IProjectExplorerState = action.data as IProjectExplorerState;
      return {
        ...state,
        projectContentDialogIsOpen: payload.open,
        projectContentDialogIsSelectable: payload.selectable,
        itemToReplace: payload.open ? payload.itemToReplace : null,
      };
    }

    case EProjectContentActionType.LOADING_PROJECTANDPARENT:
      return { ...state, loadingProjectAndParent: action.data };
    case EProjectContentActionType.LOADING_PROJECTCONTENT:
      return { ...state, loadingProjectContent: action.data };
    case EProjectContentActionType.SET_PROJECTCONTENT: {
      const payload: ICurrenNaviationProject = action.data as ICurrenNaviationProject;
      return {
        ...state,
        projectContent: payload.projectContent,
        currentNavigationParentProject: payload.parent,
        currentNavigationProject: payload.project,
        currentNavigationProjectPath: payload.path,
        loadingProjectContent: false,
      };
    }
    case EProjectContentActionType.SET_PROJECTANDPARENT:
      const payload: IProjectParent = action.data as IProjectParent;
      return {
        ...state,
        project: payload.project,
        projectParent: payload.projectParent,
        loadingProjectAndParent: false,
      };
    case EProjectContentActionType.SET_PROJECTCONTENT_PAGE:
      return {
        ...state,
        page: action.data,
      };
    case EProjectContentActionType.SET_PROJECTCONTENT_ROWSPERPAGE:
      return {
        ...state,
        rowsPerPage: action.data,
      };
    case EProjectContentActionType.SET_PROJECTCONTENT_ORDER:
      return {
        ...state,
        order: action.data,
      };
    case EProjectContentActionType.SET_PROJECTCONTENT_ORDERBY:
      return {
        ...state,
        orderBy: action.data,
      };

    default:
      return state;
  }
}
