/**
 * Internationalization (i18n) module.
 *
 * @example
 * import { load } from 'translations/i18n.ts';
 *
 * const enTranslations = {
 *   'TRANSLATION_KEY': 'Translation value'
 * };
 *
 * load('en', enTranslations); // This loads the 'en' dictionary & sets module language to 'en'.
 * t('TRANSLATION_KEY'); // 'Translation value'
 *
 * @module i18n
 * @version 1.0.0
 */
import { stripIndents } from 'common-tags';

const INITIAL_DICTIONARY = {};
const INITIAL_LANG = 'en';

let dictionary = { ...INITIAL_DICTIONARY };
let lang: string = INITIAL_LANG;
let debug = false;

export const turnOnDebug = () => {
  console.warn('i18n debug is on. It should be off in production.');
  debug = true;
};

export const turnOffDebug = () => {
  debug = false;
};

/**
 * Loads a dictionary object for a language.
 * By default, it sets the module language to the loaded provided `_lang`.
 *
 * @param _lang The language to load the dictionary for; a ISO 639-1 region code, i.e. 'da', 'en', etc.
 * @param _dictionary An object containing key/value pairs of translations.
 */
export const load = (_lang: string, _dictionary: object) => {
  dictionary[_lang] = _dictionary;
  lang = _lang;
};

/**
 * Get the key/value object containing translations for the current module language.
 *
 * @return An object containing key/value pairs of translations.
 */
export const getCurrentTranslations = (): object => dictionary[lang] || {};

/**
 * Get the current module language.
 *
 * @return A ISO 639-1 region code, i.e. 'da', 'en', etc.
 */
export const getLang = (): string => lang;

/**
 * Sets the current module language.
 *
 * @param newLang A ISO 639-1 region code, i.e. 'da', 'en', etc.
 */
export const setLang = (newLang: string) => {
  lang = newLang;
};

/**
 * Resets the module language and dictionary.
 */
export const _reset = () => {
  dictionary = { ...INITIAL_DICTIONARY };
  lang = INITIAL_LANG;
};

/**
 * Parses parameters in the form of {param} for a string and returns a list with all of the found ones.
 * i.e. 'This {number} is {large}' => ['number', 'large']
 *
 * @param str
 */
export const _parseStringParameters = (str): Array<string> => {
  const paramExpression = new RegExp(/{(.*?)}/gm);
  const params = [];
  let match;

  do {
    match = paramExpression.exec(str);
    if (match) {
      params.push(match[1]); // First group is the match we want (without {}).
    }
  } while (match);

  return params;
};

const debugEmptyTranslations = !!localStorage.getItem('debugEmptyTranslations');
/**
 * t = translate
 * A function that given a trnslation key will return the translation for it, if it exists.
 *
 * @param key The translation key to look for.
 * @param count The count (number of) items to return translation for (see examples below).
 * @param parameters Parameters to replace within the translation, for the given count (see examples below).
 *
 * Given a key, looks for a translation for it.
 *
 * @example
 * - 1. Basic.
 *     SOME_KEY = 'Some key'
 *     t('SOME_KEY') // 'Some key'
 * - 2. Plural
 *     SOME_PLURAL = 'Thing | Things'
 *     t('SOME_PLURARL', 1) // 'Thing'
 *     t('SOME_PLURARL', 2) // 'Things'
 *     t('SOME_PLURARL', 10) // 'Things'
 * - 3. Parameters
 *     SOME_KEY = 'Some key'
 *     PARAM_KEY = 'Translate {thing}'
 *     t('PARAM_KEY', 1, { thing: t('SOME_KEY') }) // 'Translate Some key'
 */
export const t = (key: string, count = 1, parameters = {}): string => {
  let translationIndex = 0;

  if (!key) {
    // Return empty string if no key is provided; nothing to do.
    if (debug) {
      console.info(`No translation key provided`);
    }
    return '';
  }

  // Try to get translations for a given key.
  const translations = getCurrentTranslations();
  const upperCaseKey = key.toUpperCase();
  const translationString = translations[upperCaseKey];

  if (!translationString && translationString !== '') {
    // If a translation key doesn't exist, return the key.
    if (debugEmptyTranslations) {
      console.info(`No translation found for key: ${upperCaseKey}`);
    }
    return key;
  }

  // Parse translation string and split on "|" for pluralization.
  const plurals = translationString.replace(' | ', '|').split('|');

  if (count > 1) {
    translationIndex = count > plurals.length ? plurals.length - 1 : count - 1;
  }

  const rawTranslation = stripIndents(plurals[translationIndex]);
  const parametersToReplace = _parseStringParameters(rawTranslation);

  return parametersToReplace && parametersToReplace.length
    ? parametersToReplace.reduce((str, param) => {
        const toReplace = parameters[param];

        if (!toReplace) {
          /* if (debug) {
            console.debug(`No parameter value passed for ${param} in key ${upperCaseKey} & count ${count}`);
          } */
          return str.replace(`{${param}}`, '');
        }

        return str.replace(`{${param}}`, toReplace);
      }, rawTranslation)
    : rawTranslation;
};
