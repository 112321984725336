import { createSelector } from 'reselect';
import { IOperationMetadata } from 'models/IOperations';
import WorkspaceOperationUtils from 'store/selectors/WorkspaceOperationUtils';
import { IGlobalState } from '../store';

interface IOperationId {
  operationId: string;
}

interface IOperationIds {
  operationIds: Array<string>;
}

const workspaceOperations = (state: IGlobalState) => state.WorkspaceOperationReducer.workspaceOperations;

// selects the operationId from props
const operationIdProp = (_state, props: IOperationId) => props.operationId;

// selects the operationIds from props
const operationIdsProp = (_state, props: IOperationIds) => props.operationIds;

/**
 * Gets all operations not superseded.
 */
const getOperationsNotSuperseded = createSelector([workspaceOperations], (operations: Array<IOperationMetadata>) => {
  if (!operations) {
    return operations;
  }

  return WorkspaceOperationUtils.filterOperationsNotSuperseded(operations);
});

/**
 * Selector to return the operation metadata based on the operationId prop.
 */
const _getOperationMetadata = createSelector(
  [workspaceOperations, operationIdProp],
  (operations: Array<IOperationMetadata>, operationId: string) => {
    if (!operations || !operationId) {
      return null;
    }
    return operations.find(({ id }) => operationId === id) || null;
  },
);

/**
 * Returns all operation metadata having id corresponding to the given ids. If none is matching an empty array is returned.
 *
 * @param operations
 * @param operationIds
 */
const _getOperationMetadataByIds = createSelector(
  [workspaceOperations, operationIdsProp],
  (operations: Array<IOperationMetadata>, operationIds: Array<string>) => {
    if (!operations || !operationIds) {
      return [];
    }

    const filteredItems = operations.filter(({ id }) => operationIds.indexOf(id) !== -1);

    return filteredItems;
  },
);

/**
 * Returns an instance of a selector for getting the operation metadata based on the operationId prop.
 */
export const makeGetOperationMetadataSelector = () => {
  return createSelector([_getOperationMetadata], (operation) => {
    return operation;
  });
};

/**
 * Returns an instance of a selector for getting all operation metadata having id corresponding to the given ids. If none is matching an empty array is returned.
 */
export const makeGetOperationMetadataByIdsSelector = () => {
  return createSelector([_getOperationMetadataByIds], (operation) => {
    return operation;
  });
};

const self = {
  getOperationsNotSuperseded,
  makeGetOperationMetadataSelector,
  makeGetOperationMetadataByIdsSelector,

  _getOperationMetadata,
  _getOperationMetadataByIds,
};

export default self;
