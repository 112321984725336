import React from 'react';
import { css } from 'emotion';

import { ReactComponent as MeshGenerator } from '@mike/mike-shared-frontend/media/icons/MeshGenerator';
import CircularProgress from '@material-ui/core/CircularProgress';

const IconStyle = css`
  width: 100px;
  height: 100px;
`;

export const MmgAnimatedIcon = () => (
  <>
    <MeshGenerator viewBox={`0 0 40 40`} className={IconStyle} />

    <div>
      <CircularProgress color="secondary" />
    </div>
  </>
);
