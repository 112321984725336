import { IQueryDefinitionApi } from './IQueryDefinitions';
import { IParameterDescription } from './IOperationDescriptions';

/**
 * Operation states are typically returned by the backend.
 * However, in the front-end they are not only used for operations, but also for enriching: Meshes, Geometries & Variables.
 *
 * For that purposes, meshes can have multiple, distinct operations with the same output id, that do not have matching states.
 * i.e. a CreateMeshOperation & InterpolateOperation have the stats of succeeded and failed, respectively. In this case, we consider the state to be CONFLICTING.
 * i.e. a CreateMeshOperation & ModifyMeshOperation in the same scenario.
 *
 * Important! The following assumption is made: InterpolateOperation & ModifyMeshOperation can only fail if applied on a SUCCEEDED CreateMeshOperation.
 * Subsequently, it is assumed that if a CreateMeshOperation has failed, it is also the latest operation with that outputId.
 *
 * An outdated state means that the item is no longer valid. Eg.A mesh can be outdated if one of the geoemtries for the mesh have changed. O
 * An interpolation can be outdated if some of the variables have changed or if the mesh have changed after applying post operations
 *
 * A conflicting state means we do not know what the overall item state is, so we do not use it.
 * Conflicting states should be appropriately represented in the UI, possibly together with a custom message.
 */
export enum EOperationStates {
  DRAFT = 'Draft',
  PROCESSING = 'Processing',
  PARTIALLYSUCCEEDED = 'PartiallySucceeded',
  FAILED = 'Failed',
  SCHEDULED = 'Scheduled',
  SUCCEEDED = 'Succeeded',
  OUTDATED = 'Outdated',
  CANCELLED = 'Cancelled',

  // Custom, UI-only states
  CONFLICTING = 'Conflicting',
}

export const OPERATION_STATES = {
  DRAFT: EOperationStates.DRAFT,
  PROCESSING: EOperationStates.PROCESSING,
  PARTIALLYSUCCEEDED: EOperationStates.PARTIALLYSUCCEEDED,
  FAILED: EOperationStates.FAILED,
  SCHEDULED: EOperationStates.SCHEDULED,
  SUCCEEDED: EOperationStates.SUCCEEDED,
  OUTDATED: EOperationStates.OUTDATED,

  // Custom, UI-only states
  CONFLICTING: EOperationStates.CONFLICTING,
};

export enum EOperationResultTypes {
  MESH = 'Mesh',
  GEOMETRY = 'Geometry',
  VARIABLE = 'Variable',
}

export const OPERATION_RESULT_TYPES = {
  MESH: EOperationResultTypes.MESH,
  GEOMETRY: EOperationResultTypes.GEOMETRY,
  VARIABLE: EOperationResultTypes.VARIABLE,
};

export enum EOperationDefinitionTypes {
  CREATE_MESH = 'CreateMeshOperationDefinition',
  ELEVATION_INTERPOLATION = 'MeshElevationOperationDefinition', //parent operation for elevation interpolation,
  OTHER_INTERPOLATION = 'MeshInterpolationOperationDefinition', // parent operation for 'other' variable interpolation
}

export const OPERATION_DEFINITION_TYPES = {
  CREATE_MESH: EOperationDefinitionTypes.CREATE_MESH,
  ELEVATION_INTERPOLATION: EOperationDefinitionTypes.ELEVATION_INTERPOLATION,
  OTHER_INTERPOLATION: EOperationDefinitionTypes.OTHER_INTERPOLATION,
};

export interface IOperationMetadata {
  id?: string;
  parentOperationId?: string;
  userId?: string;
  inputIds?: Array<string>;
  outputIds?: Array<string>;
  created?: string;
  updated?: string;
  startExecuting?: string;
  endExecuting?: string;
  state?: EOperationStates;
  latestMessage?: {
    code: string;
    message: string;
  };
  resultType?: EOperationResultTypes;
  operationType?: string;
  isSuperseded?: boolean;
  isSupersededBy?: string; // todo hevo needs alignement with api. Is expected to replace isSuperseded.
}

export interface IOperationConfiguration extends IOperationMetadata {
  type: string;
  operationDefinition?: IOperationDefinition;
  parameterDescriptions?: { [parameterKey: string]: IParameterDescription };
  parameters?: { [parameterKey: string]: string | number | boolean | object }; // todo hevo, replace this by operationDefinition ? - or only keep the parameters!
  childOperations?: Array<IOperationConfiguration>;
  queryDefinition?: IQueryDefinitionApi;
  postOperation?: IOperationConfiguration;
}

export interface IOperationDefinition {
  type: string;
  parameters?: { [parameterKey: string]: string | number | boolean | object }; //todo hevo both here and in IOperationConfiguration - confusing!
  operationResultType?: string;
}

export interface IItemOperationState {
  state?: EOperationStates;
  message?: string;
}
