import React, { useEffect, useState } from 'react';
import { t } from 'src/translations/i18n';

import { Drawer } from '@material-ui/core';
import { MmgProjectCreatePanel } from 'src/projects/project-create-panel';
import { css } from 'emotion';
import { useSelector } from 'react-redux';
import { IGlobalState, store } from 'src/store';
import { useParams } from 'react-router-dom';
import { IWorkspaceRouteParams } from 'src/models/IRoutes';
import PlatformManager from 'src/managers/PlatformManager';
import { routeToPanel } from '../workspace-utils';
import { ROUTES } from 'src/app/routes';
import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles';
import MikeButton from '@mike/mike-shared-frontend/mike-button/MikeButton';

export const ButtonStyle = css`
  padding: 0;
  padding-right: 12;
  border: 2px solid ${mikePalette.secondary.main};
  color: ${mikePalette.background.default};
  &[disabled] {
    color: ${mikePalette.background.paper};
    opacity: 0.5;
    cursor: not-allowed;
    border: 2px solid ${mikePalette.secondary.light};
  }
`;

const FolderCreateDrawerContentStyle = css`
  width: 100%;
  z-index: 1;
  @media (min-width: 460px) {
    width: 400px;
  }
`;

const FolderCreateStyle = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const MmgConnectedCreateFolder = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [, setCreateProjectOpen] = useState(false);

  const { projectId } = useParams<IWorkspaceRouteParams>();
  const tenantId: string = useSelector((state: IGlobalState) => state.UserReducer.user.tenantId);
  const capabilities = useSelector((state: IGlobalState) => state.AppReducer.capabilities);

  const targetProjectId = projectId ? projectId : tenantId;

  const canCreate = capabilities && capabilities.canCreateContent;

  useEffect(
    () => {
      PlatformManager.getTenantCapabilities(targetProjectId).then((resp) => {
        store.dispatch({
          type: 'app/tenant/SET_CAPABILITIES',
          capabilities: resp,
        });
      });
    },
    [targetProjectId],
  );

  const onFolderCreated = (createdProject) => {
    routeToPanel(ROUTES.workspaceList.path, {
      projectId: createdProject.id,
    });
    setCreateProjectOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div className={FolderCreateStyle}>
      <MikeButton className={ButtonStyle} onClick={toggleDrawer} disabled={!canCreate}>
        {t('FOLDER_CREATE_BUTTON')}
      </MikeButton>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <div className={FolderCreateDrawerContentStyle}>
          <MmgProjectCreatePanel
            onProjectCreated={onFolderCreated}
            onPanelExit={toggleDrawer}
            targetProjectId={targetProjectId}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default MmgConnectedCreateFolder;
