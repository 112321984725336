import { put, takeEvery, delay, select, call } from 'redux-saga/effects';
import { t } from 'src/translations/i18n';
import { WorkspaceActionType } from '../store/WokspaceActionType';
import { updateOrAddProgressItem, removeProgressItem } from '../../store/actions/progressItems';
import { IExports, EEXPORT_STATUS, EEXPORT_TYPE } from 'src/models/IExports';
import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { getRouteParams } from './selectors';
import { IResponse } from 'src/models/IResponse';
import * as WorkspaceProxy from 'src/proxies/WorkspaceProxy';

export default function* watchWorkspaceExports() {
  yield takeEvery(WorkspaceActionType.EXPORT_STARTED_OR_COMPLETED, handleExportStartedOrCompleted);
  yield takeEvery(WorkspaceActionType.CREATE_SNAPSHOT, handleExportSnapshot);
}

function* handleExportSnapshot(action) {
  const { isSupportSnapshot } = action;
  const { workspaceId } = yield select(getRouteParams);

  if (workspaceId) {
    try {
      const response: IResponse = yield call(WorkspaceProxy.createWorkspaceSnapshot, workspaceId, isSupportSnapshot);
      if (response.status === 200 || response.status === 202) {
        yield put({
          type: 'toast/ADD/SUCCESS',
          toast: {
            text: t('WORKSPACE_SNAPSHOT_SUBMITTED'),
          },
        });
      }
    } catch (error) {
    } finally {
      if (isSupportSnapshot) {
        yield put({ type: 'workspace/SET_SNAPSHOT_DIALOG_CLOSE' });
      }
    }
  }
}

export function* handleExportStartedOrCompleted(action) {
  const { exports } = action;
  const { id: operationId, status, type } = exports as IExports;

  switch (status) {
    case EEXPORT_STATUS.SCHEDULED: {
      const scheduledItem: IProgressItem = {
        title:
          type === EEXPORT_TYPE.MESH
            ? t('EXPORT_MESHES_SCHEDULED')
            : type === EEXPORT_TYPE.GEOMETRY
              ? t('EXPORT_GEOMETRIES_SCHEDULED')
              : t('EXPORT_VARIABLES_SCHEDULED'),
        id: operationId,
        progressValue: 0,
      };
      yield put(updateOrAddProgressItem(scheduledItem));
      break;
    }
    case EEXPORT_STATUS.PROCESSING: {
      const processingItem: IProgressItem = {
        title:
          type === EEXPORT_TYPE.MESH
            ? t('EXPORT_MESHES_PROCESSING')
            : type === EEXPORT_TYPE.GEOMETRY
              ? t('EXPORT_GEOMETRIES_PROCESSING')
              : t('EXPORT_VARIABLES_PROCESSING'),
        id: operationId,
        progressValue: 50,
      };
      yield put(updateOrAddProgressItem(processingItem));
      break;
    }
    case EEXPORT_STATUS.FAILED: {
      const failedItem: IProgressItem = {
        title:
          type === EEXPORT_TYPE.MESH
            ? t('EXPORT_MESHES_FAILED')
            : type === EEXPORT_TYPE.GEOMETRY
              ? t('EXPORT_GEOMETRIES_FAILED')
              : t('EXPORT_VARIABLES_FAILED'),
        id: operationId,
        progressValue: 0,
        error: true,
      };
      yield put(updateOrAddProgressItem(failedItem));
      yield delay(6000);
      yield put(removeProgressItem(failedItem));
      break;
    }
    case EEXPORT_STATUS.SUCCEEDED: {
      const succeededItem: IProgressItem = {
        title:
          type === EEXPORT_TYPE.MESH
            ? t('EXPORT_MESHES_SUCCESSFUL')
            : type === EEXPORT_TYPE.GEOMETRY
              ? t('EXPORT_MESHES_SUCCESSFUL')
              : t('EXPORT_VARIABLES_SUCCESSFUL'),
        id: operationId,
        progressValue: 100,
        done: true,
      };
      yield put(updateOrAddProgressItem(succeededItem));
      yield delay(6000);
      yield put(removeProgressItem(succeededItem));
      break;
    }
    default:
      console.warn('Unknown itemType', status);
      break;
  }
}
