/**
 * Exposes reselect-selectors methods for meshes.
 *
 * @module WorkspaceMeshSelectors
 * @version 1.0.0
 */

import { createSelector } from 'reselect';
import WorkspaceDataItemUtils from './WorkspaceDataItemUtils';
import WorkspaceDrawnDataUtils from './WorkspaceDrawnDataUtils';
import WorkspaceQueryUtils from './WorkspaceQueryUtils';
import ModelUtils from 'managers/model-utils';
import { IWorkspaceAttribute } from 'models/IWorkspaceAttributes';
import { IWorkspaceAttributeSettings, ATTRIBUTE_SETTINGS } from 'models/IWorkspaceAttributeSettings';
import { IDrawnDataItem } from 'models/IWorkspaceData';
import { IWorkspaceEnrichedMesh, IWorkspaceMesh } from 'models/IMeshes';
import { IWorkspaceQuery } from 'models/IQueries';
import WorkspaceMeshOperationSelectors from 'store/selectors/WorkspaceMeshOperationSelectors';
import { IOperationMetadata } from 'models/IOperations';
import { IGlobalState, store } from '../store';

export interface IMeshId {
  meshId: string;
}

export const getWorkspaceMeshes = (state: IGlobalState): Array<IWorkspaceEnrichedMesh> =>
  state.WorkspaceMeshReducer.workspaceMeshes;

export const simplygetWorkspaceMeshes = () => {
  const state = store.getState();
  return getWorkspaceMeshes(state);
};

const selectedWorkspaceMeshIds = (state: IGlobalState): Array<string> =>
  state.WorkspaceMeshReducer.selectedWorkspaceMeshes;

const workspaceData = (state: IGlobalState): Array<IDrawnDataItem> => state.WorkspaceDataReducer.workspaceData;

const workspaceQueries = (state: IGlobalState): Array<IWorkspaceQuery> => state.WorkspaceQueryReducer.workspaceQueries;

// selects the meshId from props
const meshIdProp = (_state, props: IMeshId) => props.meshId;

/**
 * Enriches workspace meshes with metadata from latest operation
 */
const _getEnrichedWorkspaceMeshes = createSelector(
  [
    WorkspaceMeshOperationSelectors.getLatestCreateMeshOperations,
    WorkspaceMeshOperationSelectors.getMeshOperationsNotSuperseded,
    getWorkspaceMeshes,
  ],
  (
    latestCreateMeshOperations: Array<IOperationMetadata>,
    currentOperations: Array<IOperationMetadata>,
    meshes: Array<IWorkspaceMesh>,
  ): Array<IWorkspaceEnrichedMesh> => {
    return WorkspaceDataItemUtils.getEnrichedMeshItems(latestCreateMeshOperations, currentOperations, meshes) as Array<
      IWorkspaceEnrichedMesh
    >;
  },
);

export const getTiledMeshIds = createSelector([getWorkspaceMeshes], (meshes) => {
  if (!meshes) {
    return [];
  }
  const tiledMeshes = meshes.filter((mesh: IWorkspaceMesh) => mesh.isTiled);
  return tiledMeshes.map((mesh: IWorkspaceMesh) => mesh.id);
});

/**
 * Selector to return sorted enriched workspace meshes.
 * Notice that mesh members will not be sorted.
 *
 * todo hevo Consider turn this around, to sort first - then enrich. Sortorder does not rely on anything from the enrichment
 * also consider not using the list of enriched items unleass needd. The entire list will change whenever just one of the items in the list changes.
 *
 */
const getSortedEnrichedWorkspaceMeshes = createSelector([_getEnrichedWorkspaceMeshes], (meshes) => {
  if (!meshes) {
    return [];
  }

  return ModelUtils.sortMeshes(meshes);
});

/**
 * Selector to return selected enriched workspace meshes.
 * The meshes will be sorted, but the mesh members will not be sorted.
 *todo hevo consider if these always need to be enriched. It might trigger too many re-renders
 */
const getSelectedEnrichedWorkspaceMeshes = createSelector(
  [_getEnrichedWorkspaceMeshes, selectedWorkspaceMeshIds],
  (meshes, selectedIds) => {
    const selectedMeshes = WorkspaceDataItemUtils.getDataItemsByIds(meshes, selectedIds);

    return ModelUtils.sortMeshes(selectedMeshes);
  },
);

/**
 * Selector to return workspace meshes not having the status failed. Will be sorted.
 */
const getWorkspaceMeshesNotFailed = createSelector([_getEnrichedWorkspaceMeshes], (meshes) => {
  if (!meshes) {
    return [];
  }

  const filteredMeshes = WorkspaceDataItemUtils.getItemsNotFailed(meshes);

  return ModelUtils.sortMeshes(filteredMeshes);
});

/**
 * Selector to return the workspace mesh based on the meshId prop.
 */
const _getMesh = createSelector([_getEnrichedWorkspaceMeshes, meshIdProp], (meshes, meshId) => {
  if (!meshes || !meshId) {
    return null;
  }
  return meshes.find(({ id }) => meshId === id) || null;
});

/**
 * Returns an instance of a selector for getting the workspace mesh based on the meshId prop.
 */
const makeGetMesh = () => {
  return createSelector([_getMesh], (mesh) => {
    return mesh;
  });
};

/**
 * Selector to return drawn data for the workspace mesh based on the meshId prop.
 */
const _getMeshDrawnData = createSelector([workspaceData, meshIdProp], (drawnDataItems, meshId) => {
  if (!drawnDataItems || !meshId) {
    return null;
  }

  return drawnDataItems.find(({ id }) => meshId === id) || null;
});

/**
 * Returns an instance of a selector for getting drawn data for the workspace mesh based on the meshId prop.
 */
const makeGetMeshDrawnData = () => {
  return createSelector([_getMeshDrawnData], (drawnData) => {
    return drawnData;
  });
};

/**
 * Selector to return queries for the workspace mesh based on the meshId prop.
 */
const _getMeshQueries = createSelector([workspaceQueries, meshIdProp], (queries: Array<IWorkspaceQuery>, meshId) => {
  if (!queries || !meshId) {
    return null;
  }

  const queriesForMesh = WorkspaceQueryUtils.filterQueriesByTargetItem(queries, meshId);

  if (!queriesForMesh.length) {
    return null;
  }

  return ModelUtils.sortQueries(queriesForMesh);
});

/**
 * Returns an instance of a selector for getting queries for the workspace mesh based on the meshId prop.
 */
const makeGetMeshQueries = () => {
  return createSelector([_getMeshQueries], (queries) => {
    return queries;
  });
};

/**
 * Selector to return mesh attributes of the mesh based on the meshId prop.
 */
const _getMeshAttributes = createSelector([workspaceData, meshIdProp], (drawnDataItems, meshId) => {
  return WorkspaceDrawnDataUtils.getAttributes(meshId, drawnDataItems);
});

/**
 * Returns an instance of a selector for getting mesh attributes of the mesh based on the meshId prop.
 */
const makeGetMeshAttributes = () => {
  return createSelector([_getMeshAttributes], (attributes) => {
    return attributes;
  });
};

/**
 * Selector to return settings for the the mesh based on the meshId prop.
 */
const _getMeshAttributeSettings = createSelector(
  [_getMeshAttributes, meshIdProp],
  (attributes: Array<IWorkspaceAttribute>): Array<IWorkspaceAttributeSettings> => {
    if (!attributes || !attributes.length) {
      return [];
    }

    const attributeSettings = ATTRIBUTE_SETTINGS.filter(
      (setting) => attributes.findIndex((d) => d.name === setting.name) !== -1,
    );

    return attributeSettings;
  },
);

/*
* Returns an instance of a selector for getting mesh attributes settings of the mesh based on the meshId prop.
*/
const makeGetMeshAttributeSettings = () => {
  return createSelector([_getMeshAttributeSettings], (attributeSettings) => {
    return attributeSettings;
  });
};

const self = {
  getWorkspaceMeshes,
  getSortedEnrichedWorkspaceMeshes,
  getSelectedEnrichedWorkspaceMeshes,
  getWorkspaceMeshesNotFailed,
  getTiledMeshIds,
  makeGetMesh,
  makeGetMeshDrawnData,
  makeGetMeshQueries,
  makeGetMeshAttributes,
  makeGetMeshAttributeSettings,

  _getMesh,
  _getMeshDrawnData,
  _getMeshQueries,
  _getMeshAttributes,
  _getMeshAttributeSettings,
  _getEnrichedWorkspaceMeshes,
};

export default self;
