export enum EMapToolActionType {
  VALIDATE_CHANGED_VARIABLE_POINTS = 'maptools/VALIDATE_CHANGED_VARIABLE_POINTS',
  SET_SELECTION_MODE = 'maptools/SET_SELECTION_MODE',
  SET_EDIT_MODE = 'maptools/SET_EDIT_MODE',
  ADD_DRAWN_FEATURES = 'maptools/ADD_DRAWN_FEATURES',
  SET_PROPERTIES_FOR_FEATURES_FOR_EDITING = 'maptools/SET_PROPERTIES_FOR_FEATURES_FOR_EDITING',
  SET_PROPERTIES_FOR_FEATURES_FOR_CREATING = 'maptools/SET_PROPERTIES_FOR_FEATURES_FOR_CREATING',
  UPDATE_PROPERTY_FOR_FEATURES_FOR_EDITING = 'maptools/UPDATE_PROPERTY_FOR_FEATURES_FOR_EDITING',
  STORE_FEATURES_FOR_EDITING = 'maptools/STORE_FEATURES_FOR_EDITING',
  UNDO_FEATURES_FOR_EDITING = 'maptools/UNDO_FEATURES_FOR_EDITING',
  CLEAR_FEATURES_FOR_EDITING = 'maptools/CLEAR_FEATURES_FOR_EDITING',
  CLEAR_SELECTION_FOR_EDITING = 'maptools/CLEAR_SELECTION_FOR_EDITING',
  UPDATE_FEATURES_FOR_EDITING_VALUE = 'maptools/UPDATE_FEATURES_FOR_EDITING_VALUE',
  GET_FEATURES_FOR_EDITING = 'maptools/GET_FEATURES_FOR_EDITING',
  SET_FEATURES_FOR_EDITING = 'maptools/SET_FEATURES_FOR_EDITING',
  SET_GEOPROCESSING_EDITS_TO_APPLY = 'maptools/SET_GEOPROCESSING_EDITS_TO_APPLY',
  LOADING_FEATURES_FOR_EDITING = 'maptools/LOADING_FEATURES_FOR_EDITING',
  LOADING_FEATURES_FOR_EDITING_FAILED = 'maptools/LOADING_FEATURES_FOR_EDITING_FAILED',
  SET_FEATURES_FOR_EDITING_IDS = 'maptools/SET_FEATURES_FOR_EDITING_IDS',
  VARIABLE_EDIT = 'maptools/variable/EDIT',
  VARIABLE_CREATE = 'maptools/variable/CREATE',
  VARIABLE_EDIT_LOAD = 'maptools/variable/EDIT/LOAD',
  VARIABLE_EDIT_LOAD_SUCCESS = 'maptools/variable/EDIT/LOAD_SUCCESS',
  VARIABLE_EDIT_LOAD_FAILED = 'maptools/variable/EDIT/LOAD_FAILED',
  RESET_ALLOWED_TOOLS = 'maptools/RESET_ALLOWED_TOOLS',
  ALLOW_MAPTOOLS = 'maptools/ALLOW/MAPTOOLS',
  DISALLOW_MAPTOOLS = 'maptools/DISALLOW/MAPTOOLS',
  ENABLE_FEATURE_INFO = 'maptools/ENABLE_FEATURE_INFO',
  DISABLE_FEATURE_INFO = 'maptools/DISABLE_FEATURE_INFO',
  ENABLE_POLYGON_DRAWING = 'maptools/ENABLE_POLYGON_DRAWING',
  DISABLE_POLYGON_DRAWING = 'maptools/DISABLE_POLYGON_DRAWING',
  ENABLE_POLYLINE_DRAWING = 'maptools/ENABLE_POLYLINE_DRAWING',
  DISABLE_POLYLINE_DRAWING = 'maptools/DISABLE_POLYLINE_DRAWING',
  ENABLE_POINT_DRAWING = 'maptools/ENABLE_POINT_DRAWING',
  DISABLE_POINT_DRAWING = 'maptools/DISABLE_POINT_DRAWING',
  ENABLE_CIRCLE_DRAWING = 'maptools/ENABLE_CIRCLE_DRAWING',
  DISABLE_CIRCLE_DRAWING = 'maptools/DISABLE_CIRCLE_DRAWING',
  ENABLE_BOX_DRAWING = 'maptools/ENABLE_BOX_DRAWING',
  DISABLE_BOX_DRAWING = 'maptools/DISABLE_BOX_DRAWING',
  DISABLE_ALL_DRAWING_TOOLS = 'maptools/DISABLE_ALL_DRAWING_TOOLS',
  VARIABLES_UNLOAD = 'maptools/variable/UNLOAD',
  GEOMETRY_UNLOAD = 'maptools/geometry/UNLOAD',
  GEOMETRY_EDIT_LOAD_SUCCESS = 'maptools/geometry/EDIT/LOAD_SUCCESS',
  GEOMETRY_EDIT_LOAD_FAILED = 'maptools/geometry/EDIT/LOAD_FAILED',
  GEOMETRY_EDIT_LOAD = 'maptools/geometry/EDIT/LOAD',
  GEOMETRY_EDIT = 'maptools/geometry/EDIT',
  SET_DRAW_TYPE = 'maptools/SET_DRAW_TYPE',
  GEOMETRY_CREATE = 'maptools/geometry/CREATE',
  GEOMETRY_SET_SPATIAL_SELECTION_TARGET = 'maptools/geometry/SET_SPATIAL_SELECTION_TARGET',
  GEOMETRY_ALLOW_SPATIAL_SELECTION = 'maptools/geometry/ALLOW/SPATIAL_SELECTION',
  ENABLE_ADD_COMMENT = 'maptools/ENABLE_ADD_COMMENT',
  DISABLE_ADD_COMMENT = 'maptools/DISABLE_ADD_COMMENT',
  ENABLE_LINE_MEASUREMENT = 'maptools/ENABLE_LINE_MEASUREMENT',
  DISABLE_LINE_MEASUREMENT = 'maptools/DISABLE_LINE_MEASUREMENT',
  ENABLE_AREA_MEASUREMENT = 'maptools/ENABLE_AREA_MEASUREMENT',
  DISABLE_AREA_MEASUREMENT = 'maptools/DISABLE_AREA_MEASUREMENT',
  ENABLE_VARIABLE_DRAWING = 'maptools/ENABLE_VARIABLE_DRAWING',
  ENABLE_VARIABLE_PROPERTY_EDITING = 'maptools/ENABLE_VARIABLE_PROPERTY_EDITING',
  DISABLE_VARIABLE_DRAWING = 'maptools/DISABLE_VARIABLE_DRAWING',
  SET_OPEN_SUBMENU = 'maptools/SET_OPEN_SUBMENU',
  CLEAR_OPEN_SUBMENU = 'maptools/CLEAR_OPEN_SUBMENU',
  ALLOW_EXTRACT_FEATURES = 'maptools/ALLOW/EXTRACT_FEATURES',
  DISALLOW_EXTRACT_FEATURES = 'maptools/DISALLOW/EXTRACT_FEATURES',
  ALLOW_GEOMETRY_DRAWING = 'maptools/ALLOW/GEOMETRY_DRAWING',
  ALLOW_FEATURE_INFO = 'maptools/ALLOW/FEATURE_INFO',
  ALLOW_ADD_COMMENT = 'maptools/ALLOW/ADD_COMMENT',
  ALLOW_VARIABLE_DRAWING = 'maptools/ALLOW/VARIABLE_DRAWING',
  ALLOW_VARIABLE_PROPERTY_EDITING = 'maptools/ALLOW/VARIABLE_PROPERTY_EDITING',
  DISALLOW_VARIABLE_PROPERTY_EDITING = 'maptools/DISALLOW/VARIABLE_PROPERTY_EDITING',
  DISABLE_VARIABLE_PROPERTY_EDITING = 'maptools/DISABLE_VARIABLE_PROPERTY_EDITING',
  SET_MAPDATA_TYPE = 'maptools/SET_MAPDATA_TYPE',
  SET_VIEWER_MODE_TO_2D = 'maptools/SET_VIEWER_MODE_TO_2D',
  SET_VIEWER_MODE_TO_3D = 'maptools/SET_VIEWER_MODE_TO_3D',
  SET_VIEWER_MODE = 'maptools/SET_VIEWER_MODE',
  RESET_VIEW = 'maptools/RESET_VIEW',
  SET_VIEWER_MODE_USER_SETTINGS = 'maptools/SET_VIEWER_MODE_USER_SETTINGS',

  // Unique values from EViewerModeActionType:
  SET_GRIDOVERLAY = 'maptools/SET_GRIDOVERLAY',
  SET_VIEWER_BASEMAP_SUPPORTED = 'maptools/SET_VIEWER_BASEMAP_SUPPORTED',
  SET_BASEMAP_TO_DEFAULT = 'maptools/SET_BASEMAP_TO_DEFAULT',
  SET_BASEMAP = 'maptools/SET_BASEMAP',
  ENABLE_3D_MAP_DATA = 'maptools/ENABLE_3D_MAP_DATA',
  ENABLE_3D_EXAGGARATION = 'maptools/ENABLE_3D_EXAGGARATION',
}
