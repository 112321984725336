/**
 * Exposes methods to handle mesh related requests.
 *
 * @module WorkspaceMeshesManager
 * @version 2.0.0
 * @requires WorkspaceMeshesProxy
 */
import * as WorkspaceMeshesProxy from 'proxies/WorkspaceMeshesProxy';
import MikeVisualizerUtils from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerUtil';

import { HttpErrorHandler } from './http-utils';
import { EMeshFormats } from 'models/IWorkspaceData';

/**
 * Transforms a request that creates a new mesh metadata object.
 * Operations can have this as input/output in order to perform actual meshing.
 *
 * @param workspaceId
 * @param meshName
 */
export const createMesh = (workspaceId: string, meshName?: string) => {
  const meshPayload = {
    name: meshName,
  };

  return WorkspaceMeshesProxy.createMesh(workspaceId, meshPayload)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to create mesh.', error));
};

/**
 * Transforms a request that creates a new mesh based by cloning the mesh given.
 *
 * @param workspaceId
 * @param meshId
 */
export const duplicateMesh = (workspaceId: string, meshId: string) => {
  return WorkspaceMeshesProxy.duplicateMesh(workspaceId, meshId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to duplicate mesh.', error));
};

/**
 * Transforms a request that duplicates a list of meshes.
 *
 * @param workspaceId
 * @param meshIds
 */
export const batchDuplicateMeshes = (workspaceId: string, meshIds: Array<string>) => {
  return Promise.all(meshIds.map((meshId) => self.duplicateMesh(workspaceId, meshId))).catch((error) =>
    HttpErrorHandler('Failed to batch duplicate meshes.', error),
  );
};

/**
 * Transforms a request that renames a mesh.
 *
 * @param workspaceId
 * @param meshId
 * @param meshName
 */
export const renameMesh = (workspaceId: string, meshId: string, meshName: string) => {
  return WorkspaceMeshesProxy.updateMesh(workspaceId, meshId, {
    id: meshId,
    name: meshName,
  })
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to rename mesh.', error));
};

/**
 * Transforms a request that deletes a list of meshes.
 *
 * @param workspaceId
 * @param meshIds
 */
export const deleteMeshes = (workspaceId: string, meshIds: Array<string>) => {
  if (!meshIds || meshIds.length < 1) {
    return Promise.resolve();
  }
  return WorkspaceMeshesProxy.deleteMeshes(workspaceId, meshIds)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to delete meshes.', error)) as Promise<void>;
};

/**
 * Transforms a request that gets a list of mesh ids that allows export to the platform.
 *
 * @param workspaceId
 */
export const getExportableMeshes = (workspaceId: string): Promise<Array<string> | void> => {
  return WorkspaceMeshesProxy.getExportableMeshes(workspaceId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get exportable meshes.', error));
};

/**
 * Transforms a request that exports a list of meshes.
 *
 * @param workspaceId
 * @param meshIds
 * @param formatType
 */
export const exportMeshes = (workspaceId: string, meshIds: Array<string>, formatType: EMeshFormats) => {
  if (!meshIds || meshIds.length < 1) {
    return Promise.resolve();
  }
  return WorkspaceMeshesProxy.exportMeshes(workspaceId, meshIds, formatType)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to export meshes.', error)) as Promise<void>;
};

/**
 * Transforms a request that gets mesh attribute values based on data loaded in the viewer.
 * Because of loading the attributes from data in the 3D viewer, attributes for meshes are not available when the 2D viewer is used.
 *
 * This is different from attributes on geometries, that are loaded by an api call. We might want to change either one of the two to be consistent.
 *
 * @param _workspaceId
 * @param _meshId
 * @param attributeName
 */
export const getMeshAttributeValues = (
  _workspaceId: string,
  _meshId: string,
  attributeName: string,
): Promise<Array<string | number>> => {
  const values = MikeVisualizerUtils.getDataValues(_meshId, attributeName);

  return Promise.resolve(values);
};

/**
 * For a given geometry, gets all meshes that this geometry is currently part of.
 *
 * @param workspaceId
 * @param geometryId
 */
export const getGeometryCurrentMeshIds = (workspaceId: string, geometryId: string): Promise<Array<string>> =>
  WorkspaceMeshesProxy.getGeometryCurrentMeshIds(workspaceId, geometryId)
    .then((res) => res.data)
    .catch((error) => HttpErrorHandler('Failed to get geometry current meshes.', error));

const self = {
  createMesh,
  duplicateMesh,
  batchDuplicateMeshes,
  renameMesh,
  deleteMeshes,
  getMeshAttributeValues,
  exportMeshes,
  getExportableMeshes,
  getGeometryCurrentMeshIds,
};

export default self;
