import { IWorkspaceQuery } from 'models/IQueries';

/**
 * Returns the queries having the itemId in the list of targetItems. I no queries are found an empty array is returned.
 * @param queries
 * @param itemId
 */
export const filterQueriesByTargetItem = (queries: Array<IWorkspaceQuery>, itemId: string) => {
  if (!queries) {
    return queries;
  }

  if (!itemId) {
    return [];
  }

  const filteredQueries = queries.filter(
    ({ queryDefinition }) => (queryDefinition.targetItems || []).indexOf(itemId) !== -1,
  );

  return filteredQueries;
};
/**
 * Returns the quries having at least one of the itemIds in the list of targetItems. I no queries are found an empty array is returned.
 * @param queries
 * @param itemIds
 */
export const filterQueriesByTargetItems = (queries: Array<IWorkspaceQuery>, itemIds: Array<string>) => {
  if (!queries) {
    return queries;
  }

  if (!itemIds || itemIds.length === 0) {
    return [];
  }

  const filteredQueries = queries.filter(({ queryDefinition }) => {
    const { targetItems } = queryDefinition;

    return (targetItems || []).filter((targetId) => itemIds.indexOf(targetId) !== -1).length !== 0;
  });

  return filteredQueries;
};

const self = {
  filterQueriesByTargetItem,
  filterQueriesByTargetItems,
};

export default self;
