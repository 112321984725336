import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'src/translations/i18n';
import { css } from 'emotion';
import { Typography } from '@material-ui/core';
import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import BreadcrumbUtils from 'src/app/topbar/breadcrumb-utils';
import { ReactComponent as Left } from '@mike/mike-shared-frontend/media/icons/Left';
import { IGlobalState, store } from 'store/store';
import { Link, useRouteMatch } from 'react-router-dom';
import { AuditCompareRouteParams } from 'src/app/topbar/topbar';
import { getRouteByPath, ROUTES } from 'src/app/routes';
import { history } from 'src';
import MmgWorkspaceEdit from './workspace-edit';
import { MmgPanelSubsection } from 'src/shared/panels/panel-subsection';
import { theme } from 'src/shared/styles/theme';
import { MmgMetas } from 'src/shared/metas/metas';
import { WorkspaceActionType } from '../store/WokspaceActionType';

const WorkspaceEditPageLinkContainerStyle = css`
  padding: ${theme.spacing(2)}px;
`;

const WorkspaceEditPageLinkStyle = css`
  color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
`;

const WorkspaceEditPageContainerStyle = css`
  width: 30vw;
  padding-top: ${theme.spacing(3)}px;
`;

/**
 * @name MmgConnectedWorkspaceEditPage
 * @summary Connected workspace page - allows editing workspace datasets
 *
 */
const MmgConnectedWorkspaceEditPage = () => {
  const match = useRouteMatch<AuditCompareRouteParams>({
    path: '/project/:id/workspace/:workspaceId',
    strict: true,
    sensitive: true,
  });
  const currentProjectId = match && match.params.id;
  const currentWorkspaceId = match && match.params.workspaceId;

  const { project } = useSelector((state: IGlobalState) => state.ProjectReducer);
  const { workspace } = useSelector((state: IGlobalState) => state.WorkspaceReducer);

  // on change of projectId and workspaceId load workspace metadata and update breadcrumps
  useEffect(
    () => {
      if (currentProjectId && currentWorkspaceId) {
        const data = { pId: currentProjectId, wsId: currentWorkspaceId };
        store.dispatch({ type: WorkspaceActionType.WORKSPACE_LOAD_METADATA, data });
      }
    },
    [currentProjectId, currentWorkspaceId],
  );

  const setBreadcrumb = () => {
    if (!workspace) {
      return;
    }

    const crumbs = [
      BreadcrumbUtils.getHomeCrumb(),
      BreadcrumbUtils.getProjectCrumb(currentProjectId, getProjectName()),
      BreadcrumbUtils.getWorkspaceCrumb(currentWorkspaceId, currentProjectId, getWorkspaceName()),
    ];

    store.dispatch({
      type: 'app/breadcrumbs/SET',
      breadcrumbs: crumbs,
    });
  };

  const getProjectName = () => {
    if (project && project.id === currentProjectId) {
      return project.name;
    }

    // we do not know the name yet
    return '';
  };

  const getWorkspaceName = () => {
    if (workspace && workspace.id === currentWorkspaceId) {
      return workspace.name;
    }
    // we do not know the name yet
    return '';
  };

  const onWorkspaceUpdated = () => {
    setBreadcrumb();
  };

  const onWorkspaceDeleted = (projectId) => {
    const workspaceListRoutePath = getRouteByPath(ROUTES.workspaceList.path, {
      projectId,
    });
    history.push(workspaceListRoutePath);
  };

  return (
    <>
      <MmgMetas
        metaTitle={workspace ? t('EDITING', 1, { thing: workspace.name }) : t('WORKSPACE')}
        metaDescription={workspace ? workspace.description : ''}
      />
      <div className={WorkspaceEditPageLinkContainerStyle}>
        <Link className={WorkspaceEditPageLinkStyle} to={'/project/' + currentProjectId + '/workspace-list'}>
          <Left />
          <Typography> {t('BACK_TO_WORKSPACES')}</Typography>
        </Link>
      </div>
      <div className={WorkspaceEditPageContainerStyle}>
        <MmgPanelSubsection>
          <Typography variant={'h3'}>
            {t('EDITING', 1, { thing: t('WORKSPACE') })}
            <b>{workspace ? ' ' + workspace.name : ''}</b>
          </Typography>
        </MmgPanelSubsection>
        <MmgWorkspaceEdit onWorkspaceUpdated={onWorkspaceUpdated} onWorkspaceDeleted={onWorkspaceDeleted} />
      </div>
    </>
  );
};
export default MmgConnectedWorkspaceEditPage;
