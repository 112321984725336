import tinycolor from 'tinycolor2';
import { values } from 'lodash-es';
import { IWorkspaceBounds } from 'models/IWorkspaces';
import { DEFAULTS as SHAREDDEFAULTS } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConfiguration';
import { IConfiguration } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/models/IConfiguration';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { FeatureCollection } from 'geojson';
import { FONTFAMILY } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme';
import { MIKE_COLORS, MIKE_MAP_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { theme } from 'src/shared/styles/theme';
import { INotificationLabel } from 'src/models/ILabelNotification';
import { getConfiguration } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConfiguration';
import MultiPoint from 'ol/geom/MultiPoint.js';
import { Fill, Stroke, Circle as CircleStyle, Style, RegularShape, Text } from 'ol/style';
import GeometryType from 'ol/geom/GeometryType';
import RenderFeature from 'ol/render/Feature';
import Geometry from 'ol/geom/Geometry';
import { Polygon as OlPolygon, Point as OlPoint } from 'ol/geom';
import LineString from 'ol/geom/LineString';
export const LABELFONTSIZE = '0.8rem';

export interface ILabel {
  xCoord: number;
  yCoord: number;
  labelName: string;
  labelId: string;
}

export interface IGeometryLabel extends INotificationLabel {
  parent: string;
  attributeName: string;
}

const { drawColors, ol } = getConfiguration();

export const editStyleFunction = (feature) => {
  if (feature && feature.values_ && feature.values_.geometry && feature.values_.geometry.getType() === 'Point') {
    return [
      new Style({
        image: new RegularShape({
          fill: new Fill({
            color: drawColors.primary,
          }),
          points: ol.pointNumber,
          radius: ol.pointRadius,
          angle: ol.pointAngle,
        }),
        fill: new Fill({
          color: drawColors.bright,
        }),
      }),
    ];
  }

  const defaultStyle = new Style({
    fill: new Fill({
      color: drawColors.background,
    }),
    stroke: new Stroke({
      color: drawColors.bright,
      width: ol.strokeWidth,
    }),
  });

  const vertexStyle = new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: 'white',
      }),
      stroke: new Stroke({
        color: drawColors.bright,
        width: 2,
      }),
    }),
    geometry: () => {
      const geom = feature.getGeometry();
      if (geom instanceof RenderFeature || geom === undefined) {
        console.debug(`Geometry not valid`, geom);
        return;
      }
      const geomType = (geom as Geometry).getType();
      if (geomType === GeometryType.LINE_STRING) {
        const coords = (geom as LineString).getCoordinates();
        return new MultiPoint(coords);
      }
      if (geomType === GeometryType.POLYGON) {
        const coords = (geom as OlPolygon).getCoordinates();
        return new MultiPoint(coords[0]);
      }
      if (geomType === GeometryType.CIRCLE) {
        // Currently do nothing
        return;
      }
      if (geomType === GeometryType.POINT) {
        const coords = (geom as OlPoint).getCoordinates();
        return new OlPoint(coords);
      }
      console.debug(`No match for geometry type "${geomType}"`);
      return;
    },
  });
  return [defaultStyle, vertexStyle];
};

export const getSelectionLabelStyle = (labelName: string, parentId?: string) => {
  const labelStyle = () => {
    let colorToApply = MIKE_MAP_COLORS.PLUM;
    if (parentId) {
      const { getState } = MikeVisualizerLib;
      const { renderedElements } = getState();
      const element = renderedElements.find((p) => p.id === parentId);
      if (element && element.surfaceColor) {
        const c = element.surfaceColor;
        colorToApply = tinycolor.fromRatio({ r: c[0], g: c[1], b: c[2] }).toHexString();
      }
    }

    const p = theme.spacing(0.5);
    return [
      new Style({
        text: new Text({
          text: labelName,
          fill: new Fill({ color: colorToApply }),
          font: `${LABELFONTSIZE} ${FONTFAMILY}`,
          backgroundStroke: new Stroke({ color: colorToApply }),
          backgroundFill: new Fill({ color: MIKE_COLORS.WHITE }),
          padding: [p, p, p, p],
        }),
      }),
    ];
  };
  return labelStyle;
};

export const getGeometryLabelStyle = (parentId: string) => {
  const labelStyle = (feature) => {
    const labelText = feature && feature.values_ && feature.values_.name ? feature.values_.name : '';
    let colorToApply = MIKE_MAP_COLORS.PLUM;
    if (parentId) {
      const { getState } = MikeVisualizerLib;
      const { renderedElements } = getState();
      const element = renderedElements.find((p) => p.id === parentId);
      if (element && element.surfaceColor) {
        const c = element.surfaceColor;
        colorToApply = tinycolor.fromRatio({ r: c[0], g: c[1], b: c[2] }).toHexString();
      }
    }
    const p = theme.spacing(0.5);
    return [
      new Style({
        text: new Text({
          text: labelText,
          fill: new Fill({ color: MIKE_COLORS.WHITE }),
          font: `${LABELFONTSIZE} ${FONTFAMILY}`,
          backgroundFill: new Fill({ color: colorToApply }),
          padding: [p, p, p, p],
        }),
      }),
    ];
  };
  return labelStyle;
};

export const addLabel = (labelName: string, labelId: string, xCoord: number, yCoord: number, style: Style) => {
  const { append2DData } = MikeVisualizerLib;
  const labelFeatureCollection: FeatureCollection<any, any> = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        id: labelId,
        properties: {
          id: labelId,
        },
        geometry: {
          type: 'Point',
          coordinates: [xCoord, yCoord],
        },
      },
    ],
  };
  const appended2D = append2DData(labelFeatureCollection, labelId, null, null, 1, style);
  if (appended2D) {
    const label: ILabel = {
      xCoord,
      yCoord,
      labelName,
      labelId,
    };
    return label;
  }
};

export const addLabels = (labelLayerId: string, labels: Array<INotificationLabel>, style: Style) => {
  const { append2DData } = MikeVisualizerLib;

  const labelFeatureCollection: FeatureCollection<any, any> = {
    type: 'FeatureCollection',
    features: labels.map((l: INotificationLabel) => {
      return {
        type: 'Feature',
        id: `${labelLayerId}_${l.value}`,
        properties: {
          id: `${labelLayerId}_${l.value}_prop`,
          name: l.value,
        },
        geometry: {
          type: 'Point',
          coordinates: [l.x, l.y],
        },
      };
    }),
  };
  const appendedLayer = append2DData(labelFeatureCollection, labelLayerId, null, null, 1, style);
  return appendedLayer;
};

export const deleteLabels = (labelsId: string) => {
  const { delete2DData } = MikeVisualizerLib;
  delete2DData(labelsId);
};

export interface IMapColorConfiguration extends IConfiguration {
  colorsAdaptedToBaseMap: {
    defaultGeometryLight: {
      edge: Array<number>;
      surface: Array<number>;
    };
    defaultMeshLight: {
      edge: Array<number>;
      surface: Array<number>;
    };
    defaultGeometryDark: {
      edge: Array<number>;
      surface: Array<number>;
    };
    defaultMeshDark: {
      edge: Array<number>;
      surface: Array<number>;
    };
    highlightSelection: {
      edge: Array<number>;
      surface: Array<number>;
    };
    selectionResultColor: {
      edge: Array<number>;
      surface: Array<number>;
    };
    selectionResultHighlightColor: {
      edge: Array<number>;
      surface: Array<number>;
    };
    selectionSourceColor: {
      edge: Array<number>;
      surface: Array<number>;
    };
  };
}

/**
 * Returnes true if x,y-bounds are defined, otherwise false
 * @param bounds
 */
export const areXYBoundsDefined = (bounds: IWorkspaceBounds) => {
  if (!bounds) {
    return false;
  }

  // If bounds are not calculated, api sets min to Double.MAX and max to Double.MIN

  const UNKNOWN_MIN = 1.7976931348623157e308;
  const UNKNOWN_MAX = -1.7976931348623157e308;

  const [xMin, xMax, yMin, yMax] = bounds;
  if (xMin === UNKNOWN_MIN && xMax === UNKNOWN_MAX && yMin === UNKNOWN_MIN && yMax === UNKNOWN_MAX) {
    return false;
  }

  return true;
};

/**
 * Return [r, g, b] values for the provided color.
 *
 * @param color
 */
const _getRgbArray = (color: string): Array<number> => {
  const rgb = tinycolor(color).toRgb();
  const res = values(rgb)
    .slice(0, 3)
    .map((val) => val / 255);
  return res;
};

export const MB_MAP_COLORS = {
  COBALT: _getRgbArray('#001BCB'),
  BRANDBLUE_DEFAULT: _getRgbArray(MIKE_COLORS.BRANDBLUE_DEFAULT),
  YELLOW: _getRgbArray('#FFFF00'),
  APRICOT: _getRgbArray('#F9BA32'),
  LIME: _getRgbArray('#B9D400'),
  MUSTARD: _getRgbArray('#817200'),
  LEMONGREEN: _getRgbArray('#E8FD97'),
  POPPYGREEN: _getRgbArray('#22EE5B'),
  OLIVE: _getRgbArray('#0D6D27'),
  PASTELGREEN: _getRgbArray('#B7F9A2'),
  LILAC: _getRgbArray('#AB8BE5'),
  ROYALPURPLE: _getRgbArray('#6046A4'),
  LAVENDER: _getRgbArray('#E7D4FC'),
  BROWNIE: _getRgbArray('#7D4C05'),
  CHOCOLATE: _getRgbArray('#4A1A00'),
  LATTE: _getRgbArray('#D2A16D'),
  GRAPHITE: _getRgbArray('#6D8390'),
  IRON: _getRgbArray('#424C53'),
  RHINO: _getRgbArray('#D7D7D7'),
  PINK: _getRgbArray(MIKE_COLORS.PINK_DEFAULT),
  BURGUNDYPLUM: _getRgbArray('#A9034B'),
  PERSIANGREEN: _getRgbArray('#00B283'),
  TURQUOISE: _getRgbArray('#00FFE1'),
  PASTELBLUE: _getRgbArray('#00A4EC'),
  ORANGE: _getRgbArray('#FF8A00'),
};

export const LIGHTMAP_COLORS = [
  MB_MAP_COLORS.COBALT,
  MB_MAP_COLORS.APRICOT,
  MB_MAP_COLORS.LIME,
  MB_MAP_COLORS.POPPYGREEN,
  MB_MAP_COLORS.LILAC,
  MB_MAP_COLORS.BROWNIE,
  MB_MAP_COLORS.GRAPHITE,
];

export const DARKMAP_COLORS = [
  MB_MAP_COLORS.PASTELBLUE,
  MB_MAP_COLORS.YELLOW,
  MB_MAP_COLORS.LEMONGREEN,
  MB_MAP_COLORS.PASTELGREEN,
  MB_MAP_COLORS.LAVENDER,
  MB_MAP_COLORS.LATTE,
  MB_MAP_COLORS.RHINO,
];

export const LIGHTMAP_HIGHLIGHT_COLORS = [
  MB_MAP_COLORS.BRANDBLUE_DEFAULT,
  MB_MAP_COLORS.ORANGE,
  MB_MAP_COLORS.MUSTARD,
  MB_MAP_COLORS.OLIVE,
  MB_MAP_COLORS.ROYALPURPLE,
  MB_MAP_COLORS.CHOCOLATE,
  MB_MAP_COLORS.IRON,
];

export const DARKMAP_HIGHLIGHT_COLORS = [
  MB_MAP_COLORS.COBALT,
  MB_MAP_COLORS.APRICOT,
  MB_MAP_COLORS.LIME,
  MB_MAP_COLORS.POPPYGREEN,
  MB_MAP_COLORS.LILAC,
  MB_MAP_COLORS.BROWNIE,
  MB_MAP_COLORS.GRAPHITE,
];

export const CUSTOMSELECTIONCOLOR: IConfiguration = {
  ...SHAREDDEFAULTS,
  colors: {
    ...SHAREDDEFAULTS.colors,
    select: {
      edge: [...MB_MAP_COLORS.PINK, 1],
      surface: [...MB_MAP_COLORS.PINK, 0.9],
    },
  },
};

export const DEFAULTS: IMapColorConfiguration = {
  ...SHAREDDEFAULTS,
  colorsAdaptedToBaseMap: {
    defaultGeometryLight: {
      edge: [...MB_MAP_COLORS.COBALT, 1], // Geometries currently don't ave an edge representation
      surface: [...MB_MAP_COLORS.COBALT, 1],
    },
    defaultMeshLight: {
      edge: [...MB_MAP_COLORS.COBALT, 1],
      surface: [...MB_MAP_COLORS.APRICOT, 0.9],
    },
    defaultGeometryDark: {
      edge: [...MB_MAP_COLORS.PASTELBLUE, 1], // Geometries currently don't ave an edge representation
      surface: [...MB_MAP_COLORS.PASTELBLUE, 1],
    },
    defaultMeshDark: {
      edge: [...MB_MAP_COLORS.PASTELBLUE, 1],
      surface: [...MB_MAP_COLORS.YELLOW, 0.9],
    },
    highlightSelection: {
      edge: [...MB_MAP_COLORS.BURGUNDYPLUM, 1],
      surface: [...MB_MAP_COLORS.BURGUNDYPLUM, 0.9],
    },
    selectionResultColor: {
      edge: [0, 0, 0, 0.9], // dummy color; not rendered
      surface: [...MB_MAP_COLORS.TURQUOISE, 0.9],
    },
    selectionResultHighlightColor: {
      edge: [0, 0, 0, 0.9], // dummy color; not rendered
      surface: [...MB_MAP_COLORS.PERSIANGREEN, 0.9],
    },
    selectionSourceColor: {
      edge: [...MB_MAP_COLORS.YELLOW, 0.5],
      surface: [...MB_MAP_COLORS.YELLOW, 0.5],
    },
  },
};

/**
 * Return adapted [r, g, b] values for the provided rbgColor.
 *
 * @param rbgColor
 * @param toLightBackgroundMap
 */
export const _getRgbColorAdaptedToBaseMap = (rbgColor: Array<number>, toLightBackgroundMap: boolean): Array<number> => {
  const colorIndex = toLightBackgroundMap
    ? DARKMAP_COLORS.findIndex(
        (color) => color[0] === rbgColor[0] && color[1] === rbgColor[1] && color[2] === rbgColor[2],
      )
    : LIGHTMAP_COLORS.findIndex(
        (color) => color[0] === rbgColor[0] && color[1] === rbgColor[1] && color[2] === rbgColor[2],
      );
  return colorIndex === -1
    ? rbgColor
    : toLightBackgroundMap
      ? [...LIGHTMAP_COLORS[colorIndex], rbgColor[3]]
      : [...DARKMAP_COLORS[colorIndex], rbgColor[3]];
};

/**
 * Return adapted [r, g, b] values for the provided rbgColor.
 *
 * @param rbgColor
 * @param isLightBackgroundMap
 */
export const _getRgbHighlightColorAdaptedToBaseMap = (
  rbgColor: Array<number>,
  isLightBackgroundMap: boolean,
): Array<number> => {
  if (!rbgColor) {
    return null;
  }
  const colorIndex = isLightBackgroundMap
    ? LIGHTMAP_COLORS.findIndex(
        (color) => color[0] === rbgColor[0] && color[1] === rbgColor[1] && color[2] === rbgColor[2],
      )
    : DARKMAP_COLORS.findIndex(
        (color) => color[0] === rbgColor[0] && color[1] === rbgColor[1] && color[2] === rbgColor[2],
      );
  return colorIndex === -1
    ? rbgColor
    : isLightBackgroundMap
      ? [...LIGHTMAP_HIGHLIGHT_COLORS[colorIndex], rbgColor[3]]
      : [...DARKMAP_HIGHLIGHT_COLORS[colorIndex], rbgColor[3]];
};
