/**
 * Exposes workspace REST endpoints to managers.
 *
 * @module WorkspaceProxy
 * @version 2.0.0
 * @requires Proxy
 */
import { endpoints, paths } from './config';
import Proxy from './Proxy';
import { IWorkspace } from 'models/IWorkspaces';
import { AxiosPromise } from 'axios';
import { IUserSettings } from 'src/models/IUserSettings';
const { workspaces, replaceItem, withId } = paths;

const { meshAppService } = endpoints;

/**
 * Get user settings for a workspace.
 *
 * @param workspaceId
 */
export const getWorkspaceUserSettings = (workspaceId: string) => {
  return Proxy.http.get(`${meshAppService}/${workspaces}/userSettings?id=${workspaceId}`);
};

/**
 * Save user settings for a workspace.
 *
 * @param workspaceId
 * @param settings
 */
export const saveWorkspaceUserSettings = (workspaceId: string, settings: IUserSettings) => {
  return Proxy.http.post(`${meshAppService}/${workspaces}/userSettings?id=${workspaceId}`, settings);
};

/**
 * Get a list of all workspace overviews for a project.
 *
 * @param projectId
 * @param includeScreenshots
 */
export const getWorkspaceOverviewList = (projectId: string, includeScreenshots = false) => {
  return Proxy.http.get(
    `${meshAppService}/${workspaces}/overview?projectId=${projectId}&includeScreenshots=${includeScreenshots}`,
  );
};

/**
 * Export workspace as snapshot.
 *
 * @param workspaceId
 * @param isSupportSnapshot
 */
export const createWorkspaceSnapshot = (workspaceId: string, isSupportSnapshot = true) => {
  return Proxy.http.post(
    `${meshAppService}/${workspaces}/createSnapshot/${workspaceId}/targetSupport/${isSupportSnapshot}`,
  );
};

/**
 * Get a workspace overview.
 *
 * @param workspaceId
 * @param includeScreenshots
 */
export const getWorkspaceOverview = (workspaceId: string, includeScreenshots = false) => {
  return Proxy.http.get(
    `${meshAppService}/${workspaces}/${workspaceId}/overview?includeScreenshot=${includeScreenshots}`,
  );
};

/**
 * Get a workspace.
 *
 * @param workspaceId
 */
export const getWorkspace = (workspaceId: string) => {
  return Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}`);
};

/**
 * Create a workspace.
 *
 * @param workspace
 */
export const createWorkspace = (workspace: IWorkspace) => {
  return Proxy.http.post(`${meshAppService}/${workspaces}`, workspace);
};

/**
 * Update a workspace from a project.
 *
 * @param workspaceId
 * @param workspace
 */
export const updateWorkspace = (workspaceId: string, workspace: IWorkspace) =>
  Proxy.http.patch(`${meshAppService}/${workspaces}/${workspaceId}`, workspace);

/**
 * Update a workspace item from a dataset.
 *
 * @param workspaceId
 * @param itemId
 * @param datasetId
 */
export const updateWorkspaceItem = (workspaceId: string, itemId: string, datasetId: string) =>
  Proxy.http.patch(
    `${meshAppService}/${workspaces}/${workspaceId}/${replaceItem}/${itemId}/${withId}/${datasetId}`,
    {},
  );

/**
 * Delete a workspace from a project.
 *
 * @param workspaceId
 */
export const deleteWorkspace = (workspaceId: string) =>
  Proxy.http.delete(`${meshAppService}/${workspaces}/${workspaceId}`);

/**
 * Check if a workspace exists.
 *
 * @param workspaceId
 */
export const doesWorkspaceExist = (workspaceId: string) => {
  return Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/exists`) as AxiosPromise<boolean>;
};

/**
 * Check if an item exists within a workspace.
 *
 * @param workspaceId
 * @param itemId
 */
export const doesWorkspaceItemExist = (workspaceId: string, itemId: string) => {
  return Proxy.http.get(`${meshAppService}/${workspaces}/${workspaceId}/itemExists/${itemId}`) as AxiosPromise<boolean>;
};
