import { call, put, select, takeEvery } from 'redux-saga/effects';
import { EWorkspaceQueryActionType } from 'src/store/actions/WorkspaceQueryActionType';
import { getSelectionResultIds, getRouteParams, getSelectionGeometryIds, getShownSelectionIds } from './selectors';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import * as WorkspaceQueryProxy from 'proxies/WorkspaceQueryProxy';
import { t } from 'src/translations/i18n';
import { getSelectionGeometryId, getSelectionLabelId } from 'src/store/reducers/WorkspaceQueryReducer';

export default function* watchWorkspaceQuery() {
  yield takeEvery(EWorkspaceQueryActionType.SELECTION_RESULT_SHOW, handleShowSelectionResult);
  yield takeEvery(EWorkspaceQueryActionType.SELECTION_RESULT_AND_GEOMETRY_SHOW, handleShowResultAndGeometrySelection);
  yield takeEvery(EWorkspaceQueryActionType.SELECTION_RESULT_TOGGLE_VISIBILITY, handleToggleSelectionResultVisibility);
}

function* handleToggleSelectionResultVisibility(action) {
  const selectionId = action.data;
  const shownSelectionIds = yield select(getShownSelectionIds);
  const isShown = shownSelectionIds.includes(selectionId);
  if (isShown) {
    yield put({ type: EWorkspaceQueryActionType.SHOWN_SELECTION_GEOMETRY_REMOVE, data: selectionId });
  } else {
    yield put({ type: EWorkspaceQueryActionType.SELECTION_RESULT_AND_GEOMETRY_SHOW, data: selectionId });
  }
}

function* handleShowResultAndGeometrySelection(action) {
  const selectionId = action.data;
  yield put({
    type: EWorkspaceQueryActionType.SELECTION_RESULT_SHOW,
    data: { selectionId },
  });
  const { showElements, show2DElements } = MikeVisualizerLib;
  const selectionGeometryIds: Array<string> = yield select(getSelectionGeometryIds);
  const geomId = getSelectionGeometryId(selectionId);
  const labelId = getSelectionLabelId(selectionId);
  const hasRun = selectionGeometryIds.includes(geomId);
  if (hasRun) {
    showElements([geomId]);
    show2DElements([labelId]);
    yield put({
      type: EWorkspaceQueryActionType.SHOWN_SELECTION_GEOMETRY_ADD,
      data: selectionId,
    });
  }
}

function* handleShowSelectionResult(action) {
  const { selectionId } = action.data;
  const { showElement } = MikeVisualizerLib;
  const { workspaceId } = yield select(getRouteParams);
  const selectionResultIds: Array<string> = yield select(getSelectionResultIds);
  const hasRun = selectionResultIds.includes(selectionId);
  if (hasRun) {
    showElement(selectionId);
  } else {
    try {
      yield call(WorkspaceQueryProxy.executeQuery, workspaceId, selectionId);
      yield put({
        type: 'toast/ADD/SUCCESS',
        toast: { text: t('SELECTION_SUBMITTED') },
      });
    } catch (error) {
      yield put({
        type: 'toast/ADD/ERROR',
        toast: { text: t('SELECTION_RESULT_SUBMIT_FAILED') },
      });
    }
  }
}
