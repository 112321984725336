export enum EMapDataTypes {
  NONE = 'none',
  SATELLITE = 'satellite',
  STREETS = 'streets',
  TERRAIN = 'terrain',
  ELEVATION = 'elevation',
}

export const MAP_DATA_TYPES = {
  NONE: EMapDataTypes.NONE,
  SATELLITE: EMapDataTypes.SATELLITE,
  STREETS: EMapDataTypes.STREETS,
  TERRAIN: EMapDataTypes.TERRAIN,
  ELEVATION: EMapDataTypes.ELEVATION,
};
