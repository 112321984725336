import { IMmgConfiguration } from 'src/managers/ConfigurationManager';
import { IGlobalState } from '../store';

/**
 * Gets the configuration received from the api
 * @param state
 */
export const getAppConfiguration = (state: IGlobalState): IMmgConfiguration => state.AppReducer.configuration;

/**
 * Gets the basic route params for  a workspace (projectId + workspaceId) as stored in the AppReducer
 * This helps removing the need to nest components under a route to access params (HoC).
 * This does not include params of sub-routes.
 * @param state
 */
export const getWorkspaceRouteParams = (state: IGlobalState): { projectId?: string; workspaceId?: string } =>
  state.AppReducer.routeParams || {};
