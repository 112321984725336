import { put, takeEvery, call, select } from 'redux-saga/effects';
import { HttpErrorHandler } from 'src/managers/http-utils';
import { IOpenDataProvider } from 'src/models/IOpenDataProvidersApi';
import { IWorkspace } from 'src/models/IWorkspaces';
import { OpenDataProvidersProxy } from 'src/proxies/OpenDataProvidersProxy';
import { IGlobalState } from 'src/store';
import { getWorkspace } from 'src/store/selectors/WorkspaceSelectors';

import { WorkspaceActionType } from '../store/WokspaceActionType';

const getOpenDataProviders = (state: IGlobalState): Array<IOpenDataProvider> => {
  return state.WorkspaceOpenDataReducer.providers;
};

export default function* watchWorkspaceOpenData() {
  yield takeEvery(WorkspaceActionType.OPENDATA_PROVIDERS_LOAD, handleLoadOpenDataProviders);
  yield takeEvery(WorkspaceActionType.OPENDATA_PROVIDERS_EXTRACT, handleExtractOpenData);
}

export function* handleLoadOpenDataProviders() {
  const workspace: IWorkspace = yield select(getWorkspace);

  if (workspace && workspace.id) {
    try {
      yield put({
        type: WorkspaceActionType.OPENDATA_PROVIDERS_LOADING,
        providersLoading: true,
      });

      const response = yield call(OpenDataProvidersProxy.getOpenDataProviders, workspace.id);

      if (response && response.status === 200 && response.data) {
        yield put({
          type: WorkspaceActionType.OPENDATA_PROVIDERS_SET,
          providers: response.data,
        });
      }
    } catch (error) {
      HttpErrorHandler('Failed to fetch open data providers.', error);
    } finally {
      yield put({
        type: WorkspaceActionType.OPENDATA_PROVIDERS_LOADING,
        providersLoading: false,
      });
    }
  }
}

export function* handleExtractOpenData(action) {
  const workspace: IWorkspace = yield select(getWorkspace);
  const { openDataExtraction } = action;
  if (workspace && workspace.id) {
    try {
      yield call(OpenDataProvidersProxy.extractData, workspace.id, openDataExtraction);
    } catch (error) {
      HttpErrorHandler('Failed to extract open data.', error);
    }
  }
}
