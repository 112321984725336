/**
 * Exposes utility methods for variableicons.
 *
 * @module VariableIconUtils
 * @version 1.0.0
 */
import React from 'react';

import { ReactComponent as Variable } from '@mike/mike-shared-frontend/media/icons/Variable';
import { ReactComponent as Gridded } from '@mike/mike-shared-frontend/media/icons/Gridded';
import { ReactComponent as RandomPoint } from '@mike/mike-shared-frontend/media/icons/RandomPoint';
import { ReactComponent as Mystery } from '@mike/mike-shared-frontend/media/icons/Mystery';

import { ITEM_TYPES } from 'models/IWorkspaceData';
import { IWorkspaceEnrichedVariable } from 'models/IVariables';

const getIcon = (variable: IWorkspaceEnrichedVariable) => {
  const iconName = getIconName(variable);
  return getIconByName(iconName);
};

const getIconName = (variable: IWorkspaceEnrichedVariable) => {
  if (!variable) {
    return '';
  }

  if ('isGrid' in variable) {
    return variable.isGrid ? ITEM_TYPES.GRIDDED : ITEM_TYPES.SCATTER;
  }

  return variable.itemType;
};

const getIconByName = (iconName: string): React.ReactNode => {
  // todo hevo We should handle iconType. Thewrefor we keep it as a parameter even though it is not used yet
  switch (iconName) {
    case ITEM_TYPES.VARIABLE:
      return <Variable />;

    case ITEM_TYPES.ELEVATION:
      return <Variable />;

    case ITEM_TYPES.GRIDDED:
      return <Gridded />;

    case ITEM_TYPES.SCATTER:
      return <RandomPoint />;

    default: {
      console.debug(`No variable icon found for ${iconName} `);
      return <Mystery />;
    }
  }
};

const self = {
  getIcon,
  getIconName,
  getIconByName,
};

export default self;
