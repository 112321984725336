import { FeatureCollection } from 'geojson';
import tesselate from '@turf/tesselate';
import { polygon } from '@turf/helpers';
import { flatten } from 'lodash-es';

import vtk from 'vtk.js/Sources/vtk';
import MikeVisualizerVtkWriter from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/converters/MikeVisualizerVtkWriter';

const { createVtkObject } = MikeVisualizerVtkWriter;

/**
 * Util that can allows converting GeoJSON data to vtkObjects, that can be visualized in 3D.
 *
 * @module MikeVisualizerGeojsonConverter
 * @version 1.0.0
 */

/**
 * Converts geojson polygon features to vtk polygonal data.
 * TODO dan: this should eventually be extended for all types of geojson geometries, not just polygons.
 *
 * @example
 * ```
  const data = convertGeojsonToVtp({ type: 'FeatureCollection', features: [...] });
  appendData(
    data,
    'id',
    [120 / 255, 120 / 255, 120 / 255, 0.8],
    [221 / 255, 221 / 255, 221 / 255, 0.8],
    { edgeVisibility: false, representation: 2 }
  ); // Adds it to the viewer
 * ```
 *
 * @param featureCollection
 *
 * @returns { vtkObject } An instance of a vtk object that can be i.e. added to the viewer via `appendData()`.
 */
export const convertGeojsonToVtp = (featureCollection: FeatureCollection<any, any>) => {
  const points = [];
  const offsets = [];

  let pointIndex = 0;

  for (const feature of featureCollection.features) {
    if (feature.geometry.type !== 'Polygon') {
      console.warn(
        `Skipped ${feature.geometry.type} feature when converting geojson to vtp. Only Polygon types are supported.`,
      );
      continue;
    }

    const featurePoints = self._getFeaturePoints(feature.geometry.coordinates[0]);

    if (!featurePoints) {
      continue;
    }

    for (const item of featurePoints) {
      const currentOffsetValue = offsets[offsets.length - 1] || 0;
      const numberOfPoints = item.points.length / 3;

      offsets.push(currentOffsetValue + item.offset);
      points.push(...item.points);

      pointIndex = pointIndex + numberOfPoints;
    }
  }

  const connectivity = Array.from(Array(points.length / 3).keys()).map((index) => index);

  const vtkObject = vtk(
    createVtkObject('vtkPolyData', NaN, [], [], points, undefined, undefined, undefined, offsets, connectivity),
  );

  return vtkObject;
};

/**
 * Creates an object with vtk points and offsets from a geojson geometry's coordinates.
 * This object can be used to build up vtk offsets and connectivity.
 *
 * @param coordinates
 *
 * @private
 */
const _getFeaturePoints = (
  coordinates: Array<Array<number>>,
): Array<{
  points: Array<number>;
  offset: number;
}> => {
  try {
    // Coordinates must not contain z values
    const coords = coordinates
      .filter((coord: Array<number>) => coord.length > 1)
      .map((coord: Array<number>) => [coord[0], coord[1]]);

    const meshed = tesselate(polygon([coords]));

    const polygonCoordinates = meshed.features.reduce((acc, cur) => {
      return [...acc, ...[cur.geometry.coordinates[0].map(([x, y]) => [x, y, 0])]];
    }, []);

    return [...polygonCoordinates].map((points) => {
      return { points: flatten(points), offset: points.length };
    });
  } catch (error) {
    console.error(`Failed to get feature points for coordiantes ${coordinates}`, error);
  }

  return [];
};

const self = {
  _getFeaturePoints,
  convertGeojsonToVtp,
};

export default self;
