import { IGlobalState } from 'src/store';

export const hiddenGeometryIds = (state: IGlobalState) => state.WorkspaceGeometryReducer.userHiddenGeometryIds;

export const hiddenVariableIds = (state: IGlobalState) => state.WorkspaceVariableReducer.userHiddenVariableIds;

export const hiddenMeshIds = (state: IGlobalState) => state.WorkspaceMeshReducer.userHiddenMeshIds;

export const getSelectionResultIds = (state: IGlobalState) => state.WorkspaceQueryReducer.selectionResultIds;

export const getSelectionGeometryIds = (state: IGlobalState) => state.WorkspaceQueryReducer.selectionGeometryIds;

export const getShownSelectionIds = (state: IGlobalState) => state.WorkspaceQueryReducer.shownSelectionIds;

export const getSelectionLabels = (state: IGlobalState) => state.WorkspaceQueryReducer.selectionLabels;

export const getRouteParams = (state: IGlobalState) =>
  state.AppReducer.routeParams as { projectId: string; workspaceId: string };

export const getTenantId = (state: IGlobalState) => state.UserReducer.user.tenantId;

export const getTenantName = (state: IGlobalState) => state.UserReducer.user.tenantName;

export const getUserId = (state: IGlobalState) => state.UserReducer.user.id;
