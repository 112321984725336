import { all } from 'redux-saga/effects';
import geometrySagas from 'src/geometries/sagas/geometrySagas';
import meshSagas from 'src/meshes/sagas/meshSagas';
import variableSagas from 'src/variables/sagas/variableSagas';
import watchProjects from 'src/projects/sagas/projectsSagas';
import workspaceSagas from 'src/workspaces/sagas/workspaceSagas';
import { watchProject, watchProjectContent } from 'src/project-datasets/sagas/projectContent';
import watchIAM from 'src/iam/sagas/iam';

/**
 * Exposes all available sagas
 *
 * Combines sagas from different domains such as projects, workspaces etc
 *
 * todo hevo: consider if aný of the other root saga patterns is better?:  https://redux-saga.js.org/docs/advanced/RootSaga.html
 */
export default function* rootSaga() {
  yield all([
    ...watchProjects(),
    ...workspaceSagas(),
    ...meshSagas(),
    ...geometrySagas(),
    ...variableSagas(),
    ...watchProject(),
    ...watchProjectContent(),
    ...watchIAM(),
  ]);
}
