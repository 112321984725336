import { IProjection } from '../../models/IProjections';

import { EProjectionActionType } from './ProjectionActionType';

export const loadProjectionSystems = () => ({
  type: EProjectionActionType.LOAD_PROJECTION_SYSTEMS,
});

export const loadingProjectionSystems = (loading = true) => ({
  type: EProjectionActionType.LOADING_PROJECTION_SYSTEMS,
  data: loading,
});

export const setProjectionSystems = (coordinateSystems: IProjection[]) => ({
  type: EProjectionActionType.SET_PROJECTION_SYSTEMS,
  data: coordinateSystems,
});

export const getProjectionSystemsById = (id: number) => ({
  type: EProjectionActionType.GET_PROJECTION_SYSTEMS_BY_ID,
  data: id,
});

export const filterProjectionSystems = (filter: string) => ({
  type: EProjectionActionType.FILTER_PROJECTION_SYSTEMS,
  data: filter,
});

export const setSelectedProjectionSystems = (coordinateSystems: IProjection[]) => ({
  type: EProjectionActionType.SET_SELECTED_PROJECTION_SYSTEMS,
  data: coordinateSystems,
});
