import { EWorkspaceActionType } from '../actions/WorkspaceActionType';

const initialState = {
  exportableMeshIds: [], // holds ids of meshes allowed to be exported by the api
  exportableMeshesLoading: false,
  exportableMeshesLoadingFailed: false,

  exportableGeometryIds: [], // holds ids of Geometries allowed to be exported by the api
  exportableGeometriesLoading: false,
  exportableGeometriesLoadingFailed: false,

  exportableVariableIds: [], // holds ids of Variables allowed to be exported by the api
  exportableVariablesLoading: false,
  exportableVariablesLoadingFailed: false,
};

/**
 * Workspace Export Reducer.
 * - returns new states for matched workspace export actions.
 *
 * @name WorkspaceExportReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case 'workspace/export/RESET_EXPORTABLE_MESHES':
      return {
        ...state,
        exportableMeshIds: [],
        exportableMeshesLoading: false,
        exportableMeshesLoadingFailed: false,
      };

    case 'workspace/export/EXPORTABLE_MESHES_LOADING':
      return {
        ...state,
        exportableMeshesLoading: true,
        exportableMeshesLoadingFailed: false,
      };

    case 'workspace/export/LOAD_EXPORTABLE_MESHES_SUCCESS':
      return {
        ...state,
        exportableMeshIds: action.exportableMeshIds,
        exportableMeshesLoading: false,
        exportableMeshesLoadingFailed: false,
      };

    case 'workspace/export/LOAD_EXPORTABLE_MESHES_FAILED':
      return {
        ...state,
        exportableMeshesLoading: false,
        exportableMeshesLoadingFailed: true,
      };

    case 'workspace/export/RESET_EXPORTABLE_GEOMETRIES':
      return {
        ...state,
        exportableGeometryIds: [],
        exportableGeometriesLoading: false,
        exportableGeometriesLoadingFailed: false,
      };

    case 'workspace/export/EXPORTABLE_GEOMETRIES_LOADING':
      return {
        ...state,
        exportableGeometriesLoading: true,
        exportableGeometriesLoadingFailed: false,
      };

    case 'workspace/export/LOAD_EXPORTABLE_GEOMETRIES_SUCCESS':
      return {
        ...state,
        exportableGeometryIds: action.exportableGeometryIds,
        exportableGeometriesLoading: false,
        exportableGeometriesLoadingFailed: false,
      };

    case 'workspace/export/LOAD_EXPORTABLE_GEOMETRIES_FAILED':
      return {
        ...state,
        exportableGeometriesLoading: false,
        exportableGeometriesLoadingFailed: true,
      };

    case 'workspace/export/RESET_EXPORTABLE_VARIABLES':
      return {
        ...state,
        exportableVariableIds: [],
        exportableVariablesLoading: false,
        exportableVariablesLoadingFailed: false,
      };

    case 'workspace/export/EXPORTABLE_VARIABLES_LOADING':
      return {
        ...state,
        exportableVariablesLoading: true,
        exportableVariablesLoadingFailed: false,
      };

    case 'workspace/export/LOAD_EXPORTABLE_VARIABLES_SUCCESS':
      return {
        ...state,
        exportableVariableIds: action.exportableVariableIds,
        exportableVariablesLoading: false,
        exportableVariablesLoadingFailed: false,
      };

    case 'workspace/export/LOAD_EXPORTABLE_VARIABLES_FAILED':
      return {
        ...state,
        exportableVariablesLoading: false,
        exportableVariablesLoadingFailed: true,
      };

    /** Handle general actions */
    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
};
