const initialState = {
  routeParams: {},
  route: {},
  routeHash: null,
  routeName: null,

  configuration: null,
  breadcrumbs: [],
  filter: '',

  applicationError: '', // The kind of error that prevents the application from being used at all: i.e. auth error.
  applicationErrorTitle: '', // A short title or error code, i.e. 401
  applicationErrorDesc: '', // A one-line description of the error
  applicationErrorDebugText: '', // Extra debug information, typically retrieved from a sever response
  capabilities: null,
};

/**
 * App Reducer.
 * - returns new states for matched app actions.
 *
 * @name AppReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case 'app/router/NEW_ROUTE':
      return {
        ...state,
        routeParams: action.routeParams || {},
        route: action.route,
        routeHash: action.routeHash,
        routeName: action.routeName,
      };

    case 'app/SET_CONFIGURATION': {
      return {
        ...state,
        configuration: action.configuration,
      };
    }

    case 'app/error/SET': {
      return {
        ...state,
        applicationError: action.applicationError,
        applicationErrorTitle: action.applicationErrorTitle,
        applicationErrorDesc: action.applicationErrorDesc,
        applicationErrorDebugText: action.applicationErrorDebugText,
      };
    }

    case 'app/tenant/SET_CAPABILITIES': {
      console.log('SET_CAPABILITIES');
      return {
        ...state,
        capabilities: action.capabilities,
      };
    }

    case 'app/filter/SET': {
      return {
        ...state,
        filter: action.filter,
      };
    }

    case 'app/error/CLEAR': {
      return {
        ...state,
        applicationError: initialState.applicationError,
        applicationErrorTitle: initialState.applicationErrorTitle,
        applicationErrorDesc: initialState.applicationErrorDesc,
        applicationErrorDebugText: initialState.applicationErrorDebugText,
      };
    }

    case 'app/breadcrumbs/CLEAR': {
      return {
        ...state,
        breadcrumbs: initialState.breadcrumbs,
      };
    }

    case 'app/breadcrumbs/SET': {
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };
    }

    default:
      return state;
  }
}
