import { uniq } from 'lodash-es';
import { EWorkspaceMeshActionType } from '../actions/WorkspaceMeshActionType';
import { IBounds, IMeshStreamInfo } from 'src/models/IWorkspaceNotifications';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EWorkspaceDataActionType } from '../actions/WorkspaceDataActionType';
import { WorkspaceActionType } from 'src/workspaces/store/WokspaceActionType';
import { IDataArrayStatistics } from 'src/statistics-react/mmg-mesh-statistics-container';

export const RANGEMIN = '_range_min';
export const RANGEMAX = '_range_max';
export const UNIQUEVALUES = '_unique';

const {
  hideElements,
  setRepresentationToElements,
  getState,
  deleteData,
  setColorToElements,
  updateElementsGradient,
  setPointSizeToElements,
  removeDataArray,
} = MikeVisualizerLib;

export interface IFieldStatistic {
  id: string;
  name?: string;
  value: string;
}

export interface IWorkspaceMeshTiles {
  itemId: string;
  bounds: IBounds;
  partIds: Array<string>;
  size: number;
  updated: string;
  isOverview: boolean;
}

const initialState: IWorkspaceMeshState = {
  meshTiles: {},
  currentViewBounds: [],
  hiddenMeshTiles: [],
  fieldStatistics: {},
  loadingFieldStatistics: false,
  dataStatistics: {},
  streamingIds: [],
};

export interface IWorkspaceMeshState {
  meshTiles: { [key: string]: Array<IWorkspaceMeshTiles> };
  currentViewBounds: Array<Array<number>>;
  hiddenMeshTiles: Array<string>;
  fieldStatistics: { [key: string]: IDataArrayStatistics };
  loadingFieldStatistics: boolean;
  dataStatistics: { [key: string]: IDataArrayStatistics };
  streamingIds: Array<string>;
}

/**
 * Workspace Mesh Reducer.
 * - returns new states for matched workspace mesh actions.
 *
 * @name WorkspaceMeshTilesReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IWorkspaceMeshState = initialState, action) {
  switch (action.type) {
    case EWorkspaceMeshActionType.ADD_STREAMING_ID: {
      return { ...state, streamingIds: [...state.streamingIds, action.data] };
    }
    case EWorkspaceMeshActionType.LOADING_TILED_MESH_FIELD_STATISTICS: {
      return { ...state, loadingFieldStatistics: action.data };
    }
    case EWorkspaceMeshActionType.SET_TILED_MESH_FIELD_STATISTICS: {
      const { fieldStatistics, dataArrays, meshId } = action.data;
      return {
        ...state,
        fieldStatistics: { ...state.fieldStatistics, [meshId]: fieldStatistics },
        dataStatistics: { ...state.dataStatistics, [meshId]: dataArrays },
      };
    }
    case EWorkspaceMeshActionType.CLEAR_TILED_MESH_FIELD_STATISTICS: {
      const itemId = action.data;
      const mtb = state.meshTiles[itemId];
      if (mtb) {
        let partIds = [];
        mtb.forEach((mt: IWorkspaceMeshTiles) => {
          partIds = partIds.concat(mt.partIds);
        });
        partIds.forEach((pid: string) => deleteData(pid));
      }

      const reducedMeshTiles = { ...state.meshTiles };
      delete reducedMeshTiles[itemId];
      const reducedFieldData = { ...state.fieldStatistics };
      delete reducedFieldData[itemId];
      const reducedDataStats = { ...state.dataStatistics };
      delete reducedDataStats[itemId];

      return {
        ...state,
        meshTiles: reducedMeshTiles,
        dataStatistics: reducedDataStats,
        fieldStatistics: reducedFieldData,
      };
    }
    case EWorkspaceDataActionType.UPDATE_ITEM_REPRESENTATION: {
      const { workspaceItemId, workspaceRepresentation, isTiled } = action.data;
      if (isTiled) {
        const existingBounds = state.meshTiles[workspaceItemId];
        if (existingBounds && existingBounds.length > 0) {
          let partIds = [];
          existingBounds.forEach((eb: IWorkspaceMeshTiles) => {
            if (!eb.isOverview) {
              partIds = partIds.concat(eb.partIds);
            }
          });
          const uniquePartIds = uniq(partIds);
          setRepresentationToElements(uniquePartIds, workspaceRepresentation);
        }
      }
      return state;
    }
    case EWorkspaceDataActionType.UPDATE_GRADIENT: {
      const {
        isTiled,
        workspaceGradientId,
        workspaceGradientAttributeName,
        workspaceGradientSettings,
        colorRange,
      } = action.data;
      if (isTiled) {
        // apply data range of whole mesh as colorMappingRange to all tiled mesh parts
        // colorRange: { colorMappingRange: newRange },
        const existingBounds = state.meshTiles[workspaceGradientId];
        if (existingBounds && existingBounds.length > 0) {
          let partIds = [];
          existingBounds.forEach((eb: IWorkspaceMeshTiles) => {
            if (!eb.isOverview) {
              partIds = partIds.concat(eb.partIds);
            }
          });
          const uniquePartIds = uniq(partIds);
          updateElementsGradient(uniquePartIds, workspaceGradientAttributeName, workspaceGradientSettings, colorRange);
        }
      }
      return state;
    }
    case EWorkspaceDataActionType.UPDATE_ITEM_COLOR: {
      const { elementId, workspaceDataEdgeColor, workspaceDataSurfaceColor, isTiled } = action.data;
      if (isTiled) {
        const existingBounds = state.meshTiles[elementId];
        if (existingBounds && existingBounds.length > 0) {
          let partIds = [];
          existingBounds.forEach((eb: IWorkspaceMeshTiles) => {
            if (!eb.isOverview) {
              partIds = partIds.concat(eb.partIds);
            }
          });
          const uniquePartIds = uniq(partIds);
          setColorToElements(uniquePartIds, workspaceDataEdgeColor, workspaceDataSurfaceColor);
        }
      }
      return state;
    }
    case EWorkspaceDataActionType.UPDATE_ITEM_POINTSIZE: {
      const { isTiled, workspaceItemId, pointSize } = action.data;
      if (isTiled) {
        const existingBounds = state.meshTiles[workspaceItemId];
        if (existingBounds && existingBounds.length > 0) {
          let partIds = [];
          existingBounds.forEach((eb: IWorkspaceMeshTiles) => {
            if (!eb.isOverview) {
              partIds = partIds.concat(eb.partIds);
            }
          });
          const uniquePartIds = uniq(partIds);
          setPointSizeToElements(uniquePartIds, pointSize);
        }
      }
      return state;
    }
    case EWorkspaceDataActionType.REMOVE_GRADIENT: {
      const { isTiled, elementId } = action.data;
      if (isTiled) {
        const existingBounds = state.meshTiles[elementId];
        if (existingBounds && existingBounds.length > 0) {
          let partIds = [];
          existingBounds.forEach((eb: IWorkspaceMeshTiles) => {
            if (!eb.isOverview) {
              partIds = partIds.concat(eb.partIds);
            }
          });
          const uniquePartIds = uniq(partIds);
          updateElementsGradient(uniquePartIds, '');
        }
      }
      return state;
    }
    case EWorkspaceActionType.CLOSE: {
      return { ...state, ...initialState };
    }
    case EWorkspaceMeshActionType.SET_VIEW_BOUNDS: {
      return { ...state, currentViewBounds: action.data };
    }

    case EWorkspaceMeshActionType.ADD_MESH_TILE_BOUNDS: {
      const streamInfo: IMeshStreamInfo = action.data.streamInfo;
      const mtb: IWorkspaceMeshTiles = {
        ...streamInfo,
        partIds: action.data.partIds,
        size: action.data.size,
        isOverview: streamInfo.meshStreamRequestReturnType === 0,
      };
      const existingBounds = state.meshTiles[mtb.itemId];
      const withNewBounds = existingBounds ? [...existingBounds, mtb] : [mtb];
      return {
        ...state,
        meshTiles: { ...state.meshTiles, [mtb.itemId]: withNewBounds },
        streamingIds: state.streamingIds.filter((id: string) => id !== streamInfo.itemId),
      };
    }

    case EWorkspaceMeshActionType.INIT_HIDDEN_MESH_TILES: {
      const meshIds = action.data;
      return { ...state, hiddenMeshTiles: uniq(meshIds) };
    }

    case EWorkspaceMeshActionType.HIDE_MESH_TILES: {
      const ids = action.data;
      const meshIds = ids.filter((id: string) => !state.hiddenMeshTiles.includes(id));
      let partIds = new Array<string>();
      meshIds.forEach((meshId: string) => {
        const existingBounds = state.meshTiles[meshId];
        if (existingBounds) {
          existingBounds.forEach((eb: IWorkspaceMeshTiles) => {
            if (eb.partIds) {
              partIds = partIds.concat(eb.partIds);
            }
          });
        }
      });
      if (partIds && partIds.length > 0) {
        const { hiddenElementIds } = getState();
        hideElements(uniq(hiddenElementIds.concat(partIds).concat(ids)));
      }
      return { ...state, hiddenMeshTiles: uniq(state.hiddenMeshTiles.concat(ids)) };
    }

    case EWorkspaceMeshActionType.SHOW_MESH_TILES: {
      const ids = action.data;
      const meshIds = ids.filter((id: string) => state.hiddenMeshTiles.includes(id));
      return { ...state, hiddenMeshTiles: uniq(state.hiddenMeshTiles.filter((id: string) => !meshIds.includes(id))) };
    }
    case WorkspaceActionType.SOCKET_DATA_ATTRIBUTE_DELETED: {
      const { itemId, propertyName } = action.data;
      const fieldStats = state.fieldStatistics[itemId];
      if (fieldStats) {
        const mtb = state.meshTiles[itemId];
        if (mtb) {
          let partIds = [];
          mtb.forEach((mt: IWorkspaceMeshTiles) => {
            partIds = partIds.concat(mt.partIds);
          });
          partIds.forEach((pid: string) => removeDataArray(pid, propertyName));
        }
        const reducedDataStats = { ...state.dataStatistics };
        const dataStatsForItem = reducedDataStats[itemId];
        if (dataStatsForItem) {
          const reduced = dataStatsForItem.filter(({ id }) => id !== propertyName);
          return {
            ...state,
            dataStatistics: { ...state.dataStatistics, itemId: reduced },
          };
        }
      }
      return state;
    }
    case WorkspaceActionType.SOCKET_DATA_DELETED: {
      const itemId = action.data;
      const mtb = state.meshTiles[itemId];
      if (mtb) {
        let partIds = [];
        mtb.forEach((mt: IWorkspaceMeshTiles) => {
          partIds = partIds.concat(mt.partIds);
        });
        partIds.forEach((pid: string) => deleteData(pid));
      }

      const reducedMeshTiles = { ...state.meshTiles };
      delete reducedMeshTiles[itemId];
      const reducedFieldData = { ...state.fieldStatistics };
      delete reducedFieldData[itemId];
      const reducedDataStats = { ...state.dataStatistics };
      delete reducedDataStats[itemId];

      return {
        ...state,
        meshTiles: reducedMeshTiles,
        fieldStatistics: reducedFieldData,
        dataStatistics: reducedDataStats,
      };
    }

    case EWorkspaceMeshActionType.REMOVE_FIRST_MESH_TILE_BOUNDS: {
      const itemId = action.data; // optional
      const meshTileIds = itemId ? [itemId] : Object.keys(state.meshTiles);
      if (meshTileIds.length === 0) {
        return state;
      }
      let reducedMeshTiles = { ...state.meshTiles };
      meshTileIds.forEach((id: string) => {
        const mtb = state.meshTiles[id];
        if (mtb && mtb.length > 1) {
          const mt = mtb.filter((m: IWorkspaceMeshTiles) => !m.isOverview);
          if (mt && mt.length > 1) {
            // Release first mesh bounds and all mesh parts which are not in remaining bounds
            const firstMeshTileBounds = mtb[0];
            const remainingBounds = mtb.slice(1);
            let remainingPartIds = [];
            remainingBounds.forEach((rmtb: IWorkspaceMeshTiles) => {
              remainingPartIds = remainingPartIds.concat(rmtb.partIds);
            });
            const uniqueRemainingPartIds = uniq(remainingPartIds);
            const partIdsToRelease = firstMeshTileBounds.partIds.filter(
              (pid: string) => !uniqueRemainingPartIds.includes(pid),
            );
            if (partIdsToRelease.length > 0) {
              partIdsToRelease.forEach((pid: string) => deleteData(pid));
            }
            reducedMeshTiles = { ...reducedMeshTiles, [id]: remainingBounds };
          }
        }
      });
      return {
        ...state,
        meshTiles: reducedMeshTiles,
      };
    }

    default:
      return state;
  }
}
