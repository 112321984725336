import { EViewerCursorActionType } from '../actions/ViewerCursorActionType';

const initialState = {
  viewerWorking: false,
  viewerWaiting: false,
  viewerCrosshair: false,
};

/**
 * Viewer Cursor Reducer.
 * - returns new states for matched viewer cursor actions. Based on these states, the viewer should update the cursor style.
 *
 * @name ViewerCursorReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case EViewerCursorActionType.SET_WORKING:
      return {
        ...state,
        viewerWorking: true,
      };

    case EViewerCursorActionType.UNSET_WORKING:
      return {
        ...state,
        viewerWorking: false,
      };

    case EViewerCursorActionType.SET_WAITING:
      return {
        ...state,
        viewerWaiting: true,
      };

    case EViewerCursorActionType.UNSET_WAITING:
      return {
        ...state,
        viewerWaiting: false,
      };

    case EViewerCursorActionType.SET_CROSSHAIR:
      return {
        ...state,
        viewerCrosshair: true,
      };

    case EViewerCursorActionType.UNSET_CROSSHAIR:
      return {
        ...state,
        viewerCrosshair: false,
      };

    default:
      return state;
  }
}
