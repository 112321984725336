import { ENotificationActionType } from '../actions/NotificationActionType';
import { IAction } from '../actions/Action';
import { INotification } from '@mike/mike-shared-frontend/mike-notification-list/INotification';

export interface INotificationState {
  hideBlueDot: boolean;
  notifications: INotification[];
}

const initState: INotificationState = {
  hideBlueDot: true,
  notifications: Array<INotification>(),
};

export default function(state: INotificationState = initState, action: IAction<INotification[] | boolean | null>) {
  switch (action.type) {
    case ENotificationActionType.HIDE_BLUE_DOT:
      return { ...state, hideBlueDot: action.data };
    case ENotificationActionType.SET_NOTIFICATION:
      return {
        ...state,
        notifications: action.data,
        hideBlueDot: action.data === null,
      };
    default:
      return state;
  }
}
