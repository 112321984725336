/**
 * Exposes project REST endpoints to managers.
 * Note: the mesh Front-end API proxies these requests to WDP (metadata service).
 *
 * @module ProjectDatasetProxy
 * @version 2.0.0
 * @requires Proxy
 *
 */
import { endpoints, paths } from 'proxies/config';
import Proxy from './Proxy';

const { project, dataset } = paths;

/**
 * Get project datasets.
 *
 * @param projectId
 */
export const getProjectDatasets = (projectId: string) =>
  Proxy.http.get(`${endpoints.meshAppService}/${project}/${projectId}/${dataset}`);
