// TODO heveo: Currently these also exists in IWorkspaceQueries. Should only live in one place,
export enum EAttributeDataTypes {
  INT32 = 'Int32',
  DOUBLE = 'Double',
  STRING = 'String',
}

export const ATTRIBUTE_DATA_TYPES = {
  INT32: EAttributeDataTypes.INT32,
  DOUBLE: EAttributeDataTypes.DOUBLE,
  STRING: EAttributeDataTypes.STRING,
};

export interface IWorkspaceAttribute {
  name: string;
  dataType: EAttributeDataTypes;
  range?: Array<number>;
}

export interface IWorkspaceStringAttributeValue {
  value: string;
  count: number;
}

export interface IWorkspaceNumberAttributeValue {
  min: number;
  max: number;
  count: number;
}

export interface IWorkspaceOptionAttributeValue {
  value: string | number;
  displayName: string;
}
