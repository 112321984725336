import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { EProgressItemsActionType } from '../actions/ProgressItemsActionType';
import { IAction } from '../actions/Action';

export interface IProgressState {
  progressItems: IProgressItem[];
}

const initState = Array<IProgressItem>();

const addProgressItem = (state: IProgressItem[], action: IAction<IProgressItem>) => {
  const _state = [action.data, ...state];
  return _state;
};
const updateProgressItem = (state: IProgressItem[], action: IAction<IProgressItem>, ix: number) => {
  state[ix] = action.data;
  return [...state];
};

const getMatchIx = (state: IProgressItem[], item: IProgressItem) => {
  let ix = -1;
  state.forEach((entry, i) => {
    if (entry.id === item.id) ix = i;
  });
  return ix;
};

export default function(state = initState, action: IAction<IProgressItem>) {
  switch (action.type) {
    case EProgressItemsActionType.UPDATE_OR_ADD_PROGRESS_ITEM: {
      const jx = getMatchIx(state, action.data);
      if (jx > -1) {
        return updateProgressItem(state, action, jx);
      } else {
        return addProgressItem(state, action);
      }
    }

    case EProgressItemsActionType.REMOVE_PROGRESS_ITEM: {
      const rmState = state.filter((item) => {
        return item.id !== action.data.id;
      });
      return [...rmState];
    }

    default:
      return state;
  }
}
