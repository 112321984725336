import React from 'react';
import { css } from 'emotion';
import { t } from 'src/translations/i18n';

import { ReactComponent as Projects } from '@mike/mike-shared-frontend/media/icons/Projects';
import { IProject } from 'models/IProject';

// style for project name cell, icon + text, align and truncate long names
const SubProjectNameCellStyle = css`
  cursor: pointer;
  display: block;
  margin-top: 12px;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// colums array for projects table,
export const columns = [
  {
    id: 'name',
    type: 'link',
    label: t('PROJECTS_TABLE_COLUMN_NAME_LABEL'),
    sortable: true,
    render(_row: IProject, value: React.ReactNode) {
      return (
        <>
          <span>
            <Projects />
          </span>
          <span className={SubProjectNameCellStyle}>{value}</span>
        </>
      );
    },
    filtrable: true,
  },
  {
    id: 'description',
    label: t('PROJECTS_TABLE_COLUMN_DESCRIPTION_LABEL'),
    filtrable: true,
  },
  {
    id: 'createdAt',
    label: t('PROJECTS_TABLE_COLUMN_CREATED_AT_LABEL'),
    sortable: true,
    type: 'date',
  },
  {
    id: 'updatedAt',
    label: t('PROJECTS_TABLE_COLUMN_UPDATED_AT_LABEL'),
    sortable: true,
    type: 'date',
  },
];

export const tableConfig = {
  pagination: true,
  defaultSort: {
    field: 'updatedAt',
    dir: 'desc',
    type: 'date',
  },
};
