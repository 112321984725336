import { EGeometryItemTypes } from 'models/IGeometries';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import { EMapToolActionType } from '../actions/MapToolActionType';

/**
 * Defines wich geometry tools are allowed by default and which are not.
 */
const defaultAllowedTools = {
  geometrySpatialSelectionAllowed: false,
};

const initialState: IGeometryToolState = {
  ...defaultAllowedTools,
  mapToolsSupportedByProjection: true, // Map projection is assumed to support map tools by default
  geometrySpatialSelectionTargetId: null,
  geometryEditItemType: null,
  geometryEditTargetId: null,
  geometryEditLoading: false,
  geometryEditLoadFailed: false,
};

export interface IGeometryToolState {
  geometrySpatialSelectionAllowed: boolean;
  mapToolsSupportedByProjection: boolean;
  // The target of the geometry spatial selection.
  geometrySpatialSelectionTargetId: string;
  // State related to editing geometries.
  geometryEditItemType: EGeometryItemTypes;
  geometryEditTargetId: string;
  geometryEditLoading: boolean;
  geometryEditLoadFailed: boolean;
}

/**
 * Returns a full state object with all the tools not supported by projection being disallowed.
 * Eventually this will hide the tools completely in the toolbar.
 *
 * @param state - GeometryToolState
 */
const disallowNotSupportedToolsOnState = (state: IGeometryToolState): IGeometryToolState => {
  console.debug('NB: Geometry Map tools not supported.');
  return {
    ...state,
    //  tools relying on projection
    geometrySpatialSelectionAllowed: false,
  };
};

/**
 * Geometry Map Tool Reducer.
 * - returns new states for matched Geometry Map tool message actions. They are typically related to drawing, editing, selections, etc.
 *
 * @name GeometryMapToolReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case EWorkspaceActionType.CLOSE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case EMapToolActionType.RESET_ALLOWED_TOOLS: {
      const nextState = {
        ...state,
        ...defaultAllowedTools,
      };
      // disallow those not supported by projection
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(nextState);
      }
      return nextState;
    }

    case 'maptools/ALLOW/MAPTOOLS': {
      return {
        ...state,
        mapToolsSupportedByProjection: true,
      };
    }

    case EMapToolActionType.DISALLOW_MAPTOOLS: {
      return {
        ...disallowNotSupportedToolsOnState(state),
        mapToolsSupportedByProjection: false,
      };
    }

    case EMapToolActionType.GEOMETRY_ALLOW_SPATIAL_SELECTION:
      if (!state.mapToolsSupportedByProjection) {
        return disallowNotSupportedToolsOnState(state);
      }
      return {
        ...state,
        geometrySpatialSelectionAllowed: state.mapToolsSupportedByProjection,
      };

    case EMapToolActionType.GEOMETRY_SET_SPATIAL_SELECTION_TARGET:
      return {
        ...state,
        geometrySpatialSelectionTargetId: action.geometrySpatialSelectionTargetId,
      };

    case EMapToolActionType.GEOMETRY_EDIT:
      return {
        ...state,
        geometryEditItemType: action.data.geometryEditItemType,
        geometryEditTargetId: action.data.geometryEditTargetId,
      };

    case EMapToolActionType.GEOMETRY_EDIT_LOAD:
      return {
        ...state,
        geometryEditLoading: true,
        geometryEditLoadFailed: false,
      };

    case EMapToolActionType.GEOMETRY_EDIT_LOAD_FAILED:
      return {
        ...state,
        geometryEditLoading: false,
        geometryEditLoadFailed: true,
      };

    case EMapToolActionType.GEOMETRY_EDIT_LOAD_SUCCESS:
      return {
        ...state,
        geometryEditLoading: false,
        geometryEditLoadFailed: false,
      };

    case EMapToolActionType.GEOMETRY_UNLOAD: {
      return {
        ...state,
        ...initialState,
      };
    }

    default:
      return state;
  }
}
