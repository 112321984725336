import { store } from 'store/store';
import FeatureFlags from 'src/app/feature-flags';
import { getContext } from 'managers/application-insights';
import { stripIndents } from 'common-tags';
import { apiVersion } from 'proxies/config';
import appVersion from './appVersion';

declare global {
  interface Window {
    __mmbAppVersion: { [key: string]: string };
    _paq: any;
  }
}
// Set the Git info globally for debugging:
window.__mmbAppVersion = appVersion;

/**
 * Gathers useful debug information.
 * Used for: display of configuration in the console, support email body, etc.
 *
 * Typical outpit:
 *
 * - General app info, i.e.:
 *  api: 1.0.342.0
 *  algorithms: 0.9.218.0
 *  vtk: 1.0.7.0
 *  environment: dev
 *  clientVersion: 1.7.0
 *
 * - Route info, i.e.:
 *  workspaceId: <guid>
 *  projectId: <guid>
 *
 * - Feature flags
 */
export const getDebugProperties = (): { [key: string]: string } => {
  const { AppReducer, UserReducer } = store.getState();
  const routeProperties = AppReducer.routeParams || {};
  const appInsightsContext = getContext() || {}; // NB: context is not available in the dev env.
  const userId = UserReducer.user ? UserReducer.user.id : 'N/A';
  const operationId = appInsightsContext.operation ? appInsightsContext.operation.id : 'N/A';
  const otherConfigurationProperties = {
    operationId,
    userId,
    environment: AppReducer.configuration ? AppReducer.configuration.environment : '',
    clientVersion: process.env.VERSION,
    vtkJsVersion: process.env.VTK_JS_VERSION,
    olVersion: process.env.OL_VERSION,
    mikeSharedVersion: process.env.MIKE_SHARED_VERSION,
    reactVersion: process.env.REACT_VERSION,
    apiEndpointVersion: apiVersion,
  };

  const configurationProperties = AppReducer.configuration
    ? Object.keys(AppReducer.configuration.versionInfo).reduce(
        (acc, cur) => {
          const value = AppReducer.configuration.versionInfo[cur];

          return {
            ...acc,
            [cur]: value,
          };
        },
        { ...otherConfigurationProperties },
      )
    : { ...otherConfigurationProperties };

  return {
    ...configurationProperties,
    ...routeProperties,
    ...FeatureFlags,
  };
};

/**
 * Logs debug information to the console (*not* application insights).
 */
export const logDebugProperties = () => {
  // Makes it possible to see the versions of api, algorithm etc in developer tools
  console.info(
    `%cConfiguration%c${JSON.stringify(getDebugProperties(), null, '  ').replace(/(,|"|\{|\})/g, '')}`,
    'background-color: black; font-weight: bold; color: white',
    'background-color: #cbffcf; font-weight: bold; color: green',
  );
  console.table(appVersion);
};

/**
 * Gets debug properties as formatted text.
 */
export const getDebugPropertiesAsText = () => {
  return stripIndents`
    ${JSON.stringify(getDebugProperties(), null, ' ').replace(/(,|"|\{|\})/g, '')}
    Requested at ${Date.now()} from ${window.location.href}.
  `;
};
