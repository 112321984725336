import { IWorkspace, IWorkspaceBounds, IWorkspaceOverview } from 'src/models/IWorkspaces';
import { WorkspaceActionType } from 'src/workspaces/store/WokspaceActionType';
import { EWorkspaceActionType } from '../actions/WorkspaceActionType';
import MikeVisualizer from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { IViewerBounds } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';

export const RECENT_WORKSPACES_KEY = 'recentlyOpened';
export const RECENT_WORKSPACES_BOUNDS = 'recentBounds';

export interface IWorkspaceState {
  workspace: IWorkspace;
  workspaceLoading: boolean;
  workspaceLoadingError: boolean;
  workspaces: Array<IWorkspaceOverview>;
  workspaceListLoading: boolean;
  workspaceListLoadingError: boolean;
  workspaceCreating: boolean;
  workspaceCreateError: boolean;
  workspaceBoundingBox: Array<number> | null;
  highlightedWorkspaceElementId: string | null;
  recentWorkspaces: Array<IWorkspaceOverview>;
  loadingRecentWorkspaces;
  workspaceUpdating: boolean;
  isGroupingSelections: boolean;
  isSnapshotDialogOpen: boolean;
  hoveringLayer: Array<any>;
}

const initialState: IWorkspaceState = {
  workspace: null as IWorkspace,
  workspaceLoading: false,
  workspaceLoadingError: false,
  workspaces: [],
  workspaceListLoading: false,
  workspaceListLoadingError: false,
  workspaceCreating: false,
  workspaceCreateError: false,
  workspaceBoundingBox: null,
  highlightedWorkspaceElementId: null,
  recentWorkspaces: [],
  loadingRecentWorkspaces: false,
  workspaceUpdating: false,
  isGroupingSelections: false,
  isSnapshotDialogOpen: false,
  hoveringLayer: [],
};

/**
 * Workspace Reducer.
 * - returns new states for matched workspace actions.
 *
 * @name WorkspaceReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default function(state: IWorkspaceState = initialState, action): IWorkspaceState {
  switch (action.type) {
    case WorkspaceActionType.LOADING_RECENTS: {
      return { ...state, loadingRecentWorkspaces: action.data };
    }
    case WorkspaceActionType.SET_RECENTS: {
      return { ...state, recentWorkspaces: action.data, loadingRecentWorkspaces: false };
    }
    case 'workspace/LOAD': {
      const { loading = true, loadingError = false } = action;
      return {
        ...state,
        workspaceLoading: loading,
        workspaceLoadingError: loadingError,
      };
    }
    case 'workspace/SET_SNAPSHOT_DIALOG_OPEN': {
      return {
        ...state,
        isSnapshotDialogOpen: true,
      };
    }
    case 'workspace/SET_LAYER_HOVERING_ON': {
      return {
        ...state,
        hoveringLayer: [action.id],
      };
    }
    case 'workspace/SET_LAYER_HOVERING_OFF': {
      return {
        ...state,
        hoveringLayer: [],
      };
    }
    case 'workspace/SET_SNAPSHOT_DIALOG_CLOSE': {
      return {
        ...state,
        isSnapshotDialogOpen: false,
      };
    }

    case 'workspace/SET_GROUPING_SELECTION': {
      const { isGrouping } = action;
      return {
        ...state,
        isGroupingSelections: isGrouping,
      };
    }

    case WorkspaceActionType.WORKSPACE_UPDATING: {
      const { updating = true } = action;
      return {
        ...state,
        workspaceUpdating: updating,
      };
    }

    case WorkspaceActionType.WORKSPACE_LOADING: {
      return {
        ...state,
        workspaceLoading: true,
      };
    }

    case WorkspaceActionType.WORKSPACE_LOAD_SUCCESS: {
      const { workspace: actionWorkspace } = action;
      const currentWorkspace = state.workspace;

      let nextWorkspace: IWorkspace;

      if (currentWorkspace && currentWorkspace.id === actionWorkspace.id) {
        const {
          datasets,
          geometries,
          meshes,
          variables,
          queries,
          operations,
          image,
          ...rest
        } = actionWorkspace as IWorkspace;

        // If a workspaceOverview is loaded after the full workspace, we do not want to override the datasets etc with undefined values.
        nextWorkspace = {
          ...rest,
          datasets: datasets || currentWorkspace.datasets,
          geometries: geometries || currentWorkspace.geometries,
          meshes: meshes || currentWorkspace.meshes,
          variables: variables || currentWorkspace.variables,
          queries: queries || currentWorkspace.queries,
          operations: operations || currentWorkspace.operations,
          image: image || currentWorkspace.image,
        };
      } else {
        nextWorkspace = actionWorkspace;
      }

      return {
        ...state,
        workspaceLoading: false,
        workspaceLoadingError: false,
        workspace: nextWorkspace,
        workspaceBoundingBox: getBoundingBox(nextWorkspace ? nextWorkspace.bounds : null), // todo hveo make selector instead ?
      };
    }

    case 'workspace/OVERVIEW_UPDATED': {
      const { workspaceOverview } = action;
      const currentWorkspace = state.workspace;

      let nextWorkspace: IWorkspace;

      if (currentWorkspace && currentWorkspace.id === workspaceOverview.id) {
        const { image, ...rest } = workspaceOverview as IWorkspaceOverview;

        // We patch the workspace, but do not want to override the image with undefined values if we had it already
        nextWorkspace = {
          ...currentWorkspace,
          ...rest,
          image: image || currentWorkspace.image,
        };
      } else {
        nextWorkspace = workspaceOverview;
      }

      return {
        ...state,
        workspace: nextWorkspace,
        workspaceBoundingBox: getBoundingBox(nextWorkspace ? nextWorkspace.bounds : null), // todo hveo make selector instead ?
      };
    }

    case 'workspace/LOAD_FAILED':
      return {
        ...state,
        workspaceLoading: false,
        workspaceLoadingError: true,
      };

    case 'workspace/CREATE':
      return {
        ...state,
        workspaceCreating: true,
        workspaceCreateError: false,
      };

    case 'workspace/CREATE_SUCCESS':
      return {
        ...state,
        workspaceCreating: false,
        workspaceCreateError: false,
      };

    case 'workspace/CREATE_FAILED':
      return {
        ...state,
        workspaceCreating: false,
        workspaceCreateError: true,
      };

    case 'workspaces/LIST':
      return {
        ...state,
        workspaces: [] as Array<IWorkspaceOverview>,
        workspaceListLoading: true,
        workspaceListLoadingError: false,
      };

    case 'workspaces/LIST_SUCCESS':
      return {
        ...state,
        workspaceListLoading: false,
        workspaceListLoadingError: false,
        workspaces: action.workspaces,
      };

    case 'workspaces/LIST_FAILED':
      return {
        ...state,
        workspaceListLoading: false,
        workspaceListLoadingError: true,
      };

    case 'workspace/NEW_BOUNDING_BOX':
      const bounds = getBoundingBox(action.workspaceBounds);
      const { resetCameraToBounds } = MikeVisualizer;
      resetCameraToBounds(bounds as IViewerBounds);
      return {
        ...state,
        workspaceBoundingBox: bounds,
      };

    case 'workspace/element/HIGHLIGHT':
      return {
        ...state,
        highlightedWorkspaceElementId: action.highlightedWorkspaceElementId,
      };

    case 'workspace/element/CLEAR_HIGHLIGHT':
      return {
        ...state,
        highlightedWorkspaceElementId: '',
      };

    case 'workspace/recent/ADD': {
      const newEntry = {
        id: action.recentWs.id,
        epsg: action.recentWs.epsgCode,
        timestamp: Date.now(),
        name: action.recentWs.name,
      };
      const recentWorkspaces = [newEntry, ...state.recentWorkspaces.filter((w) => w.id !== action.recentWs.id)];

      // Store recent workspaces in localStorage to use as initial state next time the app is loaded.
      localStorage.setItem(RECENT_WORKSPACES_KEY, JSON.stringify(recentWorkspaces));

      return {
        ...state,
        recentWorkspaces,
      };
    }

    case 'workspace/recent/DELETE': {
      const recentWorkspaces = state.recentWorkspaces.filter((w) => w.id !== action.workspaceId);

      // Store updated recent workspaces in localStorage (similar to 'workspace/recent/ADD').
      localStorage.setItem(RECENT_WORKSPACES_KEY, JSON.stringify(recentWorkspaces));

      return {
        ...state,
        recentWorkspaces,
      };
    }

    case EWorkspaceActionType.CLOSE: {
      const { recentWorkspaces, workspaces, workspaceListLoading, workspaceListLoadingError, ...rest } = initialState;
      return {
        ...state,
        ...rest,
      };
    }

    default:
      return state;
  }
}

const getBoundingBox = (bounds: IWorkspaceBounds) => {
  return bounds && bounds.length > 3 // bounds needs to include at least 4 values (xMin, xMax, yMin, yMax)
    ? [...bounds.slice(0, 4), 0, 0] // Z bounds are set to 0, because they seem to conflict with open layers' zoom. Zooming is done instead by centering workspace [xmin, ymin, xmax, ymax] coordinates, so zmin & zmax are not relevant & might clash with open layers zoom logic. Worth investgating if this can be prevented as part of MikeVisualizer, although it might make sense to set z bounds for 'pure' 3D visualizations :/
    : null;
};
