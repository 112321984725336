import { VIEWER_MODES } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';
import { DEFAULT_BASE_MAP } from 'src/workspaces/viewer/tools/viewer-tool-constants';
import { IViewerMode } from 'src/models/IViewMode';
import { LIGHTMAP_COLORS, DARKMAP_COLORS } from 'src/workspaces/viewer/viewer-utils';
import { EMapToolActionType } from '../actions/MapToolActionType';
import { IAction } from '../actions/Action';
import { EBASEMAP, EViewerMode, ISettingsMap } from 'src/models/IUserSettings';
import { EBaseMapIds } from '@mike/mike-shared-frontend/lab/mike-visualizer/MikeBaseMapConfigurations';

const initialState: IViewerMode = {
  // Viewer mode is typically related to tools; some tools are only allowed in 2D, other in 3D.
  // we initialize the viewer to 3D. Later when workspace loads, viewer mode will switch
  viewerMode: VIEWER_MODES.THREE_D,
  viewerBaseMapId: '',
  viewerBaseMapSupported: true,
  availableColors: LIGHTMAP_COLORS,
  showGridOverlay: false,
};

export interface IViewerModeAction extends Partial<IViewerMode>, IAction<any, EMapToolActionType> {}

const mapBaseMapId = (id?: EBASEMAP) => {
  switch (id) {
    case EBASEMAP.MAPBOXSATTELITE:
      return EBaseMapIds.MAPBOX_SATELLITE;
    case EBASEMAP.MAPTILERSTREET:
      return EBaseMapIds.MAPTILER_STREET;
    default:
      return EBaseMapIds.NONE;
  }
};

/**
 * Viewer Mode Reducer.
 * - returns new states for matched viewer mode actions. Controls 2d/3d selection and basemaps.
 *
 * @name ViewerModeReducer
 * @type { Reducer }
 * @memberof Store
 * @protected
 * @inheritdoc
 */
export default (state = initialState, action: IViewerModeAction) => {
  switch (action.type) {
    case EMapToolActionType.SET_VIEWER_MODE_USER_SETTINGS: {
      const map: ISettingsMap = action.data;
      if (!map) {
        return { ...state };
      } else {
        const basemapId = mapBaseMapId(map.backgroundMap);
        return {
          ...state,
          viewerMode: map.mode === EViewerMode.THREED ? VIEWER_MODES.THREE_D : VIEWER_MODES.TWO_D,
          viewerBaseMapId: basemapId,
          availableColors: basemapId === DEFAULT_BASE_MAP.id ? DARKMAP_COLORS : LIGHTMAP_COLORS,
        };
      }
    }

    case EMapToolActionType.SET_VIEWER_BASEMAP_SUPPORTED:
      return {
        ...state,
        viewerBaseMapSupported: !!action.viewerBaseMapSupported,
      };

    case EMapToolActionType.SET_VIEWER_MODE_TO_2D:
      return {
        ...state,
        viewerMode: VIEWER_MODES.TWO_D,
      };

    case EMapToolActionType.SET_VIEWER_MODE_TO_3D:
      return {
        ...state,
        viewerMode: VIEWER_MODES.THREE_D,
      };

    case EMapToolActionType.SET_VIEWER_MODE: {
      const { viewerMode } = action;
      return {
        ...state,
        viewerMode,
      };
    }

    case EMapToolActionType.SET_BASEMAP_TO_DEFAULT:
      return {
        ...state,
        viewerBaseMapId: DEFAULT_BASE_MAP.id,
        availableColors: DARKMAP_COLORS,
      };

    case EMapToolActionType.SET_BASEMAP: {
      const { viewerBaseMapId } = action;

      return {
        ...state,
        viewerBaseMapId: viewerBaseMapId || '',
        availableColors: viewerBaseMapId === DEFAULT_BASE_MAP.id ? DARKMAP_COLORS : LIGHTMAP_COLORS,
      };
    }

    case EMapToolActionType.ENABLE_POLYGON_DRAWING:
    case EMapToolActionType.ENABLE_POLYLINE_DRAWING:
    case EMapToolActionType.ENABLE_POINT_DRAWING:
    case 'maptools/ENABLE_ADD_COMMENT':
    case 'maptools/ENABLE_LINE_MEASUREMENT':
    case 'maptools/ENABLE_AREA_MEASUREMENT':
      return {
        ...state,
        viewerMode: VIEWER_MODES.TWO_D,
      };

    case 'maptools/ENABLE_3D_MAP_DATA':
    case 'maptools/ENABLE_3D_EXAGGARATION':
      return {
        ...state,
        viewerMode: VIEWER_MODES.THREE_D,
      };

    case EMapToolActionType.SET_GRIDOVERLAY:
      checkForActionMember('showGridOverlay', action, EMapToolActionType.SET_GRIDOVERLAY);
      return {
        ...state,
        showGridOverlay: action.showGridOverlay,
      };

    default:
      return state;
  }
};

export const checkForActionMember = (member: string, action: IAction<any>, name: string) => {
  if (typeof action[member] === 'undefined') {
    console.error(`Member '${member}' required for action '${name}'.`);
  }
};
