export const PANEL_CONTAINER_ID = 'panel-container';
export const ACTIVE_PANEL_CSS_ID = 'active-panel';

export const PANEL_IDS = {
  ELEMENTS: 'dataset-elements',
  MESHES: 'dataset-meshes',
  GEOMETRIES: 'dataset-geometries',
  VARIABLES: 'dataset-variables',
};

export const PANEL_TABS = {
  GEOMETRY_OVERVIEW_TAB: 'geometry-overview-tab',
  GEOMETRY_SELECTIONS_TAB: 'geometry-selections-tab',
  GEOMETRY_OPERATIONS_TAB: 'geometry-operations-tab',

  MESH_OVERVIEW_TAB: 'mesh-overview-tab',
  MESH_CONFIGURATION_TAB: 'mesh-configuration-tab',
  MESH_INTERPOLATION_TAB: 'mesh-interpolation-tab',
  MESH_SELECTIONS_TAB: 'mesh-selections-tab',
  MESH_QUERY_TAB: 'mesh-query-tab',

  VARIABLE_OVERVIEW_TAB: 'variable-overview-tab',

  MESH_INTERPOLATE_ELEVATION: 'mesh-interpolate-elevation',
  MESH_INTERPOLATE_VARIABLE: 'mesh-interpolate-variable',
  MESH_OPERATION_LIST_TAB: 'mesh-operation-list-tab',
  MESH_CONFIGURE_GEOM_TAB: 'mesh-configure-geom-tab',
};

export enum EElementCategories {
  GEOMETRY = 'geometry',
  VARIABLE = 'variable',
  MESH = 'mesh',
  QUERY = 'query', // is considered a vtk item like any other by the api. Not used as such in UI yet
}

export const ELEMENT_CATEGORIES = {
  GEOMETRY: EElementCategories.GEOMETRY,
  VARIABLE: EElementCategories.VARIABLE,
  MESH: EElementCategories.MESH,
  QUERY: EElementCategories.QUERY,
};
